import React, { useCallback, useMemo, useState } from 'react'
import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './InfoPreview.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ReactComponent as InfoIcon } from '@assets/images/info-icon.svg'
import { formatValue } from '../../helpers/helpers'

interface IProps {
  className?: string
  title: string
  value?: string | number
  type?: string
  isText?: boolean
}

const InfoPreview = ({ className, title, value, type, isText }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleOpenInfo = useCallback(
    async (event) => {
      if (!isText) {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl(event.currentTarget)
      }
    },
    [isText],
  )

  const handleClose = useCallback(
    (event) => {
      event.stopPropagation()
      setAnchorEl(null)
    },
    [setAnchorEl],
  )
  const isAmount = useMemo(() => type === 'amount', [type])

  const icon = <InfoIcon className={cn(styles.icon, className)} onClick={handleOpenInfo} />

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.isOpen]: isOpen,
      })}
    >
      {isText ? (
        <Tooltip title={title} arrow>
          {icon}
        </Tooltip>
      ) : (
        icon
      )}

      {!isText && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{ paper: styles.popover }}
        >
          <div className={styles.dataContainer}>
            <div className={styles.dataLabel}>{title}:</div>
            <div
              className={cn(styles.dataValue, {
                [genericSs.pricePrefix]: isAmount,
              })}
            >
              {isAmount ? '$' : ''} {formatValue({ value, type })}
            </div>
          </div>
        </Popover>
      )}
    </div>
  )
}

export default InfoPreview
