import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Link from '@mui/material/Link'
import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import cn from 'classnames'
import TableLoader from '../../Common/TableLoader'
import { Tooltip } from '@mui/material'
import styles from './BankAccountInformation.module.scss'
import genericSs from '@styles/generic.module.scss'
import Card from '../../Common/Card'
import { IBankAccount } from '@common/interfaces/bankAccount'
import Table from '../../Common/Table'
import TableBody from '../../Common/TableBody'
import TableCell from '../../Common/TableCell'
import TableContainer from '../../Common/TableContainer'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import { IBank } from '@common/interfaces/bank'
import { ReactComponent as MoneyIcon } from '../../../assets/images/money-icon-oulined.svg'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import AddButton from '../AddButton'
import TableFiltersRow from '../../Common/TableFiltersRow'
import { BANK_ACCOUNT_FILTERS_CONFIG } from '@common/constants/filters'
import FilterContainer from '../../Filters/FilterContainer'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../../helpers/filters'
import { debounceEventHandler } from '../../../helpers/helpers'
import { addHttp } from '@common/helpers/helpers'
import AddEditBankAccount from './AddEditBankAccount'
import { ILoadingData } from '../../../redux/types'
import SaveState from '../../Common/SaveState'
import { MenuIcon } from '../../Common/Icons'

const filtersDefaults = buildFiltersDefaults(BANK_ACCOUNT_FILTERS_CONFIG)
const filtersValidate = buildFiltersValidateSchema(BANK_ACCOUNT_FILTERS_CONFIG)

interface IProps {
  isLoading: boolean
  fundingBankAccountId: string
  banks: IBank[]
  bankAccounts: ILoadingData<{ data: IBankAccount[] }>
  createBank: (data: { name: string }) => void
  listBanks: () => void
  createBankAccount: (data: Partial<IBankAccount> & { clientId: string }) => void
  updateBankAccount: (id: string, data: Partial<IBankAccount>) => void
  deleteBankAccount: (id: string) => void
  listBankAccounts: (clientId: string, data: object) => void
  clientId: string
}

const BankAccountInformation = ({
  banks,
  listBanks,
  createBank,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  clientId,
  bankAccounts,
  fundingBankAccountId,
  listBankAccounts,
}: IProps) => {
  const [currentFundingAccount, setCurrentFundingAccount] = useState(fundingBankAccountId)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: 'bank_name',
    direction: 'ASC',
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  const handleClickMenu = useCallback((bankAccount, event: React.MouseEvent<HTMLElement>) => {
    setSelectedRow(bankAccount)
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setSelectedRow(null)
    setAnchorEl(null)
    setActionsMenuOpen(false)
    setIsEditModalShown(false)
  }, [])

  const handleFundingChange = useCallback(
    (newFundingAccount: string) => {
      currentFundingAccount && updateBankAccount(currentFundingAccount, { isFunding: false })
      newFundingAccount && updateBankAccount(newFundingAccount, { isFunding: true })

      setCurrentFundingAccount(newFundingAccount)
      handleCloseMenu()
    },
    [updateBankAccount, currentFundingAccount, handleCloseMenu],
  )

  const { isLoading, bankAccountsData, isSaving, isSaved } = useMemo(
    () => ({
      isLoading: bankAccounts.isLoading,
      isSaving: bankAccounts.isSaving,
      isSaved: bankAccounts.isSaved,
      bankAccountsData: bankAccounts?.data?.data || [],
    }),
    [bankAccounts],
  )

  useEffect(() => {
    setCurrentFundingAccount(fundingBankAccountId)
  }, [fundingBankAccountId])

  useEffect(() => {
    listBanks()
  }, [listBanks])

  const handleAddBank = useCallback((name: string) => createBank({ name }), [createBank])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const debounceListSummary = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        clientId && listBankAccounts(clientId, data)
      }, 500),
    [clientId, listBankAccounts],
  )

  useEffect(() => {
    debounceListSummary({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListSummary])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const handleDeleteBankAccount = useCallback(
    (id: string) => {
      deleteBankAccount(id)
      handleCloseMenu()
    },
    [deleteBankAccount, handleCloseMenu],
  )

  return (
    <Card noHeaderMargin>
      <Grid container>
        <Box flex={1}>
          <Card withBorder={false} noPadding noHeaderMargin className={styles.card}>
            <AddEditBankAccount
              isEditModalShown={isEditModalShown}
              setIsEditModalShown={setIsEditModalShown}
              handleAddEditBankAccount={!selectedRow ? createBankAccount : updateBankAccount}
              handleAddBank={handleAddBank}
              isAddModal={!selectedRow}
              selectedRow={selectedRow}
              handleCloseMenu={handleCloseMenu}
              banks={banks}
              clientId={clientId}
            />
            <Card withBorder={false} noPadding noHeaderMargin>
              <TableContainer className={styles.table}>
                <Form
                  onSubmit={handleFiltersChange}
                  validate={filtersValidate}
                  initialValues={filters}
                  mutators={{
                    setFieldData: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value)
                    },
                  }}
                  render={({ values, handleSubmit, form: { mutators } }) => (
                    <div className={styles.filtersHeader}>
                      <FilterContainer
                        filters={BANK_ACCOUNT_FILTERS_CONFIG}
                        handleSubmit={handleSubmit}
                        mutators={mutators}
                        values={values}
                        appliedFilters={filters}
                        title={`Bank Accounts`}
                        actions={
                          <AddButton
                            variant="outlined"
                            disabled={!clientId}
                            onClick={() => setIsEditModalShown(true)}
                          ></AddButton>
                        }
                      />
                    </div>
                  )}
                />

                <Table>
                  <TableHead>
                    <TableFiltersRow
                      filters={BANK_ACCOUNT_FILTERS_CONFIG}
                      orderBy={orderBy}
                      handleOrderChange={handleOrderChange}
                    />
                  </TableHead>
                  <TableBody>
                    {isLoading && <TableLoader columnsCount={6} />}
                    {bankAccountsData?.length > 0 &&
                      bankAccountsData.map((bankAccount: IBankAccount) => {
                        return (
                          <TableRow>
                            <TableCell className={genericSs.tableTextLeft}>
                              {fundingBankAccountId === bankAccount.id && <MoneyIcon />}
                              <Link
                                href={addHttp(bankAccount.bankAccountLink)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cn({
                                  [styles.link]: fundingBankAccountId !== bankAccount.id,
                                })}
                              >
                                {bankAccount.bankName}
                              </Link>
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {bankAccount.accountHolderName}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Tooltip
                                title={bankAccount.bankAccountNumber}
                                arrow
                                placement="top"
                                classes={{
                                  tooltip: styles.bankAccountNumberTooltip,
                                  arrow: styles.bankAccountNumberTooltipArrow,
                                }}
                                leaveDelay={1000}
                              >
                                <span>{`XXXXX${bankAccount.bankAccountNumber.slice(-4)}`}</span>
                              </Tooltip>
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {bankAccount.abaRoutingNumber}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {
                                <Tooltip
                                  title={bankAccount.purpose}
                                  arrow
                                  placement="top"
                                  classes={{
                                    tooltip: styles.bankAccountNumberTooltip,
                                    arrow: styles.bankAccountNumberTooltipArrow,
                                  }}
                                  leaveDelay={1000}
                                >
                                  <span>{bankAccount.purpose}</span>
                                </Tooltip>
                              }
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Box display="inline-box" ml={1}>
                                <MenuIcon
                                  onClick={(event) => handleClickMenu(bankAccount, event)}
                                  size="small"
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    <Menu
                      open={actionsMenuOpen}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      className={styles.actionsMenu}
                    >
                      <MenuItem
                        disabled={fundingBankAccountId === selectedRow?.id}
                        onClick={() => {
                          handleFundingChange(selectedRow.id)
                        }}
                      >
                        Set as funding account
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setIsEditModalShown(true)
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDeleteBankAccount(selectedRow.id)
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableBody>
                </Table>
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </TableContainer>
            </Card>
          </Card>
        </Box>
      </Grid>
    </Card>
  )
}

export default BankAccountInformation
