import React, { useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import { Form, FormSpy } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'

import styles from './GetStartedForm.module.scss'

import { IOPSReporting } from '@common/interfaces/prospects'
import InputLabel from '../../Common/InputLabel'
import TextField from '../../Common/TextField'
import Button from '../../Common/Button'
import { PhoneMaskInput } from '../../Common/MaskInput'
import { PHONE_VALIDATE_REGEXP } from '../../../constants/common'

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email(),
  phone: Yup.string().nullable(true).matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
})
const validate = makeValidate(schema)

interface IProps {
  isSaving: boolean
  opsReporting: IOPSReporting
  handleSubmit: (data: any) => void
  setIsDirty: (dirty: boolean) => void
}

const GetStartedForm = ({ isSaving, opsReporting, handleSubmit, setIsDirty }: IProps) => {
  const maskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])
  const initialValues = useMemo(
    () => ({
      email: opsReporting.clientIntakeInfo?.primaryContactEmail || '',
      firstName: opsReporting.clientIntakeInfo?.primaryContactFirstName || '',
      lastName: opsReporting.clientIntakeInfo?.primaryContactLastName || '',
      phone: opsReporting.clientIntakeInfo?.primaryContactPhone || null,
    }),
    [opsReporting],
  )

  const onFormChange = useCallback(
    (props: any) => {
      setIsDirty(props.dirty)
    },
    [setIsDirty],
  )

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={(props) => (
        <form className={styles.formWrapper}>
          <div className={styles.formContent}>
            <Box mb={2}>
              <InputLabel className={styles.inputLabel}>First name</InputLabel>
              <TextField
                name="firstName"
                placeholder="E.g., John"
                size="big"
                className={styles.inputField}
              />
            </Box>
            <Box mb={2}>
              <InputLabel className={styles.inputLabel}>Last name</InputLabel>
              <TextField
                name="lastName"
                placeholder="E.g., Smith"
                size="big"
                className={styles.inputField}
              />
            </Box>
            <Box mb={2}>
              <InputLabel className={styles.inputLabel}>Email address</InputLabel>
              <TextField
                name="email"
                placeholder="E.g., johnsmith@email.com"
                size="big"
                className={styles.inputField}
              />
            </Box>
            <Box mb={2}>
              <InputLabel className={styles.inputLabel}>Phone number</InputLabel>
              <TextField
                name="phone"
                InputProps={maskProps}
                placeholder="E.g., (123) 456-7890"
                size="big"
                className={styles.inputField}
              />
            </Box>
          </div>

          <div className={styles.buttonDivider} />

          <div className={styles.buttonWrapper}>
            <div />
            <Button
              className={styles.button}
              variant="contained"
              disabled={props.submitting || props.invalid}
              onClick={props.handleSubmit}
              isLoading={isSaving}
            >
              Next
            </Button>
          </div>
          <FormSpy subscription={{ dirty: true }} onChange={onFormChange} />
        </form>
      )}
    />
  )
}

export default GetStartedForm
