import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import cn from 'classnames'
import { IUser, USER_ROLE_LABEL, UserRole, UserStatus } from '@common/interfaces/user'
import { IClientInfo } from '@common/interfaces/client'
import TableLoader from '../../components/Common/TableLoader'

import styles from './UserManagementPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import Autocomplete from '../../components/Common/Autocomplete'
import Card from '../../components/Common/Card'
import Button from '../../components/Common/Button'
import Modal from '../../components/Common/Modal'
import Table from '../../components/Common/Table'
import TableRow from '../../components/Common/TableRow'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableCell from '../../components/Common/TableCell'
import TableContainer from '../../components/Common/TableContainer'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import AddButton from '../../components/Client/AddButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import WarningModal from '../../components/WarningModal'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import SaveState from '../../components/Common/SaveState'
import { dateToString, debounceEventHandler } from '../../helpers/helpers'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { USERS_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import FilterContainer from '../../components/Filters/FilterContainer'
import AddEditUser from './AddEditUser'
import SearchableList from '../../components/Common/SearchableList'
import { ILoadingData } from '../../redux/types'
import { MenuIcon } from '../../components/Common/Icons'
import useTable from '../../hooks/useTable'

const filtersValidate = buildFiltersValidateSchema(USERS_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(USERS_LIST_FILTERS_CONFIG)

const roles = [
  UserRole.ADMIN,
  UserRole.PORTFOLIO_ADMIN,
  UserRole.PORTFOLIO_USER,
  UserRole.BDO,
  UserRole.UW_USER,
]

const roleOptions = roles.map((role) => ({ value: role, label: USER_ROLE_LABEL[role] }))

interface IProps {
  clientId: string
  clients: ILoadingData<{ data: IClientInfo[] }>
  users: ILoadingData<{ data: IUser[] }>
  listClients: () => void
  listUsers: (params: object) => void
  createUser: (data: Partial<IUser>) => void
  updateUser: (id: string, data: Partial<IUser>) => void
  deleteUser: (id: string) => void
  inviteUser: (id: string) => void
  addOrRemoveManager: ({ clientIds, managerId }: { clientIds: string[]; managerId: string }) => void
  removeManager: ({ clientIds, managerId }: { clientIds: string[]; managerId: string }) => void
}

const UserManagementPage = ({
  clientId,
  clients,
  users,
  listClients,
  listUsers,
  createUser,
  updateUser,
  deleteUser,
  inviteUser,
  addOrRemoveManager,
}: IProps) => {
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUser>(null)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isAddModalShown, setIsAddModalShown] = useState(false)
  const [isEditClientModalShown, setIsEditClientModalShown] = useState(false)

  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)

  const { filters, handleFiltersChange, handleOrderChange, orderBy } = useTable({
    tableId: 'user',
    filtersDefaults,
    sortDefault: {
      field: 'email',
      direction: 'ASC',
    },
  })

  const handleClickMenu = useCallback((user, event: React.MouseEvent<HTMLElement>) => {
    setSelectedUser(user)
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setActionsMenuOpen(false)
    setAnchorEl(null)
    setIsEditModalShown(false)
    setIsAddModalShown(false)
  }, [])

  const handleAddModal = useCallback(() => {
    setIsAddModalShown(true)
  }, [])

  const { isLoading, usersData, isSaving, isSaved } = useMemo(
    () => ({
      isLoading: users.isLoading,
      isSaving: users.isSaving,
      isSaved: users.isSaved,
      usersData: users?.data?.data,
    }),
    [users],
  )

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  useEffect(() => {
    listClients()
  }, [listClients])

  useSetPageTitle('Users')

  const debounceListUsers = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        const { first_name, last_name, ...values } = data.filters

        const params = {
          roles: [
            UserRole.ADMIN,
            UserRole.PORTFOLIO_ADMIN,
            UserRole.PORTFOLIO_USER,
            UserRole.BDO,
            UserRole.UW_USER,
          ],
          ...data,
          filters: {
            ...values,
            firstName: first_name,
            lastName: last_name,
          },
        }

        if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
          params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
        }

        if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
          params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
        }

        listUsers(params)
      }, 500),
    [listUsers],
  )

  useEffect(() => {
    debounceListUsers({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListUsers])

  const handleEditUser = useCallback(() => {
    setIsEditModalShown(true)
  }, [])

  const handleEditClients = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleCreateUser = useCallback(
    async (data: any) => {
      setIsButtonLoading(true)
      createUser({ clientId, ...data })
      setIsButtonLoading(false)
      setIsEditModalShown(false)
    },
    [createUser, clientId],
  )

  const handleSaveUser = useCallback(
    async (data: any) => {
      setIsButtonLoading(true)
      await updateUser(data.id, data)
      setIsButtonLoading(false)
      setIsAddModalShown(false)
    },
    [updateUser],
  )

  const handleDeleteUser = useCallback(() => {
    setIsDeleteModalShow(true)
  }, [])

  const handleDeleteUserConfirm = useCallback(async () => {
    setIsButtonLoading(true)
    await deleteUser(selectedUser.id)
    setIsButtonLoading(false)
    setIsDeleteModalShow(false)
    handleCloseMenu()
  }, [deleteUser, selectedUser, handleCloseMenu])

  const handleDeleteUserCancel = useCallback(async () => {
    setIsDeleteModalShow(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleUserModalClose = useCallback(() => {
    setIsOpen(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleOpenEditClients = useCallback(() => {
    setIsEditClientModalShown(true)
    setIsOpen(false)
  }, [])

  const handleCloseEditClients = useCallback(() => {
    setIsEditClientModalShown(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleUserAssigned = useCallback(
    async (data: any) => {
      setIsButtonLoading(true)
      await addOrRemoveManager({
        managerId: selectedUser.id,
        clientIds: data.clients,
      })
      setIsButtonLoading(false)
      handleCloseEditClients()
    },
    [selectedUser, addOrRemoveManager, handleCloseEditClients],
  )

  const clientsOptions = useMemo(
    () =>
      (clientsData ?? []).map((client) => ({
        value: client.id,
        label: client.clientName,
      })),
    [clientsData],
  )

  const filtersConfig = useMemo(
    () =>
      USERS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'client'
            ? clientsData
              ? [
                  {
                    value: 'all',
                    label: 'All',
                  },
                  ...clientsData.map((client) => ({
                    value: client.clientName,
                    label: client.clientName,
                  })),
                ]
              : [
                  {
                    value: 'all',
                    label: 'All',
                  },
                ]
            : item.options,
      })),
    [clientsData],
  )

  const formClients = useMemo(() => {
    if (!selectedUser) {
      return []
    }

    const clientIds = selectedUser.clientInfoUsers.map((item) => {
      const client = clientsData?.find(({ id }) => id === item.clientInfo.id)
      if (client) {
        return { value: client.id, label: client.clientName }
      }
      return null
    })

    return clientIds.filter(Boolean)
  }, [selectedUser, clientsData])

  const editClients = useMemo(() => {
    if (!selectedUser) {
      return []
    }

    const clientIds = selectedUser.clientInfoUsers.map((item) => {
      const client = clientsData?.find(({ id }) => id === item.clientInfo.id)

      return client?.id
    })

    return clientIds.filter(Boolean)
  }, [selectedUser, clientsData])

  return (
    <>
      <Box py={1} pr={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card noHeaderMargin withBorder={false}>
              <TableContainer className={styles.tableContainer}>
                <Form
                  validate={filtersValidate}
                  onSubmit={handleFiltersChange}
                  initialValues={filters}
                  mutators={{
                    setFieldData: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value)
                    },
                  }}
                  render={({ values, handleSubmit, form: { mutators } }) => (
                    <div className={styles.filtersHeader}>
                      <FilterContainer
                        filters={filtersConfig}
                        handleSubmit={handleSubmit}
                        mutators={mutators}
                        values={values}
                        appliedFilters={filters}
                        actions={
                          <AddButton
                            type="button"
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={handleAddModal}
                          ></AddButton>
                        }
                      />
                    </div>
                  )}
                />
                <Table>
                  <TableHead>
                    <TableFiltersRow
                      filters={filtersConfig}
                      orderBy={orderBy}
                      handleOrderChange={handleOrderChange}
                    />
                  </TableHead>
                  <TableBody>
                    {isLoading && <TableLoader columnsCount={filtersConfig.length} height={26} />}
                    {usersData?.length > 0 &&
                      usersData.map((item, index) => {
                        //Disables allowing any non-internal users to Atlas.
                        const disabled = item.email.split('@')?.pop() !== 'dwightfund.com'

                        return (
                          <TableRow key={index}>
                            <TableCell className={cn(styles.textCell, genericSs.tableTextLeft)}>
                              <span>{item.firstName}</span>
                            </TableCell>
                            <TableCell className={cn(styles.textCell, genericSs.tableTextLeft)}>
                              <span>{item.lastName}</span>
                            </TableCell>
                            <TableCell className={cn(styles.textCell, genericSs.tableTextLeft)}>
                              <span>{item.email}</span>
                            </TableCell>
                            <TableCell className={cn(styles.textCell, genericSs.tableTextLeft)}>
                              <span>{USER_ROLE_LABEL[item.role]}</span>
                            </TableCell>
                            <TableCell className={genericSs.textLeft}>
                              {item.status === UserStatus.Active ? (
                                <span className={cn(genericSs.activeTag, styles.userManagementTab)}>
                                  Active
                                </span>
                              ) : item.isInvited ? (
                                <span className={cn(genericSs.yellowTag, styles.userManagementTab)}>
                                  Invitation sent
                                </span>
                              ) : !disabled ? (
                                <Button
                                  variant="text"
                                  color="primary"
                                  onClick={() => inviteUser(item.id)}
                                  className={cn(genericSs.inlineButton, styles.inviteButton)}
                                >
                                  Send invite
                                </Button>
                              ) : null}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {disabled && (
                                <div
                                  className={cn(genericSs.blueTag, styles.userManagementTab, {
                                    [genericSs.ineligibleCategory]: ![
                                      UserRole.PORTFOLIO_ADMIN,
                                      UserRole.PORTFOLIO_USER,
                                    ].includes(item.role),
                                  })}
                                >
                                  {[UserRole.PORTFOLIO_ADMIN, UserRole.PORTFOLIO_USER].includes(
                                    item.role,
                                  ) ? (
                                    <>
                                      <span>
                                        {item.clientInfoUsers?.length ?? 0} active clients
                                      </span>
                                    </>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </div>
                              )}
                            </TableCell>
                            <TableCell>
                              <Box display="inline-box" ml={1}>
                                <MenuIcon
                                  onClick={(event) => handleClickMenu(item, event)}
                                  size="small"
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    <Menu
                      open={actionsMenuOpen}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      className={styles.actionsMenu}
                    >
                      {[UserRole.PORTFOLIO_ADMIN, UserRole.PORTFOLIO_USER].includes(
                        selectedUser?.role,
                      ) && (
                        <MenuItem
                          onClick={
                            selectedUser?.clientInfoUsers?.length
                              ? handleEditClients
                              : handleOpenEditClients
                          }
                        >
                          Edit assigned clients
                        </MenuItem>
                      )}
                      {selectedUser?.status !== UserStatus.Active && selectedUser?.isInvited && (
                        <MenuItem
                          onClick={() => {
                            inviteUser(selectedUser.id)
                            handleCloseMenu()
                          }}
                        >
                          Resend invitation
                        </MenuItem>
                      )}
                      <MenuItem onClick={handleEditUser}>Edit</MenuItem>

                      <MenuItem onClick={handleDeleteUser}>Delete</MenuItem>
                    </Menu>
                  </TableBody>
                </Table>
                <Grid container justifyContent={'flex-end'}>
                  <SaveState isSaving={isSaving} isSaved={isSaved} />
                </Grid>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={isOpen}
        onCancel={handleUserModalClose}
        size="small"
        title={selectedUser?.firstName + "'s clients"}
      >
        <SearchableList
          data={formClients}
          handleSubmit={handleOpenEditClients}
          submitTitle="Edit"
        ></SearchableList>
      </Modal>

      <Modal
        open={isEditClientModalShown}
        onCancel={handleCloseEditClients}
        size="small"
        title={'Edit ' + selectedUser?.firstName + "'s clients"}
      >
        <Form
          initialValues={{ clients: editClients }}
          onSubmit={handleUserAssigned}
          render={({ handleSubmit }) => (
            <>
              <div className={styles.clientsTitle}>Client(s)</div>
              <Autocomplete
                name="clients"
                // @ts-ignore
                multiple
                placeholder="Search"
                clearIcon={null}
                options={clientsOptions}
                getOptionValue={(option) => option.value}
                openOnFocus={true}
                limitTags={-1}
                autocompleteSize={'allTags'}
              />
              <Button
                className={styles.submitModalButton}
                small={false}
                variant="text"
                color="primary"
                onClick={handleSubmit}
                isLoading={isButtonLoading}
              >
                Save
              </Button>
            </>
          )}
        />
      </Modal>

      <AddEditUser
        isEditModalShown={isEditModalShown}
        setIsEditModalShown={setIsEditModalShown}
        handleCreateAddUser={handleSaveUser}
        roleOptions={roleOptions}
        handleCloseMenu={handleCloseMenu}
        user={selectedUser}
        isButtonLoading={isButtonLoading}
      />
      <AddEditUser
        isEditModalShown={isAddModalShown}
        setIsEditModalShown={setIsAddModalShown}
        handleCreateAddUser={handleCreateUser}
        handleCloseMenu={handleCloseMenu}
        roleOptions={roleOptions}
        isButtonLoading={isButtonLoading}
      />

      {isDeleteModalShow && (
        <WarningModal
          warningMessage="Deleting this record will be permanent."
          onConfirm={handleDeleteUserConfirm}
          onCancel={handleDeleteUserCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isButtonLoading}
        />
      )}
    </>
  )
}

export default UserManagementPage
