import React, { useMemo } from 'react'
import cn from 'classnames'

import styles from './ChangedValue.module.scss'
import { formatAmount } from '../../helpers/helpers'
import Tooltip from '@mui/material/Tooltip'
import { formatPrice } from '../../helpers/helpers'

interface IProps {
  className?: string
  changedValue?: number
  isPrice?: boolean
  isPercent?: boolean
  isChip?: boolean
}

const ChangedValue = ({ className, changedValue, isPrice = true, isChip, isPercent }: IProps) => {
  const roundedValue = useMemo(() => {
    return changedValue && Math.round(changedValue * 100) / 100
  }, [changedValue])

  const value = useMemo(
    () =>
      isChip
        ? `${isPrice ? '$ ' : ''}${
            isPrice ? formatAmount(Math.abs(roundedValue)) : Math.abs(roundedValue)
          }${isPercent ? '%' : ''}`
        : `${isPrice ? '$ ' : ''}${
            isPrice ? formatPrice(Math.abs(roundedValue), 0) : Math.abs(roundedValue)
          }`,
    [isPrice, roundedValue, isChip, isPercent],
  )

  if (!roundedValue) {
    return null
  }

  return isChip ? (
    <Tooltip
      title={`${isPrice ? '$ ' : ''}${formatPrice(roundedValue)}${isPercent ? '%' : ''}`}
      placement="top"
      disableTouchListener
    >
      <span
        className={cn(styles.rootChip, className, {
          [styles.up]: roundedValue > 0,
        })}
      >
        {value}
      </span>
    </Tooltip>
  ) : (
    <span
      className={cn(styles.root, className, {
        [styles.up]: roundedValue > 0,
        [styles.down]: roundedValue < 0,
      })}
    >
      {value}
    </span>
  )
}

export default ChangedValue
