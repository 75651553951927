import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import Card from '../../components/Common/Card'
import ClientSetupHeader from '../../components/ClientSetupHeader'
import InventoryEligibleCustomRules from '../../components/Client/InventoryEligibleCustomRules'
import BBCInventoryIneligibleSKUTable from '../../components/BBCInventoryIneligibleSKUTable'
import BBCInventoryIneligibleFields from '../../components/BBCInventoryIneligibleFields'
import { CollateralTypes } from '@common/constants/client'
import ReservesTable from '../../components/ReservesTable'

const ClientSetupCollateralInventoryPage = () => {
  const [refreshCounter, setRefreshCounter] = useState(0)

  return (
    <Box py={1} pr={2}>
      <ClientSetupHeader />

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card noHeaderMargin>
              <InventoryEligibleCustomRules />
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card noHeaderMargin>
              <BBCInventoryIneligibleSKUTable isClientPage setRefreshCounter={setRefreshCounter} />
            </Card>
          </Grid>

          <Grid item xs={12}>
            <BBCInventoryIneligibleFields isEligibleTable refreshCounter={refreshCounter} />
          </Grid>

          <Grid item xs={12}>
            <ReservesTable isClientPage type={CollateralTypes.inventory} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ClientSetupCollateralInventoryPage
