import React from 'react'
import MuiSnackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertColor } from '@mui/material/Alert'

import styles from './Snackbar.module.scss'
import { ReactComponent as ErrorIcon } from '../../../assets/images/alert-snackbar.svg'
import { ReactComponent as SuccessIcon } from '../../../assets/images/alert-success-snackbar.svg'
interface IProps {
  notification: {
    isOpen: boolean
    type: AlertColor
    code: string
    message: string
    messagesList: string[]
    anchorOrigin: SnackbarOrigin
    autoHideDuration: number | null
    noShowMessage?: boolean
  }
  onClose: () => void
  onCloseOneMessage: (notification: { messagesList: string[] }) => void
}

const Snackbar = ({ notification, onClose, onCloseOneMessage }: IProps) => {
  const { isOpen, type, message, messagesList, anchorOrigin, autoHideDuration, noShowMessage } =
    notification

  return (
    <MuiSnackbar
      open={isOpen}
      anchorOrigin={anchorOrigin}
      autoHideDuration={messagesList?.length > 1 ? null : autoHideDuration}
      onClose={onClose}
      className={styles.messagesList}
    >
      <div>
        {messagesList &&
          messagesList.length > 0 &&
          messagesList.map((messagesListItem) => (
            <MuiAlert
              key={messagesListItem?.replaceAll(' ', '_')}
              className={styles.snackbarError}
              severity={type}
              onClose={() =>
                onCloseOneMessage({
                  messagesList: messagesList?.filter((el) => el !== messagesListItem),
                })
              }
            >
              {messagesListItem}
            </MuiAlert>
          ))}
        {!noShowMessage && message && (
          <MuiAlert
            severity={type}
            onClose={onClose}
            className={
              type === 'error'
                ? styles.snackbarError
                : type === 'info'
                ? styles.snackbarInfo
                : styles.snackbarSuccess
            }
            variant="filled"
            icon={type === 'error' ? <ErrorIcon /> : type === 'info' ? null : <SuccessIcon />}
          >
            {message}
          </MuiAlert>
        )}
      </div>
    </MuiSnackbar>
  )
}

export default Snackbar
