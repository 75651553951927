import React, { useMemo } from 'react'

import { ROUTES } from '../../constants/routes'
import TabsWithRoutesMenu from '../Common/TabsWithRoutesMenu'
enum ClientSetupTabNames {
  LoanStructure = 'Loan structure',
  Collateral = 'Collateral',
  ItemsToMonitor = 'Items to monitor',
  Reporting = 'Reporting',
  BankAccounts = 'Bank accounts',
  Users = 'Users',
  ClientTermination = 'Termination',
  General = 'General',
}

const TABS_ROUTES = {
  [ClientSetupTabNames.LoanStructure]: [
    ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED,
    ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_TERM,
    ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_OVERADVANCE,
    ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_PARTICIPANTS,
  ],
  [ClientSetupTabNames.Collateral]: [
    ROUTES.CLIENT_SETUP_COLLATERAL_RECEIVABLE,
    ROUTES.CLIENT_SETUP_COLLATERAL_PAYABLE,
    ROUTES.CLIENT_SETUP_COLLATERAL_INVENTORY,
  ],
  [ClientSetupTabNames.ItemsToMonitor]: ROUTES.CLIENT_SETUP_ITEMS_TO_MONITOR,
  [ClientSetupTabNames.Reporting]: ROUTES.CLIENT_SETUP_REPORTING,
  [ClientSetupTabNames.BankAccounts]: ROUTES.CLIENT_SETUP_BANK_ACCOUNTS,
  [ClientSetupTabNames.Users]: [
    ROUTES.CLIENT_SETUP_USERS,
    ROUTES.CLIENT_SETUP_RELATIONSHIP_MANAGEMENT,
  ],
  [ClientSetupTabNames.ClientTermination]: ROUTES.CLIENT_SETUP_TERMINATION,
  [ClientSetupTabNames.General]: ROUTES.CLIENT_SETUP_GENERAL,
}

const MENU = {
  [ClientSetupTabNames.LoanStructure]: [
    {
      label: 'Asset based loan',
      route: ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED,
    },
    {
      label: 'Term loan',
      route: ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_TERM,
    },
    {
      label: 'Overadvance',
      route: ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_OVERADVANCE,
    },
    {
      label: 'Participants',
      route: ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_PARTICIPANTS,
    },
  ],
  [ClientSetupTabNames.Users]: [
    {
      label: 'Clients',
      route: ROUTES.CLIENT_SETUP_USERS,
    },
    {
      label: 'Relationship managers',
      route: ROUTES.CLIENT_SETUP_RELATIONSHIP_MANAGEMENT,
    },
  ],
  [ClientSetupTabNames.Collateral]: [
    {
      label: 'Receivable',
      route: ROUTES.CLIENT_SETUP_COLLATERAL_RECEIVABLE,
    },
    {
      label: 'Payable',
      route: ROUTES.CLIENT_SETUP_COLLATERAL_PAYABLE,
    },
    {
      label: 'Inventory',
      route: ROUTES.CLIENT_SETUP_COLLATERAL_INVENTORY,
    },
  ],
}

const ClientSetupTabs = ({ isAdminRightsRole }: { isAdminRightsRole: boolean }) => {
  const tabs = useMemo(() => {
    const tabs = { ...TABS_ROUTES }

    if (!isAdminRightsRole) {
      delete tabs[ClientSetupTabNames.ClientTermination]
    }

    return tabs
  }, [isAdminRightsRole])

  return <TabsWithRoutesMenu tabs={tabs} menu={MENU} />
}

export default ClientSetupTabs
