import React, { useMemo } from 'react'
import cn from 'classnames'

import styles from './Sortable.module.scss'

import { ReactComponent as SortIcon } from '../../../assets/images/filter-arrow-icon.svg'

interface IProps {
  className?: string
  name: string
  orderBy: {
    field: string
    direction: string
  }
  showSortIcon?: boolean
}

const Sortable = ({ className, name, orderBy, showSortIcon }: IProps) => {
  const direction = useMemo(
    () => (orderBy.field === name ? (orderBy.direction === 'DESC' ? 'desc' : 'asc') : 'sort'),
    [name, orderBy],
  )
  if (!showSortIcon && direction === 'sort') {
    return null
  }

  return (
    <div className={cn(className, styles.root)}>
      {direction !== 'sort' && (
        <SortIcon
          className={cn(styles.sortIcon, {
            [styles.sortIconUp]: direction === 'asc',
          })}
        />
      )}

      {direction === 'sort' && showSortIcon && (
        <>
          <SortIcon className={cn(styles.sortIcon, styles.sortIconUp)} />
          <SortIcon className={cn(styles.sortIcon)} />
        </>
      )}
    </div>
  )
}

export default Sortable
