import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import groupBy from 'lodash/groupBy'
import cn from 'classnames'

import styles from './DueDiligenceValidateDocumentsPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as PriorityIcon } from '@assets/images/priority-icon.svg'

import {
  DUE_DILIGENCE_DOCUMENT_REQUEST_STATUS_OPTIONS,
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD,
  DueDiligenceDocumentRequestStatus,
  IDueDiligence,
  IDueDiligenceDocumentRequest,
  IDueDiligenceDocumentRequestType,
} from '@common/interfaces/dueDiligence'
import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { BoxLink, ExpandDetailIcon, MenuIcon } from '../../components/Common/Icons'
import TableLoader from '../../components/Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { useParams } from 'react-router'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import SelectField from '../../components/Common/SelectField'
import FilterContainer from '../../components/Filters/FilterContainer'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { DUE_DILIGENCE_DOCUMENT_REQUEST_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import {
  debounceEventHandler,
  sortAndGroupDueDiligenceDocumentsRequests,
} from '../../helpers/helpers'
import WarningModal from '../../components/WarningModal'
import DueDiligenceValidateDocumentsAddModal from './DueDiligenceValidateDocumentsAddModal'
import ActiveToolbar from '../../components/ActiveToolbar'
import { ClientInfoStatus } from '@common/interfaces/client'
import DueDiligencePageHeader from '../../components/DueDiligencePageHeader'
import DueDiligenceCommentsModal from '../../components/DueDiligenceCommentsModal/DueDiligenceCommentsModal'
import DueDiligenceDocumentUpload from '../../components/DueDiligenceDocumentUpload/DueDiligenceDocumentUpload'
import AddButton from '../../components/Client/AddButton'
import useTable from '../../hooks/useTable'

const filtersValidate = buildFiltersValidateSchema(
  DUE_DILIGENCE_DOCUMENT_REQUEST_LIST_FILTERS_CONFIG,
)
const filtersDefaults = buildFiltersDefaults(DUE_DILIGENCE_DOCUMENT_REQUEST_LIST_FILTERS_CONFIG, {
  status: [
    DueDiligenceDocumentRequestStatus.NotStarted,
    DueDiligenceDocumentRequestStatus.InReview,
    DueDiligenceDocumentRequestStatus.ActionRequired,
  ],
})

const DueDiligenceClientDocumentRequestsRow = ({
  documentRequest,
  onUpdateDocumentRequestStatus,
  handleClickMenu,
  index,
  sectionIndex,
  activeItem,
  activeItems,
  handleSelectRow,
  readOnly,
}: {
  documentRequest: IDueDiligenceDocumentRequest
  onUpdateDocumentRequestStatus: (
    documentRequest: IDueDiligenceDocumentRequest,
    status: DueDiligenceDocumentRequestStatus,
    isPrevious?: boolean,
  ) => void
  handleClickMenu: (
    event: React.MouseEvent<HTMLElement>,
    documentRequest: IDueDiligenceDocumentRequest,
  ) => void
  index: number
  sectionIndex: number
  activeItem: number
  activeItems: number[]
  handleSelectRow: any
  readOnly: boolean
}) => {
  const handleChangeStatus = useCallback(
    ({ target: { value } }) => {
      onUpdateDocumentRequestStatus(documentRequest, value)
    },
    [documentRequest, onUpdateDocumentRequestStatus],
  )

  const rowIndex = useMemo(
    () => +`${sectionIndex + 1}${index.toString().padStart(4, '0')}`,
    [sectionIndex, index],
  )

  const isActiveRow = useMemo(() => activeItems.includes(rowIndex), [activeItems, rowIndex])
  const isCurrentActiveRow = useMemo(() => activeItem === rowIndex, [activeItem, rowIndex])

  const onRowClick = useCallback(
    (event) => {
      handleSelectRow(event, rowIndex)
    },
    [handleSelectRow, rowIndex],
  )

  const withoutFiles = useMemo(
    () => DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD.includes(documentRequest.type.type),
    [documentRequest],
  )

  return (
    <TableRow
      data-index={rowIndex}
      className={cn('activableRow', styles.pastClient, {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
      })}
      onClick={onRowClick}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <div className={styles.nameWrapper}>
          {documentRequest.type.isPriority && <PriorityIcon />}
          <div className={styles.name}>{documentRequest.type.name}</div>
          {!withoutFiles && documentRequest.files.length > 0 && (
            <div className={styles.count}>{documentRequest.files.length}</div>
          )}
        </div>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {!withoutFiles && (
          <BoxLink link={documentRequest.boxLink} title={documentRequest.type.name} size="small" />
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <SelectField
          className={cn(styles.status, {
            [styles.statusNotStarted]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.NotStarted,
            [styles.statusInReview]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.InReview,
            [styles.statusActionRequired]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.ActionRequired,
            [styles.statusCompleted]:
              documentRequest.status === DueDiligenceDocumentRequestStatus.Completed ||
              documentRequest.status === DueDiligenceDocumentRequestStatus.Waived,
          })}
          disabled={readOnly}
          name="status"
          useFinalForm={false}
          options={DUE_DILIGENCE_DOCUMENT_REQUEST_STATUS_OPTIONS}
          onChange={handleChangeStatus}
          value={documentRequest.status}
        />
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="flex" alignItems="center" gap={3}>
          <MenuIcon onClick={(event) => handleClickMenu(event, documentRequest)} size="small" />
        </Box>
      </TableCell>
    </TableRow>
  )
}

const DueDiligenceValidateDocumentsSection = ({
  header,
  documents,
  handleUpdateDocumentRequestStatus,
  handleClickMenu,
  sectionIndex,
  activeItem,
  activeItems,
  handleSelectRow,
  readOnly,
}: {
  header: string
  documents: IDueDiligenceDocumentRequest[]
  handleUpdateDocumentRequestStatus: (
    documentRequest: IDueDiligenceDocumentRequest,
    status: DueDiligenceDocumentRequestStatus,
    isPrevious?: boolean,
  ) => void
  handleClickMenu: (
    event: React.MouseEvent<HTMLElement>,
    documentRequest: IDueDiligenceDocumentRequest,
  ) => void
  handleDeleteDocumentRequest: (documentRequest: IDueDiligenceDocumentRequest) => void
  sectionIndex: number
  activeItem: number
  activeItems: number[]
  handleSelectRow: any
  readOnly: boolean
}) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  return (
    <React.Fragment>
      <TableRow>
        <TableCell className={cn(genericSs.tableTextLeft, styles.headingRow)} colSpan={3}>
          <ExpandDetailIcon onClick={toggleIsExpanded} isExpanded={isExpanded} />
          {header} ({documents.length})
        </TableCell>
      </TableRow>
      {isExpanded
        ? documents.map((item, index) => (
            <DueDiligenceClientDocumentRequestsRow
              key={item.id}
              documentRequest={item}
              onUpdateDocumentRequestStatus={handleUpdateDocumentRequestStatus}
              handleClickMenu={handleClickMenu}
              index={index}
              sectionIndex={sectionIndex}
              activeItem={activeItem}
              activeItems={activeItems}
              handleSelectRow={handleSelectRow}
              readOnly={readOnly}
            />
          ))
        : null}
    </React.Fragment>
  )
}

interface IProps {
  dueDiligenceInfo: IDueDiligence
  dueDiligenceDocumentRequests: ILoadingData<{ data: IDueDiligenceDocumentRequest[] }>
  dueDiligenceDocumentRequestsTypes: IDueDiligenceDocumentRequestType[]
  currentUserId: string
  show: (id: string) => void
  showDocumentRequestsTypes: (id: string) => void
  hideDocumentRequestsTypes: () => void
  showDocumentRequests: (id: string, params?: any) => Promise<void>
  hideDocumentRequests: () => void
  createDocumentRequest: (id: string, data: object) => Promise<any>
  updateDocumentRequest: (id: string, documentRequestId: string, data: object) => void
  updateDocumentsRequests: (id: string, data: object) => void
  deleteDocumentRequest: (id: string, documentRequestId: string) => Promise<void>
  deleteDocumentsRequests: (id: string, documentRequestIds: string[]) => Promise<void>
  createDocumentRequestComment: (
    documentRequestId: string,
    data: object,
    id?: string,
  ) => Promise<any>
  updateDocumentRequestComment: (
    documentRequestId: string,
    commentId: string,
    data: object,
    id?: string,
  ) => Promise<any>
  deleteDocumentRequestComment: (
    documentRequestId: string,
    commentId: string,
    id?: string,
  ) => Promise<any>
  uploadDocumentRequestsFiles: (data: FormData, id: string) => Promise<any>
  getDocumentRequestsSharedLink: (documentRequestId: string, id: string) => Promise<any>
  deleteFile: (ids: string[]) => Promise<any>
  putNotification: (notification: object) => void
  refreshCount: number
}

const DueDiligenceValidateDocumentsPage = ({
  dueDiligenceInfo,
  dueDiligenceDocumentRequests,
  dueDiligenceDocumentRequestsTypes,
  currentUserId,
  show,
  showDocumentRequestsTypes,
  hideDocumentRequestsTypes,
  showDocumentRequests,
  hideDocumentRequests,
  createDocumentRequest,
  updateDocumentRequest,
  updateDocumentsRequests,
  deleteDocumentRequest,
  deleteDocumentsRequests,
  createDocumentRequestComment,
  updateDocumentRequestComment,
  deleteDocumentRequestComment,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
  deleteFile,
  putNotification,
  refreshCount,
}: IProps) => {
  const history = useHistory()
  const { search }: { search: string } = useLocation()
  const { documentRequestId, isComment } = queryString.parse(search) as {
    documentRequestId: string
    isComment: string
  }
  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const {
    filters,
    handleFiltersChange,
    activeItem,
    activeItems,
    setActiveItems,
    handleSelectRow,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'validateDocuments',
    filtersDefaults,
    quickFilterDefault: 'Open Requests',
  })

  const [documentRequestForDelete, setDocumentRequestForDelete] =
    useState<IDueDiligenceDocumentRequest>(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)

  const handleClickMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, documentRequest: IDueDiligenceDocumentRequest) => {
      setSelectedDocumentRequest(documentRequest)
      setAnchorEl(event.currentTarget)
      setActionsMenuOpen(true)
    },
    [],
  )
  const handleCloseMenu = useCallback(() => {
    setSelectedDocumentRequest(null)
    setAnchorEl(null)
    setActionsMenuOpen(false)
  }, [])

  const activeDocumentRequests = useMemo(() => {
    if (!activeItems.length) {
      return []
    }
    const documents = Object.values(
      groupBy(dueDiligenceDocumentRequests?.data?.data || [], 'type.subStep'),
    )

    return activeItems
      .map((activeItem) => {
        const sectionIndex = +activeItem.toString().slice(0, -4) - 1
        const index = +activeItem.toString().slice(-4)

        return documents[sectionIndex]?.[index]
      })
      .filter(Boolean)
  }, [activeItems, dueDiligenceDocumentRequests])

  const { id } = useParams<{ id: string }>()

  useLoadInfo(id, dueDiligenceInfo, show)

  useEffect(() => {
    if (id) {
      showDocumentRequestsTypes(id)
    }
  }, [id, showDocumentRequestsTypes])

  useEffect(() => {
    return () => {
      hideDocumentRequestsTypes()
    }
  }, [hideDocumentRequestsTypes])

  useEffect(() => {
    return () => {
      hideDocumentRequests()
    }
  }, [hideDocumentRequests])

  const fetchDocumentRequests = useCallback(
    (params: any) => {
      if (id) {
        showDocumentRequests(id, params)
      }
    },
    [id, showDocumentRequests],
  )

  const refetchDocumentRequests = useCallback(() => {
    if (id) {
      showDocumentRequests(id, { filters, skipLoader: true })
    }
  }, [id, showDocumentRequests, filters])

  useEffect(() => {
    if (refreshCount) {
      refetchDocumentRequests()
    }
  }, [refetchDocumentRequests, refreshCount])

  const debounceFetchDocumentRequests = useMemo(
    () => debounceEventHandler(fetchDocumentRequests, 500),
    [fetchDocumentRequests],
  )

  useEffect(() => {
    debounceFetchDocumentRequests({
      filters,
    })
  }, [filters, debounceFetchDocumentRequests])

  const [selectedDocumentRequest, setSelectedDocumentRequest] =
    useState<IDueDiligenceDocumentRequest>(null)

  const { isLoading, isSaving, data } = useMemo(() => {
    const { companyBackground, team, financials, samples } =
      sortAndGroupDueDiligenceDocumentsRequests(dueDiligenceDocumentRequests?.data?.data || [])

    return {
      isLoading: dueDiligenceDocumentRequests.isLoading,
      isSaving: dueDiligenceDocumentRequests.isSaving,
      data: [
        {
          header: 'Company Background',
          documents: companyBackground,
        },
        {
          header: `${dueDiligenceInfo?.clientName} Team`,
          documents: team,
        },
        {
          header: 'Financials and Collateral',
          documents: financials,
        },
        {
          header: 'Third Party Testing - Samples',
          documents: samples,
        },
      ],
    }
  }, [dueDiligenceInfo, dueDiligenceDocumentRequests])

  const handleAddDocumentRequest = useCallback(() => {
    setIsAddModalOpen(true)
  }, [])

  const handleOpenUploadModal = useCallback(() => {
    setIsUploadModalOpen(true)
  }, [])

  const handleCloseUploadModal = useCallback(() => {
    setIsUploadModalOpen(false)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleAddDocumentRequestConfirm = useCallback(
    async (data: any) => {
      const result = await createDocumentRequest(id, {
        type: {
          ...data,
          fileType: data.fileType?.value || data.fileType,
          subStep: data.isPriority ? 'Priority' : data.subStep,
        },
      })
      if (!result?.error) {
        showDocumentRequestsTypes(id)
        setIsAddModalOpen(null)
      }
    },
    [id, createDocumentRequest, showDocumentRequestsTypes],
  )

  const handleAddDocumentRequestCancel = useCallback(() => {
    setIsAddModalOpen(null)
  }, [])

  const handleUpdateDocumentRequestStatus = useCallback(
    (
      documentRequest: IDueDiligenceDocumentRequest,
      status: DueDiligenceDocumentRequestStatus,
      isPrevious: boolean = false,
    ) => {
      if (readOnly) {
        return
      }
      if (
        activeDocumentRequests.length > 1 &&
        activeDocumentRequests.map(({ id }) => id).includes(documentRequest.id)
      ) {
        updateDocumentsRequests(id, {
          documentRequests: activeDocumentRequests.map((documentRequest) => ({
            id: documentRequest.id,
            status: isPrevious
              ? documentRequest.status === DueDiligenceDocumentRequestStatus.Completed
                ? documentRequest.previousStatus ?? DueDiligenceDocumentRequestStatus.InReview
                : DueDiligenceDocumentRequestStatus.Completed
              : status,
          })),
        })
        setActiveItems([])
      } else {
        updateDocumentRequest(id, documentRequest.id, { status })
      }
    },
    [
      id,
      activeDocumentRequests,
      updateDocumentRequest,
      updateDocumentsRequests,
      readOnly,
      setActiveItems,
    ],
  )

  const handleOpenCommentsModal = useCallback(() => {
    setIsCommentsModalOpen(true)
    history.replace({
      search: queryString.stringify({
        documentRequestId: selectedDocumentRequest.id,
      }),
    })
  }, [history, selectedDocumentRequest?.id])

  const handleCloseCommentsModal = useCallback(() => {
    setIsCommentsModalOpen(false)
    history.replace({
      search: null,
    })
    handleCloseMenu()
  }, [history, handleCloseMenu])

  const handleDeleteDocumentRequest = useCallback(
    (documentRequest: IDueDiligenceDocumentRequest) => {
      setDocumentRequestForDelete(documentRequest)
    },
    [],
  )

  const handleDeleteDocumentRequestConfirm = useCallback(async () => {
    if (readOnly) {
      return
    }
    if (
      activeDocumentRequests.length > 1 &&
      activeDocumentRequests.map(({ id }) => id).includes(documentRequestForDelete.id)
    ) {
      await deleteDocumentsRequests(
        id,
        activeDocumentRequests.map(({ id }) => id),
      )
      setActiveItems([])
    } else {
      await deleteDocumentRequest(id, documentRequestForDelete.id)
    }
    showDocumentRequestsTypes(id)
    setDocumentRequestForDelete(null)
  }, [
    id,
    documentRequestForDelete,
    activeDocumentRequests,
    deleteDocumentRequest,
    deleteDocumentsRequests,
    showDocumentRequestsTypes,
    readOnly,
    setActiveItems,
  ])

  const handleDeleteDocumentRequestCancel = useCallback(() => {
    setDocumentRequestForDelete(null)
  }, [])

  const handleSaveDocumentRequestComment = useCallback(
    async (data: any) => {
      if (readOnly) {
        return
      }
      if (data.id) {
        return updateDocumentRequestComment(
          selectedDocumentRequest.id,
          data.id,
          {
            comment: data.comment,
          },
          id,
        )
      } else {
        return createDocumentRequestComment(selectedDocumentRequest.id, data, id)
      }
    },
    [
      id,
      selectedDocumentRequest,
      createDocumentRequestComment,
      updateDocumentRequestComment,
      readOnly,
    ],
  )

  const handleDeleteDocumentRequestComment = useCallback(
    async (commentId: string) => {
      if (readOnly) {
        return
      }
      return deleteDocumentRequestComment(selectedDocumentRequest.id, commentId, id)
    },
    [id, selectedDocumentRequest, deleteDocumentRequestComment, readOnly],
  )

  const handleUploadDocumentRequestFiles = useCallback(
    async (loadedFiles: File[]) => {
      if (readOnly) {
        return
      }
      const formData = new FormData()

      setSelectedDocumentRequest({
        ...selectedDocumentRequest,
        files: [
          ...selectedDocumentRequest.files,
          ...loadedFiles.map((file) => ({
            ...file,
            id: null,
            name: file.name,
            fileName: file.name,
            isUploading: true,
          })),
        ],
      })

      for (const file of loadedFiles) {
        formData.append('files[]', file, file.name)
      }
      formData.append('documentRequestId', selectedDocumentRequest.id)

      await uploadDocumentRequestsFiles(formData, id)
      await refetchDocumentRequests()
    },
    [id, selectedDocumentRequest, uploadDocumentRequestsFiles, refetchDocumentRequests, readOnly],
  )

  const handleViewSample = useCallback(
    (documentRequestId: string) => getDocumentRequestsSharedLink(documentRequestId, id),
    [id, getDocumentRequestsSharedLink],
  )

  const handleDeleteDocumentRequestFiles = useCallback(
    async (ids: string[]) => {
      if (readOnly) {
        return
      }
      await deleteFile(ids)
      await refetchDocumentRequests()
    },
    [deleteFile, refetchDocumentRequests, readOnly],
  )

  useEffect(() => {
    if (dueDiligenceDocumentRequests?.data?.data) {
      setSelectedDocumentRequest((selectedDocumentRequest) =>
        selectedDocumentRequest
          ? dueDiligenceDocumentRequests.data.data.find(
              ({ id }) => id === selectedDocumentRequest.id,
            )
          : null,
      )
    }
  }, [dueDiligenceDocumentRequests.data])

  useEffect(() => {
    if (documentRequestId && !selectedDocumentRequest && dueDiligenceDocumentRequests.data?.data) {
      const documentRequest = dueDiligenceDocumentRequests.data.data.find(
        ({ id }) => id === documentRequestId,
      )
      if (documentRequest) {
        setSelectedDocumentRequest(documentRequest)
        if (isComment) {
          const { isComment: _, ...rest } = queryString.parse(search)
          setIsCommentsModalOpen(true)
          history.replace({
            search: queryString.stringify(rest),
          })
        }
      }
    }
  }, [
    documentRequestId,
    isComment,
    selectedDocumentRequest,
    dueDiligenceDocumentRequests,
    search,
    history,
  ])

  return (
    <div>
      <DueDiligencePageHeader />
      <Grid container py={1} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
        <Grid
          container
          item
          className={styles.gridContainer}
          xs={12}
          alignItems="flex-start"
          justifyContent="flex-start"
          ml={3}
        >
          <TableContainer className={styles.table}>
            <Form
              validate={filtersValidate}
              onSubmit={handleFiltersChange}
              initialValues={filters}
              mutators={{
                setFieldData: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              render={({ values, handleSubmit, form: { mutators } }) => (
                <FilterContainer
                  filters={DUE_DILIGENCE_DOCUMENT_REQUEST_LIST_FILTERS_CONFIG}
                  handleSubmit={handleSubmit}
                  mutators={mutators}
                  values={values}
                  appliedFilters={filters}
                  appliedQuickFilter={quickFilter}
                  handleAppliedQuickFilterChange={handleQuickFilterChange}
                  actions={!readOnly && <AddButton onClick={handleAddDocumentRequest} />}
                />
              )}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft}>Documents</TableCell>
                  <TableCell className={genericSs.tableTextLeft}>Folder</TableCell>
                  <TableCell className={genericSs.tableTextLeft}>Status</TableCell>
                  <TableCell className={genericSs.tableTextLeft}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableLoader columnsCount={4} rowsCount={10} height={36} />
                ) : (
                  data.map(({ header, documents }, index) => (
                    <DueDiligenceValidateDocumentsSection
                      key={header}
                      header={header}
                      documents={documents}
                      handleUpdateDocumentRequestStatus={handleUpdateDocumentRequestStatus}
                      handleClickMenu={handleClickMenu}
                      handleDeleteDocumentRequest={handleDeleteDocumentRequest}
                      sectionIndex={index}
                      activeItem={activeItem}
                      activeItems={activeItems}
                      handleSelectRow={handleSelectRow}
                      readOnly={readOnly}
                    />
                  ))
                )}
              </TableBody>
              <Menu
                open={actionsMenuOpen}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                className={styles.actionsMenu}
              >
                <MenuItem onClick={handleOpenCommentsModal}>Comments</MenuItem>
                <MenuItem onClick={handleOpenUploadModal}>Documents</MenuItem>
              </Menu>
            </Table>
          </TableContainer>

          {isCommentsModalOpen && (
            <DueDiligenceCommentsModal
              isSaving={isSaving}
              isUW
              readOnly={readOnly}
              documentRequest={selectedDocumentRequest}
              currentUserId={currentUserId}
              handleSaveComment={handleSaveDocumentRequestComment}
              handleDeleteComment={handleDeleteDocumentRequestComment}
              handleClose={handleCloseCommentsModal}
            />
          )}

          {documentRequestForDelete && (
            <WarningModal
              warningMessage={
                activeItems.length > 1
                  ? 'Deleting these documents types will be permanent.'
                  : 'Deleting this document type will be permanent.'
              }
              onConfirm={handleDeleteDocumentRequestConfirm}
              onCancel={handleDeleteDocumentRequestCancel}
              confirmText="Yes, proceed"
              cancelText="Cancel"
              isLoading={isSaving}
              disabled={isSaving}
            />
          )}

          {isAddModalOpen && (
            <DueDiligenceValidateDocumentsAddModal
              isSaving={isSaving}
              handleSave={handleAddDocumentRequestConfirm}
              handleClose={handleAddDocumentRequestCancel}
              documentRequestTypes={dueDiligenceDocumentRequestsTypes}
            />
          )}
          {isUploadModalOpen && (
            <DueDiligenceDocumentUpload
              readOnly={readOnly}
              documentRequest={selectedDocumentRequest}
              handleUploadFiles={handleUploadDocumentRequestFiles}
              handleDeleteFiles={handleDeleteDocumentRequestFiles}
              handleViewSample={handleViewSample}
              handleClose={handleCloseUploadModal}
              putNotification={putNotification}
            />
          )}
        </Grid>
      </Grid>
      <ActiveToolbar activeItems={activeItems} className={styles.activeToolbar} />
    </div>
  )
}

export default DueDiligenceValidateDocumentsPage
