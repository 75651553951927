import React from 'react'
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell'
import cn from 'classnames'

import styles from './TableCell.module.scss'

interface IProps extends TableCellProps {
  isAutocompleteFilter?: boolean
  isAmountFilter?: boolean
  isDateFilter?: boolean
  isTextFilter?: boolean
}

const TableCell = ({
  isAutocompleteFilter,
  isAmountFilter,
  isDateFilter,
  isTextFilter,
  ...props
}: IProps) => {
  return (
    <MuiTableCell
      classes={{
        root: cn(styles.root, {
          [styles.autocompleteFilter]: isAutocompleteFilter,
          [styles.amountFilter]: isAmountFilter,
          [styles.dateFilter]: isDateFilter,
          [styles.textFilter]: isTextFilter,
        }),
        head: styles.headRow,
      }}
      {...props}
    />
  )
}

export default TableCell
