import React from 'react'
import MuiTableRow, { TableRowProps } from '@mui/material/TableRow'
import cn from 'classnames'

import styles from './TableRow.module.scss'

interface IProps extends TableRowProps {
  isEditable?: boolean
  isFiltersRow?: boolean
  isNewFiltersRow?: boolean
}

const TableRow = ({ isEditable, isFiltersRow, isNewFiltersRow, ...props }: IProps) => {
  return (
    <MuiTableRow
      classes={{
        root: cn(styles.root, {
          [styles.editable]: isEditable,
          [styles.filters]: isFiltersRow,
          [styles.newFilters]: isNewFiltersRow,
        }),
      }}
      {...props}
    />
  )
}

export default TableRow
