import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Form, FormSpy } from 'react-final-form'
import cn from 'classnames'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { diff } from 'deep-object-diff'

import styles from './BBCArIneligibilityTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'

import ActiveToolbar from '../ActiveToolbar'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import {
  debounceEventHandler,
  formatPrice,
  handleMultipleSelect,
  unFormatPrice,
  formatter,
  formatDate,
  voidHandler,
} from '../../helpers/helpers'
import {
  IArIneligibilityData,
  IArIneligibility,
  IneligibleCategory,
  IBorrowingBase,
  FundingRequestStatus,
} from '@common/interfaces/bbc'
import TextField from '../Common/TextField'
import CurrencyField from '../Common/CurrencyField'
import Card from '../Common/Card'
import TableFiltersRow from '../Common/TableFiltersRow'
import Autocomplete from '../Common/Autocomplete'
import {
  BBC_AR_INELIGIBILITY_LIST_FILTERS_CONFIG,
  CLIENT_AR_ELIGIBILITY_LIST_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import SaveState from '../Common/SaveState'
import { WorkflowTypes } from '@common/interfaces/notes'
import EntityPreview from '../EntityPreview'
import { usePermissions } from '../../helpers/permissionContext'

const filtersValidate = buildFiltersValidateSchema(BBC_AR_INELIGIBILITY_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(BBC_AR_INELIGIBILITY_LIST_FILTERS_CONFIG)

const INELIGIBLE_CATEGORY_LIST = Object.keys(IneligibleCategory).map((ineligibleCategory) => ({
  value: ineligibleCategory,
  label: ineligibleCategory,
}))

const mutators = {
  ...arrayMutators,
}

interface IProps {
  bbc: IBorrowingBase
  arEligibilityData: ILoadingData<IArIneligibilityData>
  arIneligibilityData: ILoadingData<IArIneligibilityData>
  listArIneligibility: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      orderBy?: string
      orderDirection?: string
      filters?: object
    },
  ) => Promise<{ data: IArIneligibilityData }>
  listArEligibility: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      orderBy?: string
      orderDirection?: string
      filters?: object
    },
  ) => Promise<{ data: IArIneligibilityData }>
  updateArIneligibility: (id: string, itemId: string, data: object) => Promise<IArIneligibility>
  isEligibilityTable?: boolean
}

const BBCArIneligibilityTableRow = ({
  index,
  item,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
  isHistorical,
  isEligibilityTable,
}: {
  index: number
  item: IArIneligibility
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
  isHistorical: boolean
  isEligibilityTable: boolean
}) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])
  const { isParticipant } = usePermissions()

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={item.id}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
        [styles.newRow]: item.isNew,
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <LinkButton
          component={Link}
          to={generatePath(ROUTES.ENTITY_PAGE, {
            id: item.entityInfo?.id,
          })}
          className={cn(styles.entityLink, {
            [styles.entityLinkShort]: !!item.isCritical,
          })}
        >
          {item.entityInfo?.name}
        </LinkButton>
        {item.entityInfo?.id && !isParticipant && (
          <EntityPreview
            id={item.entityInfo?.id}
            workflow={isEligibilityTable ? WorkflowTypes.clientPage : WorkflowTypes.bbc}
            isEligiblePage
            className={styles.entityPreviewIcon}
          />
        )}
        {item.isCritical && (
          <Tooltip placement="top" title="Over $50k in eligible AR">
            <div className={genericSs.yellowTag}>Critical</div>
          </Tooltip>
        )}
        {item.isContra && (
          <Tooltip placement="top" title="Contra">
            <div className={genericSs.yellowTag}>Contra</div>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        {isHistorical ? (
          item.ineligibleCategory
        ) : (
          <Autocomplete
            className={cn('focusableInput', styles.ineligibleCategoryList)}
            tabIndex={4 * index}
            name={`arIneligibility[${index}].ineligibleCategory`}
            options={INELIGIBLE_CATEGORY_LIST}
            disableClearable
            withBorder
          />
        )}
      </TableCell>
      {isEligibilityTable && (
        <TableCell className={genericSs.tableTextRight}>
          {item.lastAmount && '$' + formatPrice(item.lastAmount)}
        </TableCell>
      )}

      <TableCell className={genericSs.tableTextRight}>
        {isHistorical ? (
          formatter.format(unFormatPrice(item.creditLimits))
        ) : (
          <CurrencyField
            className="focusableInput"
            size="small"
            fullWidth={false}
            tabIndex={4 * index + 1}
            name={`arIneligibility[${index}].creditLimits`}
          />
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        {isHistorical ? (
          item.concentrationLimit
        ) : (
          <TextField
            className="focusableInput"
            tabIndex={4 * index + 2}
            size="small"
            name={`arIneligibility[${index}].concentrationLimit`}
            fullWidth={false}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              type: 'number',
            }}
          />
        )}
      </TableCell>
      {!isEligibilityTable && (
        <TableCell className={genericSs.tableTextRight}>
          {formatter.format(item.totalIneligible)}
        </TableCell>
      )}
      {!isEligibilityTable && (
        <TableCell className={genericSs.tableTextRight}>{formatter.format(item.totalAR)}</TableCell>
      )}
      {isEligibilityTable && (
        <TableCell className={genericSs.tableTextLeft}>
          {item.lastActive && formatDate(item.lastActive)}
        </TableCell>
      )}

      <TableCell className={genericSs.tableTextLeft}>
        {isHistorical ? (
          item.notesReason
        ) : (
          <Tooltip
            title={item.notesReason}
            placement="top"
            disableFocusListener={item.notesReason?.length < 18 || !item.notesReason}
            disableTouchListener
            disableHoverListener
          >
            <TextField
              className="focusableInput"
              tabIndex={4 * index + 3}
              name={`arIneligibility[${index}].notesReason`}
              placeholder="Notes"
              fullWidth={false}
            />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  )
}

const BBCArIneligibilityTable = ({
  bbc,
  arIneligibilityData,
  arEligibilityData,
  listArIneligibility,
  updateArIneligibility,
  listArEligibility,
  isEligibilityTable = false,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const isHistorical = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )

  const {
    isLoading,
    isSaving,
    isSaved,
    data: result,
  } = useMemo(
    () => (isEligibilityTable ? arEligibilityData : arIneligibilityData),
    [isEligibilityTable, arEligibilityData, arIneligibilityData],
  )
  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: isEligibilityTable ? null : 'total',
    direction: 'DESC',
  })
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const itemsCount = result?.data.length

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems),
    [activeItems],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const fetchArIneligibilityList = useCallback(
    async (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        perPage: data.perPage || PER_PAGE,
      }
      isEligibilityTable
        ? await listArEligibility(id, params)
        : await listArIneligibility(id, params)
      if (!data.loadMore) {
        setActiveItems([])
      }
    },
    [id, listArIneligibility, listArEligibility, isEligibilityTable],
  )

  const debounceArIneligibilityList = useMemo(
    () => debounceEventHandler(fetchArIneligibilityList, 500),
    [fetchArIneligibilityList],
  )

  useEffect(() => {
    debounceArIneligibilityList({
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [filters, orderBy, debounceArIneligibilityList])

  const refetchArIneligibilityList = useCallback(
    (skipLoader: boolean = false) => {
      fetchArIneligibilityList({
        page: 0,
        perPage: itemsCount,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        skipLoader,
      })
    },
    [itemsCount, filters, orderBy, fetchArIneligibilityList],
  )

  const activeItemsIds = useMemo(
    () => result?.data.filter((_, index) => activeItems.includes(index)).map(({ id }) => id),
    [activeItems, result],
  )

  const handleUpdateArIneligibility = useCallback(
    async (itemId: string, data: Partial<IArIneligibility>, activeItemsIds: string[]) => {
      if (activeItemsIds.length > 1 && activeItemsIds.includes(itemId)) {
        await updateArIneligibility(id, activeItemsIds[0], {
          ...data,
          itemId: activeItemsIds,
        })
      } else {
        await updateArIneligibility(id, itemId, data)
      }
      refetchArIneligibilityList(true)
    },
    [id, updateArIneligibility, refetchArIneligibilityList],
  )

  const handleUpdateArIneligibilityDebounce = useMemo(
    () =>
      debounceEventHandler(
        async (itemId: string, data: Partial<IArIneligibility>, activeItemsIds: string[]) => {
          await handleUpdateArIneligibility(itemId, data, activeItemsIds)
        },
        1000,
      ),
    [handleUpdateArIneligibility],
  )

  const handleUpdate = useCallback(
    (props) => {
      if (props.dirty) {
        const changedRows = diff(props.initialValues.arIneligibility, props.values.arIneligibility)
        if (!Object.entries(changedRows).length) {
          return
        }
        const [[updatedRowIndex, updatedData]] = Object.entries(changedRows)
        if (Object.keys(updatedData).includes('ineligibleCategory')) {
          handleUpdateArIneligibility(
            props.values.arIneligibility[updatedRowIndex].id,
            {
              ineligibleCategory: updatedData.ineligibleCategory?.value || null,
            },
            activeItemsIds,
          )
        } else {
          Object.keys(updatedData).forEach((field) => {
            if (updatedData[field] === undefined) {
              updatedData[field] = null
            }
          })
          handleUpdateArIneligibilityDebounce(
            props.values.arIneligibility[updatedRowIndex].id,
            updatedData,
            activeItemsIds,
          )
        }
      }
    },
    [handleUpdateArIneligibility, handleUpdateArIneligibilityDebounce, activeItemsIds],
  )

  const debtors = useMemo(() => {
    if (!result?.dictionary?.debtors) {
      return []
    }

    return result.dictionary.debtors.map((debtor) => ({
      value: debtor,
      label: debtor,
    }))
  }, [result])

  const filtersConfig = useMemo(
    () =>
      (isEligibilityTable
        ? CLIENT_AR_ELIGIBILITY_LIST_FILTERS_CONFIG
        : BBC_AR_INELIGIBILITY_LIST_FILTERS_CONFIG
      ).map((item) => ({
        ...item,
        options: item.field === 'debtor' ? debtors : item.options,
      })),
    [isEligibilityTable, debtors],
  )

  const loadMore = useCallback(() => {
    fetchArIneligibilityList({
      loadMore: true,
      page: Math.ceil(result?.data.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [result, orderBy, filters, fetchArIneligibilityList])

  const totalRow = useMemo(
    () =>
      result?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (summary, row) => {
            summary.totalAR += row.totalAR
            summary.totalIneligible += row.totalIneligible
            summary.lastAmount += row.lastAmount

            return summary
          },
          { totalAR: 0, totalIneligible: 0, lastAmount: 0 },
        ),
    [result, activeItems],
  )

  const initialValues = useMemo(
    () => ({
      arIneligibility: (result?.data || []).map((item) => ({
        ...item,
        ineligibleCategory:
          !bbc || isHistorical
            ? item.ineligibleCategory
            : item.ineligibleCategory
            ? {
                value: item.ineligibleCategory,
                label: item.ineligibleCategory,
              }
            : null,
      })),
    }),
    [result, bbc, isHistorical],
  )

  return (
    <Card
      noPadding={!isEligibilityTable}
      withBorder={isEligibilityTable}
      noHeaderMargin
      ref={wrapperRef}
    >
      <Form
        initialValues={initialValues}
        onSubmit={voidHandler}
        mutators={mutators}
        render={(formProps) => (
          <TableContainer
            className={cn(styles.table)}
            isActivable
            onActiveRowsChange={setActiveItems}
            onActiveRowChange={setActiveItem}
          >
            <FormSpy
              subscription={{ initialValues: true, values: true, dirty: true }}
              onChange={handleUpdate}
            />
            <Form
              onSubmit={handleFiltersChange}
              initialValues={filters}
              validate={filtersValidate}
              mutators={{
                setFieldData: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              render={({ values, handleSubmit, form: { mutators } }) => (
                <FilterContainer
                  filters={filtersConfig}
                  handleSubmit={handleSubmit}
                  mutators={mutators}
                  values={values}
                  appliedFilters={filters}
                  title={
                    <Box mr={2}>
                      <h2>{isEligibilityTable ? 'AR Eligibility' : 'AR Ineligibility'}</h2>
                    </Box>
                  }
                />
              )}
            />
            <Table>
              <TableHead>
                <TableFiltersRow
                  filters={filtersConfig}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                />
              </TableHead>
              <TableBody id="scrollableTable">
                {isLoading ? (
                  <TableLoader columnsCount={7} height={24} />
                ) : (
                  result?.data && (
                    <InfiniteScroll
                      dataLength={result?.data.length}
                      next={loadMore}
                      hasMore={result?.data.length < result?.totals.totalItems}
                      loader={<TableLoader columnsCount={7} height={24} rowsCount={1} />}
                      scrollableTarget="scrollableTable"
                    >
                      <FieldArray name="arIneligibility">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <BBCArIneligibilityTableRow
                              key={name}
                              index={index}
                              item={formProps.values.arIneligibility[index]}
                              isActiveRow={activeItems.includes(index)}
                              isCurrentActiveRow={activeItem === index}
                              onSelectRow={handleSelectRow}
                              isHistorical={isHistorical}
                              isEligibilityTable={isEligibilityTable}
                            />
                          ))
                        }
                      </FieldArray>
                    </InfiniteScroll>
                  )
                )}
              </TableBody>
            </Table>

            <Box display="flex" alignItems="center" justifyContent="space-between">
              {result?.totals.totalItems > 0 && (
                <div className={genericSs.itemsCount}>
                  {result.data.length} / {result.totals.totalItems}
                </div>
              )}
              <SaveState isSaving={isSaving} isSaved={isSaved} />
            </Box>

            <ActiveToolbar
              activeItems={activeItems}
              className={
                isEligibilityTable ? styles.activeToolbarWithoutTotal : styles.activeToolbar
              }
              containerRef={wrapperRef}
              resetActiveItems={resetActiveItems}
            >
              {isEligibilityTable && (
                <div className={genericSs.tableTextLeft}>
                  <span className={cn(genericSs.pricePrefix, styles.priceAdornment)}>$</span>
                  {formatPrice(totalRow?.lastAmount)}
                </div>
              )}
              {!isEligibilityTable && (
                <div className={genericSs.tableTextRight}>
                  <span className={cn(genericSs.pricePrefix, styles.priceAdornment)}>$</span>
                  {formatPrice(totalRow?.totalIneligible)}
                </div>
              )}
              {!isEligibilityTable && (
                <div className={genericSs.tableTextRight}>
                  <span className={genericSs.pricePrefix}>$</span>
                  {formatPrice(totalRow?.totalAR)}
                </div>
              )}
              {isEligibilityTable && <div> </div>}
            </ActiveToolbar>
          </TableContainer>
        )}
      />
    </Card>
  )
}

export default BBCArIneligibilityTable
