import Base from './base'

export default class Newsfeed extends Base {
  listNewsfeed(params: object) {
    return this.apiClient.get('clients/me/newsfeed', params)
  }

  listClientNewsfeed() {
    return this.apiClient.get('clients/client/newsfeed')
  }

  capture(id: string) {
    return this.apiClient.get(`newsfeed/${id}/capture`)
  }
}
