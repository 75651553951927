import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { matchPath } from 'react-router'

import SubmitReportingPage from './SubmitReportingPage'

import {
  clientInfo as profileClientInfo,
  isExtendedRightsRole,
} from '../../redux/profile/selectors'
import { submitOngoingReporting, show as showClient } from '../../redux/clientInfo/actions'
import { clientInfo } from '../../redux/clientInfo/selectors'

import { pathname } from '../../redux/router/selectors'
import { ROUTES } from '../../constants/routes'
import { list as banks } from './../../redux/bankAccount/selectors'
import { listBankAccounts } from '../../redux/bankAccount/actions'
import { user } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  clientInfo: createSelector(profileClientInfo, clientInfo, pathname, (profileInfo, info, path) =>
    matchPath(path, { path: ROUTES.CLIENT_SUBMIT_REPORTING_PAGE }) ? info : profileInfo,
  ),
  isExtendedRightsRole,
  banks,
  user,
})
const actions = {
  showClient,
  submitOngoingReporting,
  listBankAccounts,
}

export { SubmitReportingPage }

export default connect(selector, actions)(SubmitReportingPage)
