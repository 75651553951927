import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const loanBalanceStartDate = (state: IRootState) => state[prefix].loanBalanceStartDate

export const loanBalanceLastRecalculationDate = (state: IRootState) =>
  state[prefix].loanBalanceLastRecalculationDate

export const endOfMonthFlow = (state: IRootState) => state[prefix].endOfMonthFlow
