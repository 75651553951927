import React, { useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { Form } from 'react-final-form'
import * as Yup from 'yup'
import moment from 'moment'
import styles from './ClientAccountActivityEditModal.module.scss'
import genericSs from '@styles/generic.module.scss'
import Modal from '../../Common/Modal'
import Button from '../../Common/Button'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../../constants/common'
import CurrencyField from '../../Common/CurrencyField'
import InputLabel from '../../Common/InputLabel'
import TextField from '../../Common/TextField'
import CreatableSelectField from '../../Common/CreatableSelectField'
import { FeeType, miscOptions, passthroughOptions } from '@common/interfaces/loanServicing'
import { IAccountActivity, IClientInfo } from '@common/interfaces/client'
import { AGGREGATION_TYPES } from '@common/constants/client'
import { dateToString, voidHandler } from '../../../helpers/helpers'
import FormField from '../../Common/FormField'
import { OnChange } from 'react-final-form-listeners'

interface IProps {
  loanBalanceStartDate: string
  clientInfo: IClientInfo
  item: IAccountActivity
  updateAdjustment: (id: string, data: object) => void
  updateFunding: (id: string, data: object) => void
  updatePassthroughs: (id: string, data: object) => void
  updateFee: (id: string, data: object) => void
  handleCancel: () => void
  handleConfirm: () => void
}

const ClientAccountActivityEditModal = ({
  loanBalanceStartDate,
  clientInfo,
  item,
  updateAdjustment,
  updateFunding,
  updatePassthroughs,
  updateFee,
  handleCancel,
  handleConfirm,
}: IProps) => {
  const [error, setError] = useState<string | null>(null)
  const validate = useMemo(() => {
    const minDate = moment(loanBalanceStartDate || undefined).toDate()
    const schema = Yup.object()
      .shape({
        recordDate: Yup.date()
          .typeError('Please type date in MM/DD/YY format')
          .min(minDate, 'Cannot set record date for a locked month'),
        amount: Yup.number().typeError('Invalid number').required('Required'),
      })
      .test('sumOfShares', 'Sum of shares must be less than or equal to amount', (values) => {
        let sumOfShares = 0
        Object.keys(values).forEach((key) => {
          if (key.endsWith('Share')) {
            const share = +values[key]
            sumOfShares += share
          }
        })
        if (values.amount < 0) {
          return sumOfShares >= values.amount && sumOfShares <= 0
        }
        return sumOfShares <= values.amount
      })

    return schema
  }, [loanBalanceStartDate])

  const handleSubmit = useCallback(
    async (values: any) => {
      const participationData = item.fee?.participationFeeExposure?.reduce((acc, participation) => {
        acc[participation.id] = {
          override: values[`${participation.id}-Override`],
          share: values[`${participation.id}-Share`],
        }
        return acc
      }, {})

      switch (item.type) {
        case AGGREGATION_TYPES.adjustment:
          await updateAdjustment(item.id, {
            title: values.description,
            recordDate: dateToString(values.recordDate),
            amount: values.amount,
          })
          break
        case AGGREGATION_TYPES.funding:
          await updateFunding(clientInfo.id, {
            id: item.id,
            amount: values.amount,
            description: values.description,
          })
          break
        case AGGREGATION_TYPES.passThroughs:
          await updatePassthroughs(clientInfo.id, {
            id: item.id,
            amount: values.amount,
          })
          break
        default:
          await updateFee(item.id, {
            title: values.feeTitle?.value,
            description: values.feeDescription,
            recordDate: dateToString(values.recordDate),
            amount: values.amount,
            participations: participationData,
          })
          break
      }
      handleConfirm()
    },
    [
      item,
      clientInfo,
      updateAdjustment,
      updateFunding,
      updatePassthroughs,
      updateFee,
      handleConfirm,
    ],
  )
  const initialValues = useMemo(
    () => ({
      amount: item.amount,
      recordDate: new Date(item.recordDate),
      description: item.description?.replace('Adjustment ', ''),
      feeTitle: item.fee?.title
        ? {
            value: item.fee.title,
            label: item.fee.title,
          }
        : null,
      feeDescription: item.fee?.description,
      ...item.fee?.participationFeeExposure?.reduce((acc, participation) => {
        acc[`${participation.id}-Override`] = participation.feeOverride * 100
        acc[`${participation.id}-Share`] = participation.participantExposureAmount
        return acc
      }, {}),
    }),
    [item],
  )

  const updateParticipationFees = useCallback(
    (form: any, newAmount, oldAmount) => {
      if (!form) return

      const participationFees = item.fee?.participationFeeExposure
      let changeFields = true
      const formsToChange = {}
      if (participationFees) {
        participationFees.forEach((participationFee) => {
          const currentShareValue = form.getFieldState(`${participationFee.id}-Share`)?.value
          const previousSharePercent = (currentShareValue / oldAmount).toFixed(2)

          if (
            !previousSharePercent ||
            previousSharePercent === participationFee.participantExposurePercentage.toFixed(2)
          ) {
            const shareForm = `${participationFee.id}-Share`
            const newFee = (newAmount * participationFee.participantExposurePercentage).toFixed(2)
            formsToChange[shareForm] = newFee
          } else {
            changeFields = false
          }
        })
        if (changeFields) {
          for (const [key, value] of Object.entries(formsToChange)) {
            form.change(key, value)
          }
        }
      }
    },
    [item],
  )

  return (
    <Modal open onCancel={handleCancel} title="Edit account activity" size="small">
      <Form
        validate={(values) => {
          try {
            validate.validateSync(values, { abortEarly: false })
            setError(null)
          } catch (errors) {
            return errors.inner.reduce((formErrors: any, error: any) => {
              error.message !== 'Required' && setError(error.message)
              return {
                ...formErrors,
                [error.path]: error.message,
              }
            }, {})
          }
        }}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ pristine, invalid, submitting, values, handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
              {[AGGREGATION_TYPES.adjustment, AGGREGATION_TYPES.funding].includes(item.type) ? (
                <Box flex={1}>
                  <InputLabel htmlFor="description" className={genericSs.textLeft}>
                    Description
                  </InputLabel>
                  <TextField
                    className={styles.textFormField}
                    id="description"
                    name="description"
                    placeholder="Description (Optional)"
                    InputProps={{
                      type: 'text',
                    }}
                  />
                </Box>
              ) : ![AGGREGATION_TYPES.passThroughs].includes(item.type) ? (
                item.fee?.type === FeeType.PassThroughs ? (
                  <Box flex={1}>
                    <InputLabel htmlFor="feeTitle" className={genericSs.textLeft}>
                      Fee subtype
                    </InputLabel>
                    <CreatableSelectField
                      className={styles.creatableSelectFormField}
                      name="feeTitle"
                      placeholder="Fee Type"
                      onAddValue={voidHandler}
                      options={passthroughOptions}
                      height="medium"
                      disabledClearable
                    />
                  </Box>
                ) : item.fee?.type === FeeType.Miscellaneous ? (
                  <Box flex={1}>
                    <InputLabel htmlFor="feeTitle" className={genericSs.textLeft}>
                      Fee subtype
                    </InputLabel>
                    <CreatableSelectField
                      className={styles.creatableSelectFormField}
                      name="feeTitle"
                      placeholder="Fee Type"
                      onAddValue={voidHandler}
                      options={miscOptions}
                      height="medium"
                      disabledClearable
                    />
                  </Box>
                ) : (
                  <Box flex={1}>
                    <InputLabel htmlFor="feeDescription" className={genericSs.textLeft}>
                      Description
                    </InputLabel>
                    <TextField
                      className={styles.textFormField}
                      id="feeDescription"
                      name="feeDescription"
                      placeholder="Description (Optional)"
                      InputProps={{
                        type: 'text',
                      }}
                    />
                  </Box>
                )
              ) : null}
              {![AGGREGATION_TYPES.funding, AGGREGATION_TYPES.passThroughs].includes(item.type) && (
                <Box flex={1}>
                  <InputLabel htmlFor="recordDate" className={genericSs.textLeft}>
                    Date
                  </InputLabel>
                  <KeyboardDatePicker
                    className={styles.dateFormField}
                    name="recordDate"
                    inputFormat={DATE_FORMAT}
                    minDate={moment(loanBalanceStartDate || undefined).toDate()}
                    placeholder="Select date"
                  />
                </Box>
              )}
              <Box flex={1}>
                <InputLabel htmlFor="amount" className={genericSs.textLeft}>
                  Amount
                </InputLabel>
                <CurrencyField
                  id="amount"
                  name="amount"
                  placeholder="Enter Amount"
                  className={styles.textFormField}
                />
              </Box>
            </Box>
            <OnChange name="amount">
              {(value, previous) => {
                updateParticipationFees(form, value, previous)
              }}
            </OnChange>
            {item.fee?.participationFeeExposure?.length
              ? item?.fee?.participationFeeExposure?.map((participation) => {
                  const overrideForm = `${participation.id}-Override`
                  const shareForm = `${participation.id}-Share`

                  const currentShareValue = values[shareForm]
                  const currentAmount = values.amount

                  const sharePercent = currentAmount
                    ? ((currentShareValue / currentAmount) * 100).toFixed(2)
                    : 0

                  return (
                    <Box flex={1} key={participation.participant} mt={2}>
                      <InputLabel
                        htmlFor={participation.participant}
                        className={genericSs.textLeft}
                      >
                        {participation.participant}
                      </InputLabel>
                      <Box display="flex" flexDirection="row" flexWrap="wrap" rowGap={1}>
                        <Box flex={1} marginRight={2}>
                          <InputLabel htmlFor={overrideForm} className={genericSs.textLeft}>
                            Override (%)
                          </InputLabel>
                          <FormField
                            name={overrideForm}
                            type="percent"
                            size="big"
                            placeholder="Enter override"
                          />
                        </Box>
                        <Box flex={1}>
                          <InputLabel htmlFor={shareForm} className={genericSs.textLeft}>
                            Share ({sharePercent}%)
                          </InputLabel>
                          <CurrencyField
                            className={styles.textFormField}
                            id={shareForm}
                            name={shareForm}
                            placeholder="Enter share"
                          />
                        </Box>
                      </Box>
                    </Box>
                  )
                })
              : null}

            <Box mt={5}>
              {error && <Box color="error.main">{error}</Box>}

              <Button
                type="submit"
                fullWidth
                small={false}
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={pristine || invalid || submitting}
                isLoading={submitting}
              >
                Apply
              </Button>
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

export default ClientAccountActivityEditModal
