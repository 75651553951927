import React, { useRef } from 'react'
import { TextFieldProps } from 'mui-rff'
import { NumericFormat } from 'react-number-format'
import styles from './CurrencyField.module.scss'
import { ReactComponent as DollarIcon } from '@assets/images/dollar-icon.svg'
import TextField from '../TextField'
import cn from 'classnames'
import InputAdornment from '@mui/material/InputAdornment'

function CurrencyFieldComponent(props: any) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
    />
  )
}

interface IProps extends Omit<TextFieldProps, 'size'> {
  size?: 'small' | 'big'
  useFinalForm?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: any
}

const CurrencyField = ({ InputProps, ...props }: IProps) => {
  const input = useRef<HTMLInputElement>(null)

  return (
    <TextField
      inputRef={input}
      size={props.size || 'small'}
      {...props}
      InputProps={{
        ...InputProps,
        className: styles.input,
        startAdornment: (
          <InputAdornment position="start">
            <DollarIcon
              className={cn(styles.adornment, {
                [styles.adornmentSmallSize]: props.size === 'small',
              })}
            />
          </InputAdornment>
        ),
        inputComponent: CurrencyFieldComponent,
      }}
    />
  )
}

export default CurrencyField
