import React, { useCallback, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { PrincipalPayment } from '@common/interfaces/termLoan'
import { MenuIcon } from '../../Common/Icons'

interface IProps {
  item: PrincipalPayment
  onEdit: (item: PrincipalPayment) => void
  onDelete: (id: string) => void
}

const ClientTermLoanAmortizationAction = ({ item, onEdit, onDelete }: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleEdit = useCallback(() => {
    onEdit(item)
    handleCloseMenu()
  }, [item, onEdit, handleCloseMenu])

  const handleDelete = useCallback(() => {
    onDelete(item.id)
    handleCloseMenu()
  }, [item, onDelete, handleCloseMenu])

  if (!item.isEditable) {
    return null
  }

  return (
    <>
      <MenuIcon isActive={menuOpen} onClick={handleClickMenu} size="small" />
      <Menu open={menuOpen} onClose={handleCloseMenu} anchorEl={anchorEl}>
        {item.isEditable && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
        {item.isEditable && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
      </Menu>
    </>
  )
}

export default ClientTermLoanAmortizationAction
