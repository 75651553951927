import { useEffect, useMemo, useRef } from 'react'
import isDate from 'lodash/isDate'
import { useDispatch } from 'react-redux'

import { trackVisualizations } from '../redux/visualizationsTracking/actions'
import { EVENTS } from '@common/constants/tracking'
import { IFilter } from '@common/constants/filters'
import { dateTimeToString, debounceEventHandler } from '../helpers/helpers'

const isEmpty = (value: any): boolean =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

interface IProps {
  isInitialized?: boolean
  category: string
  params: object
  filtersConfig?: IFilter[]
  filters?: any
  orderBy?: {
    field: string
    direction: string
  }
}

const useTrackVisualizationsTable = ({
  isInitialized = true,
  category,
  params,
  filtersConfig,
  filters,
  orderBy,
}: IProps) => {
  const isInitializedRef = useRef(isInitialized)
  const categoryRef = useRef(category)

  useEffect(() => {
    isInitializedRef.current = isInitialized
  }, [isInitialized])

  useEffect(() => {
    categoryRef.current = category
  }, [category])

  const dispatch = useDispatch()

  const trackOrderChange = useMemo(
    () =>
      debounceEventHandler((orderBy: { field: string; direction: string }) => {
        const field = filtersConfig?.find(({ field }) => field === orderBy.field)
        if (field) {
          dispatch(
            trackVisualizations(EVENTS.orderBy, categoryRef.current, {
              ...(params || {}),
              data: {
                field: field.title,
                direction: orderBy.direction,
              },
            }),
          )
        }
      }, 500),
    [filtersConfig, dispatch, params],
  )

  const trackFiltersChange = useMemo(
    () =>
      debounceEventHandler((filters: any) => {
        const appliedFilters = Object.keys(filters).reduce((result, field) => {
          if (isDate(filters[field])) {
            result[field] = dateTimeToString(filters[field])
          } else if (!isEmpty(filters[field])) {
            result[field] = filters[field]
          }
          return result
        }, {})

        if (!isEmpty(appliedFilters)) {
          dispatch(
            trackVisualizations(EVENTS.filters, categoryRef.current, {
              ...(params || {}),
              data: {
                ...appliedFilters,
              },
            }),
          )
        }
      }, 500),
    [dispatch, params],
  )

  useEffect(() => {
    if (isInitializedRef.current) {
      trackOrderChange(orderBy)
    }
  }, [trackOrderChange, orderBy])

  useEffect(() => {
    if (isInitializedRef.current) {
      trackFiltersChange(filters)
    }
  }, [trackFiltersChange, filters])

  return {}
}

export default useTrackVisualizationsTable
