import React, { useCallback, useState, useMemo } from 'react'
import { Form } from 'react-final-form'
import TextField from '../TextField'
import cn from 'classnames'
import { OnChange } from 'react-final-form-listeners'

import styles from './SearchableList.module.scss'
import Button from '../Button'
import { ReactComponent as SearchIcon } from '@assets/images/search-icon.svg'

interface IProps {
  data: {
    value: string
    label: string
  }[]
  handleSubmit: () => void
  submitTitle?: string
}

const SearchableList = ({ data, handleSubmit, submitTitle }: IProps) => {
  const [searchFilter, setSearchFilter] = useState<string>('')

  const handleSearchChange = useCallback((data: any) => {
    setSearchFilter(data)
  }, [])

  const filteredData = useMemo(
    () => data.filter((item) => item.label.toLowerCase().includes(searchFilter.toLowerCase())),
    [searchFilter, data],
  )

  return (
    <Form
      initialValues={{ clients: data }}
      onSubmit={handleSearchChange}
      render={() => (
        <>
          <div className={styles.searchContainer}>
            <TextField
              name="fullSearch"
              placeholder="Search"
              className={cn(styles.searchField, styles.searchFieldOpen)}
              size="big"
              InputProps={{
                disableUnderline: true,
                startAdornment: <SearchIcon className={styles.startAdornment} />,
              }}
            />
            <OnChange name="fullSearch">
              {(data) => {
                handleSearchChange(data)
              }}
            </OnChange>
          </div>
          <div className={styles.dataContainer}>
            {filteredData.map((currentData) => {
              return (
                <div className={styles.dataRow}>
                  <div>{currentData.label}</div>
                </div>
              )
            })}
          </div>

          <Button
            className={styles.submitButton}
            small={false}
            variant="text"
            color="primary"
            onClick={handleSubmit}
          >
            {submitTitle ? submitTitle : 'Submit'}
          </Button>
        </>
      )}
    />
  )
}

export default SearchableList
