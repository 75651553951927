import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import styles from './BankAccountInformation.module.scss'
import CreatableSelectField from '../../Common/CreatableSelectField'
import TextField from '../../Common/TextField'
import Button from '../../Common/Button'
import { IBankAccount } from '@common/interfaces/bankAccount'
import Modal from '../../Common/Modal'
import Box from '@mui/material/Box'
import InputLabel from '../../Common/InputLabel'
import TableFields from '../../TableFields'
import { makeValidate } from 'mui-rff'
import Card from '../../Common/Card'
import { IBank } from '@common/interfaces/bank'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  bankName: Yup.object().nullable().required('Required'),
  accountHolderName: Yup.string(),
  bankAccountNumber: Yup.string().required('Required'),
  abaRoutingNumber: Yup.string().required('Required'),
  bankAccountLink: Yup.string().required('Required'),
  purpose: Yup.string().nullable().max(500),
})
const validate = makeValidate(schema)

interface IProps {
  isEditModalShown: boolean
  setIsEditModalShown: (val: boolean) => void
  handleAddEditBankAccount:
    | ((data: Partial<IBankAccount> & { clientId: string }) => void)
    | ((id: string, data: Partial<IBankAccount>) => void)
  handleCloseMenu: () => void
  handleAddBank: (name: string) => void
  isAddModal: boolean
  selectedRow: IBankAccount
  banks: IBank[]
  clientId: string
}

const AddEditBankAccount = ({
  isEditModalShown,
  setIsEditModalShown,
  handleAddEditBankAccount,
  selectedRow,
  handleCloseMenu,
  handleAddBank,
  isAddModal,
  banks,
  clientId,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const normalizeBankName = ({ value }: { label: string; value: string }) => value
  const handleAddBankAccount = useCallback(
    async ({ bankName, ...data }: any) => {
      setIsSubmitting(true)
      ;(handleAddEditBankAccount as (data: Partial<IBankAccount> & { clientId: string }) => void)({
        clientId,
        bankName: normalizeBankName(bankName),
        ...data,
      })
    },
    [handleAddEditBankAccount, clientId],
  )

  const handleUpdateBankAccount = useCallback(
    async ({ id, ...data }: any) => {
      setIsSubmitting(true)
      data.bankName = data.bankName.value
      data.purpose = data.purpose || ''
      data.accountHolderName = data.accountHolderName || ''
      ;(handleAddEditBankAccount as (id: string, data: Partial<IBankAccount>) => void)(id, data)
    },
    [handleAddEditBankAccount],
  )
  return (
    <Card withBorder={false} noPadding noHeaderMargin>
      <Form
        onSubmit={isAddModal ? handleAddBankAccount : handleUpdateBankAccount}
        validate={validate}
        initialValues={{
          id: selectedRow?.id,
          bankName: { value: selectedRow?.bankName, label: selectedRow?.bankName },
          accountHolderName: selectedRow?.accountHolderName,
          bankAccountNumber: selectedRow?.bankAccountNumber,
          abaRoutingNumber: selectedRow?.abaRoutingNumber,
          bankAccountLink: selectedRow?.bankAccountLink,
          purpose: selectedRow?.purpose,
        }}
        render={({ dirty, submitting, invalid, form, handleSubmit }) => (
          <form>
            <Modal
              open={isEditModalShown}
              onCancel={handleCloseMenu}
              title={isAddModal ? 'Add Account' : 'Edit Account'}
              classes={{
                root: styles.editModal,
                footer: styles.editModalFooter,
              }}
              footer={[
                <Button
                  key="submit"
                  color="primary"
                  variant="contained"
                  disabled={!dirty || invalid || submitting}
                  className={styles.submitModalButton}
                  isLoading={isSubmitting}
                  onClick={(data) => {
                    handleSubmit(data).then(() => {
                      form.restart()
                      handleCloseMenu()
                      setIsSubmitting(false)
                      setIsEditModalShown(false)
                    })
                  }}
                >
                  {isAddModal ? 'Add Account' : 'Edit Account'}
                </Button>,
              ]}
            >
              <Box className={styles.fieldsContainer}>
                <TableFields
                  rows={[
                    [
                      <InputLabel
                        key="bankNameLabel"
                        htmlFor="bankName"
                        size="normal"
                        position="horizontal"
                      >
                        Bank name
                      </InputLabel>,
                    ],
                    [
                      <CreatableSelectField
                        key="bankNameInput"
                        name="bankName"
                        className={styles.selectInput}
                        onAddValue={handleAddBank}
                        height="medium"
                        placeholder="Enter bank account name"
                        options={banks.map(({ name }) => ({ value: name, label: name }))}
                      />,
                    ],
                    [
                      <InputLabel
                        key="accountHolderName"
                        htmlFor="accountHolderName"
                        size="normal"
                        position="horizontal"
                      >
                        Account holder
                      </InputLabel>,
                    ],
                    [
                      <TextField
                        placeholder="Enter account holder"
                        key="accountHolderNameInput"
                        name="accountHolderName"
                      />,
                    ],
                    [
                      <InputLabel
                        key="bankAccountNumberLabel"
                        htmlFor="bankAccountNumber"
                        size="normal"
                        position="horizontal"
                      >
                        Bank account #
                      </InputLabel>,
                    ],
                    [
                      <TextField
                        placeholder="Enter bank account #"
                        key="bankAccountNumberInput"
                        name="bankAccountNumber"
                      />,
                    ],
                    [
                      <InputLabel
                        key="abaRoutingNumberLabel"
                        htmlFor="abaRoutingNumber"
                        size="normal"
                        position="horizontal"
                      >
                        ABA routing #
                      </InputLabel>,
                    ],
                    [
                      <TextField
                        placeholder="Enter ABA routing #"
                        key="abaRoutingNumberInput"
                        name="abaRoutingNumber"
                      />,
                    ],
                    [
                      <InputLabel
                        key="abaRoutingNumberLabel"
                        htmlFor="abaRoutingNumber"
                        size="normal"
                        position="horizontal"
                      >
                        Link
                      </InputLabel>,
                    ],
                    [
                      <TextField
                        placeholder="Enter bank url"
                        key="bankAccountLinkInput"
                        name="bankAccountLink"
                      />,
                    ],
                    [
                      <InputLabel
                        key="purposeLabel"
                        htmlFor="purpose"
                        size="normal"
                        position="horizontal"
                      >
                        Purpose
                      </InputLabel>,
                    ],
                    [
                      <TextField
                        placeholder="Purpose of the account"
                        key="purposeInput"
                        name="purpose"
                        rows={4}
                        multiline
                        fullWidth
                      />,
                    ],
                  ]}
                />
              </Box>
            </Modal>
          </form>
        )}
      />
    </Card>
  )
}

export default AddEditBankAccount
