import React, { useState, useCallback, useMemo, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import {
  IEntityInfo,
  IEntitiesWithDuplicates,
  IInvestorAggregation,
  IEntityMergeConflicts,
} from '@common/interfaces/entityInfo'
import styles from './EntityDashboardHeader.module.scss'
import Box from '@mui/material/Box'
import { ReactComponent as SalesforceIcon } from '@assets/images/salesforce-icon.svg'
import Modal from '../../components/Common/Modal'
import Tooltip from '@mui/material/Tooltip'
import Button from '../Common/Button'
import Skeleton from '@mui/material/Skeleton'
import { generatePath, Link } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'
import { ReactComponent as RecoveryIcon } from '../../assets/images/round-restart-alt-icon.svg'
import { useLocation, useHistory } from 'react-router'
import Tabs from '../../components/Common/Tabs'
import queryString, { ParsedQuery } from 'query-string'
import { ReactComponent as WarningIconOutline } from '../../assets/images/warning-icon-outline.svg'
import EntityDashboardSummaryMetrics from './EntityDashboardSummaryMetrics'
import InvestorDashboardSummaryMetrics from './InvestorDashboardSummaryMetrics'
import cn from 'classnames'
import genericSs from '@styles/generic.module.scss'
import { addHttp } from '@common/helpers/helpers'
import WarningModal from '../WarningModal'
import { ExternalLink, SettingsIcon } from '../Common/Icons'
import { ILoadingData } from '../../redux/types'
import EntityMergeConflictModal from '../EntityMergeConflictModal'

const SelectDuplicateEntity = ({
  originalEntity,
  potentialDuplicate,
  handleMergeEntities,
  handleUpdateDuplicateEntity,
  setIsDuplicatesModalShown,
}: {
  originalEntity: IEntityInfo
  potentialDuplicate: IEntitiesWithDuplicates
  handleMergeEntities: (fromEntity: IEntityInfo, toEntity: IEntityInfo) => void
  handleUpdateDuplicateEntity: (id: string, isRejected: boolean) => void
  setIsDuplicatesModalShown: (value: boolean) => void
}) => {
  const [radioValue, setRadioValue] = useState('original')

  const [fromAndToEntity, setFromAndToEntity] = useState<{
    fromEntity: IEntityInfo
    toEntity: IEntityInfo
  }>({
    fromEntity: potentialDuplicate.duplicateEntity,
    toEntity: originalEntity,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setRadioValue(value)
    if (value === 'original') {
      setFromAndToEntity({
        fromEntity: potentialDuplicate.duplicateEntity,
        toEntity: originalEntity,
      })
    } else if (value === 'duplicate') {
      setFromAndToEntity({
        fromEntity: originalEntity,
        toEntity: potentialDuplicate.duplicateEntity,
      })
    }
  }

  const handleMerge = useCallback(async () => {
    await setIsDuplicatesModalShown(false)
    await handleMergeEntities(fromAndToEntity.fromEntity, fromAndToEntity.toEntity)
  }, [fromAndToEntity, handleMergeEntities, setIsDuplicatesModalShown])

  return (
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={radioValue}
      onChange={handleChange}
    >
      <TableRow>
        <TableCell className={genericSs.tableTextLeft}>
          <FormControlLabel
            value={'duplicate'}
            control={<Radio />}
            label={
              <a
                href={generatePath(ROUTES.ENTITY_PAGE, {
                  id: potentialDuplicate?.duplicateEntity?.id,
                })}
                target="_blank"
                className={styles.duplicateLink}
                rel="noreferrer"
              >
                {potentialDuplicate?.duplicateEntity?.name}
              </a>
            }
          />
        </TableCell>
        <TableCell className={genericSs.tableTextLeft}>
          <FormControlLabel
            value={'original'}
            control={<Radio />}
            label={
              <a
                href={generatePath(ROUTES.ENTITY_PAGE, {
                  id: originalEntity?.id,
                })}
                target="_blank"
                className={styles.duplicateLink}
                rel="noreferrer"
              >
                {originalEntity?.name}
              </a>
            }
          />
        </TableCell>
        <TableCell>
          <Button
            className={styles.mergeButton}
            size="small"
            disabled={potentialDuplicate?.isRejected}
            onClick={handleMerge}
          >
            Merge
          </Button>
        </TableCell>
        <TableCell className={genericSs.tableTextRight}>
          {potentialDuplicate?.isRejected ? (
            <Tooltip title="Recover" placement="top">
              <RecoveryIcon
                className={styles.actionBtn}
                onClick={() =>
                  handleUpdateDuplicateEntity(
                    potentialDuplicate?.id,
                    !potentialDuplicate?.isRejected,
                  )
                }
              />
            </Tooltip>
          ) : (
            <Tooltip title="Reject" placement="top">
              <DeleteIcon
                onClick={() =>
                  handleUpdateDuplicateEntity(
                    potentialDuplicate?.id,
                    !potentialDuplicate?.isRejected,
                  )
                }
                className={styles.actionBtn}
              />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </RadioGroup>
  )
}

interface IProps {
  entityInfo: IEntityInfo
  listEntityInfo: (data?: object) => Promise<{ data: IEntityInfo[] }>
  entityMergeConflicts?: IEntityMergeConflicts[]
  updateEntityInfo: (id: string, data: object) => void
  updateDuplicateEntity?: (id: string, data: object) => void
  tabs?: string[]
  setCurrentTab?: (tab: string) => void
  isLoading: boolean
  isLoadingDuplicates?: boolean
  isInvestor?: boolean
  investorAggregation?: ILoadingData<IInvestorAggregation>
  listInvestors?: (params: object) => void
}

const EntityDashboardHeader = ({
  entityInfo,
  listEntityInfo,
  entityMergeConflicts,
  updateEntityInfo,
  updateDuplicateEntity,
  tabs,
  setCurrentTab,
  isLoading,
  isLoadingDuplicates,
  isInvestor = false,
  investorAggregation,
  listInvestors,
}: IProps) => {
  const [isDuplicatesModalShown, setIsDuplicatesModalShown] = useState(false)

  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [mergeEntity, setMergeEntity] = useState({
    id: entityInfo?.id,
    newName: entityInfo?.name,
    oldName: entityInfo?.name,
  })
  const { search }: { search: string } = useLocation()
  const { tab = tabs[0] } = queryString.parse(search) as { tab: string }
  const [queryParams, setQueryParams] = useState<ParsedQuery>({ tab })
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isConfirmWithConflictModalShown, setIsConfirmWithConflictModalShown] = useState(false)

  const history = useHistory()

  useEffect(() => {
    history.replace({
      search: queryString.stringify(queryParams),
    })
  }, [queryParams, history])

  useEffect(() => {
    if (entityMergeConflicts?.length > 0) {
      setIsConfirmWithConflictModalShown(true)
    }
  }, [entityMergeConflicts])

  const handleCloseConfirmWithConflictModal = useCallback(() => {
    setIsConfirmWithConflictModalShown(false)
  }, [])

  const { data: investorData, isLoading: isLoadingInvestor } = useMemo(() => {
    return {
      isLoading: investorAggregation?.isLoading,
      data: investorAggregation?.data?.data?.[0],
    }
  }, [investorAggregation])

  useEffect(() => {
    if (isInvestor && entityInfo?.name) {
      listInvestors({ investor: entityInfo.name })
    }
  }, [isInvestor, entityInfo?.name, listInvestors])

  useEffect(() => {
    listEntityInfo()
  }, [listEntityInfo])

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      setQueryParams({ tab: selectedTab })
      setCurrentTab(selectedTab)
    },
    [setQueryParams, setCurrentTab],
  )

  const handleCancelMerge = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [setIsConfirmModalShown])

  const handleMergeEntities = useCallback(
    (fromEntity: IEntityInfo, toEntity: IEntityInfo) => {
      setIsConfirmModalShown(true)
      setMergeEntity({
        id: fromEntity.id,
        newName: toEntity.name,
        oldName: fromEntity.name,
      })
    },
    [setIsConfirmModalShown, setMergeEntity],
  )
  const updateEntity = useCallback(
    async (isConflict: boolean = false) => {
      const { id, newName } = mergeEntity
      setIsDuplicatesModalShown(false)
      await updateEntityInfo(id, { newName, isConfirmedWithConflict: isConflict })
    },
    [updateEntityInfo, mergeEntity],
  )

  const handleConfirmMerge = useCallback(async () => {
    setIsButtonLoading(true)
    await updateEntity()
    setIsButtonLoading(false)
    setIsConfirmModalShown(false)
  }, [updateEntity, setIsConfirmModalShown])

  const handleConfirmWithConflicts = useCallback(async () => {
    setTimeout(() => {
      setIsConfirmWithConflictModalShown(false)
    })
    await updateEntity(true)
    setIsConfirmModalShown(false)
  }, [updateEntity])

  const handleUpdateDuplicateEntity = useCallback(
    (id: string, isRejected: boolean) => {
      updateDuplicateEntity(id, { isRejected })
    },
    [updateDuplicateEntity],
  )

  const duplicatesCount = useMemo(() => entityInfo?.entitiesWithDuplicates?.length, [entityInfo])

  const rejectedDuplicatesCount = useMemo(
    () =>
      entityInfo?.entitiesWithDuplicates?.filter(
        (entity: IEntitiesWithDuplicates) => entity.isRejected,
      ).length,
    [entityInfo],
  )

  const isShowLoader = useMemo(
    () =>
      (isLoading || (isLoadingInvestor && isInvestor)) &&
      !!isConfirmModalShown &&
      !isDuplicatesModalShown,
    [isLoading, isLoadingInvestor, isConfirmModalShown, isDuplicatesModalShown, isInvestor],
  )

  const goToSettings = useCallback(() => {
    history.push(generatePath(ROUTES.ENTITY_SETTINGS, { id: entityInfo.id }))
  }, [history, entityInfo])

  if (isShowLoader || !entityInfo?.id) {
    return (
      <Grid container xs={12} spacing={3}>
        <Grid item xs={6}>
          <Skeleton height={300} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton className={styles.headerLoader} height={300} />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Grid container className={styles.tabButtonsHeader}>
        <Grid item justifyContent="flex-start">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Tabs tabs={tabs} selected={tab} handleChange={handleChangeTab} />
          </Box>
        </Grid>
        <Grid item justifyContent="flex-end" className={styles.headerButtons}>
          <Box display="flex" justifyContent="flex-end">
            {entityInfo.salesforceLink && (
              <LinkButton
                className={styles.salesforceLinkIcon}
                href={entityInfo.salesforceLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SalesforceIcon className={styles.salesforceLinkIcon} />
              </LinkButton>
            )}
            {isLoadingDuplicates ? (
              <Box mr={2.5}>
                <Skeleton width={196} height={32} />
              </Box>
            ) : (
              duplicatesCount - rejectedDuplicatesCount > 0 &&
              !isInvestor && (
                <Button
                  variant="outlined"
                  onClick={() => setIsDuplicatesModalShown(true)}
                  className={[styles.button, styles.warningLink].join(' ')}
                >
                  <WarningIconOutline className={styles.warningIconOutline} />
                  {duplicatesCount - rejectedDuplicatesCount + '  Potential Duplicates'}
                </Button>
              )
            )}
            <SettingsIcon onClick={goToSettings} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={styles.entityInfoGrid}>
        <Grid item className={styles.headerContainer} xs={6} lg={6}>
          <div className={styles.dashboardBlock}>
            <div className={styles.dashboardBlockTitleWrapper}>
              <div className={styles.dashboardBlockTitleWrapper}>
                {entityInfo.iconUrl && (
                  <div className={styles.dashboardAvatarWrapper}>
                    <img
                      className={styles.dashboardAvatarImg}
                      src={entityInfo.iconUrl}
                      alt={entityInfo.name}
                    />
                  </div>
                )}
                {!entityInfo.iconUrl && (
                  <div className={styles.dashboardBlockTitle}>{entityInfo.name}</div>
                )}
                {entityInfo.website && (
                  <ExternalLink
                    className={styles.dashboardBlockTitleLink}
                    title={entityInfo.website}
                    link={addHttp(entityInfo.website)}
                  />
                )}
              </div>
              {entityInfo.parent ? (
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Parent</div>
                  <LinkButton
                    className={cn(styles.dashboardBlockContentValue, styles.link)}
                    to={generatePath(ROUTES.ENTITY_PAGE, { id: entityInfo.parent.id })}
                    component={Link}
                  >
                    {entityInfo.parent.name}
                  </LinkButton>
                </div>
              ) : null}
            </div>

            <div className={styles.dashboardBlockTitleWrapper}>
              <div className={styles.dashboardBlockContent}>
                <div className={styles.dashboardBlockContentLabel}>Industries</div>
                <div className={styles.dashboardBlockContentValue}>
                  {entityInfo.industries ? entityInfo.industries : '-'}
                </div>
              </div>
              <div className={styles.dashboardBlockContent}>
                <div className={styles.dashboardBlockContentLabel}>Sub-Type</div>
                <div className={styles.dashboardBlockContentValue}>{entityInfo.type}</div>
              </div>
            </div>

            <div
              className={cn(
                styles.dashboardBlockTitleWrapper,
                styles.dashboardBlockTitleWrapperWithBorder,
              )}
            >
              <div className={styles.dashboardBlockContent}>
                <div className={styles.dashboardBlockContentLabel}>Description</div>
                <div
                  className={cn(
                    styles.dashboardBlockContentValue,
                    styles.dashboardBlockContentValueDescription,
                  )}
                >
                  {entityInfo.description}
                </div>
              </div>
            </div>

            {entityInfo.subsidiaries?.length > 0 && (
              <div className={styles.dashboardBlockTitleWrapper}>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Subsidiaries</div>
                  <div className={cn(styles.dashboardBlockContentValue, styles.subsidiariesLinks)}>
                    {entityInfo.subsidiaries.map((item) => (
                      <LinkButton
                        key={item.id}
                        className={cn(
                          styles.dashboardBlockContentValue,
                          styles.dashboardBlockContentValueDescription,
                          styles.link,
                        )}
                        component={Link}
                        to={generatePath(ROUTES.ENTITY_PAGE, {
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </LinkButton>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Grid>

        {!isInvestor ? (
          <EntityDashboardSummaryMetrics entityInfo={entityInfo} currentTab={tab} />
        ) : (
          <InvestorDashboardSummaryMetrics investorData={investorData} />
        )}
      </Grid>

      {isConfirmModalShown && (
        <WarningModal
          onCancel={handleCancelMerge}
          onConfirm={handleConfirmMerge}
          warningMessage={`Are you sure you want to merge ${mergeEntity?.oldName} into ${mergeEntity?.newName}?`}
          isLoading={isButtonLoading}
        />
      )}

      {isDuplicatesModalShown && !isConfirmModalShown && duplicatesCount && (
        <Modal
          open
          title="Potential Duplicate(s)"
          onCancel={() => setIsDuplicatesModalShown(false)}
        >
          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={genericSs.tableTextLeft} colSpan={2}>
                    <div>Select Primary Entity</div>
                  </TableCell>
                  <TableCell className={genericSs.tableTextLeft}>
                    <div>Action</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entityInfo?.entitiesWithDuplicates?.map(
                  (duplicate: IEntitiesWithDuplicates, index: number) => (
                    <SelectDuplicateEntity
                      key={index}
                      originalEntity={entityInfo}
                      potentialDuplicate={duplicate}
                      handleMergeEntities={handleMergeEntities}
                      handleUpdateDuplicateEntity={handleUpdateDuplicateEntity}
                      setIsDuplicatesModalShown={setIsDuplicatesModalShown}
                    />
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal>
      )}

      {isConfirmWithConflictModalShown && (
        <EntityMergeConflictModal
          handleCloseConfirmWithConflictModal={handleCloseConfirmWithConflictModal}
          handleConfirmWithConflicts={handleConfirmWithConflicts}
        />
      )}
    </Grid>
  )
}

export default EntityDashboardHeader
