import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import styles from './SaveState.module.scss'

import { ReactComponent as CheckIcon } from '@assets/images/check-mark-icon.svg'
import { SAVED_LABEL_DELAY } from '../../../constants/common'
import { usePrevious } from '../../../hooks/usePrevious'

interface IProps {
  className?: string
  isSaving: boolean
  isSaved: boolean
}

const SaveState = ({ className, isSaving, isSaved }: IProps) => {
  const [isSuccessful, setIsSuccessful] = useState(false)

  const wasSaving = usePrevious<boolean>(isSaving, false)

  useEffect(() => {
    if (wasSaving && !isSaving) {
      if (!isSaved) {
        return
      }
      setTimeout(() => {
        setIsSuccessful(false)
      }, SAVED_LABEL_DELAY)
      setIsSuccessful(true)
    }
  }, [isSaving, isSaved, wasSaving])

  return (
    <div className={cn(styles.container, className)}>
      {isSaving && <div className={styles.overlay} />}
      {!isSaving && isSuccessful && <CheckIcon />}
      <span className={styles.label}>{isSaving ? 'Saving...' : isSuccessful ? 'Saved' : ''}</span>
    </div>
  )
}

export default SaveState
