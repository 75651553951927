import React, { useCallback, useMemo, useState } from 'react'
import MuiAutocomplete from '@mui/material/Autocomplete'
import { createFilterOptions } from '@mui/base'
import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { AutocompleteProps } from 'mui-rff'
import { Field, FieldInputProps } from 'react-final-form'
import cn from 'classnames'

import styles from './CreatableSelectField.module.scss'
import { ReactComponent as PlusIcon } from '../../../assets/images/add-select-field.svg'

import { debounceEventHandler } from '../../../helpers/helpers'

export interface IOptionType {
  label?: string
  value: string
  inputValue?: string
}

interface IProps extends Partial<AutocompleteProps<IOptionType, false, false, false>> {
  name: string
  onAddValue: (value: string) => void
  style?: object
  placeholder?: string
  onChangeCustom?: (event: object, newValue: object) => void
  onChangeCustomWithLabel?: (input: FieldInputProps<any, HTMLElement>, newValue: object) => void
  disabledClearable?: boolean
  leftIcon?: React.ReactNode
  height: 'small' | 'medium' | 'large'
  isAsync?: boolean
  loadOptions?: (value: string) => Promise<IOptionType[]>
}

const filter = createFilterOptions<IOptionType>()

const CreatableSelectField = ({
  name,
  value,
  options,
  placeholder,
  style,
  onAddValue,
  helperText,
  disabled,
  disabledClearable,
  className,
  onFocus,
  onBlur,
  tabIndex,
  onChangeCustom,
  onChangeCustomWithLabel,
  leftIcon,
  height,
  isAsync = false,
  loadOptions,
  clearOnBlur,
}: IProps) => {
  const [asyncOptions, setAsyncOptions] = useState<IOptionType[]>([])
  const onChangeDefault = useCallback(
    (newValue: any, input: any) => {
      if (typeof newValue === 'string') {
        setTimeout(() => {
          onAddValue(newValue)
        })
        input.onChange({ value: newValue, label: newValue })
      } else if (newValue && newValue.inputValue) {
        onAddValue(newValue.inputValue)
        input.onChange({ value: newValue.inputValue, label: newValue.inputValue })
      } else {
        input.onChange(newValue)
      }
    },
    [onAddValue],
  )

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation()
    }
  }, [])

  const handleInputChange = useCallback(
    async (
      event: React.ChangeEvent<{}>,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (!isAsync) {
        return
      }
      if (reason === 'input') {
        if (inputValue.length > 1) {
          const loadedOptions = await loadOptions(inputValue)
          setAsyncOptions(loadedOptions)
        } else {
          setAsyncOptions([])
        }
      }
    },
    [isAsync, loadOptions],
  )
  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  const getValue = useCallback((option?: IOptionType) => {
    return typeof option === 'string'
      ? option
      : option?.value !== undefined && option?.label === option?.value
      ? option.value
      : option?.label !== undefined
      ? option
      : option?.value
      ? option.value
      : option?.label === undefined
      ? null
      : option
  }, [])

  return (
    <Field
      name={name}
      render={({ input, meta }) => {
        const { error, touched } = meta || {}
        const isError = !!error && touched

        const handleOpen = () => input.onFocus()
        const handleClose = () => input.onBlur()

        return (
          <MuiAutocomplete
            key={options?.length}
            value={getValue(value || input.value)}
            className={cn(styles.root, className, {
              [styles.inputFieldLargeHeight]: height === 'large',
            })}
            onChange={(event, newValue) =>
              // @ts-ignore
              onChangeCustomWithLabel
                ? // @ts-ignore
                  onChangeCustomWithLabel(input, newValue)
                : onChangeCustom
                ? // @ts-ignore
                  onChangeCustom(event, newValue)
                : onChangeDefault(newValue, input)
            }
            filterOptions={(currentOptions, params) => {
              // @ts-ignore
              const filtered = filter(currentOptions, params)

              // Suggest the creation of a new value
              if (
                params.inputValue !== '' &&
                filtered.findIndex((option) => option.value === params.inputValue) === -1
              ) {
                filtered.push({
                  inputValue: params.inputValue,
                  value: params.inputValue,
                  label: `New Value: ${params.inputValue}`,
                })
              }

              return filtered
            }}
            selectOnFocus
            handleHomeEndKeys
            options={isAsync ? asyncOptions : options}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue
              }
              // Regular option
              return option.label
            }}
            // tslint:disable-next-line:no-shadowed-variable
            isOptionEqualToValue={(option: any, value: any) =>
              option?.value === (value && value?.value ? value.value : value)
            }
            renderOption={(props, option: any) =>
              option.label && option.label.includes('New Value: ') ? (
                <li {...props} key={option.value}>
                  {' '}
                  {option.value} <PlusIcon className={styles.endAdorn} />
                </li>
              ) : (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )
            }
            style={style}
            tabIndex={tabIndex}
            freeSolo
            disableClearable={disabledClearable}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                placeholder={placeholder}
                helperText={isError ? error : helperText}
                error={isError}
                FormHelperTextProps={{ classes: { root: styles.error } }}
                InputProps={{
                  ...params.InputProps,
                  className: cn(styles.inputField, {
                    [styles.inputFieldMediumHeight]: height === 'medium',
                    [styles.inputFieldLargeHeight]: height === 'large',
                  }),
                  startAdornment: <InputAdornment position="start"> {leftIcon}</InputAdornment>,
                  disableUnderline: true,
                }}
                variant="standard"
                onKeyDown={(e) => handleKeyDown(e)}
              />
            )}
            onOpen={handleOpen}
            onClose={handleClose}
            disabled={disabled}
            classes={{
              inputRoot: styles.input,
              popper: styles.popper,
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            onInputChange={debounceHandleInputChange}
            clearOnBlur={clearOnBlur}
          />
        )
      }}
    />
  )
}

CreatableSelectField.defaultProps = {
  height: 'small',
}

export default CreatableSelectField
