import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import moment from 'moment'
import cn from 'classnames'

import styles from './DilutionByCustomer.module.scss'
import genericSs from '@styles/generic.module.scss'

import TableFooter from '../Common/TableFooter'
import { debounceEventHandler, formatPrice, formatPercent } from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader/TableLoader'
import { IClientGeneralLedgerDilutionByCustomerData } from '@common/interfaces/clientGeneralLedger'
import { ExpandAndMinimize } from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import DatePicker from '../Common/DatePicker'
import {
  IDebtorIneligibleCategories,
  IneligibleCategory,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import { WorkflowTypes } from '@common/interfaces/notes'
import useTable from '../../hooks/useTable'
import ActiveToolbar from '../ActiveToolbar'

const filtersValidate = buildFiltersValidateSchema(DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG)

interface IProps {
  workflow: string
  dilutionByCustomer: ILoadingData<IClientGeneralLedgerDilutionByCustomerData>
  listDilutionByCustomer: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) => void
  hideDilutionByCustomer: () => void
  refreshCounter?: number
  debtorIneligibleCategories: ILoadingData<IDebtorIneligibleCategories>
}

const DilutionByCustomer = ({
  workflow,
  dilutionByCustomer,
  listDilutionByCustomer,
  hideDilutionByCustomer,
  refreshCounter,
  debtorIneligibleCategories,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [isModalShown, setIsModalShown] = useState(false)
  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [isInitialized, setIsInitialized] = useState(false)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'dilutionByCustomer',
    filtersDefaults,
    sortDefault: {
      field: 'invoices',
      direction: 'DESC',
    },
  })

  const {
    isLoading,
    dilutionByCustomerData,
    dilutionByCustomerDataLength,
    dilutionByCustomerDataTotals,
    dilutionByCustomerDataTotalItems,
    debtors,
  } = useMemo(
    () => ({
      isLoading: dilutionByCustomer.isLoading,
      dilutionByCustomerData: dilutionByCustomer?.data?.data || [],
      dilutionByCustomerDataLength: dilutionByCustomer?.data?.data?.length || 0,
      dilutionByCustomerDataTotals: dilutionByCustomer?.data?.totals,
      dilutionByCustomerDataTotalItems: dilutionByCustomer?.data?.totals?.totalItems || 0,
      debtors: dilutionByCustomer?.data?.totals?.debtors || [],
    }),
    [dilutionByCustomer],
  )

  const ineligibleCategories = useMemo(
    () => debtorIneligibleCategories.data?.ineligibleCategories || [],
    [debtorIneligibleCategories],
  )

  useEffect(() => {
    if (!isInitialized && dilutionByCustomer.data) {
      if (workflow === WorkflowTypes.clientPage) {
        if (dilutionByCustomer.data?.totals?.maxDate) {
          setCurrentDateRange({
            startDate: moment
              .max([
                moment(dilutionByCustomer.data?.totals?.minDate),
                moment(dilutionByCustomer.data?.totals?.maxDate).subtract(1, 'year'),
              ])
              .format('YYYY-MM-DD'),
            endDate: dilutionByCustomer.data?.totals?.maxDate,
          })
        }
      } else {
        setCurrentDateRange({
          startDate: dilutionByCustomer.data?.totals?.minDate || '',
          endDate: dilutionByCustomer.data?.totals?.maxDate || '',
        })
      }
      setIsInitialized(true)
    }
  }, [isInitialized, dilutionByCustomer, workflow])

  useEffect(() => {
    return () => {
      hideDilutionByCustomer()
    }
  }, [hideDilutionByCustomer])

  const datesBoundary = useMemo(
    () => ({
      minDate: dilutionByCustomerDataTotals?.minDate || null,
      maxDate: dilutionByCustomerDataTotals?.maxDate || null,
    }),
    [dilutionByCustomerDataTotals],
  )

  const fetchDilutionByCustomer = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
      }
      listDilutionByCustomer(id, params)
    },
    [listDilutionByCustomer, id, currentDateRange],
  )

  const debounceListDilutionByCustomer = useMemo(
    () => debounceEventHandler(fetchDilutionByCustomer, 500),
    [fetchDilutionByCustomer],
  )

  useEffect(() => {
    debounceListDilutionByCustomer({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListDilutionByCustomer])

  const loadMore = useCallback(() => {
    fetchDilutionByCustomer({
      loadMore: true,
      page: Math.ceil(dilutionByCustomerDataLength / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [dilutionByCustomerDataLength, orderBy, filters, fetchDilutionByCustomer])

  const refetchDilutionByCustomer = useCallback(() => {
    fetchDilutionByCustomer({
      page: 0,
      perPage: dilutionByCustomerDataLength,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [dilutionByCustomerDataLength, orderBy, filters, fetchDilutionByCustomer])

  useEffect(() => {
    if (refreshCounter) {
      refetchDilutionByCustomer()
    }
  }, [refreshCounter, refetchDilutionByCustomer])

  const handleModalChange = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const filtersConfig = useMemo(
    () =>
      DILUTION_BY_CUSTOMER_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'debtor'
            ? debtors.map((item) => ({
                label: item,
                value: item,
              }))
            : item.options,
      })).map((filter) =>
        filter.type === 'quickFilter'
          ? {
              ...filter,
              quickFilters: [
                {
                  title: 'Eligible Customers',
                  isHidden: true,
                  filters: {
                    ineligible_category: [
                      IneligibleCategory.Eligible,
                      IneligibleCategory.Foreign,
                      IneligibleCategory.Government,
                      IneligibleCategory.Intercompany,
                      IneligibleCategory.Other,
                      IneligibleCategory.Distressed,
                      IneligibleCategory.Affiliate,
                    ].filter((category) => ineligibleCategories.includes(category)),
                  },
                },
              ],
            }
          : filter,
      ),
    [debtors, ineligibleCategories],
  )

  const totalRow = useMemo(
    () =>
      dilutionByCustomerData
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (result, row) => {
            result.invoices += row.invoices
            result.payments += row.payments
            result.dilutiveCredits += row.dilutiveCredits
            result.other += row.other

            return result
          },
          {
            invoices: 0,
            payments: 0,
            dilutiveCredits: 0,
            other: 0,
          },
        ),
    [dilutionByCustomerData, activeItems],
  )

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <TableContainer
        className={styles.table}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title={
                <Box mr={2}>
                  <h2>{workflow === WorkflowTypes.clientPage ? 'Dilution Summary' : ''}</h2>
                </Box>
              }
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  <DatePicker
                    reportingPeriod={ReportingPeriods.Monthly}
                    currentDateRange={currentDateRange}
                    datesBoundary={datesBoundary}
                    onChange={handleDateChange}
                  />

                  <ExpandAndMinimize action={handleModalChange} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />
        <Table ref={wrapperRef}>
          <TableHead>
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTable">
            {isLoading ? (
              <TableLoader columnsCount={filtersConfig.length} />
            ) : (
              dilutionByCustomerDataLength > 0 && (
                <InfiniteScroll
                  dataLength={dilutionByCustomerDataLength}
                  next={loadMore}
                  hasMore={dilutionByCustomerDataLength < dilutionByCustomerDataTotalItems}
                  loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
                  scrollableTarget="scrollableTable"
                >
                  {dilutionByCustomerData.map((item, index) => (
                    <TableRow
                      key={item.debtor}
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextLeft}>{item.debtor}</TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(item.invoices)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(item.payments)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(item.dilutiveCredits)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(item.other)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPercent(item.dilution)}
                      </TableCell>
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={genericSs.tableTextLeft}>Total</TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(dilutionByCustomerDataTotals?.invoices)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(dilutionByCustomerDataTotals?.payments)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(dilutionByCustomerDataTotals?.dilutiveCredits)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                <span className={genericSs.pricePrefix}>$</span>
                {formatPrice(dilutionByCustomerDataTotals?.other)}
              </TableCell>
              <TableCell className={genericSs.tableTextRight}>
                {formatPercent(dilutionByCustomerDataTotals?.dilution)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>

        <ActiveToolbar
          activeItems={activeItems}
          className={styles.activeToolbar}
          containerRef={wrapperRef}
          resetActiveItems={resetActiveItems}
          isFullscreen={isModalShown}
        >
          <div className={genericSs.tableTextRight}>
            <span className={genericSs.pricePrefix}>$</span>
            {formatPrice(totalRow?.invoices)}
          </div>
          <div className={genericSs.tableTextRight}>
            <span className={genericSs.pricePrefix}>$</span>
            {formatPrice(totalRow?.payments)}
          </div>
          <div className={genericSs.tableTextRight}>
            <span className={genericSs.pricePrefix}>$</span>
            {formatPrice(totalRow?.dilutiveCredits)}
          </div>
          <div className={genericSs.tableTextRight}>
            <span className={genericSs.pricePrefix}>$</span>
            {formatPrice(totalRow?.other)}
          </div>
          <div className={genericSs.tableTextRight}>
            {formatPercent(
              totalRow.invoices && totalRow.dilutiveCredits
                ? Math.abs(totalRow.dilutiveCredits / totalRow.invoices)
                : 0,
            )}
          </div>
        </ActiveToolbar>
      </TableContainer>
    </FullscreenModal>
  )
}

export default DilutionByCustomer
