import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import OngoingReportingARGeneralLedgerPage from './OngoingReportingARGeneralLedgerPage'

import { ongoingReporting } from '../../redux/ongoingReporting/selectors'
import {
  showOngoingReporting,
  updateOngoingReportingStatus,
} from '../../redux/ongoingReporting/actions'
import { updateGeneralLedger, listTypeMapping } from '../../redux/generalLedger/actions'
import { typeMapping } from '../../redux/generalLedger/selectors'
import { entities } from '../../redux/entityInfo/selectors'
import { getDebtorIneligibleCategories } from '../../redux/options/actions'

const selector = createStructuredSelector({
  data: ongoingReporting,
  entities,
  typeMapping,
})

const actions = {
  showOngoingReporting,
  updateGeneralLedger,
  listTypeMapping,
  updateOngoingReportingStatus,
  getDebtorIneligibleCategories,
}

export default connect(selector, actions)(OngoingReportingARGeneralLedgerPage)
