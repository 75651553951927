import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Form, FormSpy } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import { OVERPAYABLE_INTERVAL_VALUES } from '@common/constants/client'
import { ILoadingData } from '../../redux/types'
import { diff } from 'deep-object-diff'
import TableLoader from '../Common/TableLoader'
import { FieldArray } from 'react-final-form-arrays'
import Card from '../Common/Card'
import ActiveToolbar from '../ActiveToolbar'
import SaveState from '../Common/SaveState'
import Checkbox from '../Common/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import TextField from '../Common/TextField'
import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'

import styles from './BBCApIneligibilityTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import {
  debounceEventHandler,
  handleMultipleSelect,
  formatPrice,
  formatDate,
  voidHandler,
} from '../../helpers/helpers'
import { IExtraReserve, IExtraReserveData } from '@common/interfaces/bbc'

import TableFiltersRow from '../Common/TableFiltersRow'
import Autocomplete from '../Common/Autocomplete'
import arrayMutators from 'final-form-arrays'
import { usePermissions } from '../../helpers/permissionContext'
import { CLIENT_AP_ELIGIBILITY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import Box from '@mui/material/Box'
import { ENTITY_SUB_TYPE_OPTIONS } from '@common/interfaces/entityInfo'
import EntityPreview from '../EntityPreview'
import { WorkflowTypes } from '@common/interfaces/notes'

const filtersValidate = buildFiltersValidateSchema(CLIENT_AP_ELIGIBILITY_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(CLIENT_AP_ELIGIBILITY_LIST_FILTERS_CONFIG)

const mutators = {
  ...arrayMutators,
}

interface IProps {
  apEligibilityData: ILoadingData<IExtraReserveData>

  listApEligibility: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      orderBy?: string
      orderDirection?: string

      filters?: object
    },
  ) => Promise<{ data: IExtraReserveData }>
  updateApEligibility: (id: string, itemId: string, data: object) => Promise<IExtraReserve>
}

const BBCApEligibilityTableRow = ({
  index,
  item,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
}: {
  index: number
  item: IExtraReserve
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
}) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])
  const isPriority = item.priorityPayable ? true : false
  const { isParticipant } = usePermissions()

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={item.id}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextLeft}>
        <LinkButton
          component={Link}
          to={generatePath(ROUTES.ENTITY_PAGE, {
            id: item.entityId,
          })}
          className={styles.entityLink}
        >
          {item.creditor}
        </LinkButton>
        {item.entityId && !isParticipant && (
          <EntityPreview
            id={item.entityId}
            workflow={WorkflowTypes.clientPage}
            isEligiblePage
            className={styles.entityPreviewIcon}
          />
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Autocomplete
          className={cn('focusableInput', styles.ineligibleCategoryList)}
          tabIndex={4 * index}
          name={`apEligibility[${index}].type`}
          options={ENTITY_SUB_TYPE_OPTIONS}
        />
      </TableCell>
      <TableCell className={genericSs.tableTextCenter}>
        <div className={styles.disabledCheckbox}>
          <Checkbox checked={isPriority} color="primary" disabled={true} />
        </div>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {item.lastAmount && '$' + formatPrice(item.lastAmount)}
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        <Autocomplete
          label=""
          className={cn('focusableInput', styles.ineligibleCategoryList)}
          tabIndex={4 * index + 1}
          name={`apEligibility[${index}].priorityPayable`}
          options={OVERPAYABLE_INTERVAL_VALUES}
        />
      </TableCell>

      <TableCell className={genericSs.tableTextLeft}>
        {item.lastActive && formatDate(item.lastActive)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip
          title={item.notes}
          placement="top"
          disableFocusListener={item.notes?.length < 18 || !item.notes}
          disableTouchListener
          disableHoverListener
        >
          <TextField
            className="focusableInput"
            tabIndex={4 * index + 2}
            name={`apEligibility[${index}].notes`}
            placeholder="Notes"
            fullWidth={false}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

const BBCApIneligibilityTable = ({
  apEligibilityData,
  listApEligibility,
  updateApEligibility,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const {
    isLoading,
    isSaving,
    isSaved,
    data: result,
  } = useMemo(() => apEligibilityData, [apEligibilityData])

  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: 'creditor',
    direction: 'ASC',
  })

  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])

  const itemsCount = result?.data.length

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems),
    [activeItems],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const fetchApEligibleList = useCallback(
    async (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        perPage: data.perPage || PER_PAGE,
      }

      await listApEligibility(id, params)

      if (!data.loadMore) {
        setActiveItems([])
      }
    },
    [id, listApEligibility],
  )

  const debounceApEligibleList = useMemo(
    () => debounceEventHandler(fetchApEligibleList, 500),
    [fetchApEligibleList],
  )

  useEffect(() => {
    debounceApEligibleList({
      page: 0,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [filters, orderBy, debounceApEligibleList])

  const refetchApEligibilityList = useCallback(
    (skipLoader: boolean = false) => {
      fetchApEligibleList({
        page: 0,
        perPage: itemsCount,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        skipLoader,
      })
    },
    [itemsCount, filters, orderBy, fetchApEligibleList],
  )

  const activeItemsIds = useMemo(
    () => result?.data.filter((_, index) => activeItems.includes(index)).map(({ id }) => id),
    [activeItems, result],
  )

  const handleUpdateApEligibility = useCallback(
    async (itemId: string, data: Partial<IExtraReserve>, activeItemsIds: string[]) => {
      if (activeItemsIds.length > 1 && activeItemsIds.includes(itemId)) {
        await updateApEligibility(id, activeItemsIds[0], {
          ...data,
          itemId: activeItemsIds,
        })
      } else {
        await updateApEligibility(id, itemId, data)
      }
      refetchApEligibilityList(true)
    },
    [id, updateApEligibility, refetchApEligibilityList],
  )

  const handleUpdateApEligibilityDebounce = useMemo(
    () =>
      debounceEventHandler(
        async (itemId: string, data: Partial<IExtraReserve>, activeItemsIds: string[]) => {
          await handleUpdateApEligibility(itemId, data, activeItemsIds)
        },
        1000,
      ),
    [handleUpdateApEligibility],
  )

  const handleUpdate = useCallback(
    (props) => {
      if (props.dirty) {
        const changedRows = diff(props.initialValues.apEligibility, props.values.apEligibility)
        if (!Object.entries(changedRows).length) {
          return
        }
        const [[updatedRowIndex, updatedData]] = Object.entries(changedRows)
        if (Object.keys(updatedData).includes('priorityPayable')) {
          handleUpdateApEligibility(
            props.values.apEligibility[updatedRowIndex].id,
            {
              priorityPayable: updatedData.priorityPayable?.value || null,
            },
            activeItemsIds,
          )
        } else if (Object.keys(updatedData).includes('type')) {
          handleUpdateApEligibility(
            props.values.apEligibility[updatedRowIndex].id,
            {
              type: updatedData.type?.value || null,
            },
            activeItemsIds,
          )
        } else {
          Object.keys(updatedData).forEach((field) => {
            if (updatedData[field] === undefined) {
              updatedData[field] = null
            }
          })
          handleUpdateApEligibilityDebounce(
            props.values.apEligibility[updatedRowIndex].id,
            updatedData,
            activeItemsIds,
          )
        }
      }
    },
    [handleUpdateApEligibility, handleUpdateApEligibilityDebounce, activeItemsIds],
  )

  const creditors = useMemo(() => {
    if (!result?.dictionary?.creditors) {
      return []
    }
    return result.dictionary.creditors.map((item) => ({
      label: item,
      value: item,
    }))
  }, [result])

  const filtersConfig = useMemo(
    () =>
      CLIENT_AP_ELIGIBILITY_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options: item.field === 'creditor' ? creditors : item.options,
      })),
    [creditors],
  )

  const loadMore = useCallback(() => {
    fetchApEligibleList({
      loadMore: true,
      page: Math.ceil(result?.data.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [result, orderBy, filters, fetchApEligibleList])

  const totalRow = useMemo(
    () =>
      result?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (summary, row) => {
            summary.lastAmount += row.lastAmount

            return summary
          },
          { lastAmount: 0 },
        ),
    [result, activeItems],
  )

  const initialValues = useMemo(
    () => ({
      apEligibility: (result?.data || []).map((item) => ({
        ...item,
        priorityPayable: item.priorityPayable
          ? {
              value: item.priorityPayable,
              label: item.priorityPayable,
            }
          : null,
      })),
    }),
    [result],
  )

  return (
    <Card noPadding={false} withBorder={true} noHeaderMargin ref={wrapperRef}>
      <Form
        initialValues={initialValues}
        onSubmit={voidHandler}
        mutators={mutators}
        render={(formProps) => (
          <TableContainer
            className={cn(styles.table)}
            isActivable
            onActiveRowsChange={setActiveItems}
            onActiveRowChange={setActiveItem}
          >
            <FormSpy
              subscription={{ initialValues: true, values: true, dirty: true }}
              onChange={handleUpdate}
            />
            <Form
              onSubmit={handleFiltersChange}
              initialValues={filters}
              validate={filtersValidate}
              mutators={{
                setFieldData: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              render={({ values, handleSubmit, form: { mutators } }) => (
                <FilterContainer
                  filters={filtersConfig}
                  handleSubmit={handleSubmit}
                  mutators={mutators}
                  values={values}
                  appliedFilters={filters}
                  title={
                    <Box mr={2}>
                      <h2>AP Eligibility</h2>
                    </Box>
                  }
                />
              )}
            />
            <Table>
              <TableHead>
                <TableFiltersRow
                  filters={filtersConfig}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                />
              </TableHead>
              <TableBody id="scrollableTable">
                {isLoading ? (
                  <TableLoader columnsCount={7} height={24} />
                ) : (
                  result?.data && (
                    <InfiniteScroll
                      dataLength={result?.data.length}
                      next={loadMore}
                      hasMore={result?.data.length < result?.totals.totalItems}
                      loader={<TableLoader columnsCount={7} height={24} rowsCount={1} />}
                      scrollableTarget="scrollableTable"
                    >
                      <FieldArray name="apEligibility">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <BBCApEligibilityTableRow
                              key={name}
                              index={index}
                              item={formProps.values.apEligibility[index]}
                              isActiveRow={activeItems.includes(index)}
                              isCurrentActiveRow={activeItem === index}
                              onSelectRow={handleSelectRow}
                            />
                          ))
                        }
                      </FieldArray>
                    </InfiniteScroll>
                  )
                )}
              </TableBody>
            </Table>

            <Box display="flex" alignItems="center" justifyContent="space-between">
              {result?.totals.totalItems > 0 && (
                <div className={genericSs.itemsCount}>
                  {result.data.length} / {result.totals.totalItems}
                </div>
              )}
              <SaveState isSaving={isSaving} isSaved={isSaved} />
            </Box>

            <ActiveToolbar
              activeItems={activeItems}
              className={styles.activeToolbarWithoutTotal}
              containerRef={wrapperRef}
              resetActiveItems={resetActiveItems}
            >
              <div className={genericSs.tableTextLeft}></div>
              <div className={genericSs.tableTextLeft}>
                <span className={cn(genericSs.pricePrefix, styles.priceAdornment)}>$</span>
                {formatPrice(totalRow?.lastAmount)}
              </div>

              <div> </div>
            </ActiveToolbar>
          </TableContainer>
        )}
      />
    </Card>
  )
}

export default BBCApIneligibilityTable
