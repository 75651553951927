import React from 'react'
import { Avatar as MuiAvatar } from '@mui/material'
import cn from 'classnames'

import styles from './Avatar.module.scss'

import { IClientInfo } from '@common/interfaces/client'
import { IUser } from '@common/interfaces/user'

const Avatar = ({
  user,
  clientInfo,
  size = 'big',
}: {
  user: IUser
  clientInfo?: IClientInfo
  size?: 'small' | 'big'
}) => {
  if (clientInfo) {
    if (clientInfo.iconUrl) {
      return (
        <MuiAvatar
          className={cn(styles.clientIcon, {
            [styles.small]: size === 'small',
          })}
          alt={clientInfo.clientName}
          src={clientInfo.iconUrl}
        />
      )
    }

    return (
      <MuiAvatar
        className={cn({
          [styles.small]: size === 'small',
        })}
        alt={clientInfo.clientName}
      >
        {clientInfo.clientName}
      </MuiAvatar>
    )
  }

  if (!user) {
    return null
  }

  if (user.avatar) {
    return (
      <MuiAvatar
        className={cn({
          [styles.small]: size === 'small',
        })}
        alt={user.firstName + ' ' + user.lastName}
        src={user.avatar}
      />
    )
  }

  return (
    <MuiAvatar
      className={cn({
        [styles.small]: size === 'small',
      })}
      alt={user.firstName + ' ' + user.lastName}
    >
      {user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()}
    </MuiAvatar>
  )
}

export default Avatar
