import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { Link, generatePath } from 'react-router-dom'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import LinkButton from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import { addMonths, subMonths, format, parseISO, min, max } from 'date-fns'
import cn from 'classnames'

import styles from './BBCAPSummaryTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import Card from '../Common/Card'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import DatePicker from '../Common/DatePicker'
import Autocomplete from '../../components/Common/Autocomplete'
import {
  IApSummaryStatsData,
  IBorrowingBase,
  IClientCollateral,
  IExtraReserveData,
  BBC_AGING_REPORT,
  IARAPChart,
} from '@common/interfaces/bbc'
import { debounceEventHandler, formatDate, formatPrice } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import FullscreenModal from '../Common/FullscreenModal'
import SelectField from '../Common/SelectField'
import ChangedValue from '../ChangedValue'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { BBC_AP_SUMMARY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { ROUTES } from '../../constants/routes'
import { maxOutAt99WithTooltip } from '../Common/Helpers/helpers'
import BBCAPDetailRow from '../BBCAPDetailRow'
import FilterContainer from '../Filters/FilterContainer'
import { ExpandAndMinimize, ExpandDetailIcon } from '../Common/Icons'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import { IClientInfo } from '@common/interfaces/client'
import ARAPSummaryOverTime from '../ARAPSummaryOverTime'
import Empty from '../Common/Empty'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import useGraphToggle from '../../hooks/useGraphTogggle'
import { usePermissions } from '../../helpers/permissionContext'
import EntityPreview from '../EntityPreview'
import { WorkflowTypes } from '@common/interfaces/notes'
import MultiCellTooltip, { IMultiCellTotal } from '../MultiCellTooltip'
import useTable from '../../hooks/useTable'

const CELL_INDEXES_MAP = {
  2: 'ap_1To_30Days',
  3: 'ap_31To_60Days',
  4: 'ap_61To_90Days',
  5: 'ap_91PlusDays',
  6: 'total',
  7: 'percent',
}

const PERCENT_CELL_INDEXES = [7]
const TOTALS_CELL_INDEXES = [6, 7]

const BBCAPSummaryCell = ({
  type,
  data,
  changedValue,
  dataTotal,
  rowIndex,
  cellIndex,
  onSelectCell,
  isSelected,
  isActive,
  isTotals = false,
  withChangedValue = true,
}: {
  type: 'currency' | 'percent'
  data: number
  changedValue: number
  dataTotal?: IMultiCellTotal
  rowIndex: number
  cellIndex: number
  onSelectCell: (event: any, rowIndex: number, columnIndex: number) => void
  isSelected: boolean
  isActive: boolean
  isTotals?: boolean
  withChangedValue?: boolean
}) => {
  const handleSelectCell = useCallback(
    (event) => onSelectCell(event, rowIndex, cellIndex),
    [rowIndex, cellIndex, onSelectCell],
  )

  return (
    <TableCell
      className={cn(genericSs.tableTextRight, 'activableCell', {
        [styles.totals]: isTotals,
        activeCell: isSelected,
      })}
      onClick={handleSelectCell}
      data-index={cellIndex}
    >
      <MultiCellTooltip isActive={isActive} data={dataTotal}>
        <div>
          {withChangedValue && (
            <ChangedValue
              className={styles.changedValue}
              isChip
              isPercent={type === 'percent'}
              changedValue={changedValue}
              isPrice={type === 'currency'}
            />
          )}
          {type === 'percent' ? (
            <>{(data * 100 || 0).toFixed(2)}%</>
          ) : (
            <>
              <span className={genericSs.pricePrefix}>$</span>
              {formatPrice(data)}
            </>
          )}
        </div>
      </MultiCellTooltip>
    </TableCell>
  )
}

interface IProps {
  isLoadingBBC: boolean
  bbc: IBorrowingBase
  apSummaryStatsData: ILoadingData<IApSummaryStatsData>
  previousClientCollaterals: IClientCollateral[]
  getBBCAPSummaryStats: (id: string, data: object) => void
  apReserves: ILoadingData<IExtraReserveData>
  refreshCount: number
  isFilesSaving: boolean
  getPreviousBBCClientCollateral: (id: string, params?: object) => void
  title?: string
  isClientPage?: boolean
  isClientUserPage?: boolean
  clientInfo?: IClientInfo
  arapChartData: ILoadingData<IARAPChart>
}

const BBCAPSummaryTable = ({
  isLoadingBBC,
  bbc,
  apSummaryStatsData,
  previousClientCollaterals,
  getBBCAPSummaryStats,
  apReserves,
  refreshCount,
  isFilesSaving,
  getPreviousBBCClientCollateral,
  title = 'AP Summary',
  isClientPage = false,
  isClientUserPage = false,
  clientInfo,
  arapChartData,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const { isParticipant } = usePermissions()

  const [expanded, setExpanded] = useState([])
  const [recordDate, setRecordDate] = useState<string>('')
  const [compareDate, setCompareDate] = useState<string>('')

  const { isLoading, data: apSummaryStats } = useMemo(
    () => apSummaryStatsData,
    [apSummaryStatsData],
  )
  const [isModalShown, setIsModalShown] = useState(false)

  const [currentCompanies, setCurrentCompanies] = useState<any>([])
  const [currentMetric, setCurrentMetric] = useState<string>('value')

  const { isGraphShown, TabsComponent: Tabs } = useGraphToggle({
    defaultTab: isClientUserPage ? 'Chart' : 'Table',
  })

  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [datesBoundary, setDatesBoundary] = useState<{ minDate: string; maxDate: string }>({
    minDate: '',
    maxDate: '',
  })
  const [entitiesOptions, setEntitiesOptions] = useState<{ value: string; label: string }[]>([])

  useEffect(() => {
    if (isClientPage && clientInfo?.id === id) {
      const clientName = clientInfo?.clientName
      getPreviousBBCClientCollateral(id, { isClientPage, clientName })
    }
    if (isClientUserPage && clientInfo?.id) {
      getPreviousBBCClientCollateral(clientInfo.id, {
        isClientUserPage,
        clientName: clientInfo.clientName,
      })
    }
  }, [id, isClientPage, isClientUserPage, clientInfo, getPreviousBBCClientCollateral])

  const filtersConfig = useMemo(
    () =>
      BBC_AP_SUMMARY_LIST_FILTERS_CONFIG.filter(
        (config) =>
          (!isClientPage && !isClientUserPage) ||
          ((isClientPage || isClientUserPage) &&
            !['invoice', 'daysPastInvoice'].includes(config.field)),
      )
        .map((filter) =>
          filter.type === 'quickFilter'
            ? isClientUserPage
              ? null
              : {
                  ...filter,
                  quickFilters: [
                    {
                      title: 'Priority Vendors',
                      isHidden: true,
                      filters: {
                        isPriorityPayable: 'true',
                      },
                    },
                  ],
                }
            : filter,
        )
        .filter(Boolean),
    [isClientPage, isClientUserPage],
  )

  const filtersValidate = buildFiltersValidateSchema(filtersConfig)
  const filtersDefaults = buildFiltersDefaults(filtersConfig)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeCells,
    activeRows,
    handleSelectCell,
    handleSelectActiveRow,
    resetActiveCells,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'apSummary',
    filtersDefaults,
    sortDefault: { field: 'total', direction: 'DESC' },
  })

  const isInitialized = useMemo(() => !!apSummaryStatsData?.data?.data, [apSummaryStatsData])
  const visualizationsParams = useMemo(
    () => ({
      [isClientPage || isClientUserPage ? 'clientId' : 'borrowingBaseId']: id,
    }),
    [isClientPage, isClientUserPage, id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      recordDate,
      compareDate,
    }),
    [filters, recordDate, compareDate],
  )
  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.apSummary,
    params: visualizationsParams,
    filtersConfig: BBC_AP_SUMMARY_LIST_FILTERS_CONFIG,
    filters: visualizationsFilters,
    orderBy,
  })

  const priorityPayables = useMemo(
    () =>
      apReserves?.data?.data
        .filter(({ priorityPayable }) => !!priorityPayable)
        .map(({ creditor }) => creditor) || [],
    [apReserves],
  )

  const debounceListApSummary = useMemo(
    () =>
      debounceEventHandler(
        (data: any) =>
          getBBCAPSummaryStats(isClientUserPage ? clientInfo?.id : id, {
            ...data,
            recordDate,
            compareDate,
            isClientPage,
            isClientUserPage,
            clientName: clientInfo?.clientName,
          }),
        500,
      ),
    [
      id,
      getBBCAPSummaryStats,
      compareDate,
      recordDate,
      isClientPage,
      isClientUserPage,
      clientInfo?.id,
      clientInfo?.clientName,
    ],
  )

  const loadMore = useCallback(() => {
    debounceListApSummary({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      recordDate,
      compareDate,
      page: Math.ceil(apSummaryStats?.data.length / PER_PAGE),
    })
  }, [orderBy, filters, recordDate, compareDate, debounceListApSummary, apSummaryStats])

  const handleRecordDateChange = useCallback(({ target: { value } }) => {
    setRecordDate(value)
  }, [])

  const handleCompareDateChange = useCallback(({ target: { value } }) => {
    setCompareDate(value)
  }, [])

  const handleCompanyChange = useCallback((event, newValue) => {
    setCurrentCompanies([...newValue])
  }, [])

  const handleMetricChange = useCallback(({ target: { value } }) => {
    setCurrentMetric(value)
  }, [])

  const readyToLoad = useMemo(() => {
    if (!isClientUserPage && !isClientPage) {
      return !!id
    }
    return !!recordDate
  }, [id, recordDate, isClientUserPage, isClientPage])

  useEffect(() => {
    refreshCount &&
      readyToLoad &&
      debounceListApSummary({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
        recordDate,
        compareDate,
      })
  }, [orderBy, filters, debounceListApSummary, compareDate, recordDate, refreshCount, readyToLoad])

  const summaryRow = useMemo(
    () =>
      apSummaryStats?.data
        ?.filter((_, index) => activeRows.includes(index))
        .reduce(
          (totalRowResult, row) => {
            totalRowResult.invoiceCount += +row.invoiceCount || 0
            totalRowResult.daysPastInvoice += row.daysPastInvoice / activeRows?.length || 0
            totalRowResult.ap_1To_30Days += row.ap_1To_30Days || 0
            totalRowResult.ap_31To_60Days += row.ap_31To_60Days || 0
            totalRowResult.ap_61To_90Days += row.ap_61To_90Days || 0
            totalRowResult.ap_91PlusDays += row.ap_91PlusDays || 0
            totalRowResult.total += row.total || 0
            totalRowResult.percent = apSummaryStats?.totals.total
              ? +((totalRowResult.total / apSummaryStats?.totals.total) * 100).toFixed(2)
              : 0

            return totalRowResult
          },
          {
            invoiceCount: 0,
            daysPastInvoice: 0,
            ap_1To_30Days: 0,
            ap_31To_60Days: 0,
            ap_61To_90Days: 0,
            ap_91PlusDays: 0,
            total: 0,
            percent: 0,
          },
        ),
    [activeRows, apSummaryStats],
  )

  const previousClientCollateralsDatesOptions = useMemo(() => {
    return [bbc, ...(previousClientCollaterals || [])].filter(Boolean).map((clientCollateral) => ({
      value: clientCollateral.recordDate,
      label: formatDate(clientCollateral.recordDate),
    }))
  }, [bbc, previousClientCollaterals])

  const compareableClientCollaterals = useMemo(() => {
    if (isClientPage) {
      return previousClientCollateralsDatesOptions?.filter((option) => option.value !== recordDate)
    } else {
      return previousClientCollateralsDatesOptions?.slice(1)
    }
  }, [previousClientCollateralsDatesOptions, recordDate, isClientPage])

  const metricOptions = useMemo(() => {
    return [
      { value: 'value', label: '$ Value' },
      { value: 'percent', label: '% of Total' },
    ]
  }, [])

  const companyValues: string[] = useMemo(() => {
    return currentCompanies?.map((company: any) => company?.value)
  }, [currentCompanies])

  const { minBBCDate, maxBBCDate } = useMemo(() => {
    const dateObjects = previousClientCollateralsDatesOptions.map((dateString) =>
      parseISO(dateString.value),
    )
    const maxBBCDate = new Date(max(dateObjects))
    maxBBCDate.setDate(maxBBCDate.getDate() + 1)
    const minBBCDate = new Date(min(dateObjects))
    minBBCDate.setDate(minBBCDate.getDate() - 1)
    return {
      minBBCDate,
      maxBBCDate,
    }
  }, [previousClientCollateralsDatesOptions])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      if (minBBCDate && maxBBCDate) {
        setCurrentDateRange(values)
        let minDate = subMonths(new Date(values.endDate), 24)
        let maxDate = addMonths(new Date(values.startDate), 24)

        minDate = minDate < minBBCDate ? minBBCDate : minDate
        maxDate = maxBBCDate > maxDate ? maxDate : maxBBCDate

        const formattedMinDate = format(minDate, 'yyyy-MM-dd')
        const formattedMaxDate = format(maxDate, 'yyyy-MM-dd')

        setDatesBoundary({
          minDate: formattedMinDate,
          maxDate: formattedMaxDate,
        })
      }
    },
    [setCurrentDateRange, minBBCDate, maxBBCDate],
  )

  useEffect(() => {
    if (bbc?.recordDate) {
      handleDateChange({
        startDate: format(subMonths(new Date(bbc.recordDate), 12), 'yyyy-MM-dd'),
        endDate: bbc.recordDate,
      })
    }
  }, [bbc, handleDateChange, isClientPage])

  useEffect(() => {
    if ((isClientPage || isClientUserPage) && previousClientCollaterals?.[0]?.recordDate) {
      setRecordDate(previousClientCollaterals?.[0]?.recordDate)

      handleDateChange({
        startDate: format(
          subMonths(new Date(previousClientCollaterals?.[0]?.recordDate), 12),
          'yyyy-MM-dd',
        ),
        endDate: previousClientCollaterals?.[0]?.recordDate,
      })
    }
  }, [previousClientCollaterals, handleDateChange, isClientPage, isClientUserPage])

  const handleExpand = useCallback(
    (label: string) => {
      if (expanded.length === 0) {
        setIsModalShown(true)
      }
      setExpanded((values) =>
        values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
      )
    },
    [expanded],
  )

  const toggleModal = useCallback(() => {
    setIsModalShown((prev) => !prev)
    resetActiveCells()
  }, [resetActiveCells])

  const totalCell: IMultiCellTotal = useMemo(() => {
    const rowIndexesWithActiveCells = activeCells
      .map((indexes, index) => (indexes?.length ? index : null))
      .filter((index) => index !== null)

    const cellIndexesWithActiveCells = [...new Set(activeCells.flat().filter(Boolean))]
    const filteredData = {
      fields: cellIndexesWithActiveCells.map((cellIndex) => CELL_INDEXES_MAP[cellIndex]),
      amounts: [] as number[],
      values: [] as number[],
    }

    const isSameType =
      cellIndexesWithActiveCells.every((cellIndex) => PERCENT_CELL_INDEXES.includes(cellIndex)) ||
      cellIndexesWithActiveCells.every((cellIndex) => !PERCENT_CELL_INDEXES.includes(cellIndex))
    rowIndexesWithActiveCells.forEach((rowIndex) => {
      activeCells[rowIndex].forEach((cellIndex) => {
        filteredData.values.push(apSummaryStats?.data[rowIndex][CELL_INDEXES_MAP[cellIndex]])
        if (!PERCENT_CELL_INDEXES.includes(cellIndex)) {
          filteredData.amounts.push(apSummaryStats?.data[rowIndex][CELL_INDEXES_MAP[cellIndex]])
        }
      })
    })
    const amountSum = filteredData.amounts.reduce(
      (result: number, amount: number) => result + amount,
      0,
    )
    const sum = filteredData.values.reduce((result: number, amount: number) => result + amount, 0)

    return {
      type: cellIndexesWithActiveCells.every((cellIndex) =>
        PERCENT_CELL_INDEXES.includes(cellIndex),
      )
        ? 'percent'
        : 'currency',
      count: filteredData.values.length,
      sum,
      avg: isSameType
        ? filteredData.values.length
          ? sum / filteredData.values.length
          : 0
        : filteredData.amounts.length
        ? amountSum / filteredData.amounts.length
        : 0,
    }
  }, [activeCells, apSummaryStats])

  const { isLoading: isChartDataLoading } = useMemo(() => arapChartData, [arapChartData])

  const handlClearCompareDate = useCallback(() => {
    setCompareDate('')
  }, [setCompareDate])

  useEffect(() => {
    if (recordDate === compareDate) {
      handlClearCompareDate()
    }
  }, [recordDate, compareDate, handlClearCompareDate])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card id="ap-summary" className={styles.cardContainer} noHeaderMargin ref={wrapperRef}>
        <TableContainer
          className={cn({
            [styles.table]: !isClientPage && !isClientUserPage,
            [styles.tableClientPage]: isClientPage,
            [styles.tableClientUserPage]: isClientUserPage,
          })}
          hasFooter
        >
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                appliedQuickFilter={quickFilter}
                handleAppliedQuickFilterChange={handleQuickFilterChange}
                withFilters={!isGraphShown}
                withFullSearch={!isGraphShown}
                title={
                  <Box
                    mr={2}
                    className={cn({
                      [styles.clientUserTitle]: isClientUserPage && !isGraphShown,
                    })}
                  >
                    <h2>
                      {isClientPage && isGraphShown ? title + ' Over Time' : title}
                      {isGraphShown && isChartDataLoading && (
                        <CircularProgress className={styles.loadingIndicator} size={18} />
                      )}
                    </h2>
                    {isClientUserPage && !isGraphShown && recordDate && (
                      <span className={styles.asOfLabel}>as of {formatDate(recordDate)}</span>
                    )}
                  </Box>
                }
                actionsSize={!isGraphShown && isClientUserPage ? 6 : 7}
                actions={
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                    {!isGraphShown ? (
                      <>
                        {(isClientPage || isClientUserPage) && (
                          <div className={styles.recordDateWrapper}>
                            <SelectField
                              key={recordDate}
                              label="Date"
                              variant="outlined"
                              className={styles.selectField}
                              useFinalForm={false}
                              name="recordDate"
                              options={previousClientCollateralsDatesOptions}
                              onChange={handleRecordDateChange}
                              value={recordDate}
                              defaultValue=""
                              withTopLabel
                            />
                          </div>
                        )}
                        {!isClientUserPage && (
                          <div className={styles.recordDateWrapper}>
                            <SelectField
                              key={compareDate}
                              label="Compare"
                              variant="outlined"
                              className={styles.selectField}
                              useFinalForm={false}
                              name="compareDate"
                              options={compareableClientCollaterals}
                              onChange={handleCompareDateChange}
                              value={compareDate}
                              defaultValue=""
                              withTopLabel
                              handleClear={handlClearCompareDate}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className={styles.recordDateWrapper}>
                          <SelectField
                            key={currentMetric}
                            label="Mertic"
                            variant="outlined"
                            className={styles.selectField}
                            useFinalForm={false}
                            name="recordDate"
                            options={metricOptions}
                            onChange={handleMetricChange}
                            value={currentMetric}
                            defaultValue=""
                          />
                        </div>
                        <div className={styles.recordDateWrapper}>
                          <Autocomplete
                            key={currentCompanies?.[0]}
                            withTopLabel
                            label={isClientUserPage ? 'Vendor' : 'Company'}
                            className={cn(styles.selectField, styles.selectFieldWide)}
                            value={currentCompanies}
                            name="currentCompanies"
                            options={entitiesOptions}
                            getOptionLabel={(option: any) => option?.label || option || ''}
                            getOptionValue={(option: any) => option?.value || option || ''}
                            onChange={handleCompanyChange}
                            defaultValue=""
                            // @ts-ignore
                            multiple
                            hideTags
                          />
                        </div>
                        <div className={styles.recordDateWrapper}>
                          <DatePicker
                            currentDateRange={currentDateRange}
                            datesBoundary={datesBoundary}
                            onChange={handleDateChange}
                          />
                        </div>
                      </>
                    )}
                    <div className={styles.recordDateWrapper}>{Tabs}</div>
                    <ExpandAndMinimize action={toggleModal} isExpanded={isModalShown} />
                  </Box>
                }
              />
            )}
          />
          {!previousClientCollaterals.length && !bbc ? (
            <Empty
              message={
                isClientUserPage ? 'No data is available' : 'No payables exist for this client'
              }
            />
          ) : !isGraphShown ? (
            <>
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={filtersConfig}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                    isChildrenAtStart
                  >
                    <TableCell />
                  </TableFiltersRow>
                </TableHead>
                <TableBody id="scrollableARSummaryTable">
                  {isFilesSaving || isLoadingBBC || isLoading ? (
                    <TableLoader columnsCount={10} />
                  ) : (
                    apSummaryStats?.data.length > 0 && (
                      <InfiniteScroll
                        dataLength={apSummaryStats?.data.length}
                        next={loadMore}
                        hasMore={
                          apSummaryStats?.data.length < apSummaryStats?.data?.[0]?.totalCount
                        }
                        loader={<TableLoader columnsCount={10} rowsCount={1} />}
                        scrollableTarget="scrollableARSummaryTable"
                      >
                        {apSummaryStats?.data.map((item, index) => {
                          const isExpanded = expanded.includes(item.creditor)

                          return (
                            <React.Fragment key={item.creditor}>
                              <TableRow data-index={index} className="activableRow">
                                <TableCell className={genericSs.tableTextCenter}>
                                  {item?.invoiceCount > 0 && (
                                    <ExpandDetailIcon
                                      onClick={() => handleExpand(item.creditor)}
                                      isExpanded={isExpanded}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  className={genericSs.tableTextLeft}
                                  onClick={(event) => handleSelectActiveRow(event, index)}
                                >
                                  <Tooltip
                                    title={item.creditor || ''}
                                    placement="top"
                                    disableHoverListener={
                                      item.creditor?.length < 20 || !item.creditor
                                    }
                                    disableTouchListener
                                  >
                                    {item?.entityId && !isParticipant && !isClientUserPage ? (
                                      <LinkButton
                                        component={Link}
                                        to={generatePath(ROUTES.ENTITY_PAGE, {
                                          id: item.entityId,
                                        })}
                                        className={cn(styles.entityLink, {
                                          [styles.entityLinkShort]:
                                            !isClientUserPage &&
                                            (priorityPayables.includes(item.creditor) ||
                                              item.priorityPayable),
                                        })}
                                      >
                                        {item.creditor}
                                      </LinkButton>
                                    ) : (
                                      <span>{item.creditor}</span>
                                    )}
                                  </Tooltip>

                                  {item?.entityId && !isParticipant && !isClientUserPage && (
                                    <EntityPreview
                                      id={item.entityId}
                                      workflow={
                                        isClientPage ? WorkflowTypes.clientPage : WorkflowTypes.bbc
                                      }
                                      className={styles.entityPreviewIcon}
                                    />
                                  )}

                                  {!isClientUserPage &&
                                    (priorityPayables.includes(item.creditor) ||
                                      item.priorityPayable) && (
                                      <Tooltip placement="top" title="Priority Payable">
                                        <div
                                          className={cn(genericSs.yellowTag, styles.priorityTag)}
                                        >
                                          Priority
                                        </div>
                                      </Tooltip>
                                    )}
                                </TableCell>
                                {!isClientPage && !isClientUserPage && (
                                  <>
                                    <TableCell className={genericSs.tableTextCenter}>
                                      <ChangedValue
                                        isChip
                                        className={styles.changedValue}
                                        changedValue={item.invoiceCountChanged}
                                        isPrice={false}
                                      />
                                      <span className={styles.aggregationCountLabel}>
                                        {maxOutAt99WithTooltip(+item.invoiceCount) || '-'}
                                      </span>
                                    </TableCell>
                                    <TableCell className={genericSs.tableTextRight}>
                                      <ChangedValue
                                        isChip
                                        className={styles.changedValue}
                                        changedValue={item.daysPastInvoiceChanged}
                                        isPrice={false}
                                      />
                                      {Math.round(+item?.daysPastInvoice)}
                                    </TableCell>
                                  </>
                                )}
                                {Object.keys(CELL_INDEXES_MAP).map((cellIndex) => (
                                  <BBCAPSummaryCell
                                    key={cellIndex}
                                    type={
                                      PERCENT_CELL_INDEXES.includes(+cellIndex)
                                        ? 'percent'
                                        : 'currency'
                                    }
                                    data={item[CELL_INDEXES_MAP[cellIndex]]}
                                    changedValue={item[`${[CELL_INDEXES_MAP[cellIndex]]}Changed`]}
                                    rowIndex={index}
                                    cellIndex={+cellIndex}
                                    onSelectCell={handleSelectCell}
                                    isSelected={
                                      activeRows.includes(index) ||
                                      activeCells[index]?.includes(+cellIndex)
                                    }
                                    isActive={activeCells[index]?.includes(+cellIndex)}
                                    dataTotal={totalCell}
                                    isTotals={TOTALS_CELL_INDEXES.includes(+cellIndex)}
                                    withChangedValue={!isClientUserPage}
                                  />
                                ))}
                              </TableRow>
                              {isExpanded && (
                                <TableRow>
                                  <TableCell className={genericSs.nestedRowColumn} colSpan={10}>
                                    <BBCAPDetailRow
                                      apDetailStats={item.rows}
                                      filters={filters}
                                      isModalShown={isModalShown}
                                      recordDate={recordDate}
                                      compareDate={compareDate}
                                      creditor={item.creditor}
                                      isClientPage={isClientPage}
                                      clientName={clientInfo?.clientName}
                                      isClientUserPage={isClientUserPage}
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </InfiniteScroll>
                    )
                  )}
                </TableBody>
                <TableFooter>
                  {isFilesSaving || isLoadingBBC || !apSummaryStats ? (
                    <TableLoader columnsCount={10} rowsCount={2} />
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={4} className={genericSs.tableTextLeft}>
                          Total
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(apSummaryStats?.totals.ap_1To_30Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(apSummaryStats?.totals.ap_31To_60Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(apSummaryStats?.totals.ap_61To_90Days)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(apSummaryStats?.totals.ap_91PlusDays)}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          <span className={genericSs.pricePrefix}>$</span>
                          {formatPrice(apSummaryStats?.totals.total)}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          100%
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4} className={genericSs.tableTextLeft}>
                          % of Total
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {apSummaryStats?.totals.ap_1To_30DaysPercent?.toFixed(2)}%
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {apSummaryStats?.totals.ap_31To_60DaysPercent?.toFixed(2)}%
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {apSummaryStats?.totals.ap_61To_90DaysPercent?.toFixed(2)}%
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {apSummaryStats?.totals.ap_91PlusDaysPercent?.toFixed(2)}%
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          100%
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                          100%
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {activeRows.length > 1 && (
                    <TableRow className="summaryRow">
                      <TableCell className={genericSs.tableTextLeft} colSpan={4}>
                        {activeRows.length} rows selected
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ap_1To_30Days)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ap_31To_60Days)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ap_61To_90Days)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatPrice(summaryRow?.ap_91PlusDays)}
                      </TableCell>
                      <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                        {formatPrice(summaryRow?.total)}
                      </TableCell>
                      <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                        {summaryRow?.percent}%
                      </TableCell>
                    </TableRow>
                  )}
                </TableFooter>
              </Table>
            </>
          ) : (
            <div className={styles.chartContainer}>
              <ARAPSummaryOverTime
                isClientPage={isClientPage}
                isClientUserPage={isClientUserPage}
                clientId={clientInfo?.id}
                companyValues={companyValues}
                currentDateRange={currentDateRange}
                currentMetric={currentMetric}
                agingReport={BBC_AGING_REPORT.AP}
                setEntitiesOptions={setEntitiesOptions}
                isFullscreen={isModalShown}
              />
            </div>
          )}
        </TableContainer>
      </Card>
    </FullscreenModal>
  )
}

export default BBCAPSummaryTable
