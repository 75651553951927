import { Action } from 'redux'
import { takeEvery, put, select } from 'redux-saga/effects'
import { History } from 'history'
import { pathname } from '../router/selectors'
import {
  UPLOAD_WIRES_SUCCESS,
  UPDATE_CHECK_SUCCESS,
  UPDATE_CHECKS_SUCCESS,
  RESTORE_CHECK_SUCCESS,
  DELETE_CHECK_SUCCESS,
  UPLOAD_CHECKS_SUCCESS,
  ADD_CHECK_ACCOUNT_SUCCESS,
  UPDATE_WIRE_SUCCESS,
  UPDATE_WIRES_SUCCESS,
  RESTORE_WIRE_SUCCESS,
  DELETE_WIRE_SUCCESS,
  SAVE_CHECKS_SUCCESS,
  SAVE_WIRES_SUCCESS,
  UPLOAD_MID_DAY_WIRES_SUCCESS,
  UPDATE_MID_DAY_WIRE_SUCCESS,
  UPDATE_MID_DAY_WIRES_SUCCESS,
  RESTORE_MID_DAY_WIRE_SUCCESS,
  DELETE_MID_DAY_WIRE_SUCCESS,
  SAVE_MID_DAY_WIRES_SUCCESS,
  EXPORT_AGGREGATION_SUCCESS,
  LIST_MID_DAY_WIRES_NEW_SUCCESS,
  listChecks,
  listCheckAccounts,
  updateCheck,
  listNewWires,
  listNegativeWires,
  listDeletedWires,
  listMidDayWiresNew,
  listMidDayWiresNegative,
  listMidDayWiresDeleted,
  listMidDayWiresTotals,
  listWireFilesAndStatus,
  EXPORT_AGGREGATION_FAILURE,
} from './actions'
import { IResponse } from '@common/interfaces/request'
import { IApi } from '../../api'
import { ROUTES } from '../../constants/routes'
import { location } from '../router/selectors'
import { download } from '../../helpers/helpers'
import imageStore from '../../helpers/imageStore'
import { ICheckData } from '@common/interfaces/collection'
import { DELETE_SUCCESS } from '../file/actions'
import { matchPath } from 'react-router-dom'
import { processPromisesBatch, retryPromise } from '@common/helpers/helpers'

export function* uploadChecksSuccess(api: IApi) {
  yield takeEvery(UPLOAD_CHECKS_SUCCESS, function* (data: IResponse & Action) {
    const {
      data: { checks },
    } = data

    yield processPromisesBatch(
      checks,
      10,
      async (check: ICheckData) => {
        let img = await imageStore.get(check.id)

        if (!img) {
          img = await retryPromise(api.collection.loadCheckImage(check.id), 3)
          await imageStore.set(check.id, img)
        }
      },
      100,
    )
    yield put(listCheckAccounts())
  })
}

export function* updateChecksSuccess() {
  yield takeEvery(
    [UPDATE_CHECK_SUCCESS, UPDATE_CHECKS_SUCCESS, RESTORE_CHECK_SUCCESS, DELETE_CHECK_SUCCESS],
    function* () {
      const { query } = yield select(location)
      yield put(listChecks({ activityId: query.activityId }))
    },
  )
}

export function* addCheckAccountSuccess() {
  yield takeEvery([ADD_CHECK_ACCOUNT_SUCCESS], function* (data: IResponse & Action) {
    const {
      // @ts-ignore
      checkId,
      data: { accountNumber },
    } = data
    if (checkId) {
      yield put(updateCheck(checkId, { accountNumber }))
    }
    yield put(listCheckAccounts())
  })
}

export function* refreshWiresAfterDelete() {
  yield takeEvery([DELETE_SUCCESS], function* () {
    const path: string = yield select(pathname)
    const { query } = yield select(location)
    if (query.activityId) {
      const { activityId }: { activityId: string } = query
      if (matchPath(path, { path: ROUTES.COLLECTIONS_MID_DAY_WIRES })) {
        yield put(listMidDayWiresNew({ activityId }))
        yield put(listMidDayWiresNegative({ activityId }))
        yield put(listMidDayWiresDeleted({ activityId }))
        yield put(listWireFilesAndStatus(activityId))
      } else if (matchPath(path, { path: ROUTES.COLLECTIONS_WIRES })) {
        yield put(listNewWires({ activityId }))
        yield put(listDeletedWires({ activityId }))
        yield put(listNegativeWires({ activityId }))
        yield put(listWireFilesAndStatus(activityId))
      }
    }
  })
}

export function* updateWireSuccess() {
  yield takeEvery(
    [
      UPLOAD_WIRES_SUCCESS,
      UPDATE_WIRE_SUCCESS,
      UPDATE_WIRES_SUCCESS,
      RESTORE_WIRE_SUCCESS,
      DELETE_WIRE_SUCCESS,
    ],
    function* (action: Action & { params: any }) {
      const { query } = yield select(location)
      const skipLoader = action?.params?.skipLoader
      if (query.activityId) {
        yield put(listNewWires({ activityId: query.activityId, skipLoader: skipLoader }))
        yield put(listDeletedWires({ activityId: query.activityId, skipLoader: skipLoader }))
        yield put(listNegativeWires({ activityId: query.activityId, skipLoader: skipLoader }))
      }
    },
  )
}

export function* repullWiresFilesAndStatus() {
  yield takeEvery([UPLOAD_WIRES_SUCCESS, UPLOAD_MID_DAY_WIRES_SUCCESS], function* () {
    const { query } = yield select(location)
    yield put(listWireFilesAndStatus(query.activityId))
  })
}

export function* updateMidDayWireSuccess() {
  yield takeEvery(
    [
      UPLOAD_MID_DAY_WIRES_SUCCESS,
      UPDATE_MID_DAY_WIRE_SUCCESS,
      UPDATE_MID_DAY_WIRES_SUCCESS,
      RESTORE_MID_DAY_WIRE_SUCCESS,
      DELETE_MID_DAY_WIRE_SUCCESS,
    ],
    function* (action: Action & { params: any }) {
      const { query } = yield select(location)
      const skipLoader = action?.params?.skipLoader
      if (query.activityId) {
        yield put(listMidDayWiresNew({ activityId: query.activityId, skipLoader: skipLoader }))
        yield put(listMidDayWiresNegative({ activityId: query.activityId, skipLoader: skipLoader }))
        yield put(listMidDayWiresDeleted({ activityId: query.activityId, skipLoader: skipLoader }))
      }
    },
  )
}

export function* pullMidDayTotals() {
  yield takeEvery([LIST_MID_DAY_WIRES_NEW_SUCCESS], function* () {
    const { query } = yield select(location)
    yield put(listMidDayWiresTotals({ activityId: query.activityId }))
  })
}

export function* submitCollectionSuccess(history: History) {
  yield takeEvery(
    [SAVE_CHECKS_SUCCESS, SAVE_WIRES_SUCCESS, SAVE_MID_DAY_WIRES_SUCCESS],
    function* () {
      yield history.push(ROUTES.ACTIVITY_QUEUE)
    },
  )
}

export function* exportAggregation() {
  yield takeEvery(EXPORT_AGGREGATION_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_AGGREGATION_FAILURE, error })
    }
  })
}
