import React, { useState, useEffect, useCallback } from 'react'

interface IProps {
  connectedTables?: { table: string; offset: number }[]
}

const useResizableColumns = ({ connectedTables }: IProps) => {
  const [resizing, setResizing] = useState(false)
  const [clickedTh, setClickedTh] = useState(null)

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
      const th = event.target as HTMLTableCellElement
      setTimeout(() => {
        setClickedTh(th)
      }, 0)
    },
    [],
  )

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (clickedTh) {
        setResizing(true)

        const width = clickedTh.style.width

        const isClickedThSticky =
          window.getComputedStyle(clickedTh).getPropertyValue('position') === 'sticky'
        if (isClickedThSticky) {
          const thead = clickedTh.closest('thead')
          const stickyElements = Array.from(thead.children[0].children).filter((element: any) => {
            const computedStyle = window.getComputedStyle(element)
            return computedStyle.getPropertyValue('position') === 'sticky'
          })
          if (stickyElements.length > 1) {
            //find which sticky elements are to the right of the clickedTh

            // offset their left position by the amount of pixels the width of the clickedTh has changed if its to the right of the clickedTh
            const widthNumber = parseInt(width)
            const currentThLeft = parseInt(
              window.getComputedStyle(clickedTh).getPropertyValue('left'),
            )

            let newLeft = currentThLeft + widthNumber
            stickyElements.forEach((element: any) => {
              const computedStyle = window.getComputedStyle(element)
              const left = parseInt(computedStyle.getPropertyValue('left'))
              const elementWidth = parseInt(computedStyle.getPropertyValue('width'))
              if (left > currentThLeft) {
                element.style.left = `${newLeft}px`
                const columnIndex = element.cellIndex
                const tds = Array.from(
                  clickedTh
                    .closest('table')
                    ?.querySelectorAll(`td:nth-child(${columnIndex + 1})`) || [],
                )
                tds.forEach((td: any) => {
                  td.style.left = `${newLeft}px`
                })
                newLeft += elementWidth
              }
            })
          }
        }

        const columnIndex = clickedTh.cellIndex
        if (!columnIndex) return
        const tds = Array.from(
          clickedTh.closest('table')?.querySelectorAll(`td:nth-child(${columnIndex + 1})`) || [],
        )

        if (connectedTables) {
          connectedTables.forEach(({ table, offset }) => {
            const tableTds = Array.from(
              document
                .getElementById(table)
                ?.querySelectorAll(`td:nth-child(${columnIndex + offset})`) || [],
            )
            const tableThs = Array.from(
              document
                .getElementById(table)
                ?.querySelectorAll(`th:nth-child(${columnIndex + offset})`) || [],
            )
            tds.push(...tableTds, ...tableThs)
          })
        }

        tds.forEach((td: any) => {
          td.style.width = width
        })
      }
    },
    [clickedTh, connectedTables],
  )

  const handleMouseUp = useCallback(() => {
    if (clickedTh) {
      setClickedTh(null)
      setResizing(false)
    }
  }, [clickedTh])

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseMove, handleMouseUp])

  return {
    resizing,
    handleMouseDown,
  }
}

export default useResizableColumns
