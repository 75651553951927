import React, { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import styles from '../UploadFile/UploadFile.module.scss'
import { Grid } from '@mui/material'
import { formatDateTime, formatUnixDate } from '../../../helpers/helpers'
import { IFile } from '@common/interfaces/box'
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
import LinearProgressBar from '../../../components/Common/LinearProgressBar'
import { usePrevious } from '../../../hooks/usePrevious'
import { SAVED_LABEL_DELAY } from '../../../constants/common'

const UploadFileManagementFile = ({
  file,
  index,
  isExternalUser,
  handleDelete,
  readOnly = false,
}: {
  file: IFile
  index: number
  isExternalUser: boolean
  handleDelete: (index: number[]) => void
  readOnly?: boolean
}) => {
  const [isSuccessful, setIsSuccessful] = useState(false)

  const wasUploading = usePrevious<boolean>(file?.isUploading, false)

  useEffect(() => {
    if (wasUploading && !file?.isUploading) {
      if (!file?.createdAt) {
        return
      }
      setTimeout(() => {
        setIsSuccessful(false)
      }, SAVED_LABEL_DELAY)
      setIsSuccessful(true)
    }
  }, [file, wasUploading])

  const onDelete = useCallback(() => {
    handleDelete([index])
  }, [handleDelete, index])

  return (
    <Grid item container xs={12} key={file?.name} alignItems="center">
      <Grid spacing={1} item container xs={10.5}>
        <Grid item xs={12} className={styles.fileNameContainer}>
          <Tooltip title={file?.name} placement="top">
            {isExternalUser || !file?.link ? (
              <span className={styles.fileName}>{file?.name}</span>
            ) : (
              <Link
                href={file?.link}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.fileName, styles.link)}
              >
                {file?.name}
              </Link>
            )}
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          {file?.isUploading ? (
            <LinearProgressBar isFullWidth isWithLabel={false} />
          ) : isSuccessful ? (
            <div className={styles.line} />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {file instanceof File && (
            <span className={styles.date}>{formatUnixDate(file?.lastModified)}</span>
          )}
          {file.modifiedAt && (
            <span className={styles.date}>{formatDateTime(file.modifiedAt)}</span>
          )}
        </Grid>
      </Grid>
      <Grid className={styles.deleteGrid} item xs={1.5} justifyContent="center" alignItems="center">
        {!file?.isUploading && !readOnly && (
          <div className={styles.deleteContainer}>
            <DeleteIcon onClick={onDelete} className={styles.deleteIcon} />
          </div>
        )}
      </Grid>
    </Grid>
  )
}

interface IProps {
  files: IFile[] | File[]
  onDelete: (index: number[]) => void
  setIsModalOpen?: (value: boolean) => void
  isDragAccept: boolean
  isDragReject: boolean
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
  open: () => void
  handleDelete: (index: number[]) => void
  dropzoneText: string
  isExternalUser: boolean
  isLoading?: boolean
  showDropzone?: boolean
  showFiles?: boolean
  className?: string
  readOnly?: boolean
}

const UploadFileManagement = ({
  files,
  onDelete,
  setIsModalOpen,
  isDragAccept,
  isDragReject,
  getRootProps,
  getInputProps,
  open,
  handleDelete,
  dropzoneText,
  isExternalUser,
  isLoading,
  showDropzone = true,
  showFiles = true,
  className = '',
  readOnly = false,
}: IProps) => {
  return (
    <div className={cn(styles.documentModal, className)}>
      <Grid container spacing={2} item xs={12} justifyContent="space-between" alignItems="center">
        {showDropzone && (
          <Grid container item xs={12} alignItems="center" justifyContent="space-between">
            <div
              className={cn(styles.root, styles.rootLarge, styles.modalDropzone, {
                [styles.accepted]: isDragAccept,
                [styles.rejected]: isDragReject,
              })}
              {...getRootProps()}
            >
              {!isLoading ? (
                <>
                  <input {...getInputProps()} />

                  <span>
                    {dropzoneText}{' '}
                    <span className={styles.fileSelectButton} onClick={open}>
                      browse files
                    </span>
                  </span>
                </>
              ) : (
                <LinearProgressBar />
              )}
            </div>
          </Grid>
        )}

        {showFiles && (
          <>
            {(files as IFile[])?.map((file: IFile, index: number) => (
              <UploadFileManagementFile
                key={file?.id || file?.name}
                file={file}
                index={index}
                isExternalUser={isExternalUser}
                handleDelete={handleDelete}
                readOnly={readOnly}
              />
            ))}

            {files?.length > 0 && !readOnly && (
              <Grid item xs={12}>
                <span
                  className={styles.clear}
                  onClick={() => {
                    onDelete && onDelete([...Array(files?.length)])
                    setIsModalOpen && setIsModalOpen(false)
                  }}
                >
                  Clear all
                </span>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  )
}

export default UploadFileManagement
