import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './CapTableMappingTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import { createFilterOptions } from '@mui/base'
import Box from '@mui/material/Box'
import Button from '../Common/Button'
import TaggingField from '../Common/TaggingField'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import CreatableSelectField from '../Common/CreatableSelectField'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import { debounceEventHandler, formatPercent, voidHandler } from '../../helpers/helpers'
import useTable from '../../hooks/useTable'

import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { CAP_TABLE_MAPPING_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'
import { ICapTableMappingData } from '@common/interfaces/capTable'
import { EntityType, IOptionType } from '@common/interfaces/entityInfo'
import SaveState from '../Common/SaveState'

const filtersValidate = buildFiltersValidateSchema(CAP_TABLE_MAPPING_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(CAP_TABLE_MAPPING_FILTERS_CONFIG)

interface IProps {
  listMapping: (id: string, params: object) => void
  capTableMapping: ILoadingData<ICapTableMappingData>
  clientName: string
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
  updateCapTableDetails: (data: object) => Promise<any>
  listBoardSeats: (id: string, params: object) => Promise<{ data: any }>
  setMappingComplete: (value: boolean) => void
}

const CapTableMappingTable = ({
  capTableMapping,
  listMapping,
  clientName,
  addEntityInfo,
  listEntityInfo,
  updateCapTableDetails,
  listBoardSeats,
  setMappingComplete,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const {
    isLoading,
    isSaving,
    isSaved,
    data: mappingData,
    totals,
  } = useMemo(() => {
    return {
      isLoading: capTableMapping?.isLoading,
      isSaving: capTableMapping?.isSaving,
      isSaved: capTableMapping?.isSaved,
      data: capTableMapping?.data?.data,
      totals: capTableMapping?.data?.totals,
    }
  }, [capTableMapping])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
  } = useTable({
    tableId: 'capTable',
    filtersDefaults: filtersDefaults,
    sortDefault: {
      field: 'CAST(SUM(capTableDetails.dilutedShares) AS DECIMAL) / CAST(:allShares AS DECIMAL)',
      direction: 'DESC',
    },
  })

  const [boardMemberOptions, setBoardMemberOptions] = useState<IOptionType[]>([])

  const filter = createFilterOptions<IOptionType>()

  useEffect(() => {
    const isMappingComplete = mappingData?.every(
      (item) =>
        item.totalDilutedShares <= 0.05 ||
        (item.totalDilutedShares > 0.05 && item.investor !== null),
    )

    setMappingComplete(isMappingComplete)
  }, [mappingData, setMappingComplete])

  const debounceListCapTableMapping = useMemo(
    () => debounceEventHandler((data: any) => listMapping(id, { ...data, clientName }), 500),
    [id, clientName, listMapping],
  )

  const loadMore = useCallback(() => {
    debounceListCapTableMapping({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(mappingData?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceListCapTableMapping, mappingData])

  useEffect(() => {
    if (clientName) {
      listMapping(id, {
        clientName,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
      })
    }
  }, [id, clientName, listMapping, orderBy, filters])

  const handleAddMapping = useCallback(
    async (mapping: string | string[]) => {
      await addEntityInfo({ name: mapping, type: EntityType.Investor, clientName })
    },
    [addEntityInfo, clientName],
  )

  const handlePredictionSelect = useCallback(
    async (itemIndex?: number) => {
      const itemsToUpdate = []
      const mappingsToAdd = []
      if (itemIndex && mappingData?.[itemIndex].investor) {
        return
      }
      if (activeItems.length > 1) {
        activeItems.forEach((index) => {
          mappingsToAdd.push(mappingData[index].investorPrediction)
          itemsToUpdate.push({
            description: mappingData[index].description,
            id: mappingData[index].id,
            investor: mappingData[index].investorPrediction,
          })
        })
      } else {
        mappingsToAdd.push(mappingData[itemIndex].investorPrediction)
        itemsToUpdate.push({
          description: mappingData[itemIndex].description,
          id: mappingData[itemIndex].id,
          investor: mappingData[itemIndex].investorPrediction,
        })
      }
      await handleAddMapping(mappingsToAdd)
      await updateCapTableDetails({
        clientName,
        descriptionData: itemsToUpdate,
        isInvestor: true,
      })
    },
    [handleAddMapping, updateCapTableDetails, mappingData, clientName, activeItems],
  )

  const handlePredictionSelectButton = useCallback(
    async (event) => {
      await handlePredictionSelect()
      setActiveItems([])
    },
    [handlePredictionSelect, setActiveItems],
  )

  const loadMappings = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map((mapping: any) => ({
        id: mapping.id,
        value: mapping.name,
        label: mapping.name,
      }))
    },
    [listEntityInfo],
  )

  const loadBoardSeats = useCallback(
    async (inputValue: string, index: number) => {
      const res = await listBoardSeats(id, {
        name: inputValue,
        entityName: mappingData[index]?.investor,
        clientName,
      })

      return res.data.map((seat: any) => ({
        value: seat.boardMember,
        label: seat.boardMember,
      }))
    },
    [listBoardSeats, id, mappingData, clientName],
  )

  const updateInvestor = useCallback(
    async (event, newValue: any, tableIndex: number) => {
      if (newValue) {
        await handleAddMapping(newValue.value)
      }
      const itemsToUpdate = []
      if (activeItems.length > 1) {
        activeItems.forEach((index) => {
          itemsToUpdate.push({
            description: mappingData[index].description,
            id: mappingData[index].id,
            investor: newValue?.value || null,
          })
        })
      } else {
        itemsToUpdate.push({
          description: mappingData[tableIndex].description,
          id: mappingData[tableIndex].id,
          investor: newValue?.value || null,
        })
      }
      await updateCapTableDetails({
        clientName,
        descriptionData: itemsToUpdate,
        isInvestor: true,
      })
    },
    [handleAddMapping, updateCapTableDetails, mappingData, clientName, activeItems],
  )

  const updateBoardMember = useCallback(
    async (event, newValue: any, tableIndex: number, isDeleteBoardMember: boolean = false) => {
      const itemsToUpdate = []
      const boardMemberValue = newValue?.[newValue?.length - 1]?.value || newValue || null

      itemsToUpdate.push({
        description: mappingData[tableIndex].description,
        id: mappingData[tableIndex].id,
        investor: mappingData[tableIndex].investor,
        boardMember: boardMemberValue,
        isDeleteBoardMember,
      })

      await updateCapTableDetails({
        clientName,
        descriptionData: itemsToUpdate,
        isBoardMember: true,
      })
      setBoardMemberOptions([])
    },
    [updateCapTableDetails, mappingData, clientName],
  )

  const handleInputChange = useCallback(
    async (
      event: React.ChangeEvent<{}>,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
      index: number,
    ) => {
      if (reason === 'input') {
        const loadedOptions = await loadBoardSeats(inputValue, index)
        setBoardMemberOptions(loadedOptions)
      }
    },
    [loadBoardSeats],
  )
  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  const handleDeleteBoardSeat = useCallback(
    async (event, boardMember, tableIndex: number) => {
      event.stopPropagation()
      await updateBoardMember(event, boardMember, tableIndex, true)
    },
    [updateBoardMember],
  )

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
    >
      <Form
        validate={filtersValidate}
        onSubmit={handleFiltersChange}
        initialValues={filters}
        mutators={{
          setFieldData: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ values, handleSubmit, form: { mutators } }) => (
          <FilterContainer
            filters={CAP_TABLE_MAPPING_FILTERS_CONFIG}
            handleSubmit={handleSubmit}
            mutators={mutators}
            values={values}
            appliedFilters={filters}
            actions={
              activeItems.length > 1 && (
                <Box display="flex" alignItems="center" gap={1}>
                  <span className={styles.selectedItems}>{activeItems.length} items selected</span>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlePredictionSelectButton}
                    small={false}
                  >
                    Select prediction
                  </Button>
                </Box>
              )
            }
          />
        )}
      />

      <>
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={CAP_TABLE_MAPPING_FILTERS_CONFIG}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableARSummaryTable">
            {isLoading ? (
              <TableLoader columnsCount={CAP_TABLE_MAPPING_FILTERS_CONFIG.length} />
            ) : (
              mappingData?.length > 0 && (
                <InfiniteScroll
                  dataLength={mappingData.length}
                  next={loadMore}
                  hasMore={mappingData.length < totals?.totalCount}
                  loader={<TableLoader columnsCount={10} rowsCount={1} />}
                  scrollableTarget="scrollableARSummaryTable"
                >
                  {mappingData.map((item, index) => {
                    return (
                      <TableRow
                        data-index={index}
                        className={cn('activableRow', {
                          activeRow: activeItems.includes(index),
                          currentActiveRow: activeItem === index,
                        })}
                        onClick={(event) => handleSelectRow(event, index)}
                      >
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.description}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatPercent(item.totalDilutedShares)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          <Form
                            onSubmit={voidHandler}
                            render={() => (
                              <CreatableSelectField
                                label=""
                                className="focusableInput"
                                tabIndex={index}
                                name={`aliases[${index}].linkedName`}
                                placeholder={`Select investor`}
                                onAddValue={(mapping: string) =>
                                  addEntityInfo({
                                    name: mapping,
                                    type: EntityType.Investor,
                                    clientName,
                                  })
                                }
                                options={[]}
                                isAsync
                                loadOptions={loadMappings}
                                onChangeCustom={(event, newValue) =>
                                  updateInvestor(event, newValue, index)
                                }
                                getOptionValue={(option) => option.value}
                                value={{
                                  value: item.investor,
                                  label: item.investor,
                                }}
                                clearOnBlur
                              />
                            )}
                          ></Form>
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.investorPrediction && (
                            <div
                              className={cn(
                                styles.percentMatchWithPrediction,
                                styles.percentMatchHigh,
                                {
                                  [styles.newValue]: !item.predictionExists,
                                  [styles.disablePrediction]: item?.investor,
                                  [styles.percentMatchHighActive]: activeItems.includes(index),
                                },
                              )}
                              onClick={() => handlePredictionSelect(index)}
                            >
                              {!item?.investor ? (
                                <Tooltip
                                  title="Create new entity"
                                  placement="top"
                                  disableHoverListener={item.predictionExists}
                                  disableTouchListener
                                >
                                  <span
                                    className={cn({
                                      [styles.newValue]: !item.predictionExists,
                                    })}
                                  >
                                    {!item.predictionExists && '+'} {item.investorPrediction}
                                  </span>
                                </Tooltip>
                              ) : (
                                item.investorPrediction
                              )}
                            </div>
                          )}
                        </TableCell>
                        <TableCell className={cn(genericSs.tableTextLeft)}>
                          <Form
                            onSubmit={voidHandler}
                            render={() => (
                              <TaggingField
                                className={styles.taggingField}
                                limtDrowdown
                                placeholder={
                                  item?.investor
                                    ? item?.boardMembers?.length
                                      ? ''
                                      : 'Select board members'
                                    : 'Select investor first'
                                }
                                disabled={item.investor ? false : true}
                                renderTags={(tagValue, getTagProps) => {
                                  return tagValue.map((option, tagIndex) => (
                                    <Tooltip
                                      key={option.value}
                                      title={option.value}
                                      placement={'top'}
                                    >
                                      <Chip
                                        {...getTagProps({ tagIndex })}
                                        className={cn(styles.chip)}
                                        label={option.value}
                                        size="small"
                                        onDelete={(event) =>
                                          handleDeleteBoardSeat(event, option.value, index)
                                        }
                                      />
                                    </Tooltip>
                                  ))
                                }}
                                options={boardMemberOptions}
                                limitTags={2}
                                onChange={(event, newValue) => {
                                  updateBoardMember(event, newValue, index)
                                }}
                                isFreeSolo={true}
                                // @ts-ignore
                                value={
                                  item.boardMembers?.map((boardMember) => ({
                                    value: boardMember,
                                    label: boardMember,
                                  })) || []
                                }
                                filterOptions={(currentOptions, params) => {
                                  let filtered = filter(currentOptions, params)

                                  if (
                                    params.inputValue !== '' &&
                                    filtered.findIndex(
                                      (option) => option.value === params.inputValue,
                                    ) === -1
                                  ) {
                                    filtered.push({
                                      value: params.inputValue,
                                      label: `Add "${params.inputValue}"`,
                                      isNew: true,
                                    })
                                  }

                                  if (item?.boardMembers?.length > 0) {
                                    filtered = filtered?.filter((param) => {
                                      return !item?.boardMembers.includes(param.value)
                                    })
                                  }

                                  return filtered
                                }}
                                // @ts-ignore
                                onInputChange={(event, valueChange, reason) =>
                                  debounceHandleInputChange(event, valueChange, reason, index)
                                }
                                onOpen={() => handleInputChange(null, '', 'input', index)}
                                getOptionValue={(option) => option?.value}
                                getOptionLabel={(option) => option?.label}
                              ></TaggingField>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </InfiniteScroll>
              )
            )}
          </TableBody>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <SaveState isSaving={isSaving} isSaved={isSaved} />
          </Box>
        </Table>
      </>
    </TableContainer>
  )
}

export default CapTableMappingTable
