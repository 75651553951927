import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'

import styles from './BBCInventoryIneligibleFields.module.scss'

import {
  FundingRequestStatus,
  IBorrowingBase,
  IInventoryIneligibilityField,
  INVENTORY_INELIGIBILITY_FIELDS,
  NOT_ALLOWED_INVENTORY_INELIGIBILITY_FIELDS,
  InventoryFieldKeys,
} from '@common/interfaces/bbc'
import Button from '../Common/Button'
import SelectField from '../Common/SelectField'
import Modal from '../Common/Modal'
import Card from '../Common/Card'
import BBCInventoryIneligibleTable, {
  BBCInventoryIneligibleTableLoader,
} from '../BBCInventoryIneligibleTable'
import TextField from '../Common/TextField'
import Box from '@mui/material/Box'
import { Form } from 'react-final-form'
import { IClientInfo } from '@common/interfaces/client'
import { ILoadingData } from '../../redux/types'

const editSchema = Yup.object().shape({
  title: Yup.string(),
})
const validateOnEdit = makeValidate(editSchema)

interface IProps {
  bbc: IBorrowingBase
  clientInfo: IClientInfo
  fields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  eligibleFields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  listFields: (data: { borrowingBaseId: string; skipLoader?: boolean }) => Promise<void>
  listEligibleFields: (id: string, data: object) => Promise<void>
  createFields: (data: object) => Promise<void>
  deleteField: (id: string) => Promise<void>
  updateEligibleFieldsAliases: (id: string, data: object) => Promise<{ data: IClientInfo }>
  isEligibleTable?: boolean
  showBBC: (id: string, params?: object) => void
  showClient: (id: string) => void
  refreshCounter?: number
}

const BBCInventoryIneligibleFields = ({
  bbc,
  clientInfo,
  fields,
  eligibleFields,
  listFields,
  createFields,
  deleteField,
  listEligibleFields,
  updateEligibleFieldsAliases,
  isEligibleTable = false,
  showBBC,
  showClient,
  refreshCounter,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [selectedField, setSelectedField] = useState(null)
  const isHistorical = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )

  const clientName = useMemo(
    () => (bbc ? bbc?.clientName : clientInfo?.clientName),
    [bbc, clientInfo],
  )

  const refetchFieldsList = useCallback(
    async (skipLoader: boolean = false) => {
      if (id) {
        return isEligibleTable
          ? await listEligibleFields(id, { skipLoader })
          : await listFields({
              borrowingBaseId: id,
              skipLoader,
            })
      }
    },
    [id, listFields, listEligibleFields, isEligibleTable],
  )

  useEffect(() => {
    refetchFieldsList()
  }, [refetchFieldsList])

  const { isLoading, isSaving, data } = useMemo(() => {
    const data = isEligibleTable ? eligibleFields : fields
    return {
      isLoading: data.isLoading,
      isSaving: data.isSaving,
      data: data?.data?.data || [],
    }
  }, [isEligibleTable, eligibleFields, fields])

  const handleCreate = useCallback(
    async (field: string) => {
      await createFields({
        field,
        clientName,
        ...(isEligibleTable
          ? {}
          : {
              borrowingBaseId: id,
            }),
      })
      refetchFieldsList()
    },
    [createFields, clientName, refetchFieldsList, id, isEligibleTable],
  )

  const handleDelete = useCallback(
    async (field: string) => {
      setIsDeleteModalShown(true)
      const selected = data?.find((item) => item.field === field)
      setSelectedField(selected.id)
    },
    [data],
  )

  const handleDeleteConfirm = useCallback(async () => {
    await deleteField(selectedField)
    refetchFieldsList(true)
    setIsDeleteModalShown(false)
    setSelectedField(null)
  }, [selectedField, refetchFieldsList, deleteField])

  const handleDeleteCancel = useCallback(async () => {
    setIsDeleteModalShown(false)
    setSelectedField(null)
  }, [])

  const handleEdit = useCallback(
    async (field: string) => {
      setIsEditModalShown(true)
      const selected = data?.find((item) => item.field === field)
      setSelectedField(selected)
    },
    [data],
  )

  const handleEditConfirm = useCallback(
    async ({ title }) => {
      const selected = data?.find((item) => item.id === selectedField.id)
      await updateEligibleFieldsAliases(bbc ? bbc.clientInfo.id : clientInfo.id, {
        aliases: {
          [selected.field]: title,
        },
      })
      bbc && showBBC(bbc.id, { skipLoader: true })
      clientInfo && showClient(clientInfo.id)
      refetchFieldsList(true)
      setIsEditModalShown(false)
      setSelectedField(null)
    },
    [
      refetchFieldsList,
      selectedField,
      updateEligibleFieldsAliases,
      data,
      bbc,
      clientInfo,
      showBBC,
      showClient,
    ],
  )

  const handleEditCancel = useCallback(async () => {
    setIsEditModalShown(false)
    setSelectedField(null)
  }, [])

  const availableFieldsOptions = useMemo(() => {
    const existsFields = data.map(({ field }) => field)
    return Object.keys(INVENTORY_INELIGIBILITY_FIELDS)
      .filter(
        (field) =>
          !NOT_ALLOWED_INVENTORY_INELIGIBILITY_FIELDS.includes(field) &&
          !existsFields.includes(field as InventoryFieldKeys),
      )
      .map((field) => ({
        value: field,
        label: INVENTORY_INELIGIBILITY_FIELDS[field].reason,
      }))
  }, [data])

  return (
    <Grid container spacing={1} className={styles.eligibilityBlock}>
      {!isHistorical && clientName && (
        <Grid item xs={12} className={styles.eligibilityButtonWrapper}>
          <div className={styles.eligibilityButton}>
            <SelectField
              placeholder="Add Eligibility Category"
              name="field"
              useFinalForm={false}
              options={availableFieldsOptions}
              onChange={({ target: { value } }) => handleCreate(value)}
              value=""
            />
          </div>
        </Grid>
      )}
      {data?.map((field) => (
        <Grid
          item
          xs={field.field === 'location' ? 8 : 4}
          xl={field.field === 'location' ? 6 : 3}
          key={field.id}
          className={styles.eligibilityTableWrapper}
        >
          <BBCInventoryIneligibleTable
            fieldId={field.id}
            field={field.field}
            ineligibleReason={field.ineligibleReason}
            title={
              bbc?.clientInfo?.inventoryIneligibilityFieldAliases[field.field] ||
              clientInfo?.inventoryIneligibilityFieldAliases[field.field] ||
              INVENTORY_INELIGIBILITY_FIELDS[field.field]?.reason
            }
            disabled={isHistorical}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            isEligibleTable={isEligibleTable}
            refreshCounter={
              ['type', 'brandCategory'].includes(field.field) ? refreshCounter : undefined
            }
          />
        </Grid>
      ))}
      {isLoading && (
        <Grid item xs={4} lg={3}>
          <Card noHeaderMargin>
            <BBCInventoryIneligibleTableLoader />
          </Card>
        </Grid>
      )}

      {isDeleteModalShown && (
        <Modal
          open={isDeleteModalShown}
          onCancel={handleDeleteCancel}
          title="Delete Field?"
          footer={[
            <Button
              key="cancel"
              color="primary"
              variant="outlined"
              onClick={handleDeleteCancel}
              secondary
            >
              Cancel
            </Button>,
            <Button
              isLoading={isSaving}
              key="submit"
              color="primary"
              variant="contained"
              onClick={handleDeleteConfirm}
            >
              Delete
            </Button>,
          ]}
        >
          Field will be deleted. Are you sure?
        </Modal>
      )}

      {isEditModalShown && (
        <Form
          validate={validateOnEdit}
          initialValues={{
            title:
              bbc?.clientInfo?.inventoryIneligibilityFieldAliases[selectedField.field] ||
              clientInfo?.inventoryIneligibilityFieldAliases[selectedField.field] ||
              INVENTORY_INELIGIBILITY_FIELDS[selectedField.field].reason,
          }}
          onSubmit={handleEditConfirm}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Modal
                classes={{ body: styles.chartsModal }}
                open={isEditModalShown}
                onCancel={handleEditCancel}
                title={`Edit title of ${
                  INVENTORY_INELIGIBILITY_FIELDS[selectedField.field].reason
                }`}
                footer={[
                  <Button
                    key="cancel"
                    color="primary"
                    variant="outlined"
                    onClick={handleEditCancel}
                    secondary
                  >
                    Cancel
                  </Button>,
                  <Button
                    isLoading={isSaving}
                    key="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={pristine || invalid}
                  >
                    Save
                  </Button>,
                ]}
              >
                <Box mt={2}>
                  <TextField name="title" fullWidth placeholder="New Title" />
                </Box>
              </Modal>
            </form>
          )}
        />
      )}
    </Grid>
  )
}

export default BBCInventoryIneligibleFields
