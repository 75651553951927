import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Skeleton from '@mui/material/Skeleton'
import { Info } from '@mui/icons-material'
import cn from 'classnames'
import styles from './BBCAvailabilityTable.module.scss'
import {
  FUNDING_REQUEST_AMOUNT_TYPE_LABEL,
  FundingRequestAmountType,
  FundingRequestStatus,
  IBorrowingBase,
} from '@common/interfaces/bbc'
import { formatPrice } from '../../helpers/helpers'
import { LOAN_TYPES } from '@common/constants/client'
import ChangedValue from '../ChangedValue'
import { IOveradvance } from '@common/interfaces/bbc'
import { DEFAULT_EMPTY_ARRAY_PROP } from '../../constants/common'
import { ReactComponent as ActionSuccessIcon } from '../../assets/images/action-success.svg'
import Icon from '@mui/material/Icon'
import { ReactComponent as WarningIcon } from '../../assets/images/warning-icon-with-halo.svg'

interface IProps {
  bbc: IBorrowingBase
  isLoading: boolean
}

const AvailabilityItem = ({
  open,
  title,
  value,
  previousValue = null,
  popover = DEFAULT_EMPTY_ARRAY_PROP,
  handleClick,
  isHeader = false,
  stringValue = null,
  isLastCalc = false,
  isAfterCalc = false,
  isLoading = false,
}: {
  open?: boolean
  title: string
  value: number
  previousValue?: number
  stringValue?: string
  popover?: Array<{ title: string; value: number }>
  handleClick?: () => void
  isHeader?: Boolean
  isAfterCalc?: Boolean
  isLastCalc?: Boolean
  isLoading?: boolean
}) => {
  const changedValue = useMemo(() => {
    if (!previousValue && previousValue !== 0) {
      return null
    }

    return value - previousValue
  }, [value, previousValue])
  return (
    <div
      key={title}
      className={cn(styles.availabilitySummaryItem, {
        [styles.availabilitySummaryItemHeader]: isHeader,
        [styles.availabilitySummaryItemBorder]: isLastCalc,
        [styles.availabilitySummaryItemTotal]: isAfterCalc,
      })}
    >
      <h4
        className={cn(styles.availabilitySummaryItemTitle, {
          [styles.availabilitySummaryItemTitleHeader]: isHeader,
        })}
      >
        <div>{title}</div>
        {popover.length > 0 && (
          <Tooltip
            placement="right"
            title={
              <>
                {popover.map((popoverItem) => (
                  <div key={popoverItem.title} className={styles.availabilitySummaryItem}>
                    <h4 className={styles.availabilitySummaryItemTitlePopover}>
                      {popoverItem.title}
                    </h4>
                    <div className={styles.availabilitySummaryItemValue}>
                      $ {formatPrice(popoverItem.value)}
                    </div>
                  </div>
                ))}
              </>
            }
            open={open}
            classes={{
              popper: styles.popover,
              tooltip: styles.tooltip,
            }}
          >
            <div className={styles.infoIcon} onClick={handleClick}>
              {' '}
              <Info className={styles.infoIconSize} />{' '}
            </div>
          </Tooltip>
        )}
      </h4>
      <Grid container className={styles.numbersContainer}>
        {isLoading ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Skeleton height={15} width={100} />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Skeleton height={15} width={100} />
            </Box>
          </>
        ) : (
          <>
            <Grid
              item
              className={cn(styles.availabilitySummaryItemValue, {
                [styles.availabilitySummaryItemeValueHeader]: isHeader,
              })}
            >
              {stringValue ? stringValue : '$' + formatPrice(value)}
            </Grid>

            <Grid item>
              <ChangedValue
                className={cn(styles.availabilityValue, {
                  [styles.availabilityHeader]: isHeader,
                })}
                changedValue={changedValue}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

const BBCAvailabilityTable = ({ bbc, isLoading }: IProps) => {
  const [open, setOpen] = useState(false)
  const handleClick = useCallback(() => {
    setOpen((previousOpen) => !previousOpen)
  }, [])

  const isSent = useMemo(() => bbc?.status === FundingRequestStatus.Sent, [bbc])

  const arAvailabilityItems = useMemo(
    () => [
      {
        title: 'Gross AR',
        value: bbc.clientCollateral?.totalGrossAr,
        previousValue: bbc.previousBBC?.clientCollateral?.totalGrossAr ?? null,
        isHeader: true,
      },
      {
        title: 'AR (Positive balances)',
        value: bbc.clientCollateral?.totalReceivables,
        previousValue: bbc.previousBBC?.clientCollateral?.totalReceivables ?? null,
      },
      {
        title: '- Ineligible AR',
        value: bbc.clientCollateral?.totalIneligibleAr,
        previousValue: bbc.previousBBC?.clientCollateral?.totalIneligibleAr ?? null,
        isLastCalc: true,
      },
      {
        title: 'Eligible AR',
        value: bbc.clientCollateral?.eligibleReceivables,
        previousValue: bbc.previousBBC?.clientCollateral?.eligibleReceivables ?? null,
        isHeader: true,
        isAfterCalc: true,
      },
      {
        title: `AR Availability @ ${(bbc.clientCollateral?.arAdvanceRate * 100).toFixed(1)}%`,
        value: bbc.clientCollateral?.arAvailability,
        previousValue: bbc.previousBBC.clientCollateral?.arAvailability ?? null,
        isHeader: true,
      },
    ],
    [bbc],
  )

  const inventoryAvailabilityItems = useMemo(
    () =>
      [
        {
          title: 'Gross Inventory',
          value: bbc.clientCollateral?.totalGrossInventory,
          previousValue: bbc.previousBBC?.clientCollateral?.totalGrossInventory ?? null,
        },
        {
          title: '- Ineligible Inventory',
          value: bbc.clientCollateral?.totalIneligibleInventory,
          previousValue: bbc.previousBBC?.clientCollateral?.totalIneligibleInventory ?? null,
          isLastCalc: true,
        },
        {
          title: 'Eligible Inventory @ Cost',
          value: bbc.clientCollateral?.eligibleInventoryCost,
          previousValue: bbc.previousBBC?.clientCollateral?.eligibleInventoryCost ?? null,
          isHeader: true,
          isAfterCalc: true,
        },
        {
          title: '- Priority Payables Reserve',
          value: bbc.clientCollateral?.over_90Payables,
          previousValue: bbc.previousBBC?.clientCollateral?.over_90Payables ?? null,
        },
        {
          title: 'Inv. Availability (Pre Sublimit)',
          value: bbc.clientCollateral?.inventoryAvailabilityPreSubLimit,
          previousValue:
            bbc.previousBBC?.clientCollateral?.inventoryAvailabilityPreSubLimit ?? null,
          popover: [
            !bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `Cost Value of Inventory @ ${(
                bbc.clientCollateral?.inventoryAdvanceRate * 100
              ).toFixed(1)}%`,
              value: bbc.clientCollateral?.costValueOfInventory,
            },
            !!bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `Cost Value of Inventory @ ${(
                bbc.clientCollateral?.inventoryAdvanceRate * 100
              ).toFixed(1)}% (Finished Goods)`,
              value: bbc.clientCollateral?.costValueOfFinishedGoodsInventory,
            },
            !!bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `Cost Value of Inventory @ ${(
                bbc.clientCollateral?.rawMaterialsAdvanceRate * 100
              ).toFixed(1)}% (Raw Materials)`,
              value: bbc.clientCollateral?.costValueOfRawMaterialsInventory,
            },
            !bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Calculation (${(bbc.clientCollateral?.nolvPercentOfCost * 100).toFixed(
                1,
              )}% of Cost)`,
              value: bbc.clientCollateral?.nolvCalculation,
            },
            !!bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Calculation (${(bbc.clientCollateral?.nolvPercentOfCost * 100).toFixed(
                1,
              )}% of Cost) (Finished Goods)`,
              value: bbc.clientCollateral?.nolvCalculationFinishedGoods,
            },
            !!bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Calculation (${(bbc.clientCollateral?.rmNolv * 100).toFixed(
                1,
              )}% of Cost) (Raw Materials)`,
              value: bbc.clientCollateral?.nolvCalculationRawMaterials,
            },
            !bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Advance @ ${(bbc.clientCollateral?.nolvAdvanceRate * 100).toFixed(1)}%`,
              value: bbc.clientCollateral?.nolvAdvance,
            },
            !!bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Advance @ ${(bbc.clientCollateral?.nolvAdvanceRate * 100).toFixed(
                1,
              )}% (Finished Goods)`,
              value: bbc.clientCollateral?.nolvAdvanceFinishedGoods,
            },
            !!bbc.clientCollateral?.costValueOfRawMaterialsInventory && {
              title: `NOLV Advance @ ${(
                bbc.clientCollateral?.rmInventoryAdvanceRateNolv * 100
              ).toFixed(1)}% (Raw Materials)`,
              value: bbc.clientCollateral?.nolvAdvanceRawMaterials,
            },
          ].filter(Boolean),
          isHeader: true,
        },
        {
          title: 'Inv. Availability (Post Sublimit)',
          value: bbc.clientCollateral?.inventoryAvailabilityPostSubLimit,
          previousValue:
            bbc.previousBBC?.clientCollateral?.inventoryAvailabilityPostSubLimit ?? null,
          isHeader: true,
          isAfterCalc: true,
        },
      ].filter(Boolean),
    [bbc],
  )
  const hasTermLoan = useMemo(() => bbc.clientCollateral?.termLoanBalance > 0, [bbc])

  const ablPrefix = useMemo(() => (hasTermLoan ? 'ABL ' : ''), [hasTermLoan])

  const availabilitySummaryItems = useMemo(
    () => [
      {
        title: 'Max Line Amount',
        value: bbc.clientCollateral?.maximumLine,
        previousValue: bbc.previousBBC?.clientCollateral?.maximumLine ?? null,
        isHeader: true,
      },
      {
        title: 'Net Availability',
        value: bbc.clientCollateral?.netAvailability,
        previousValue: bbc.previousBBC?.clientCollateral?.netAvailability ?? null,
        isHeader: !bbc.overadvances.length,
        isAfterCalc: !bbc.overadvances.length,
      },
      {
        title: '+ Overadvance',
        overadvances: bbc.overadvances,
        isOveradvance: true,
        ignore: !bbc.overadvances.length,
      },
      {
        title: 'Net Availability with Overadvance',
        value: bbc.clientCollateral?.netAvailabilityWithOveradvances,
        previousValue: bbc.previousBBC?.clientCollateral?.netAvailabilityWithOveradvances ?? null,
        isHeader: true,
        isAfterCalc: true,
        ignore: !bbc.overadvances.length,
      },
      {
        title: 'Final Net Availability',
        value: bbc.clientCollateral?.finalNetAvailability,
        previousValue: bbc.previousBBC?.clientCollateral?.finalNetAvailability ?? null,
        ignore:
          bbc.clientCollateral?.finalNetAvailability === bbc.clientCollateral?.netAvailability,
      },
      {
        title: `- Current ${ablPrefix}Loan Balance`,
        value: bbc.clientCollateral?.currentLoanAmount,
      },
      {
        title: '+ Wire Cost',
        value: bbc.clientCollateral?.wireCosts,
        isLastCalc: true,
      },
      {
        title: 'Excess Loan Availability',
        value: bbc.clientCollateral?.excessLoanAvailability,
        isHeader: true,
        isAfterCalc: true,
      },
      {
        title: '+ Requested Amount',
        value:
          bbc.requestedAmountType === FundingRequestAmountType.Specific
            ? bbc.requestedAmount
            : null,
        stringValue:
          bbc.requestedAmountType !== FundingRequestAmountType.Specific
            ? FUNDING_REQUEST_AMOUNT_TYPE_LABEL[bbc.requestedAmountType]
            : null,
        isLastCalc: true,
      },
      {
        title: !isSent ? `Projected New ${ablPrefix}Loan Amount` : `New ${ablPrefix}Loan Amount`,
        value: bbc.projectedNewLoanAmount,
        stringValue:
          bbc.requestedAmountType === FundingRequestAmountType.TBD && !isSent ? 'TBD' : null,

        isHeader: true,
        isAfterCalc: true,
      },
    ],
    [bbc, ablPrefix, isSent],
  )

  const accountActivitySummaryItems = useMemo(() => {
    const termLoanPrincipal = bbc.clientCollateral?.termLoanPrincipal > 0 && {
      title: '+ Term Loan Principal & Amortization',
      value: bbc.clientCollateral?.termLoanPrincipal,
      isLastCalc: true,
    }
    const termLoanBalances = hasTermLoan
      ? [
          {
            title: '+ Term Loan Balance',
            value: bbc.clientCollateral?.termLoanBalance,
            isHeader: true,
            isLastCalc: true,
          },
          {
            title: 'Total Current Loan Balance',
            value: bbc.clientCollateral?.totalCurrentLoanBalance,
            isHeader: true,
            isAfterCalc: true,
          },
        ]
      : []

    return [
      {
        title: `Prior ${ablPrefix}Loan Balance`,
        value: bbc.clientCollateral?.priorLoanAmount,
        isHeader: true,
      },
      {
        title: '+ Realized Interest Fees & Misc. Fees',
        value: bbc.clientCollateral?.realizedInterest + bbc.clientCollateral?.miscFees,
        isHeader: false,
      },
      {
        title: '- Collections',
        value: bbc.clientCollateral?.collections,
        previousValue: bbc.previousBBC?.clientCollateral?.collections ?? null,
      },
      {
        title: '+ Adjustments & Returned Collections',
        value: bbc.clientCollateral?.adjustments + bbc.clientCollateral?.returnedCollections,
        isLastCalc: !bbc.clientCollateral?.termLoanPrincipal,
      },
      termLoanPrincipal,
      {
        title: `Current ${ablPrefix}Loan Balance`,
        value: bbc.clientCollateral?.currentLoanAmount,
        isHeader: true,
        isAfterCalc: true,
      },
      ...termLoanBalances,
    ].filter(Boolean)
  }, [bbc, ablPrefix, hasTermLoan])

  const accountSummaryDiscrepency = useMemo(
    () =>
      +(
        bbc.clientCollateral?.priorLoanAmount +
        bbc.clientCollateral?.realizedInterest +
        bbc.clientCollateral?.miscFees -
        bbc.clientCollateral?.collections +
        bbc.clientCollateral?.adjustments +
        bbc.clientCollateral?.returnedCollections +
        (bbc.clientCollateral?.termLoanPrincipal ?? 0) -
        bbc.clientCollateral?.currentLoanAmount +
        // abocve checks if regular activity adds up to current loan balance
        // below checks if current loan balance and termloan adds up to total current loan balance
        bbc.clientCollateral?.totalCurrentLoanBalance -
        bbc.clientCollateral?.currentLoanAmount -
        (bbc.clientCollateral?.termLoanBalance ?? 0)
      ).toFixed(2),
    [bbc],
  )

  return (
    <Grid container alignItems="baseline" spacing={1}>
      <Grid className={styles.summaryColumnLeft} item xs={5} lg={5}>
        {bbc.clientInfo.loanType !== LOAN_TYPES.inventory && (
          <div>
            <h3 className={styles.availabilitySummaryTitle}>AR Availability</h3>
            {arAvailabilityItems.map((data) => {
              return (
                <AvailabilityItem
                  key={data.title}
                  open={open}
                  title={data.title}
                  value={data.value}
                  previousValue={data.previousValue}
                  handleClick={handleClick}
                  isHeader={data.isHeader}
                  isLastCalc={data.isLastCalc}
                  isAfterCalc={data.isAfterCalc}
                  isLoading={isLoading}
                />
              )
            })}
          </div>
        )}
        {bbc.clientInfo.loanType === LOAN_TYPES.arAndInventory && <br />}
        {bbc.clientInfo.loanType !== LOAN_TYPES.ar && (
          <div>
            <h3
              className={`${styles.availabilitySummaryTitle} ${styles.inventoryAvailabilityTitle}`}
            >
              Inventory Availability
            </h3>
            {inventoryAvailabilityItems.map((data) => {
              return (
                <AvailabilityItem
                  key={data.title}
                  open={open}
                  title={data.title}
                  value={data.value}
                  previousValue={data.previousValue}
                  handleClick={handleClick}
                  isHeader={data.isHeader}
                  isLastCalc={data.isLastCalc}
                  isAfterCalc={data.isAfterCalc}
                  popover={data.popover}
                  isLoading={isLoading}
                />
              )
            })}
          </div>
        )}
      </Grid>

      <Grid item xs={5} lg={5}>
        {!bbc.isTest && (
          <>
            <Grid container justifyContent={'start'} alignContent={'center'} gap={2}>
              <h3 className={styles.availabilitySummaryTitle}>Account Activity</h3>
              <Tooltip title={`Discrepency: $${formatPrice(accountSummaryDiscrepency)}`}>
                <Icon
                  component={accountSummaryDiscrepency === 0 ? ActionSuccessIcon : WarningIcon}
                  sx={{ fontSize: 20, color: 'green' }}
                />
              </Tooltip>
            </Grid>
            {accountActivitySummaryItems.map(
              ({ title, value, previousValue, isHeader, isLastCalc, isAfterCalc }) => (
                <AvailabilityItem
                  key={title}
                  title={title}
                  value={value}
                  previousValue={previousValue}
                  isHeader={isHeader}
                  isLastCalc={isLastCalc}
                  isLoading={isLoading}
                  isAfterCalc={isAfterCalc}
                />
              ),
            )}
            <br />
          </>
        )}

        <h3 className={styles.availabilitySummaryTitle}>Availability Summary</h3>
        {availabilitySummaryItems
          .filter((item) => !item.ignore)
          .map(
            ({
              title,
              value,
              previousValue,
              isOveradvance,
              overadvances,
              isHeader,
              isLastCalc,
              isAfterCalc,
              stringValue,
            }) => {
              if (isOveradvance) {
                return overadvances.map((overadvance: IOveradvance, index: number) => {
                  const isLastCalc = index === overadvances.length - 1
                  return (
                    <AvailabilityItem
                      key={overadvance.id}
                      title={title}
                      value={overadvance.overadvance}
                      previousValue={previousValue}
                      isLastCalc={isLastCalc}
                      isLoading={isLoading}
                    />
                  )
                })
              }
              return (
                <AvailabilityItem
                  key={title}
                  title={title}
                  value={value}
                  previousValue={previousValue}
                  isHeader={isHeader}
                  isLastCalc={isLastCalc}
                  isAfterCalc={isAfterCalc}
                  isLoading={isLoading}
                  stringValue={stringValue}
                />
              )
            },
          )}
      </Grid>
    </Grid>
  )
}

export default BBCAvailabilityTable
