import React, { useCallback, useMemo, useState } from 'react'
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import cn from 'classnames'

import styles from './AutocompleteField.module.scss'

import { debounceEventHandler } from '../../../helpers/helpers'

interface OptionType {
  label?: string
  value: string
  inputValue?: string
}

interface IProps extends Partial<AutocompleteProps<OptionType, false, boolean, boolean>> {
  name: string
  style?: object
  placeholder?: string
  isAsync?: boolean
  loadOptions?: (value: string) => Promise<OptionType[]>
  freeSolo?: boolean
}

const AutocompleteField = ({
  value,
  options,
  placeholder,
  style,
  disabled,
  className,
  onFocus,
  onBlur,
  tabIndex,
  onChange,
  groupBy,
  isAsync = false,
  loadOptions,
  disableClearable = false,
  freeSolo = true,
}: IProps) => {
  const [asyncOptions, setAsyncOptions] = useState<OptionType[]>([])

  const handleInputChange = useCallback(
    async (
      event: React.ChangeEvent<{}>,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (!isAsync) {
        return
      }
      if (reason === 'input') {
        if (inputValue.length > 1) {
          const loadedOptions = await loadOptions(inputValue)
          setAsyncOptions(loadedOptions)
        } else {
          setAsyncOptions([])
        }
      }
    },
    [isAsync, loadOptions],
  )
  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  return (
    <MuiAutocomplete
      forcePopupIcon={false}
      key={options.length}
      value={value}
      className={cn(styles.root, className)}
      onChange={onChange}
      selectOnFocus
      handleHomeEndKeys
      disableClearable={disableClearable}
      options={isAsync ? asyncOptions : options}
      // @ts-ignore
      getOptionLabel={(option) => option.label}
      // tslint:disable-next-line:no-shadowed-variable
      isOptionEqualToValue={(option: any, value: any) =>
        option?.value === (value && value?.value ? value.value : value)
      }
      // @ts-ignore
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      style={style}
      tabIndex={tabIndex}
      freeSolo={freeSolo}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          placeholder={placeholder}
          FormHelperTextProps={{ classes: { root: styles.error } }}
          variant="standard"
        />
      )}
      disabled={disabled}
      classes={{
        inputRoot: styles.input,
        popper: styles.popper,
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      groupBy={groupBy}
      onInputChange={debounceHandleInputChange}
    />
  )
}

export default AutocompleteField
