import React, { useCallback } from 'react'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { showErrorOnChange, ShowErrorFunc } from 'mui-rff'
import cn from 'classnames'
import { parseISO } from 'date-fns'
import moment from 'moment'

import styles from './KeyboardDatePicker.module.scss'

import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar-icon.svg'
import { Skeleton } from '@mui/material'

interface KeyboardDatePickerProps extends Partial<Omit<DatePickerProps<any, any>, 'onChange'>> {
  name: string
  placeholder?: string
  fieldProps?: Partial<FieldProps<any, any>>
  showError?: ShowErrorFunc
  useFinalForm?: boolean
  value?: any
  size?: 'small' | 'big'
  onChange?: (event: any) => void
  onBlur?: () => void
  withTopLabel?: boolean
  isLoading?: boolean
}

interface DatePickerWrapperProps extends FieldRenderProps<DatePickerProps<any, any>, HTMLElement> {}

const KeyboardDatePickerWrapper = (props: DatePickerWrapperProps) => {
  const {
    className,
    placeholder = '',
    input: { name, onChange, value, ...restInput },
    meta = {},
    showError = showErrorOnChange,
    onBlur,
    withTopLabel,
    ...rest
  } = props

  const { error, submitError } = meta
  const isError = showError({ meta })
  const customOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && onChange) {
        if (new RegExp(`\\d{4}-\\d{2}-\\d{2}`).test(event.target.value)) {
          onChange(moment(event.target.value, 'YYYY-MM-DD').toDate())
          return
        }
      }
    },
    [onChange],
  )

  const { helperText, ...lessRest } = rest
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} className={cn('commonFilter')}>
      <DatePicker
        className={className}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        renderInput={({ InputProps, ...renderProps }) => (
          <TextField
            name={name}
            {...renderProps}
            {...restInput}
            error={isError}
            InputProps={{
              ...InputProps,
              placeholder,
              classes: {
                error: styles.inputError,
              },
            }}
            helperText={isError ? error || submitError : helperText}
            variant={withTopLabel ? 'outlined' : 'standard'}
            onBlur={onBlur}
            label={withTopLabel ? placeholder : undefined}
            onChange={customOnChange}
          />
        )}
        onChange={onChange}
        value={
          (value as any) === '' ? null : typeof value === 'string' ? parseISO(value as any) : value
        }
        InputAdornmentProps={{
          position: 'start',
        }}
        {...lessRest}
      />
    </LocalizationProvider>
  )
}

const KeyboardDatePicker = (props: KeyboardDatePickerProps) => {
  const {
    className = '',
    name,
    fieldProps,
    useFinalForm = true,
    onChange,
    value,
    size = 'small',
    withTopLabel,
    isLoading,
    ...rest
  } = props

  if (isLoading) {
    return <Skeleton variant="rectangular" width="150px" height="36px" />
  }

  if (!useFinalForm) {
    return (
      <KeyboardDatePickerWrapper
        {...rest}
        // @ts-ignore
        input={{
          name,
          onChange,
          value,
        }}
        className={cn(className, {
          [styles.big]: size === 'big',
          [styles.root]: size === 'small',
          [styles.withTopLabel]: withTopLabel,
        })}
      />
    )
  }

  return (
    <Field
      name={name}
      render={({ input, ...fieldRenderProps }) => {
        const handleOpen = () => input.onFocus()
        const handleClose = () => input.onBlur()

        return (
          <KeyboardDatePickerWrapper
            {...fieldRenderProps}
            {...rest}
            input={input}
            className={cn(className, {
              [styles.big]: size === 'big',
              [styles.root]: size === 'small',
              [styles.withTopLabel]: withTopLabel,
            })}
            onOpen={handleOpen}
            onClose={handleClose}
            withTopLabel={withTopLabel}
          />
        )
      }}
      {...fieldProps}
    />
  )
}

export default KeyboardDatePicker
