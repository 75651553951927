import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Form, Field, FormRenderProps } from 'react-final-form'
import { FormApi } from 'final-form'
import { makeValidate } from 'mui-rff'
import Box from '@mui/material/Box'
import * as Yup from 'yup'
import cn from 'classnames'

import styles from './PeopleAndToolsForm.module.scss'

import { ClientERPSystem } from '@common/interfaces/client'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import InputLabel from '../../Common/InputLabel'
import TextField from '../../Common/TextField'
import Autocomplete from '../../Common/Autocomplete'
import Button from '../../Common/Button'
import { IOPSReporting } from '@common/interfaces/prospects'

const mutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue }: any) => {
    changeValue(state, field, () => value)
  },
}

const schema = Yup.object().shape({
  erpSystem: Yup.array()
    .min(1, 'At least 1 ERP or Inventory Management Systems Required')
    .required('Required'),
  erpSystemOther: Yup.string()
    .nullable()
    .when('erpSystem', (erpSystem: ClientERPSystem[], validation: any) =>
      erpSystem.includes(ClientERPSystem.Other) ? validation.required('Required') : validation,
    ),
})
const validate = makeValidate(schema)

interface IProps {
  isSaving: boolean
  opsReporting: IOPSReporting
  handleSubmit: (data: any) => void
  handleGoBack: (data: any) => void
  handleLoadVendors: (type: string, search: string) => Promise<any>
  setIsDirty: (dirty: boolean) => void
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
}

const PeopleAndToolsFormRender = ({
  form,
  dirty,
  values,
  submitting,
  invalid,
  handleSubmit,
  handleGoBack,
  handleLoadVendors,
  setIsDirty,
  formRef,
  isSaving,
}: FormRenderProps<any> & {
  handleGoBack: (data: any) => void
  handleLoadVendors: (type: string, search: string) => Promise<any>
  setIsDirty: (dirty: boolean) => void
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  isSaving: boolean
}) => {
  const [isGoBack, setIsGoBack] = useState(false)
  const {
    mutators: { setFieldData },
  } = form
  formRef.current = form

  useEffect(() => {
    setIsDirty(dirty)
  }, [setIsDirty, dirty])

  const loadOptions = useCallback(
    async (type, search: string) => {
      const res = await handleLoadVendors(type, search)
      return res.data.map((vendor: any) => ({
        value: vendor.id,
        label: vendor.name,
      }))
    },
    [handleLoadVendors],
  )

  const loadLawFirmOptions = useCallback(
    async (search: string) => loadOptions(ClientEntityRelationshipType.LawFirm, search),
    [loadOptions],
  )

  const loadOutsourcedCFOOptions = useCallback(
    async (search: string) => loadOptions(ClientEntityRelationshipType.OutsourcedCFO, search),
    [loadOptions],
  )

  const loadAccountingFirmOptions = useCallback(
    async (search: string) => loadOptions(ClientEntityRelationshipType.AccountingFirm, search),
    [loadOptions],
  )

  const loadDistributionServices3PLOptions = useCallback(
    async (search: string) =>
      loadOptions(ClientEntityRelationshipType.DistributionServices3PL, search),
    [loadOptions],
  )

  const handleSelectERPSystem = useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      const { value } = event.currentTarget.dataset
      const { erpSystem = [] } = values
      setFieldData(
        'erpSystem',
        erpSystem.includes(value)
          ? erpSystem.filter((system: string) => system !== value)
          : [...erpSystem, value],
      )
    },
    [values, setFieldData],
  )

  const onBack = useCallback(() => {
    setIsGoBack(true)
    handleGoBack(values)
  }, [handleGoBack, values])

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formContent}>
        <Box>
          <InputLabel className={styles.inputLabel}>Law firm</InputLabel>
          <Autocomplete
            label=""
            data-cy="lawFirm"
            name="lawFirm"
            className={styles.autocompleteField}
            isAsync
            loadOptions={loadLawFirmOptions}
            options={[]}
            freeSolo
            autoSelect
            placeholder="Select Law Firm"
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>Outsourced CFO</InputLabel>
          <Autocomplete
            label=""
            data-cy="outsourcedCFO"
            name="outsourcedCFO"
            className={styles.autocompleteField}
            isAsync
            loadOptions={loadOutsourcedCFOOptions}
            options={[]}
            freeSolo
            autoSelect
            placeholder="Select Outsourced CFO"
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>Accounting firm</InputLabel>
          <Autocomplete
            label=""
            data-cy="accountingFirm"
            name="accountingFirm"
            className={styles.autocompleteField}
            isAsync
            loadOptions={loadAccountingFirmOptions}
            options={[]}
            freeSolo
            autoSelect
            placeholder="Select Accounting firm"
          />
        </Box>
        <Box>
          <InputLabel className={styles.inputLabel}>3PL</InputLabel>
          <Autocomplete
            label=""
            data-cy="distributionServices3PL"
            name="distributionServices3PL"
            className={styles.autocompleteField}
            isAsync
            loadOptions={loadDistributionServices3PLOptions}
            options={[]}
            freeSolo
            autoSelect
            placeholder="Select 3PL"
          />
        </Box>
        <Box className={styles.fullWidthRow}>
          <InputLabel className={styles.inputLabel}>
            ERPs and/or Inventory Management Systems
          </InputLabel>
          <Field name="erpSystem" render={() => null} />
          <div className={styles.erpSelectorWrapper}>
            {Object.values(ClientERPSystem).map((value) => (
              <div
                key={value}
                data-value={value}
                onClick={handleSelectERPSystem}
                className={cn(styles.erpSelector, {
                  [styles.erpSelectorSelected]: values.erpSystem.includes(value),
                })}
              >
                {value}
              </div>
            ))}
          </div>
        </Box>
        {values.erpSystem.includes(ClientERPSystem.Other) && (
          <Box className={styles.fullWidthRow}>
            <InputLabel className={styles.inputLabel}>
              What other ERPs and/or Inventory Management Systems do you use?
            </InputLabel>
            <TextField
              name="erpSystemOther"
              placeholder="List them here"
              size="big"
              className={styles.inputField}
            />
          </Box>
        )}
      </div>

      <div className={styles.buttonDivider} />

      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          variant="outlined"
          onClick={onBack}
          disabled={!isGoBack && isSaving}
          isLoading={isGoBack && isSaving}
        >
          Back
        </Button>

        <Button
          isLoading={!isGoBack && isSaving}
          className={styles.button}
          variant="contained"
          disabled={submitting || invalid || isSaving}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </form>
  )
}

const PeopleAndToolsForm = ({
  isSaving,
  opsReporting,
  handleSubmit,
  handleGoBack,
  handleLoadVendors,
  setIsDirty,
  formRef,
}: IProps) => {
  const initialValues = useMemo(() => {
    const lawFirm = opsReporting?.clientInfo?.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.LawFirm,
    )
    const outsourcedCFO = opsReporting?.clientInfo?.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.OutsourcedCFO,
    )
    const accountingFirm = opsReporting?.clientInfo?.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.AccountingFirm,
    )
    const distributionServices3PL = opsReporting?.clientInfo?.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.DistributionServices3PL,
    )
    return {
      lawFirm: lawFirm
        ? lawFirm.salesforceAccount?.name || lawFirm.newSalesforceAccount
        : undefined,
      outsourcedCFO: outsourcedCFO
        ? outsourcedCFO.salesforceAccount?.name || outsourcedCFO.newSalesforceAccount
        : undefined,
      accountingFirm: accountingFirm
        ? accountingFirm.salesforceAccount?.name || accountingFirm.newSalesforceAccount
        : undefined,
      distributionServices3PL: distributionServices3PL
        ? distributionServices3PL.salesforceAccount?.name ||
          distributionServices3PL.newSalesforceAccount
        : undefined,
      erpSystem: opsReporting?.clientInfo?.erpSystem || [],
      erpSystemOther: opsReporting?.clientInfo?.erpSystemOther,
    }
  }, [opsReporting])

  const prepareData = useCallback((data: any) => {
    const { lawFirm, outsourcedCFO, accountingFirm, distributionServices3PL, ...rest } = data

    return {
      clientEntityRelationship: [
        lawFirm
          ? {
              type: ClientEntityRelationshipType.LawFirm,
              salesforceAccount: lawFirm?.label || lawFirm,
            }
          : null,
        outsourcedCFO
          ? {
              type: ClientEntityRelationshipType.OutsourcedCFO,
              salesforceAccount: outsourcedCFO?.label || outsourcedCFO,
            }
          : null,
        accountingFirm
          ? {
              type: ClientEntityRelationshipType.AccountingFirm,
              salesforceAccount: accountingFirm?.label || accountingFirm,
            }
          : null,
        distributionServices3PL
          ? {
              type: ClientEntityRelationshipType.DistributionServices3PL,
              salesforceAccount: distributionServices3PL?.label || distributionServices3PL,
            }
          : null,
      ].filter(Boolean),
      ...rest,
    }
  }, [])

  const onSubmit = useCallback(
    (data: any) => {
      handleSubmit(prepareData(data))
    },
    [handleSubmit, prepareData],
  )

  const onGoBack = useCallback(
    (data: any) => {
      handleGoBack(prepareData(data))
    },
    [handleGoBack, prepareData],
  )

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={mutators}
      render={(props) => (
        <PeopleAndToolsFormRender
          handleGoBack={onGoBack}
          handleLoadVendors={handleLoadVendors}
          setIsDirty={setIsDirty}
          formRef={formRef}
          isSaving={isSaving}
          {...props}
        />
      )}
    />
  )
}

export default PeopleAndToolsForm
