import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Select as MuiSelect, SelectProps } from 'mui-rff'
import { default as MuiCoreSelect } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import cn from 'classnames'
import CloseIcon from '@mui/icons-material/Close'
import styles from './SelectField.module.scss'
import { useFormState, useForm } from 'react-final-form'
import { ReactComponent as PlusIcon } from '@assets/images/add-select-field.svg'
import DropDownArrow from '../DropDownArrow'
import { Skeleton } from '@mui/material'

export const useRenderValue = (options: IOption[], placeholder: string) => {
  const handleRenderValue = useCallback(
    (selected: string[] | string) => {
      if (Array.isArray(selected) && selected.length > 0) {
        return selected
          .map((item) => options.find(({ value }) => value === item)?.label || item)
          .join(', ')
      }

      const selectedOption = options.find(({ value }) => value === selected?.toString())
      const label = selectedOption?.label || selected

      if (typeof selected === 'string' && selected && selectedOption?.icon) {
        return (
          <div className={styles.iconWrapper}>
            {selectedOption?.icon}
            <span className={styles.iconLabel}> {label}</span>
          </div>
        )
      } else if (label) {
        return label
      } else {
        return <span className={styles.placeholder}> {placeholder}</span>
      }
    },
    [options, placeholder],
  )

  return handleRenderValue
}

export interface IOption {
  value: string
  label: string
  disabled?: boolean
  icon?: React.ReactNode
  className?: string
}

interface IProps extends SelectProps {
  useFinalForm?: boolean
  options: IOption[]
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  align?: string
  className?: string
  selectSize?: 'small' | 'big'
  optionClassName?: string
  onAddValue?: () => void
  addValueLabel?: string
  border?: boolean
  withTopLabel?: boolean
  maxMultipleValues?: number
  isLoading?: boolean
  handleClear?: () => void
  withClear?: boolean
}

const SelectField = ({ useFinalForm = true, ...props }: IProps) => {
  if (!useFinalForm) {
    return <SelectFieldOutsideForm {...props} />
  } else {
    return <SelectFieldInForm {...props} />
  }
}

const SelectFieldInForm = ({
  options,
  placeholder,
  children,
  onChange,
  align,
  className,
  selectSize = 'small',
  optionClassName,
  onAddValue,
  addValueLabel = 'Add New',
  border,
  variant = 'standard',
  withTopLabel,
  maxMultipleValues,
  isLoading = false,
  handleClear,
  withClear = false,
  ...props
}: IProps) => {
  const handleRenderValue = useRenderValue(options, placeholder)

  const handleAddValue = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onAddValue && onAddValue()
    },
    [onAddValue],
  )

  const { values } = useFormState()
  const form = useForm()

  const isValueSelected = useMemo(() => {
    if (props.name) {
      const selectedValue = values?.[props.name]
      return !!selectedValue
    }
    return false
  }, [values, props])

  const handleClearValue = useCallback(() => {
    if (withClear) {
      if (props.name) {
        form.change(props.name, undefined)
      }
    }
  }, [form, props, withClear])

  const clearIconStyleEnabled = useMemo(() => {
    return isValueSelected && !!withClear
  }, [isValueSelected, withClear])

  if (isLoading) {
    return <Skeleton variant="rectangular" width="150px" height="36px" />
  }

  return (
    <MuiSelect
      MenuProps={{
        classes: {
          paper: selectSize === 'big' ? styles.menuPaperBig : styles.menuPaper,
          list: styles.menuList,
        },
      }}
      classes={{
        select: cn(styles.select, {
          [styles.rightAlignSelect]: align === 'right',
          [styles.withClearIconSelect]: clearIconStyleEnabled,
        }),
        icon: cn({
          [styles.withClearIcon]: clearIconStyleEnabled,
        }),
      }}
      className={cn('commonFilter', className, {
        [styles.big]: selectSize === 'big',
        [styles.border]: border,
        [styles.withTopLabel]: withTopLabel,
        [styles.withClearIconSelectCompnent]: clearIconStyleEnabled,
      })}
      IconComponent={DropDownArrow}
      displayEmpty={!!placeholder}
      disableUnderline
      variant={variant}
      // @ts-ignore
      renderValue={handleRenderValue}
      endAdornment={
        clearIconStyleEnabled && (
          <CloseIcon onClick={handleClearValue} className={styles.clearIcon} />
        )
      }
      {...props}
    >
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map(({ value, label, disabled, icon, className }) => (
        <MenuItem
          key={value}
          value={value}
          disabled={disabled}
          classes={{ selected: styles.selected, root: styles.listItemRoot }}
          className={cn('commonFilter', optionClassName)}
        >
          {icon}
          <div className={cn(styles.iconLabel, optionClassName, className)}>{label}</div>
        </MenuItem>
      ))}
      {onAddValue && (
        <MenuItem
          value={undefined}
          classes={{ root: styles.listItemRoot }}
          onClick={handleAddValue}
        >
          <PlusIcon /> {addValueLabel}
        </MenuItem>
      )}
    </MuiSelect>
  )
}

const SelectFieldOutsideForm = ({
  options,
  placeholder,
  children,
  onChange,
  align,
  className,
  selectSize = 'small',
  optionClassName,
  onAddValue,
  addValueLabel = 'Add New',
  border,
  variant = 'standard',
  withTopLabel,
  maxMultipleValues,
  isLoading = false,
  handleClear,
  withClear = false,
  ...props
}: IProps) => {
  const [isValueSelected, setIsValueSelected] = useState(false)
  const extraProps: any = {}

  let newOnChange = onChange
  if (maxMultipleValues) {
    newOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length <= maxMultipleValues) {
        onChange && onChange(event)
      }
    }
  }

  extraProps.onChange = newOnChange

  const handleRenderValue = useRenderValue(options, placeholder)

  const handleAddValue = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onAddValue && onAddValue()
    },
    [onAddValue],
  )

  useEffect(() => {
    if (props.name) {
      setIsValueSelected(!!props.value || !!props.defaultValue)
    }
  }, [props])

  const showClear = useMemo(() => withClear || !!handleClear, [withClear, handleClear])

  const handleClearValue = useCallback(() => {
    if (showClear) {
      if (props.name && handleClear) {
        handleClear()
      }
    }
  }, [props, showClear, handleClear])

  const clearIconStyleEnabled = useMemo(() => {
    return isValueSelected && !!showClear
  }, [isValueSelected, showClear])

  if (isLoading) {
    return <Skeleton variant="rectangular" width="150px" height="36px" />
  }

  return (
    <MuiCoreSelect
      MenuProps={{
        classes: {
          paper: selectSize === 'big' ? styles.menuPaperBig : styles.menuPaper,
          list: styles.menuList,
        },
      }}
      classes={{
        select: cn(styles.select, {
          [styles.rightAlignSelect]: align === 'right',
          [styles.withClearIconSelect]: clearIconStyleEnabled,
        }),
        icon: cn({
          [styles.withClearIcon]: clearIconStyleEnabled,
        }),
      }}
      className={cn('commonFilter', className, {
        [styles.big]: selectSize === 'big',
        [styles.border]: border,
        [styles.withTopLabel]: withTopLabel,
        [styles.withClearIconSelectCompnent]: clearIconStyleEnabled,
      })}
      IconComponent={DropDownArrow}
      displayEmpty={!!placeholder}
      disableUnderline
      variant={variant}
      renderValue={handleRenderValue}
      endAdornment={
        clearIconStyleEnabled && (
          <CloseIcon onClick={handleClearValue} className={styles.clearIcon} />
        )
      }
      {...props}
      {...extraProps}
    >
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map(({ value, label, disabled, icon, className }) => (
        <MenuItem
          key={value}
          value={value}
          disabled={disabled}
          classes={{ selected: styles.selected, root: styles.listItemRoot }}
          className={cn('commonFilter', optionClassName)}
        >
          {icon}
          <div className={cn(styles.iconLabel, optionClassName, className)}>{label}</div>
        </MenuItem>
      ))}
      {onAddValue && (
        <MenuItem
          value={undefined}
          classes={{ root: styles.listItemRoot }}
          onClick={handleAddValue}
        >
          <PlusIcon /> {addValueLabel}
        </MenuItem>
      )}
    </MuiCoreSelect>
  )
}

export default SelectField
