import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import cn from 'classnames'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import groupBy from 'lodash/groupBy'
import Tooltip from '@mui/material/Tooltip'

import styles from './DueDiligenceClientStepFiles.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as PriorityIcon } from '@assets/images/priority-icon.svg'
import {
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES,
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD,
  IDueDiligence,
  IDueDiligenceDocumentRequest,
  AUTO_REPORTING_DOCUMENTS,
  DUE_DILIGENCE_DOCUMENTS_TYPES_SAMPLES,
  DueDiligenceDocumentRequestStatus,
} from '@common/interfaces/dueDiligence'
import { ILoadingData } from '../../redux/types'
import UploadFileManagement from '../../components/Common/UploadFileManagement'
import { DEFAULT_MAX_FILE_SIZE } from '../../constants/common'
import { IFile } from '@common/interfaces/box'
import WarningModal from '../../components/WarningModal'
import { humanReadableFileSize } from '@common/helpers/helpers'

const DueDiligenceDocumentRequestListItem = ({
  selectedRef,
  documentRequest,
  onSelectDocumentRequest,
  isSelected = false,
  isPriority = false,
}: {
  documentRequest: IDueDiligenceDocumentRequest
  onSelectDocumentRequest: (documentRequest: IDueDiligenceDocumentRequest) => void
  isSelected: boolean
  isPriority?: boolean
  selectedRef: React.MutableRefObject<any>
}) => {
  const handleSelectDocumentRequest = useCallback(() => {
    onSelectDocumentRequest(documentRequest)
  }, [documentRequest, onSelectDocumentRequest])

  return (
    <div
      className={cn(styles.documentRequestListItem, {
        [styles.documentRequestListItemSelected]: isSelected,
      })}
      onClick={handleSelectDocumentRequest}
      ref={isSelected ? selectedRef : null}
    >
      {isPriority && <PriorityIcon />}
      <div className={styles.documentRequestListItemText}>
        <div className={styles.documentRequestListItemName}>{documentRequest.type.name}</div>
        {documentRequest.type.helperText && (
          <div className={styles.documentRequestListItemHelperText}>
            {documentRequest.type.helperText}
          </div>
        )}
      </div>
      {documentRequest.files.length > 0 && (
        <div className={styles.documentRequestListItemCount}>{documentRequest.files.length}</div>
      )}
    </div>
  )
}

interface IProps {
  step: string
  dueDiligenceInfo: IDueDiligence
  dueDiligenceDocumentRequests: ILoadingData<{ data: IDueDiligenceDocumentRequest[] }>
  getCompanyInfo: () => void
  showDocumentRequests: () => void
  uploadDocumentRequestsFiles: (data: FormData) => void
  getDocumentRequestsSharedLink: (documentRequestId: string) => void
  deleteFile: (ids: string[]) => Promise<void>
  navigationRef: React.MutableRefObject<any>
  handleIntegrations?: () => void
  putNotification: (notification: object) => void
}

const DueDiligenceClientStepFiles = ({
  step,
  dueDiligenceInfo,
  dueDiligenceDocumentRequests,
  getCompanyInfo,
  showDocumentRequests,
  uploadDocumentRequestsFiles,
  getDocumentRequestsSharedLink,
  deleteFile,
  navigationRef,
  handleIntegrations,
  putNotification,
}: IProps) => {
  const selectedDocumentRequestRef = useRef(null)
  const [selectedDocumentRequest, setSelectedDocumentRequest] =
    useState<IDueDiligenceDocumentRequest>(null)
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isFilesDeleting, setIsFilesDeleting] = useState(false)

  useEffect(() => {
    if (dueDiligenceDocumentRequests?.data?.data) {
      setSelectedDocumentRequest((selectedDocumentRequest) =>
        selectedDocumentRequest
          ? dueDiligenceDocumentRequests.data.data.find(
              ({ id }) => id === selectedDocumentRequest.id,
            )
          : null,
      )
    }
  }, [dueDiligenceDocumentRequests.data])

  useEffect(() => {
    if (selectedDocumentRequestRef.current) {
      selectedDocumentRequestRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }, [selectedDocumentRequest])

  const { priority, samples, other } = useMemo(() => {
    const stepDocumentRequests =
      dueDiligenceDocumentRequests?.data?.data
        ?.filter(
          ({ type }) =>
            type.step === step && !DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD.includes(type.type),
        )
        .filter(({ status }) => status !== DueDiligenceDocumentRequestStatus.Waived) || []
    const samples = stepDocumentRequests.filter((documentRequest) =>
      DUE_DILIGENCE_DOCUMENTS_TYPES_SAMPLES.includes(documentRequest.type.type),
    )
    const { Priority, ...other } = groupBy(
      stepDocumentRequests.filter(
        (documentRequest) =>
          !DUE_DILIGENCE_DOCUMENTS_TYPES_SAMPLES.includes(documentRequest.type.type),
      ),
      'type.subStep',
    )
    return {
      priority: Priority || [],
      samples,
      other: Object.keys(other).map((subStep) => ({
        subStep,
        documentRequests: other[subStep],
      })),
    }
  }, [dueDiligenceDocumentRequests, step])

  navigationRef.current = useCallback(
    (navigate: () => void, direction: 'next' | 'back' = 'next') => {
      const documents = [
        ...priority,
        ...other.map(({ documentRequests }) => documentRequests).flat(),
        ...samples,
      ]
      const index = documents.findIndex(({ id }) => id === selectedDocumentRequest.id)
      if (direction === 'next') {
        if (index >= documents.length - 1) {
          navigate()
        } else {
          setSelectedDocumentRequest(documents[index + 1])
        }
      } else {
        if (index === 0) {
          navigate()
        } else {
          setSelectedDocumentRequest(documents[index - 1])
        }
      }
    },
    [selectedDocumentRequest, priority, other, samples],
  )

  useEffect(() => {
    if (priority.length && !selectedDocumentRequest) {
      setSelectedDocumentRequest(priority[0])
    }
  }, [priority, selectedDocumentRequest])

  const handleSelectDocumentRequest = useCallback(
    (documentRequest: IDueDiligenceDocumentRequest) => {
      setSelectedDocumentRequest(documentRequest)
    },
    [],
  )

  const handleFileUpload = useCallback(
    (loadedFiles: File[]) => {
      const formData = new FormData()

      setSelectedDocumentRequest({
        ...selectedDocumentRequest,
        files: [
          ...selectedDocumentRequest.files,
          ...loadedFiles.map((file) => ({
            ...file,
            id: null,
            name: file.name,
            fileName: file.name,
            isUploading: true,
          })),
        ],
      })

      for (const file of loadedFiles) {
        formData.append('files[]', file, file.name)
      }
      formData.append('documentRequestId', selectedDocumentRequest.id)

      uploadDocumentRequestsFiles(formData)
    },
    [selectedDocumentRequest, uploadDocumentRequestsFiles],
  )

  const handleFileDelete = useCallback(
    (indexes: number[]) => {
      const files = selectedDocumentRequest?.files || []
      setIsDeleteModalShown(true)
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => files[number === undefined ? index : number]),
      )
    },
    [selectedDocumentRequest],
  )

  const handleDeleteConfirm = useCallback(async () => {
    setIsFilesDeleting(true)
    await deleteFile(filesForDelete.map((file) => file.id))
    await showDocumentRequests()
    await getCompanyInfo()
    setIsFilesDeleting(false)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [filesForDelete, deleteFile, showDocumentRequests, getCompanyInfo])

  const handleDeleteCancel = useCallback(async () => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleFileRejection = useCallback(
    (fileRejection: FileRejection[]) => {
      const firstError = fileRejection?.[0]?.errors?.[0]
      if (firstError) {
        putNotification({
          code: firstError.code,
          message:
            firstError.code === ErrorCode.FileTooLarge
              ? `File is too large, the maximum file size is ${humanReadableFileSize(
                  DEFAULT_MAX_FILE_SIZE,
                ).join(' ')}`
              : firstError.message,
          type: 'error',
        })
      }
    },
    [putNotification],
  )

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: handleFileUpload,
    onDropRejected: handleFileRejection,
  })

  const handleViewSample = useCallback(() => {
    if (DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES.includes(selectedDocumentRequest.type.type)) {
      getDocumentRequestsSharedLink(selectedDocumentRequest.id)
    }
  }, [getDocumentRequestsSharedLink, selectedDocumentRequest])

  const showConnectToAutoReporting = useMemo(
    () =>
      !dueDiligenceInfo?.isErpConnected &&
      handleIntegrations &&
      dueDiligenceInfo?.codatLink &&
      AUTO_REPORTING_DOCUMENTS.includes(selectedDocumentRequest?.type?.type),
    [dueDiligenceInfo, handleIntegrations, selectedDocumentRequest],
  )

  return (
    <div className={styles.container}>
      <div className={styles.documentRequestListWrapper}>
        <div className={styles.documentRequestListHeader}>Priority documents</div>

        <div className={styles.documentRequestList}>
          {priority.map((documentRequest) => (
            <DueDiligenceDocumentRequestListItem
              key={documentRequest.id}
              documentRequest={documentRequest}
              onSelectDocumentRequest={handleSelectDocumentRequest}
              isSelected={selectedDocumentRequest?.id === documentRequest.id}
              isPriority
              selectedRef={selectedDocumentRequestRef}
            />
          ))}
        </div>

        {other.map(({ subStep, documentRequests }) => (
          <React.Fragment key={subStep}>
            <div className={styles.documentRequestListHeader}>{subStep}</div>

            <div className={styles.documentRequestList}>
              {documentRequests.map((documentRequest) => (
                <DueDiligenceDocumentRequestListItem
                  key={documentRequest.id}
                  documentRequest={documentRequest}
                  onSelectDocumentRequest={handleSelectDocumentRequest}
                  isSelected={selectedDocumentRequest?.id === documentRequest.id}
                  selectedRef={selectedDocumentRequestRef}
                />
              ))}
            </div>
          </React.Fragment>
        ))}

        {samples.length > 0 && (
          <>
            <div className={styles.documentRequestListHeader}>Third Party Testing - Samples</div>

            <div className={styles.documentRequestList}>
              {samples.map((documentRequest) => (
                <DueDiligenceDocumentRequestListItem
                  key={documentRequest.id}
                  documentRequest={documentRequest}
                  onSelectDocumentRequest={handleSelectDocumentRequest}
                  isSelected={selectedDocumentRequest?.id === documentRequest.id}
                  isPriority={documentRequest.type.isPriority}
                  selectedRef={selectedDocumentRequestRef}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {selectedDocumentRequest && (
        <div className={styles.documentsUpload}>
          <div className={styles.documentsUploadHeader}>
            Upload Document
            {DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES.includes(
              selectedDocumentRequest.type.type,
            ) && (
              <Tooltip placement="top" title="View Sample">
                <div
                  onClick={handleViewSample}
                  className={styles.documentsUploadHeaderTemplateLink}
                >
                  View Sample
                </div>
              </Tooltip>
            )}
          </div>
          {showConnectToAutoReporting && (
            <div className={styles.autoReporting}>
              Shorten your documents request list and bypass certain AR, AP, and historical reports
              by authorizing Dwight to pull reports on your behalf.{' '}
              <span className={styles.autoReportingLink} onClick={handleIntegrations}>
                Connect to auto-reporting.
              </span>
            </div>
          )}
          {selectedDocumentRequest.type.helperText && (
            <div className={genericSs.warningMessage}>
              {selectedDocumentRequest.type.helperText}
            </div>
          )}
          {selectedDocumentRequest.type.fileType && (
            <div className={genericSs.warningMessage}>
              {selectedDocumentRequest.type.fileType} format is required for this document
            </div>
          )}
          <UploadFileManagement
            files={selectedDocumentRequest.files}
            onDelete={handleFileDelete}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            open={open}
            handleDelete={handleFileDelete}
            dropzoneText="Drop files here or "
          />
        </div>
      )}

      {isDeleteModalShown && filesForDelete.length > 0 && (
        <WarningModal
          warningMessage={
            filesForDelete.length > 1
              ? 'Deleting these files will be permanent.'
              : 'Deleting this file will be permanent.'
          }
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isFilesDeleting}
          disabled={isFilesDeleting}
        />
      )}
    </div>
  )
}

export default DueDiligenceClientStepFiles
