import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useParams, useHistory, useLocation, matchPath, generatePath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import cn from 'classnames'
import moment from 'moment'

import styles from './BBCSummaryPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ReactComponent as WarningIconOutline } from '@assets/images/warning-icon-outline.svg'
import Button from '../../components/Common/Button'
import BBCNotes from '../../components/BBCNotes'
import {
  FundingRequestStatus,
  IBorrowingBase,
  IClientCollateral,
  IInventoryIneligibilityField,
  BBC_TABS,
  BBC_TAB_FILE_TYPE_MAPPING,
  BBC_MAPPING_TABS,
  WorkflowPage,
} from '@common/interfaces/bbc'
import { ROUTES } from '../../constants/routes'
import Tabs from '../../components/Common/Tabs'
import { LOAN_TYPES } from '@common/constants/client'
import BBCARSummaryTable from '../../components/BBCARSummaryTable'
import BBCAPSummaryTable from '../../components/BBCAPSummaryTable'
import ReservesTable from '../../components/ReservesTable'
import BBCIneligibleARTable from '../../components/BBCIneligibleARTable'
import BBCIneligibleInventoryTable from '../../components/BBCIneligibleInventoryTable'
import BBCInventoryDistributionTable from '../../components/BBCInventoryDistributionTable'
import BBCRollforwardTable from '../../components/BBCRollforwardTable'
import BBCAvailabilityTable from '../../components/BBCAvailabilityTable'
import BBCEditModal from '../../components/BBCEditModal'
import BBCMappingModal from '../../components/BBCMappingModal'
import { ActivityType } from '@common/interfaces/activity'
import {
  formatDate,
  historyChangeEventHandler,
  visibilityChangeEventHandler,
} from '../../helpers/helpers'
import { usePermissions } from '../../helpers/permissionContext'

import {
  ExternalLink,
  PDFIcon,
  EditMapping,
  EditSourceData,
  MenuIcon,
} from '../../components/Common/Icons'
import MenuItem from '../../components/Common/MenuItem'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { CollateralTypes } from '@common/constants/client'
import BBCFileSelect from '../../components/BBCFileSelect'
import { ILoadingData } from '../../redux/types'
import BBCLoader from '../../components/BBCLoader'
import RouteLeavingGuard from '../../components/Common/RouteLeavingGuard'
import SalesBySKUVisualizationTable from '../../components/SalesBySKUVisualizationTable'
import WarningModal from '../../components/WarningModal'
import { WorkflowTypes } from '@common/interfaces/notes'

const TABS_ROUTES = {
  Receivables: ROUTES.BBC_SUMMARY_RECEIVABLES,
  Payables: ROUTES.BBC_SUMMARY_PAYABLES,
  Inventory: ROUTES.BBC_SUMMARY_INVENTORY,
}

const BBC_STEPS_TO_MAPPING_TABS_MAP = {
  [BBC_TABS.RECEIVABLES]: BBC_MAPPING_TABS.Customer,
  [BBC_TABS.PAYABLES]: BBC_MAPPING_TABS.Vendor,
}

interface IProps {
  isLoading: boolean
  bbc: IBorrowingBase
  clientCollaterals: IClientCollateral[]
  calculateClientCollateral: (id: string) => void
  submitForReview: (id: string) => Promise<any>
  submit: (id: string, data?: object) => Promise<any>
  fields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  listFields: (data: { borrowingBaseId: string }) => void
  getPreviousBBCClientCollateral: (id: string) => void
  getBBCSummaryStats: (id: string) => void
  exportBBC: (id: string, clientName: string, recordDate: string) => void
  updateBBCPdf: (id: string) => void
  show: (id: string) => void
  hide: () => void
  submitArIneligibility: (id: string) => Promise<any>
  inventoryIneligibleSubmit: (id: string) => Promise<any>
  trackActivity: (data: object, beacon?: boolean) => void
  changePageTitle: (title: string) => void
  getDebtorIneligibleCategories: (data: object) => void
}

const BBCSummaryPage = ({
  isLoading,
  bbc,
  calculateClientCollateral,
  submitForReview,
  submit,
  fields,
  listFields,
  getPreviousBBCClientCollateral,
  getBBCSummaryStats,
  exportBBC,
  updateBBCPdf,
  show,
  hide,
  submitArIneligibility,
  inventoryIneligibleSubmit,
  trackActivity,
  getDebtorIneligibleCategories,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isParticipant } = usePermissions()
  const history = useHistory()
  const { pathname } = useLocation()
  const isHistorical = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )
  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false)
  const [isMappingModalClosed, setIsMappingModalClosed] = useState(false)
  const [isAuthorizedSignorModalOpen, setIsAuthorizedSignorModalOpen] = useState(false)
  const [refreshCount, setRefreshCount] = useState(1)
  const [loadTheRest, setLoadTheRest] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)
  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [isPDFUpdating, setIsPDFUpdating] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSaveTestData, setIsSaveTestData] = useState(false)
  const [isFilesSaving, setIsFilesSaving] = useState(false)
  const funded = useMemo(
    () =>
      [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(
        bbc?.status ?? FundingRequestStatus.Requested,
      ),
    [bbc],
  )
  const isTestBBC = useMemo(() => bbc?.isTest || false, [bbc])
  const bbcId = useMemo(() => {
    if (bbc?.id === id) {
      return bbc?.id
    }
  }, [bbc, id])

  useLoadInfo(id, bbc, show, hide)
  useSetPageTitle(
    bbc ? `${bbc.clientName} ${isTestBBC ? 'Test ' : ''}BBC ${formatDate(bbc.recordDate)}` : 'BBC',
  )

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  const loanType = useMemo(() => bbc?.clientInfo?.loanType, [bbc])

  const refetchArAndInventoryIneligibility = useCallback(async () => {
    if (!isHistorical && bbcId && loanType) {
      if (loanType !== LOAN_TYPES.inventory) {
        const arResult = await submitArIneligibility(id)
        if (arResult?.error) {
          return
        }
      }
      if (loanType !== LOAN_TYPES.ar) {
        const invResult = await inventoryIneligibleSubmit(id)
        if (invResult?.error) {
          return
        }
      }
      setLoadTheRest(true)
    }
  }, [
    id,
    isHistorical,
    inventoryIneligibleSubmit,
    submitArIneligibility,
    setLoadTheRest,
    bbcId,
    loanType,
  ])

  useEffect(() => {
    id && refetchArAndInventoryIneligibility()
  }, [refetchArAndInventoryIneligibility, id])

  useEffect(() => {
    if (bbcId && !isHistorical && loadTheRest) {
      calculateClientCollateral(bbcId)
    }
    if (bbcId && isHistorical) {
      getBBCSummaryStats(bbcId)
    }
  }, [bbcId, isHistorical, calculateClientCollateral, getBBCSummaryStats, loadTheRest])

  useEffect(() => {
    if (bbcId && (loadTheRest || isHistorical)) {
      getPreviousBBCClientCollateral(bbcId)
    }
  }, [bbcId, getPreviousBBCClientCollateral, loadTheRest, isHistorical])

  useEffect(() => {
    if (bbcId && (loadTheRest || isHistorical)) {
      listFields({ borrowingBaseId: bbcId })
    }
  }, [bbcId, listFields, loadTheRest, isHistorical])

  const [isSubmitConfirmOpen, setIsSubmitConfirmOpen] = useState(false)
  const [isTestSubmitModalOpen, setIsTestSubmitModalOpen] = useState(false)

  const currentTab = useMemo(
    () =>
      matchPath(pathname, generatePath(ROUTES.BBC_SUMMARY_INVENTORY, { id }))
        ? BBC_TABS.INVENTORY
        : matchPath(pathname, generatePath(ROUTES.BBC_SUMMARY_PAYABLES, { id }))
        ? BBC_TABS.PAYABLES
        : BBC_TABS.RECEIVABLES,
    [pathname, id],
  )

  const [startTime, setStartTime] = useState(moment().toISOString())

  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (bbc && bbc?.status !== FundingRequestStatus.Completed && !isMappingModalOpen) {
        const endTime = moment().toISOString()
        const data = {
          borrowingBaseId: bbc.id,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: ActivityType.Funding,
          step: currentTab,
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [bbc, startTime, currentTab, trackActivity, isMappingModalOpen],
  )

  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const handleOpenModal = useCallback(() => {
    logActivity()
    setIsMappingModalOpen(true)
  }, [logActivity])

  const handleCloseModal = useCallback(async () => {
    setIsMappingModalOpen(false)
    setIsMappingModalClosed(true)
    if (!isHistorical) {
      await submitArIneligibility(bbcId)
      await calculateClientCollateral(bbcId)
      setRefreshCount((refreshCount) => refreshCount + 1)
      setStartTime(moment().toISOString())
    }
  }, [bbcId, submitArIneligibility, calculateClientCollateral, isHistorical])

  const submitBBC = useCallback(async () => {
    const data = await submit(id)
    if (data?.data?.signObj) {
      return window.open(
        data.data.signObj.prepare_url,
        'Send BBC for Signing',
        'height=1000,width=1000',
      )
    }
  }, [id, submit])

  const handleSubmit = useCallback(async () => {
    if (id && bbc) {
      let newWindow

      logActivity()
      if (bbc.isTest) {
        setIsTestSubmitModalOpen(true)
        return
      }
      setIsSubmitting(true)
      if (bbc.status === FundingRequestStatus.Review) {
        if (bbc?.clientCollateral?.wireCosts !== 25) {
          setIsSubmitConfirmOpen(true)
          return
        }

        if (bbc?.clientInfo?.clientInfoUsers?.every((user) => !user.isAuthorizedSignature)) {
          setIsAuthorizedSignorModalOpen(true)
          return
        }
        newWindow = await submitBBC()
      } else {
        await submitForReview(id)
      }
      setIsSubmitting(false)
      history.push(ROUTES.ACTIVITY_QUEUE)
      newWindow && newWindow.focus()
    }
  }, [id, bbc, submitForReview, submitBBC, logActivity, history])

  const handleSubmitConfirm = useCallback(async () => {
    if (id && bbc) {
      setIsSubmitConfirmOpen(false)
      if (bbc?.clientInfo?.clientInfoUsers?.every((user) => !user.isAuthorizedSignature)) {
        setIsAuthorizedSignorModalOpen(true)
        return
      }
      const newWindow = await submitBBC()
      await setIsSubmitting(false)
      history.push(ROUTES.ACTIVITY_QUEUE)
      newWindow && newWindow.focus()
    }
  }, [id, bbc, submitBBC, history])

  const handleSubmitConfirmPostSignor = useCallback(async () => {
    if (id && bbc) {
      setIsAuthorizedSignorModalOpen(false)
      await submit(id)
      await setIsSubmitting(false)
      history.push(ROUTES.ACTIVITY_QUEUE)
    }
  }, [id, bbc, submit, history])

  const tabs = useMemo(() => {
    if (!bbc?.clientInfo) {
      return []
    }

    if (bbc.clientInfo.loanType === LOAN_TYPES.ar) {
      return [BBC_TABS.RECEIVABLES, BBC_TABS.PAYABLES]
    } else if (bbc.clientInfo.loanType === LOAN_TYPES.inventory) {
      return [BBC_TABS.PAYABLES, BBC_TABS.INVENTORY]
    } else {
      return [BBC_TABS.RECEIVABLES, BBC_TABS.PAYABLES, BBC_TABS.INVENTORY]
    }
  }, [bbc])

  const onTabChange = useCallback(
    (tab) => {
      history.push(generatePath(TABS_ROUTES[tab], { id }))
    },
    [history, id],
  )

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setActionsMenuOpen(false)
  }, [])
  const handleUpdateBBC = useCallback(async () => {
    await calculateClientCollateral(bbcId)
    setRefreshCount((refreshCount) => refreshCount + 1)
  }, [calculateClientCollateral, bbcId])

  const handleExportBBC = useCallback(async () => {
    handleCloseMenu()
    setIsExporting(true)
    await exportBBC(bbc.id, bbc.clientName, bbc.recordDate)
    setIsExporting(false)
  }, [bbc, exportBBC, handleCloseMenu])

  const handleUpdatePDF = useCallback(async () => {
    setIsPDFUpdating(true)
    await updateBBCPdf(bbc.id)
    setIsPDFUpdating(false)
  }, [bbc, updateBBCPdf])

  useEffect(() => {
    if (
      tabs.length > 0 &&
      matchPath(pathname, { path: generatePath(ROUTES.BBC_SUMMARY, { id }), exact: true })
    ) {
      history.push(generatePath(TABS_ROUTES[tabs[0]], { id }))
    }
  }, [pathname, tabs, history, id])

  const handleCloseSignorModal = useCallback(() => {
    setIsAuthorizedSignorModalOpen(false)
    setIsSubmitting(false)
  }, [])

  const handleCloseWireFeeModal = useCallback(() => {
    setIsSubmitConfirmOpen(false)
    setIsSubmitting(false)
  }, [])

  const collateralEligibilityReviewLink = useMemo(() => {
    const path =
      currentTab === BBC_TABS.INVENTORY
        ? ROUTES.BBC_COLLATERAL_ELIGIBILITY_INVENTORY
        : ROUTES.BBC_COLLATERAL_ELIGIBILITY
    const pathWithId = `${generatePath(path, {
      id,
    })}?bbcStep=BBC_SUMMARY_${currentTab.toUpperCase()}`

    return pathWithId
  }, [id, currentTab])

  const shouldLoadComponents = useMemo(() => {
    if (isHistorical) {
      return true
    }
    return loadTheRest
  }, [isHistorical, loadTheRest])

  const breadcrumbs = useMemo(() => {
    const baseBreadcrumbs = [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        title: bbc?.clientName,
        link: bbc?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: bbc?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.BBC_SUMMARY, {
          id,
        }),
        title: bbc
          ? `${isTestBBC ? 'Test ' : ''}BBC ${formatDate(bbc.recordDate)}`
          : `${isTestBBC ? 'Test ' : ''}BBC`,
      },
      {
        link: pathname,
        title: `${currentTab} summary`,
      },
    ]

    if (!isParticipant) {
      baseBreadcrumbs.splice(1, 0, {
        link: ROUTES.ACTIVITY_QUEUE,
        title: 'Operations',
      })
    }

    return baseBreadcrumbs
  }, [bbc, id, pathname, currentTab, isTestBBC, isParticipant])

  const tabOptions = useMemo(() => {
    return tabs
      .map((item) =>
        BBC_STEPS_TO_MAPPING_TABS_MAP[item] ? BBC_STEPS_TO_MAPPING_TABS_MAP[item] : null,
      )
      .filter(Boolean)
  }, [tabs])

  const currentBBCTab = useMemo(() => {
    return BBC_STEPS_TO_MAPPING_TABS_MAP[currentTab]
  }, [currentTab])

  const currentBBCFile = useMemo(
    () =>
      bbc?.files?.find(
        ({ type, sheetName }) => sheetName && type === BBC_TAB_FILE_TYPE_MAPPING[currentTab],
      ),
    [bbc, currentTab],
  )

  useEffect(() => {
    if (
      currentTab !== BBC_TABS.INVENTORY &&
      bbc &&
      currentBBCFile &&
      bbc.isEntityMappingRequired &&
      !isMappingModalClosed &&
      !isHistorical
    ) {
      setIsMappingModalOpen(true)
    }
  }, [currentTab, bbc, currentBBCFile, isMappingModalClosed, isHistorical])

  const handleOpenSelectFile = useCallback(() => {
    if (isHistorical) {
      return
    }

    setIsFileSelectShown(true)
  }, [isHistorical])

  const handleRecalculate = useCallback(
    async (isUpdated: boolean = false, closeModal: boolean = true) => {
      closeModal && setIsFileSelectShown(false)
      if (isUpdated) {
        if (currentTab === BBC_TABS.RECEIVABLES) {
          await submitArIneligibility(bbcId)
        }
        if (currentTab === BBC_TABS.INVENTORY) {
          await inventoryIneligibleSubmit(bbcId)
        }
        await calculateClientCollateral(bbcId)
        currentTab !== BBC_TABS.INVENTORY && setIsMappingModalClosed(false)
        setRefreshCount((refreshCount) => refreshCount + 1)
        setStartTime(moment().toISOString())
      }
    },
    [
      currentTab,
      bbcId,
      submitArIneligibility,
      inventoryIneligibleSubmit,
      calculateClientCollateral,
    ],
  )

  useEffect(() => {
    if (currentTab === BBC_TABS.RECEIVABLES) {
      getDebtorIneligibleCategories({
        id,
        workflow: WorkflowTypes.bbc,
      })
    }
  }, [id, currentTab, getDebtorIneligibleCategories])

  const closeTestSubmitConfirmModal = useCallback(() => {
    setIsTestSubmitModalOpen(false)
  }, [])

  const handleTestSubmitConfirm = useCallback(
    async (shouldSaveTestData: boolean) => {
      setIsSaveTestData(shouldSaveTestData)
      if (id && bbc) {
        setIsSubmitting(true)
        const result = await submit(id, {
          shouldSaveTestData,
        })
        setIsSubmitting(false)
        if (!result?.error) {
          setIsTestSubmitModalOpen(false)
          history.push(ROUTES.ACTIVITY_QUEUE)
        }
      }
    },
    [id, bbc, submit, history],
  )

  const handleTestSubmitConfirmWithSave = useCallback(() => {
    handleTestSubmitConfirm(true)
  }, [handleTestSubmitConfirm])

  const handleTestSubmitConfirmWithRevert = useCallback(() => {
    handleTestSubmitConfirm(false)
  }, [handleTestSubmitConfirm])

  if (!bbc || !shouldLoadComponents) {
    return (
      <Box py={1} pr={2}>
        {!isParticipant && <BBCNotes />}

        <Grid container spacing={2}>
          <Grid className={styles.headerSummaryContainer} item xs={12}>
            {(!bbc || !shouldLoadComponents) && <BBCLoader />}
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Box py={1} pr={2}>
      {!isParticipant && <BBCNotes />}
      <Grid container spacing={2}>
        <Grid className={styles.headerSummaryContainer} item xs={12}>
          <Box
            className={styles.headerContainer}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <div className={styles.breadcrumbsContainer}>
              {bbc?.clientInfo && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            </div>
            {bbcId && !isParticipant && (
              <Box>
                {!isTestBBC && bbc?.boxLink && (
                  <Link
                    color="primary"
                    href={bbc.boxLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={styles.iconWrapper}>
                      <PDFIcon />
                    </div>
                  </Link>
                )}

                <Link
                  component={RouterLink}
                  to={collateralEligibilityReviewLink}
                  className={cn(styles.button, styles.linkButton, {
                    [styles.warningButton]: bbc?.isCollateralEligibilityReviewRequired,
                  })}
                >
                  {bbc?.isCollateralEligibilityReviewRequired && (
                    <WarningIconOutline className={styles.warningIcon} />
                  )}
                  Eligibility
                </Link>

                {bbcId && !isHistorical && (
                  <Button
                    type="submit"
                    small
                    isLoading={isSubmitting}
                    disabled={bbc?.isCollateralEligibilityReviewRequired || isLoading}
                    onClick={handleSubmit}
                    className={styles.submitButton}
                  >
                    {isTestBBC
                      ? 'Complete'
                      : bbc?.status === FundingRequestStatus.Review
                      ? 'Submit'
                      : 'Submit for Review'}
                  </Button>
                )}

                <MenuIcon
                  isLoading={isExporting}
                  isActive={actionsMenuOpen}
                  onClick={handleClickMenu}
                  className={cn(styles.button, styles.actionsButton)}
                />
                <Menu open={actionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
                  {!funded && !isTestBBC && bbc?.status === FundingRequestStatus.Review && (
                    <MenuItem disabled={isPDFUpdating} onClick={handleUpdatePDF}>
                      Update PDF
                    </MenuItem>
                  )}

                  <MenuItem onClick={handleExportBBC}>Export Collateral</MenuItem>

                  {!funded && (
                    <MenuItem>
                      <BBCEditModal
                        handleCloseMenu={handleCloseMenu}
                        handleUpdateBBC={handleUpdateBBC}
                      />
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            )}
          </Box>
          {!!bbc && shouldLoadComponents && <BBCAvailabilityTable />}
        </Grid>
        <Grid container item xs={12} rowSpacing={1}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {tabs.length > 0 && (
                <div className={styles.tabContainer}>
                  <Tabs tabs={tabs} selected={currentTab} handleChange={onTabChange} />
                </div>
              )}
              {!isParticipant && (
                <Box>
                  {bbcId && (
                    <div className={styles.iconWrapper}>
                      <EditSourceData
                        action={handleOpenSelectFile}
                        error={!!currentBBCFile?.error}
                      />
                    </div>
                  )}
                  {bbc && (
                    <div className={cn(styles.iconWrapper)}>
                      <EditMapping
                        action={handleOpenModal}
                        mappingRequired={bbc?.isEntityMappingRequired}
                      />
                    </div>
                  )}
                  {currentBBCFile?.link && (
                    <div className={styles.iconWrapper}>
                      <ExternalLink link={currentBBCFile?.link} title="Box file" />
                    </div>
                  )}
                </Box>
              )}
            </Box>
          </Grid>

          {!!bbc && currentTab === BBC_TABS.RECEIVABLES && shouldLoadComponents && (
            <Grid item xs={12}>
              <BBCARSummaryTable refreshCount={refreshCount} isFilesSaving={isFilesSaving} />
            </Grid>
          )}

          {!!bbc && currentTab === BBC_TABS.PAYABLES && shouldLoadComponents && (
            <Grid item xs={12}>
              <BBCAPSummaryTable refreshCount={refreshCount} isFilesSaving={isFilesSaving} />
            </Grid>
          )}

          {!!bbc && currentTab === BBC_TABS.RECEIVABLES && shouldLoadComponents && (
            <Grid item xs={12}>
              <BBCIneligibleARTable refreshCount={refreshCount} />
            </Grid>
          )}

          {!!bbc && currentTab === BBC_TABS.INVENTORY && shouldLoadComponents && (
            <Grid item xs={12}>
              <BBCIneligibleInventoryTable
                aliases={bbc?.clientInfo.inventoryIneligibilityFieldAliases}
                isFilesSaving={isFilesSaving}
              />
            </Grid>
          )}

          {!!bbc && currentTab === BBC_TABS.INVENTORY && shouldLoadComponents && (
            <>
              {fields?.data?.data && (
                <Grid key={'distribution-table'} item xs={12}>
                  <BBCInventoryDistributionTable
                    aliases={bbc?.clientInfo.inventoryIneligibilityFieldAliases}
                    type={WorkflowPage.borrowingbase}
                  />
                </Grid>
              )}
            </>
          )}

          {!!bbc && currentTab === BBC_TABS.INVENTORY && shouldLoadComponents && (
            <Grid item xs={12} id="sku-performance">
              <SalesBySKUVisualizationTable
                currentId={bbc?.id}
                type={WorkflowPage.borrowingbase}
                refreshCount={refreshCount}
              />
            </Grid>
          )}
          {!!bbc && currentTab === BBC_TABS.RECEIVABLES && shouldLoadComponents && (
            <Grid item xs={12}>
              <BBCRollforwardTable refreshCount={refreshCount} type={currentTab} />
            </Grid>
          )}
          {!!bbc && currentTab === BBC_TABS.PAYABLES && shouldLoadComponents && (
            <Grid item xs={12}>
              <BBCRollforwardTable refreshCount={refreshCount} type={currentTab} />
            </Grid>
          )}
          {!!bbc && currentTab === BBC_TABS.RECEIVABLES && shouldLoadComponents && (
            <Grid item xs={6}>
              <ReservesTable type={CollateralTypes.ar} />
            </Grid>
          )}
          {!!bbc && currentTab === BBC_TABS.INVENTORY && shouldLoadComponents && (
            <Grid item xs={6}>
              <ReservesTable type={CollateralTypes.inventory} />
            </Grid>
          )}
          {!!bbc && currentTab === BBC_TABS.PAYABLES && shouldLoadComponents && (
            <Grid item xs={6}>
              <ReservesTable refreshCount={refreshCount} type={CollateralTypes.ap} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box mt={1} display="flex" justifyContent="flex-end" alignItems="center">
        {bbcId && !isParticipant && (
          <Link
            component={RouterLink}
            to={collateralEligibilityReviewLink}
            className={cn(styles.button, styles.linkButton, {
              [styles.warningButton]: bbc?.isCollateralEligibilityReviewRequired,
            })}
          >
            {bbc?.isCollateralEligibilityReviewRequired && (
              <WarningIconOutline className={styles.warningIcon} />
            )}
            Eligibility
          </Link>
        )}

        {bbcId && !isHistorical && !isParticipant && (
          <Button
            type="submit"
            small
            isLoading={isSubmitting}
            disabled={bbc?.isCollateralEligibilityReviewRequired || isLoading}
            onClick={handleSubmit}
            className={styles.submitButton}
          >
            {isTestBBC
              ? 'Complete'
              : bbc?.status === FundingRequestStatus.Review
              ? 'Submit'
              : 'Submit for Review'}
          </Button>
        )}

        {isAuthorizedSignorModalOpen && (
          <WarningModal
            onCancel={handleCloseSignorModal}
            onConfirm={handleSubmitConfirmPostSignor}
            confirmText="Proceed with Manual Send"
            cancelText="Assign BBC Signor"
            warningMessage={
              'There are no authorized signors for this client. Please assign a signor or manually send the BBC.'
            }
          />
        )}
        {isSubmitConfirmOpen && (
          <WarningModal
            onCancel={handleCloseWireFeeModal}
            onConfirm={handleSubmitConfirm}
            confirmText="Yes, confirm"
            warningMessage={`The wire fee is currently set to $${bbc?.clientCollateral?.wireCosts}.`}
          />
        )}

        {isTestSubmitModalOpen && (
          <WarningModal
            title={
              <span className={genericSs.textRegular}>
                Do you want to <b>save your changes</b> to reserves & collateral eligibility?
              </span>
            }
            warningMessage=""
            onConfirm={handleTestSubmitConfirmWithSave}
            onCancel={handleTestSubmitConfirmWithRevert}
            onClose={closeTestSubmitConfirmModal}
            confirmText="Yes, save changes"
            cancelText="No, undo changes"
            disabled={isSubmitting}
            isLoading={isSubmitting && isSaveTestData}
            isLoadingCancel={isSubmitting && !isSaveTestData}
          />
        )}
        {isMappingModalOpen && (
          <BBCMappingModal
            handleCloseModal={handleCloseModal}
            bbcTab={currentBBCTab}
            tabOptions={tabOptions}
          />
        )}
        {!isHistorical && loadTheRest && (
          <BBCFileSelect
            key={currentTab}
            isOpen={isFileSelectShown}
            tab={currentTab}
            handleOpenModal={handleOpenSelectFile}
            handleRecalculate={handleRecalculate}
            setIsFilesSaving={setIsFilesSaving}
          />
        )}
        <RouteLeavingGuard
          when={isSubmitting}
          navigate={handleNavigate}
          shouldBlockNavigation={() => isSubmitting}
          helperText="You are currently submitting the BBC. Are you sure you want to leave?"
          isAlternateSubmitInvalid
        />
      </Box>
    </Box>
  )
}

export default BBCSummaryPage
