import { IClientInfo, TermLoanStatus } from './client'
import { TermLoanPrincipalPaymentType } from './loanServicing'

export enum TermLoanDealType {
  Equipment = 'Equipment',
}

export interface ITermLoan {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  status: TermLoanStatus
  boxLink?: string
  dealType: TermLoanDealType
  collateralName: string
  loanAmount: number
  amortization: number
  term: number
  baseRate: number
  abovePrime: number
  rate?: number
  closingDate: string
  terminationDate: string
  closingFeePercent: number
  closingFeeAmount: number
  miscFees: number
  wireFee: number
  loanBalance?: number
  termLoanBalances?: ITermLoanBalance[]
  amortizationSchedule?: IAmortizationSchedule[]
}

export interface ITermLoanBalance {
  termLoanId?: string
  termLoan?: ITermLoan
  clientName: string
  clientInfo?: IClientInfo
  recordDate: string
  collateralName: string
  originalLoanAmount: number
  principal: number
  interest: number
  loanBalance: number
}

interface IAmortizationSchedule extends ITermLoanBalance {
  feeId?: string
}

export interface PrincipalPayment {
  id: string
  recordDate: string
  amount: number
  type: TermLoanPrincipalPaymentType
  isEditable: boolean
}
