import React, { useMemo, useEffect, useCallback } from 'react'
import { Form, FormRenderProps, FormSpy } from 'react-final-form'
import { FormApi } from 'final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import cn from 'classnames'

import styles from './DueDiligenceTeamAdvisors.module.scss'

import InputLabel from '../Common/InputLabel'
import { IDueDiligenceCompanyInfo } from '@common/interfaces/dueDiligence'
import TextField from '../Common/TextField'
import { PhoneMaskInput } from '../Common/MaskInput'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import Autocomplete from '../Common/Autocomplete'
import { PHONE_VALIDATE_REGEXP } from '../../constants/common'
import BooleanRadios from '../Common/BooleanRadios'

const schema = Yup.object().shape({
  executivesCount: Yup.number()
    .nullable(true)
    .typeError('Invalid # of employees')
    .min(0, 'Invalid # of employees'),
  boardMembersCount: Yup.number()
    .nullable(true)
    .typeError('Invalid # of employees')
    .min(0, 'Invalid # of employees'),
  lawFirmContactPhone: Yup.string()
    .nullable(true)
    .matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
  lawFirmContactEmail: Yup.string().nullable().email('Not a valid email'),
  outsourcedCFOContactPhone: Yup.string()
    .nullable(true)
    .matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
  outsourcedCFOContactEmail: Yup.string().nullable().email('Not a valid email'),
  accountingFirmContactPhone: Yup.string()
    .nullable(true)
    .matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
  accountingFirmContactEmail: Yup.string().nullable().email('Not a valid email'),
})
const validate = makeValidate(schema)

const mutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue }: any) => {
    changeValue(state, field, () => value)
  },
}

const DueDiligenceTeamAdvisorsFormRender = ({
  form,
  values,
  invalid,
  submitting,
  formRef,
  onChange,
  setIsFormInvalid,
  handleLoadVendors,
}: FormRenderProps<any> & {
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  onChange: (values: any) => void
  setIsFormInvalid: (state: boolean) => void
  handleLoadVendors: (type: string, search: string) => Promise<any>
}) => {
  formRef.current = form

  useEffect(() => {
    setIsFormInvalid(submitting || invalid)
  }, [invalid, submitting, setIsFormInvalid])

  const phoneMaskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])

  const loadLawFirmOptions = useCallback(
    async (search: string) => handleLoadVendors(ClientEntityRelationshipType.LawFirm, search),
    [handleLoadVendors],
  )

  const loadOutsourcedCFOOptions = useCallback(
    async (search: string) => handleLoadVendors(ClientEntityRelationshipType.OutsourcedCFO, search),
    [handleLoadVendors],
  )

  const loadAccountingFirmOptions = useCallback(
    async (search: string) =>
      handleLoadVendors(ClientEntityRelationshipType.AccountingFirm, search),
    [handleLoadVendors],
  )

  return (
    <form className={styles.formWrapper}>
      <div className={styles.formContent}>
        <Box className={cn(styles.fullWidthRow, styles.formHeading)}>Legal counsel</Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Law firm</InputLabel>
          <Autocomplete
            label=""
            name="lawFirm"
            className={styles.autocompleteField}
            isAsync
            loadOptions={loadLawFirmOptions}
            options={[]}
            freeSolo
            autoSelect
            placeholder="E.g., Trust & Legal Group"
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Lawyer full name</InputLabel>
          <TextField
            name="lawFirmContactFullName"
            placeholder="E.g., John"
            size="big"
            className={styles.inputField}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Phone number</InputLabel>
          <TextField
            name="lawFirmContactPhone"
            InputProps={phoneMaskProps}
            placeholder="E.g., (123) 456-7890"
            size="big"
            className={styles.inputField}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Email address</InputLabel>
          <TextField
            name="lawFirmContactEmail"
            type="email"
            placeholder="E.g., johnsmith@email.com"
            size="big"
            className={styles.inputField}
          />
        </Box>

        <Box className={cn(styles.fullWidthRow, styles.formHeading, styles.withTopMargin)}>
          Outsourced CFO firm
        </Box>

        <Box className={styles.booleanFieldRow}>
          <div className={styles.booleanFieldRowLabel}>
            Does your company use an outsourced CFO or bookkeeper?
          </div>

          <BooleanRadios name="isOutsourcedCFO" />
        </Box>

        {values.isOutsourcedCFO === 'true' && (
          <Box>
            <InputLabel className={styles.inputLabel}>Outsourced CFO firm</InputLabel>
            <Autocomplete
              label=""
              name="outsourcedCFO"
              className={styles.autocompleteField}
              isAsync
              loadOptions={loadOutsourcedCFOOptions}
              options={[]}
              freeSolo
              autoSelect
              placeholder="E.g., Fortress Accountants"
              disabled={values.isOutsourcedCFO === 'false'}
            />
          </Box>
        )}

        {values.isOutsourcedCFO === 'true' && (
          <Box>
            <InputLabel className={styles.inputLabel}>Outsourced CFO full name</InputLabel>
            <TextField
              name="outsourcedCFOContactFullName"
              placeholder="E.g., John"
              size="big"
              className={styles.inputField}
              disabled={values.isOutsourcedCFO === 'false'}
            />
          </Box>
        )}

        {values.isOutsourcedCFO === 'true' && (
          <Box>
            <InputLabel className={styles.inputLabel}>Phone number</InputLabel>
            <TextField
              name="outsourcedCFOContactPhone"
              InputProps={phoneMaskProps}
              placeholder="E.g., (123) 456-7890"
              size="big"
              className={styles.inputField}
              disabled={values.isOutsourcedCFO === 'false'}
            />
          </Box>
        )}

        {values.isOutsourcedCFO === 'true' && (
          <Box>
            <InputLabel className={styles.inputLabel}>Email address</InputLabel>
            <TextField
              name="outsourcedCFOContactEmail"
              type="email"
              placeholder="E.g., johnsmith@email.com"
              size="big"
              className={styles.inputField}
              disabled={values.isOutsourcedCFO === 'false'}
            />
          </Box>
        )}

        <Box className={cn(styles.fullWidthRow, styles.formHeading, styles.withTopMargin)}>
          Accounting firm
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Accounting firm</InputLabel>
          <Autocomplete
            label=""
            name="accountingFirm"
            className={styles.autocompleteField}
            isAsync
            loadOptions={loadAccountingFirmOptions}
            options={[]}
            freeSolo
            autoSelect
            placeholder="E.g., Fortress Accountants"
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Accountant full name</InputLabel>
          <TextField
            name="accountingFirmContactFullName"
            placeholder="E.g., John"
            size="big"
            className={styles.inputField}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Phone number</InputLabel>
          <TextField
            name="accountingFirmContactPhone"
            InputProps={phoneMaskProps}
            placeholder="E.g., (123) 456-7890"
            size="big"
            className={styles.inputField}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>Email address</InputLabel>
          <TextField
            name="accountingFirmContactEmail"
            type="email"
            placeholder="E.g., johnsmith@email.com"
            size="big"
            className={styles.inputField}
          />
        </Box>

        <Box className={cn(styles.fullWidthRow, styles.formHeading, styles.withTopMargin)}>
          Executives & board members
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}>
            Executives with more than 5% ownership
          </InputLabel>
          <TextField
            name="executivesCount"
            type="number"
            placeholder="E.g., 5"
            size="big"
            className={styles.inputField}
          />
        </Box>

        <Box>
          <InputLabel className={styles.inputLabel}># of board members</InputLabel>
          <TextField
            name="boardMembersCount"
            type="number"
            placeholder="E.g., 5"
            size="big"
            className={styles.inputField}
          />
        </Box>
      </div>

      <FormSpy
        subscription={{ values: true }}
        onChange={(props) => {
          onChange(props.values)
        }}
      />
    </form>
  )
}

interface IProps {
  dueDiligenceCompanyInfo: IDueDiligenceCompanyInfo
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  onSubmit: (values: any) => void
  onChange: (values: any) => void
  setIsFormInvalid: (state: boolean) => void
  loadVendors: (data: { type: string; search: string }) => Promise<any>
}

const DueDiligenceTeamAdvisors = ({
  dueDiligenceCompanyInfo,
  formRef,
  onSubmit,
  onChange,
  setIsFormInvalid,
  loadVendors,
}: IProps) => {
  const initialValues = useMemo(() => {
    const lawFirm = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.LawFirm,
    )
    const outsourcedCFO = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.OutsourcedCFO,
    )
    const accountingFirm = dueDiligenceCompanyInfo.clientEntityRelationship.find(
      ({ type }) => type === ClientEntityRelationshipType.AccountingFirm,
    )

    return {
      lawFirm: lawFirm
        ? lawFirm.salesforceAccount?.name || lawFirm.newSalesforceAccount
        : undefined,
      lawFirmContactFullName: lawFirm ? lawFirm.fullName : undefined,
      lawFirmContactPhone: lawFirm ? lawFirm.phone : undefined,
      lawFirmContactEmail: lawFirm ? lawFirm.email : undefined,
      outsourcedCFO:
        dueDiligenceCompanyInfo.isOutsourcedCFO && outsourcedCFO
          ? outsourcedCFO.salesforceAccount?.name || outsourcedCFO.newSalesforceAccount
          : undefined,
      outsourcedCFOContactFullName:
        dueDiligenceCompanyInfo.isOutsourcedCFO && outsourcedCFO
          ? outsourcedCFO.fullName
          : undefined,
      outsourcedCFOContactPhone:
        dueDiligenceCompanyInfo.isOutsourcedCFO && outsourcedCFO ? outsourcedCFO.phone : undefined,
      outsourcedCFOContactEmail:
        dueDiligenceCompanyInfo.isOutsourcedCFO && outsourcedCFO ? outsourcedCFO.email : undefined,
      isOutsourcedCFO: (dueDiligenceCompanyInfo.isOutsourcedCFO || false).toString(),
      accountingFirm: accountingFirm
        ? accountingFirm.salesforceAccount?.name || accountingFirm.newSalesforceAccount
        : undefined,
      accountingFirmContactFullName: accountingFirm ? accountingFirm.fullName : undefined,
      accountingFirmContactPhone: accountingFirm ? accountingFirm.phone : undefined,
      accountingFirmContactEmail: accountingFirm ? accountingFirm.email : undefined,
      executivesCount: dueDiligenceCompanyInfo.executivesCount || null,
      boardMembersCount: dueDiligenceCompanyInfo.boardMembersCount || null,
    }
  }, [dueDiligenceCompanyInfo])

  const handleLoadVendors = useCallback(
    async (type, search: string) => {
      const res = await loadVendors({ type, search })
      return res.data.map((vendor: any) => ({
        value: vendor.id,
        label: vendor.name,
      }))
    },
    [loadVendors],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      mutators={mutators}
      render={(props) => (
        <DueDiligenceTeamAdvisorsFormRender
          formRef={formRef}
          onChange={onChange}
          setIsFormInvalid={setIsFormInvalid}
          handleLoadVendors={handleLoadVendors}
          {...props}
        />
      )}
    />
  )
}

export default DueDiligenceTeamAdvisors
