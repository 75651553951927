import React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'

import styles from './DueDiligenceClientDashboard.module.scss'
import { ReactComponent as CompletedStepIcon } from '@assets/images/dd-step-completed.svg'
import { ReactComponent as ActiveStepIcon } from '@assets/images/dd-step-active.svg'
import { ReactComponent as InactiveStepIcon } from '@assets/images/dd-step-inactive.svg'

import {
  DueDiligenceProgressStatus,
  DueDiligenceProgressStep,
  IDueDiligence,
} from '@common/interfaces/dueDiligence'

interface IProps {
  dueDiligenceInfo: IDueDiligence
}

const DueDiligenceClientDashboardProgressStepIcon = (props: StepIconProps) => {
  const { active, completed } = props
  if (completed) {
    return <CompletedStepIcon className={styles.diligenceProgressStepItemIcon} />
  }
  if (active) {
    return <ActiveStepIcon className={styles.diligenceProgressStepItemIcon} />
  }
  return <InactiveStepIcon className={styles.diligenceProgressStepItemIcon} />
}

const DueDiligenceClientDashboardProgressStepItem = ({
  step,
  index,
  status,
}: {
  step: string
  index: number
  status: DueDiligenceProgressStatus
}) => {
  return (
    <Step
      index={index}
      active={status === DueDiligenceProgressStatus.InProgress}
      completed={status === DueDiligenceProgressStatus.Completed}
    >
      <StepLabel
        classes={{
          root: styles.diligenceProgressStepItemRoot,
          label: styles.diligenceProgressStepItemLabel,
          active: styles.diligenceProgressStepItemLabelActive,
          completed: styles.diligenceProgressStepItemLabelActive,
        }}
        StepIconComponent={DueDiligenceClientDashboardProgressStepIcon}
      >
        {step}
      </StepLabel>
    </Step>
  )
}

const DueDiligenceClientDashboardProgress = ({ dueDiligenceInfo }: IProps) => {
  return (
    <Stepper
      nonLinear
      orientation="vertical"
      connector={
        <StepConnector
          classes={{
            root: styles.diligenceProgressStepConnectorRoot,
            line: styles.diligenceProgressStepConnectorLine,
          }}
        />
      }
    >
      {Object.values(DueDiligenceProgressStep).map((step, index) => (
        <DueDiligenceClientDashboardProgressStepItem
          key={step}
          step={step}
          index={index}
          status={dueDiligenceInfo?.ddInfo?.progress[step]}
        />
      ))}
    </Stepper>
  )
}

export default DueDiligenceClientDashboardProgress
