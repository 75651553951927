import { combineReducers } from 'redux'
import {
  IApRollforwardData,
  IARAPChartDropdownOptions,
  IArIneligibilityData,
  IARRollforwardGraphData,
  IExtraReserveData,
} from '@common/interfaces/bbc'
import * as actions from './actions'
import {
  IArRollforwardData,
  IBorrowingBase,
  IClientCollateral,
  IInventoryIneligibilityCustomRule,
  IInventoryIneligibilityData,
  IInventoryIneligibilityField,
  IReserve,
  IInventoryIneligibleStats,
  IARIneligibleStats,
  IArSummaryStatsData,
  IApSummaryStatsData,
  IARAPChart,
  IInventoryDistributionAnalysisData,
  IInventoryDistributionAnalysisCategories,
} from '@common/interfaces/bbc'
import {
  UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST,
  LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_REQUEST,
  LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_SUCCESS,
} from '../clientInfo/actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IBBCState {
  loadingArray: string[]
  bbc: IBorrowingBase
  fields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  inventoryIneligibilityData: ILoadingData<IInventoryIneligibilityData>
  clientCollaterals: IClientCollateral[]
  previousClientCollaterals: IClientCollateral[]
  arRollforward: ILoadingData<IArRollforwardData>
  arRollforwardGraph: ILoadingData<IARRollforwardGraphData>
  apRollforward: ILoadingData<IApRollforwardData>
  reserves: ILoadingData<{ data: IReserve[] }>
  customRules: ILoadingData<IInventoryIneligibilityCustomRule[]>
  customRulesLabels: string[]
  customRulesValues: { [key: string]: string[] }
  inventoryIneligibleStats: ILoadingData<IInventoryIneligibleStats>
  arIneligibleStats: ILoadingData<{ data: IARIneligibleStats[] }>
  arSummaryStats: ILoadingData<IArSummaryStatsData>
  apSummaryStats: ILoadingData<IApSummaryStatsData>
  apReserves: ILoadingData<IExtraReserveData>
  arIneligibilityData: ILoadingData<IArIneligibilityData>
  arapChartData: ILoadingData<IARAPChart>
  arapChartDataDropdownOptions: ILoadingData<IARAPChartDropdownOptions>
  inventoryDistributionData: ILoadingData<IInventoryDistributionAnalysisData>
  inventoryDistributionCategories: ILoadingData<IInventoryDistributionAnalysisCategories>
}

export default combineReducers<IBBCState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.SUBMIT_AR_INELIGIBILITY_REQUEST:
      case actions.INVENTORY_INELIGIBLE_SUBMIT_REQUEST:
      case actions.CALCULATE_CLIENT_COLLATERAL_REQUEST:
        return [...state, action.type]
      case actions.SUBMIT_AR_INELIGIBILITY_SUCCESS:
      case actions.SUBMIT_AR_INELIGIBILITY_FAILURE:
      case actions.INVENTORY_INELIGIBLE_SUBMIT_SUCCESS:
      case actions.INVENTORY_INELIGIBLE_SUBMIT_FAILURE:
      case actions.CALCULATE_CLIENT_COLLATERAL_SUCCESS:
      case actions.CALCULATE_CLIENT_COLLATERAL_FAILURE:
        return state.filter(
          (item) => item !== action.type.replace(/(SUCCESS|FAILURE)/gi, 'REQUEST'),
        )
      default:
        return state
    }
  },
  bbc(state: IBorrowingBase = null, action) {
    switch (action.type) {
      case actions.GET_BBC_FILES_SUCCESS:
        return {
          ...(state || {}),
          files: action.data,
        }
      case actions.SHOW_SUCCESS:
        return action.data
      case actions.HIDE:
        return null
      default:
        return state
    }
  },
  fields: createLoadingDataReducer<{ data: IInventoryIneligibilityField[] }>(
    [actions.LIST_INVENTORY_INELIGIBLE_FIELDS_REQUEST],
    [
      actions.CREATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
      UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST,
      actions.UPDATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
      actions.DELETE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
    ],
  ),
  inventoryIneligibilityData: createLoadingDataReducer<IInventoryIneligibilityData>(
    [actions.LIST_MASTER_INVENTORY_REQUEST],
    [actions.UPDATE_MASTER_INVENTORY_REQUEST],
  ),
  clientCollaterals(state: IClientCollateral[] = [], action) {
    switch (action.type) {
      case actions.GET_BBC_SUMMARY_STATS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  previousClientCollaterals(state: IClientCollateral[] = [], action) {
    switch (action.type) {
      case actions.GET_PREVIOUS_CLIENT_COLLATERALS_SUCCESS:
        return action.data.clientCollaterals
      case actions.HIDE:
        return []
      default:
        return state
    }
  },
  arRollforward: createLoadingDataReducer<IArRollforwardData>(
    [actions.GET_BBC_AR_ROLLFORWARD_STATS_REQUEST, actions.GET_BBC_AR_ROLLFORWARD_DETAILS_REQUEST],
    [actions.EXPORT_BBC_AR_ROLLFORWARD_REQUEST],
  ),
  arRollforwardGraph: createLoadingDataReducer<IARRollforwardGraphData>([
    actions.GET_BBC_AR_ROLLFORWARD_GRAPH_REQUEST,
  ]),
  apRollforward: createLoadingDataReducer<IApRollforwardData>(
    [actions.GET_BBC_AP_ROLLFORWARD_STATS_REQUEST, actions.GET_BBC_AP_ROLLFORWARD_DETAILS_REQUEST],
    [actions.EXPORT_BBC_AP_ROLLFORWARD_REQUEST],
  ),
  reserves: createLoadingDataReducer<{ data: IReserve[] }>(
    [actions.LIST_BBC_RESERVE_REQUEST, actions.LIST_CLIENT_RESERVE_REQUEST],
    [
      actions.CREATE_BBC_RESERVE_REQUEST,
      actions.UPDATE_BBC_RESERVE_REQUEST,
      actions.DELETE_BBC_RESERVE_REQUEST,
      actions.CREATE_CLIENT_RESERVE_REQUEST,
      actions.UPDATE_CLIENT_RESERVE_REQUEST,
      actions.DELETE_CLIENT_RESERVE_REQUEST,
    ],
  ),
  customRules: createLoadingDataReducer<IInventoryIneligibilityCustomRule[]>(
    [
      actions.LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
      LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_REQUEST,
    ],
    [
      actions.CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
      actions.UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
      actions.DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
    ],
  ),
  customRulesLabels(state: string[] = [], action) {
    switch (action.type) {
      case actions.LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS:
      case LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  customRulesValues(state: { [key: string]: string[] } = {}, action) {
    switch (action.type) {
      case actions.LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS:
        return {
          ...state,
          [action.field]: action.data,
        }
      default:
        return state
    }
  },
  inventoryIneligibleStats: createLoadingDataReducer<IInventoryIneligibleStats>([
    actions.LIST_INVENTORY_INELIGIBLE_STATS_SUMMARY_REQUEST,
    actions.LIST_INVENTORY_INELIGIBLE_STATS_REASON_REQUEST,
    actions.LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_REQUEST,
  ]),
  arIneligibleStats: createLoadingDataReducer<{ data: IARIneligibleStats[] }>([
    actions.LIST_AR_INELIGIBLE_STATS_REQUEST,
    actions.LIST_AR_INELIGIBLE_STATS_DETAILS_REQUEST,
    actions.LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_REQUEST,
  ]),
  arSummaryStats: createLoadingDataReducer<IArSummaryStatsData>([
    actions.GET_BBC_AR_SUMMARY_STATS_REQUEST,
    actions.GET_BBC_AR_DETAIL_STATS_REQUEST,
  ]),
  apSummaryStats: createLoadingDataReducer<IApSummaryStatsData>([
    actions.GET_BBC_AP_SUMMARY_STATS_REQUEST,
    actions.GET_BBC_AP_DETAIL_STATS_REQUEST,
  ]),

  arapChartData: createLoadingDataReducer<IARAPChart>([actions.GET_BBC_AR_AP_CHART_DATA_REQUEST]),

  arapChartDataDropdownOptions: createLoadingDataReducer<IARAPChartDropdownOptions>([
    actions.GET_AR_AP_CHART_DROPDOWN_OPTIONS_REQUEST,
  ]),

  apReserves: createLoadingDataReducer<IExtraReserveData>(
    [actions.LIST_EXTRA_RESERVES_REQUEST, actions.LIST_CLIENT_EXTRA_RESERVES_REQUEST],
    [actions.UPDATE_EXTRA_RESERVES_REQUEST, actions.UPDATE_CLIENT_EXTRA_RESERVES_REQUEST],
  ),
  arIneligibilityData: createLoadingDataReducer<IArIneligibilityData>(
    [actions.LIST_AR_INELIGIBILITY_REQUEST],
    [actions.UPDATE_AR_INELIGIBILITY_REQUEST],
  ),
  inventoryDistributionData: createLoadingDataReducer<IInventoryDistributionAnalysisData>([
    actions.LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_REQUEST,
  ]),
  inventoryDistributionCategories:
    createLoadingDataReducer<IInventoryDistributionAnalysisCategories>([
      actions.LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_REQUEST,
    ]),
})
