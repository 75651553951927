import { combineReducers } from 'redux'

import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import { IClientNewsfeedData, INewsfeedData } from '@common/interfaces/newsfeed'

export interface INewsfeedState {
  clientNewsfeedData: ILoadingData<IClientNewsfeedData>
  newsfeedData: ILoadingData<INewsfeedData>
}

export default combineReducers<INewsfeedState>({
  newsfeedData: createLoadingDataReducer<INewsfeedData>([actions.LIST_NEWSFEED_REQUEST]),
  clientNewsfeedData: createLoadingDataReducer<IClientNewsfeedData>([
    actions.LIST_CLIENT_NEWSFEED_REQUEST,
  ]),
  
})
