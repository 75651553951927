import React, { useCallback, useEffect, useMemo, useState } from 'react'
import genericSs from '@styles/generic.module.scss'
import { dateToString, formatDate } from '../../../helpers/helpers'
import {
  IAmendmentsData,
  IClientInfo,
  IAmendment,
  ClientAmendmentStatus,
} from '@common/interfaces/client'
import InfiniteScroll from 'react-infinite-scroll-component'
import useTable from '../../../hooks/useTable'
import TableFiltersRow from '../../Common/TableFiltersRow'
import Card from '../../Common/Card'
import TableLoader from '../../Common/TableLoader/TableLoader'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import TableContainer from '../../Common/TableContainer'
import TableBody from '../../Common/TableBody'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'
import styles from './ClientAmendments.module.scss'
import ClientAmendmentDetails, { AmendmentMenu } from './Amendments/ClientAmendmentDetails'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment'
import { IOveradvancePayment } from '@common/interfaces/bbc'
import { Grid } from '@mui/material'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import ClientOveradvanceDetails, { OveradvanceMenu } from './Overadvances/ClientOveradvanceDetails'
import { ExpandDetailIcon, MenuIcon } from '../../Common/Icons'
import ClientAccountActivityCreateModal from './Amendments/ClientAmendmentCreateModal'
import { AMENDMENTS_QUEUE_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'

interface IProps {
  clientInfo: IClientInfo
  amendmentsData: IAmendmentsData
  loanBalanceStartDate: string
  updateAmendment: (id: string, itemId: string, data: object) => Promise<any>
  deleteAmendment: (id: string, itemId: string) => Promise<any>
  listAmendments: (id: string, data: object) => void
  createAmendment: (id: string, data: object) => Promise<any>
  listOveradvancePayments: (clientId: string, overadvanceId: string) => void
  createOveradvancePayment: (
    clientId: string,
    overadvanceId: string,
    data: IOveradvancePayment,
  ) => void
  deleteOveradvancePayment: (clientId: string, overadvanceId: string, paymentId: string) => void
}

const ClientAmendments = ({
  clientInfo,
  amendmentsData,
  listAmendments,
  createAmendment,
  updateAmendment,
  deleteAmendment,
  loanBalanceStartDate,
  createOveradvancePayment,
  listOveradvancePayments,
  deleteOveradvancePayment,
}: IProps) => {
  const clientId = useMemo(() => clientInfo?.id, [clientInfo])

  const [isAddAmendmentModalShown, setIsAddAmendmentModalShown] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [expanded, setExpanded] = useState([])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  const { handleOrderChange, orderBy } = useTable({
    tableId: 'amendments',
    sortDefault: {
      field: 'created_at',
      direction: 'DESC',
    },
  })

  const handleListAmendments = useCallback(() => {
    listAmendments(clientId, { orderBy: orderBy.field, orderDirection: orderBy.direction })
  }, [listAmendments, clientId, orderBy])

  useEffect(() => {
    clientId && handleListAmendments()
  }, [handleListAmendments, clientId])

  const { allAmendments, totalAmendments } = useMemo(() => {
    return {
      allAmendments: amendmentsData ? amendmentsData.amendments : [],
      totalAmendments: amendmentsData ? amendmentsData.totalItems : 0,
    }
  }, [amendmentsData])

  const loadMore = useCallback(() => {
    listAmendments(clientId, {
      loadMore: true,
      page: Math.ceil(allAmendments?.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [clientId, listAmendments, allAmendments, orderBy])

  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleUpdateAmendment = useCallback(
    (id: string, data: object) => clientId && updateAmendment(clientId, id, data),
    [updateAmendment, clientId],
  )

  const handleDeleteAmendment = useCallback(
    (id: string) => clientId && deleteAmendment(clientId, id),
    [deleteAmendment, clientId],
  )

  const handleToggleAddAmendmentModal = useCallback(() => {
    setIsAddAmendmentModalShown((isShown) => !isShown)
    handleCloseMenu()
  }, [handleCloseMenu])

  const handleCreateAmendment = useCallback(
    async (data: IAmendment) => {
      setIsloading(true)
      const result = await createAmendment(clientId, {
        ...data,
        effectiveDate: dateToString(data.effectiveDate),
      })
      setIsloading(false)
      if (!result.error) {
        handleToggleAddAmendmentModal()
      }
    },
    [clientId, createAmendment, handleToggleAddAmendmentModal],
  )

  return (
    <Card
      id="amendments"
      title={
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item xs={6}>
            <span>Amendments</span>
          </Grid>
          <Grid item xs={6} container justifyContent={'flex-end'}>
            <MenuIcon isActive={isActionsMenuOpen} onClick={handleClickMenu} />
            <Menu open={isActionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
              <MenuItem
                component={RouterLink}
                to={
                  clientId &&
                  generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_OVERADVANCE, { id: clientId })
                }
              >
                Add Overadvance
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to={
                  clientId &&
                  generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED, { id: clientId })
                }
              >
                Change Terms
              </MenuItem>
              <MenuItem onClick={handleToggleAddAmendmentModal}>Add Amendment</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      }
    >
      <TableContainer className={styles.table}>
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={AMENDMENTS_QUEUE_FILTERS_CONFIG}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTableAmendments">
            {allAmendments?.length > 0 && (
              <InfiniteScroll
                className={styles.infiniteScroll}
                dataLength={allAmendments?.length}
                next={loadMore}
                hasMore={allAmendments?.length < totalAmendments}
                loader={<TableLoader columnsCount={5} rowsCount={1} />}
                scrollableTarget="scrollableTableAmendments"
              >
                {allAmendments.map((item) => {
                  const isExpanded = expanded.includes(item.id)
                  const isTermChange = !item.overadvance
                  const isAmendmentEditable = moment(loanBalanceStartDate || undefined).isBefore(
                    moment(item.effectiveDate || undefined),
                  )

                  return (
                    <React.Fragment key={`${item.id}-container`}>
                      <TableRow
                        key={item.id}
                        className={cn('activableRow', {
                          activeRow: isExpanded,
                          currentActiveRow: isExpanded,
                          isPending: item.status === ClientAmendmentStatus.Pending,
                        })}
                      >
                        <TableCell className={genericSs.tableTextCenter}>
                          {item.summaryOfChanges && (
                            <ExpandDetailIcon
                              onClick={() => handleExpand(item.id)}
                              isExpanded={isExpanded}
                            />
                          )}
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.boxLink ? (
                            <Link href={item.boxLink} target="_blank" rel="noopener noreferrer">
                              {item.amendmentType}
                            </Link>
                          ) : (
                            <span>
                              {item.amendmentType}
                              {item.participant && ' - ' + item.participant}
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatDate(item.createdAt)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {formatDate(item.effectiveDate)}
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          <Tooltip
                            title={item.summaryOfChanges || ''}
                            placement="top"
                            disableHoverListener={
                              item.summaryOfChanges?.length < 20 || !item.summaryOfChanges
                            }
                            disableTouchListener
                          >
                            <span>{item.summaryOfChanges}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          {isAmendmentEditable && isTermChange ? (
                            <AmendmentMenu
                              updateAmendment={handleUpdateAmendment}
                              deleteAmendment={handleDeleteAmendment}
                              amendment={item}
                              loanBalanceStartDate={loanBalanceStartDate}
                            />
                          ) : !isTermChange ? (
                            <OveradvanceMenu
                              overadvance={item.overadvance}
                              clientId={clientId}
                              listOveradvancePayments={listOveradvancePayments}
                              createOveradvancePayment={createOveradvancePayment}
                              deleteOveradvancePayment={deleteOveradvancePayment}
                              handleListAmendments={handleListAmendments}
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                      {isExpanded && (
                        <TableRow key={`${item.id}-details`}>
                          <TableCell className={genericSs.nestedRowColumn} colSpan={6}>
                            {isTermChange ? (
                              <ClientAmendmentDetails
                                amendmentDetails={item.clientAmendmentDetails}
                                amendmentType={item.amendmentType}
                              />
                            ) : (
                              <ClientOveradvanceDetails overadvanceDetail={item.overadvance} />
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })}
              </InfiniteScroll>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isAddAmendmentModalShown && (
        <ClientAccountActivityCreateModal
          isLoading={isLoading}
          handleClose={handleToggleAddAmendmentModal}
          createAmendment={handleCreateAmendment}
          loanBalanceStartDate={loanBalanceStartDate}
        />
      )}
    </Card>
  )
}

export default ClientAmendments
