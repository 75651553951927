import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import cn from 'classnames'
import Modal from '../../components/Common/Modal'
import styles from './SubmitReportingPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import { useHistory } from 'react-router'
import { ReactComponent as DueIcon } from '../../assets/images/warning-circle-icon-hollow.svg'
import Card from '../../components/Common/Card'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import Button from '../../components/Common/Button'
import { ReactComponent as ActionSuccessIcon } from '../../assets/images/action-success-concentric.svg'
import { ReactComponent as ActionPendingIcon } from '../../assets/images/action-pending.svg'
import { ROUTES } from '../../constants/routes'
import { UploadFile } from '../../components/Common/UploadFile'
import { IUser, UserRole } from '@common/interfaces/user'
import { formatDate } from '../../helpers/helpers'
import { IBankAccount } from '@common/interfaces/bankAccount'
import SelectField from '../../components/Common/SelectField'
import CurrencyField from '../../components/Common/CurrencyField'
import {
  REPORTING_DOCUMENT_TYPES,
  REPORTING_DOCUMENTS_TITLES,
  ReportingDocumentStatuses,
} from '@common/constants/client'
import { IRequiredReport } from '@common/interfaces/requiredReport'
import { ILoadingData } from '../../redux/types'
import SubmitReportingPageLoader from './SubmitReportingPageLoader'
import RouteLeavingGuard from '../../components/Common/RouteLeavingGuard'

interface IProps {
  clientInfo: IClientInfo
  showClient: (id: string) => void
  submitOngoingReporting: (id: string, data: FormData) => any
  isExtendedRightsRole: boolean
  banks?: ILoadingData<{ data: IBankAccount[] }>
  listBankAccounts?: (clientId: string) => void
  user: IUser
}

const mutators = { ...arrayMutators }

interface ongoingReportingDocumentProps {
  item: IRequiredReport
  isExtendedRightsRole: boolean
  clientBanks: { label: string; value: string }[]
  values: any
  user: IUser
  isOverdue: boolean
  disabled: boolean
}

const OngoingReportingDocument = ({
  item,
  isExtendedRightsRole,
  clientBanks,
  values,
  user,
  isOverdue,
  disabled,
}: ongoingReportingDocumentProps) => {
  const [openModal, setOpenModal] = useState(null)
  const frequency = useMemo(() => item?.frequency?.join(', '), [item])

  const handleToggleModal = useCallback(
    (title: string) => {
      if (openModal === title) {
        setOpenModal(null)
      } else {
        setOpenModal(title)
      }
    },
    [setOpenModal, openModal],
  )
  if (item.documentName === REPORTING_DOCUMENT_TYPES.bankTransactions && !isExtendedRightsRole) {
    return null
  }
  return (
    <Box key={item.id} className={styles.documentItem}>
      <FieldArray name={item.name}>
        {({ fields }) => (
          <div className={styles.documentItemFiles}>
            <UploadFile
              title={REPORTING_DOCUMENTS_TITLES[item.documentName] || item.documentName}
              frequency={frequency}
              key={item.documentName}
              size="lg"
              onDropAccepted={(loadedFiles: File[]) => {
                loadedFiles.forEach((file) => fields.push({ file }))
              }}
              files={values[item.name]?.map((file: any) => file.file)}
              onDelete={(index) => {
                index.sort((a, b) => b - a).forEach((i) => fields.remove(i))
              }}
              maxFiles={item.documentName === REPORTING_DOCUMENT_TYPES.capTable ? 1 : null}
              acceptedFileTypes={['excel']}
              isModalOpen={openModal === item.documentName}
              handleToggleModal={handleToggleModal}
              disabled={disabled}
            />

            {isOverdue && user.role === UserRole.CLIENT_USER && (
              <>
                {item?.reportingThoughDate && (
                  <div className={styles.documentItemDescription}>
                    <div className={styles.documentItemDescriptionLabel}>
                      Current reporting is through{' '}
                      {item?.reportingThoughDate ? formatDate(item.reportingThoughDate) : '-'}
                    </div>
                  </div>
                )}
              </>
            )}

            {user.role !== UserRole.CLIENT_USER && (
              <div className={styles.documentItemDescription}>
                <div className={styles.documentItemDescriptionLabel}>
                  Reporting submitted on{' '}
                  {item?.lastSubmittedAt ? formatDate(item.lastSubmittedAt) : '-'}
                </div>
                <div className={styles.documentItemDescriptionLabel}>
                  Current reporting is through{' '}
                  {item?.reportingThoughDate ? formatDate(item.reportingThoughDate) : '-'}
                </div>

                <Box display="flex" />
              </div>
            )}
            {isOverdue && item.daysSinceDue > 0 ? (
              <div className={styles.documentItemDescription}>
                <div className={styles.documentItemDescriptionLabel}>
                  Reporting is{' '}
                  {item.daysSinceDue > 60
                    ? `${Math.ceil(item.daysSinceDue / 30)} months`
                    : `${item.daysSinceDue} day${item.daysSinceDue > 1 ? 's' : ''}`}{' '}
                  overdue
                </div>
              </div>
            ) : item.daysSinceDue < 0 ? (
              <div className={styles.documentItemDescription}>
                <div className={styles.documentItemDescriptionLabel}>
                  Reporting is due in{' '}
                  {item.daysSinceDue < -60
                    ? `${Math.ceil(Math.abs(item.daysSinceDue) / 30)} months`
                    : `${Math.abs(item.daysSinceDue)} day${item.daysSinceDue < -1 ? 's' : ''}`}
                </div>
              </div>
            ) : null}

            <Grid item xs={6} lg={6} className={styles.documentContainer}>
              {fields.map((name, index) => (
                <Grid
                  key={name}
                  container
                  direction={'column'}
                  spacing={4}
                  className={styles.uploadedFiles}
                >
                  {item.documentName === REPORTING_DOCUMENT_TYPES.bankTransactions &&
                    clientBanks?.length > 0 && (
                      <Grid item xs={4} lg={3} className={styles.bankAccount}>
                        <SelectField
                          name={`${name}.bankAccount`}
                          className={styles.bankAccountSelect}
                          options={clientBanks}
                          placeholder="Select Bank Account"
                          value={
                            clientBanks?.length === 1
                              ? clientBanks[0]?.value
                              : values[item?.name]
                              ? values[item?.name][index]?.bankAccount
                              : ''
                          }
                        />{' '}
                      </Grid>
                    )}
                  {item.documentName === REPORTING_DOCUMENT_TYPES.bankTransactions && (
                    <Grid item xs={4} lg={3} className={styles.accountBalance}>
                      <CurrencyField
                        className={styles.accountBalanceInput}
                        name={`${name}.accountBalance`}
                        size="big"
                        placeholder="Account Balance"
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </FieldArray>
    </Box>
  )
}

const SubmitReportingPage = ({
  clientInfo,
  showClient,
  submitOngoingReporting,
  listBankAccounts,
  isExtendedRightsRole,
  user,
  banks,
}: IProps) => {
  const { id: clientId } = useParams<{ id: string }>()
  useEffect(() => {
    if (clientId) {
      showClient(clientId)
      listBankAccounts(clientId)
    }
  }, [clientId, showClient, listBankAccounts])

  const { clientBanks } = useMemo(
    () => ({
      clientBanks: banks?.data?.data?.map(({ id, bankName, bankAccountNumber }) => ({
        value: id,
        label: `${bankName} - ${bankAccountNumber.trim().slice(-4)}`,
      })),
    }),
    [banks],
  )

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmitOngoingReporting = useCallback(
    async (data) => {
      setIsSubmitting(true)
      const { OtherOngoingFiles, OtherOngoingFilesType, ...files } = data
      const formData = new FormData()

      const bankAccountNumber = clientBanks?.length === 1 ? clientBanks[0].value : null

      Object.keys(files).forEach((type) => {
        // @ts-ignore
        files[type].forEach(({ file, bankAccount, accountBalance }, index) => {
          bankAccount = bankAccountNumber || bankAccount
          file instanceof File && formData.append(`files[${type}][${index}]`, file, file.name)
          type === REPORTING_DOCUMENT_TYPES.bankTransactions &&
            file instanceof File &&
            formData.append(`files[${type}][${index}].bankAccount`, bankAccount)
          formData.append(`files[${type}][${index}].accountBalance`, accountBalance)
        })
      })
      if (OtherOngoingFiles.length) {
        // @ts-ignore
        OtherOngoingFiles.forEach((file, index) => {
          file instanceof File &&
            formData.append(
              `files[${OtherOngoingFilesType.trim()}][${index}].file`,
              file,
              file.name,
            )
        })
      }

      const response = await submitOngoingReporting(clientId || 'me', formData)
      !response?.error && setIsSubmitted(true)
      setIsSubmitting(false)
    },
    [submitOngoingReporting, clientId, clientBanks],
  )

  const ongoingInitialValues = useMemo(
    () => ({
      OtherOngoingFiles: [],
      OtherOngoingFilesType: '',
    }),
    [],
  )

  const overdueReportingDocuments = useMemo(
    () =>
      clientInfo?.ongoingReportingDocuments?.filter(
        ({ status }) =>
          status &&
          [ReportingDocumentStatuses.Overdue, ReportingDocumentStatuses.Due].includes(status),
      ),
    [clientInfo],
  )
  const otherOngoingReportingDocuments = useMemo(
    () =>
      clientInfo?.ongoingReportingDocuments?.filter(
        ({ status }) =>
          !status ||
          ![ReportingDocumentStatuses.Overdue, ReportingDocumentStatuses.Due].includes(status),
      ),
    [clientInfo],
  )
  const handleGetOtherOngoingLen = useCallback(() => {
    if (!isExtendedRightsRole) {
      return otherOngoingReportingDocuments.filter(
        (item) => item?.documentName !== REPORTING_DOCUMENT_TYPES.bankTransactions,
      ).length
    } else {
      return otherOngoingReportingDocuments.length
    }
  }, [otherOngoingReportingDocuments, isExtendedRightsRole])

  const ORSchema = useMemo(() => {
    const files = {}
    if (clientInfo?.ongoingReportingDocuments?.length) {
      clientInfo?.ongoingReportingDocuments.forEach(({ name }) => {
        files[name] = Yup.array()
          .of(Yup.mixed())
          .test({
            name: 'requiredIf',
            exclusive: false,
            message: 'At least one file is required',
            test() {
              const { OtherOngoingFilesType, ...OngoingFiles } = this.parent
              // @ts-ignore
              return Object.values(OngoingFiles).some((items) => !!items?.length)
            },
          })

        if (name === REPORTING_DOCUMENT_TYPES.bankTransactions) {
          files[name] = files[name].test({
            name: 'bankAccountRequiredIf',
            exclusive: false,
            message: 'Bank Account is required',
            test() {
              const { OtherOngoingFilesType, ...OngoingFiles } = this.parent
              const values = OngoingFiles[REPORTING_DOCUMENT_TYPES.bankTransactions]
              if (!values) {
                return true
              }
              if (clientBanks?.length > 1) {
                // @ts-ignore
                return values.every(({ bankAccount }) => !!bankAccount)
              }
              return true
            },
          })
        }
      })
    }

    return Yup.object().shape({
      OtherOngoingFiles: Yup.array(),
      OtherOngoingFilesType: Yup.string().when(
        'OtherOngoingFiles',
        (OtherOngoingFiles: any[], validation: any) =>
          !!OtherOngoingFiles?.length ? validation.required('Required') : validation,
      ),

      ...files,
    })
  }, [clientInfo, clientBanks])

  const history = useHistory()

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  if (!clientInfo) {
    return <SubmitReportingPageLoader />
  }

  if ([ClientInfoStatus.Past, ClientInfoStatus.Archived].includes(clientInfo?.clientStatus)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.cardInProgressWrapper}
      >
        <Card className={cn(styles.cardInProgress, genericSs.textCenter)}>
          <ActionPendingIcon className={styles.iconPending} />
          <div className={cn(styles.actionTitle, genericSs.colorPrimary)}>Submit Reporting</div>
          <div className={styles.descriptionInProgress}>
            Submitting reporting is not allowed after payoff date
          </div>

          <Button
            variant="contained"
            color="primary"
            size="small"
            // @ts-ignore
            component={Link}
            to={clientId ? ROUTES.CLIENT_REPORTING : ROUTES.HOMEPAGE}
          >
            Return to Dashboard
          </Button>
        </Card>
      </Box>
    )
  }

  return (
    <div>
      <Box py={1} pr={2}>
        {clientInfo?.ongoingReportingDocuments && (
          <Form
            initialValues={ongoingInitialValues}
            onSubmit={handleSubmitOngoingReporting}
            validate={makeValidate(ORSchema)}
            mutators={mutators}
            render={({
              values,
              invalid,
              handleSubmit,
              form,
            }: {
              values: any
              invalid: boolean
              handleSubmit: any
              errors: any
              form: any
            }) => (
              <Grid container spacing={2} justifyContent={'center'} mt={5}>
                <Grid item container xs={12} spacing={2} justifyContent={'center'}>
                  {overdueReportingDocuments?.length > 0 && (
                    <Grid container direction={'column'} item xs={5} justifyContent={'center'}>
                      <Grid item xs={12}>
                        <div className={styles.overdueReportingSection}>
                          <div>
                            <div className={styles.ongoingReportingDocumentSectionTitle}>
                              Overdue reporting
                              <DueIcon className={styles.lateReporting} />
                            </div>
                          </div>

                          <div
                            className={cn(
                              styles.dashboardBlockContentLabel,
                              styles.dashboardAlertTile,
                            )}
                          ></div>
                          {overdueReportingDocuments?.length > 0 && (
                            <div className={styles.uploadReportingDescription}>
                              Upload documents
                            </div>
                          )}

                          {overdueReportingDocuments.map((item) => (
                            <OngoingReportingDocument
                              key={item.name}
                              values={values}
                              clientBanks={clientBanks}
                              item={item}
                              isExtendedRightsRole={isExtendedRightsRole}
                              user={user}
                              isOverdue={true}
                              disabled={isSubmitting}
                            />
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {handleGetOtherOngoingLen() > 0 && (
                    <Grid item xs={5}>
                      <div className={styles.overdueReportingSection}>
                        <div className={styles.ongoingReportingDocumentSectionTitle}>
                          Ongoing reporting
                        </div>

                        {otherOngoingReportingDocuments?.length > 0 && (
                          <div className={styles.uploadReportingDescription}>Upload documents</div>
                        )}
                        {otherOngoingReportingDocuments?.length > 0 &&
                          otherOngoingReportingDocuments.map((item) => (
                            <OngoingReportingDocument
                              key={item.name}
                              values={values}
                              clientBanks={clientBanks}
                              item={item}
                              isExtendedRightsRole={isExtendedRightsRole}
                              user={user}
                              isOverdue={false}
                              disabled={isSubmitting}
                            />
                          ))}
                      </div>
                    </Grid>
                  )}
                </Grid>
                <RouteLeavingGuard
                  when={form.getState().dirty}
                  navigate={handleNavigate}
                  shouldBlockNavigation={() => form.getState().dirty}
                  helperText="You have not submitted your documents. Are you sure you want to leave?"
                  buttonText="Submit documents"
                  alternateSubmit={handleSubmit}
                  isAlternateSubmitInvalid={form.getState().invalid}
                />

                <Grid
                  item
                  xs={
                    overdueReportingDocuments?.length > 0 && handleGetOtherOngoingLen() > 0 ? 10 : 5
                  }
                  className={styles.submitButtonContainer}
                >
                  <Box mt={3} display="flex" justifyContent={'flex-start'}>
                    <Button
                      className={styles.submitButton}
                      disabled={invalid}
                      type="button"
                      onClick={async () => {
                        await handleSubmit()
                        form.reset()
                      }}
                      color="primary"
                      variant="contained"
                      size="small"
                      isLoading={isSubmitting}
                    >
                      Submit documents
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          />
        )}
      </Box>

      {isSubmitted && (
        <Modal open={isSubmitted} onCancel={() => setIsSubmitted(false)}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.cardInProgressWrapper}
          >
            <Grid container xs={12} spacing={1} justifyContent={'center'} alignItems={'center'}>
              <Grid item xs={12} justifyContent={'center'} display={'flex'}>
                <ActionSuccessIcon className={styles.iconSuccess} />
              </Grid>
              <Grid item xs={12}>
                <div className={cn(styles.actionTitle)}>Document(s) submitted</div>
              </Grid>
              <Grid item xs={12} justifyContent={'center'} display={'flex'}>
                <div className={cn(genericSs.textCenter, styles.descriptionInProgress)}>
                  Thank you! Compliant reporting helps avoid funding interruption
                </div>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent={'center'}
            display={'flex'}
            className={styles.confirmButtonContainer}
          >
            <Button
              className={styles.submitModalButton}
              variant="contained"
              color="primary"
              size="small"
              // @ts-ignore
              component={Link}
              to={clientId ? ROUTES.CLIENT_REPORTING : ROUTES.HOMEPAGE}
            >
              Back to dashboard
            </Button>
          </Grid>
        </Modal>
      )}
    </div>
  )
}

export default SubmitReportingPage
