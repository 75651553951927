import { IClientInfo } from './client'
import { IUser } from './user'
import { IBorrowingBase } from './bbc'
import { ICheckData } from './collection'

export enum ActivityType {
  Wire = 'Wire Upload',
  MidDayWire = 'Mid Day Wires',
  Check = 'Check Upload',
  Funding = 'Funding',
  BankTransaction = 'Bank Transactions',
  TestBBC = 'Test Borrowing Base',
}

export const ACTIVITY_TYPE_LABEL = {
  [ActivityType.Wire]: 'Wires',
  [ActivityType.MidDayWire]: 'Mid Day Wires',
  [ActivityType.Check]: 'Checks',
  [ActivityType.Funding]: 'Funding',
  [ActivityType.BankTransaction]: 'Bank Transactions',
  [ActivityType.TestBBC]: 'Test BBC',
}

export enum ActivityStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Review = 'Ready for Review',
  Complete = 'Complete',
  Archived = 'Archived',
}

export enum ActivityViews {
  Portfolio = 'Portfolio',
  Diligence = 'Diligence',
}

export interface IActivityData {
  data: IActivity[]
  totals: {
    totalItems: number
    totalCollections: number
    totalFundings: number
    totalUnprocessedBBCs: number
    totalProcessedBBCs: number
    totalPassthroughs: number
  }
}

export interface IActivity {
  id: string
  clientName: string
  type: ActivityType
  status: ActivityStatus
  recordDate: string
  clientInfo?: IClientInfo
  requester?: IUser
  requesterId: string
  processor?: IUser
  processorId: string
  reviewer?: IUser
  reviewerId: string
  borrowingBase?: IBorrowingBase
  checkData?: ICheckData[]
  createdAt: string
  completedAt: string
  totalCollections: number
}

export const activityTypeGroups = {
  BBC: [ActivityType.Funding, ActivityType.TestBBC],
  COLLECTIONS: [ActivityType.Check, ActivityType.Wire, ActivityType.MidDayWire],
}
