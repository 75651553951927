import { IApi } from '../../api'
import { createRequestTypes } from '../../helpers/helpers'
import { CommonPromiseAction } from '../types'
export const prefix = 'newsfeed'

export const [LIST_NEWSFEED_REQUEST, LIST_NEWSFEED_SUCCESS, LIST_NEWSFEED_FAILURE] =
  createRequestTypes(prefix, 'LIST_NEWSFEED')

export const listNewsfeed: CommonPromiseAction = (params: object) => ({
  type: [LIST_NEWSFEED_REQUEST, LIST_NEWSFEED_SUCCESS, LIST_NEWSFEED_FAILURE],
  promise: (api: IApi) => api.newsfeed.listNewsfeed(params),
  params,
})

export const [
  LIST_CLIENT_NEWSFEED_REQUEST,
  LIST_CLIENT_NEWSFEED_SUCCESS,
  LIST_CLIENT_NEWSFEED_FAILURE,
] = createRequestTypes(prefix, 'LIST_CLIENT_NEWSFEED')

export const listClientNewsfeed: CommonPromiseAction = () => ({
  type: [LIST_CLIENT_NEWSFEED_REQUEST, LIST_CLIENT_NEWSFEED_SUCCESS, LIST_CLIENT_NEWSFEED_FAILURE],
  promise: (api: IApi) => api.newsfeed.listClientNewsfeed(),
})

export const CAPTURE_CLICK_REQUEST = `${prefix}/CAPTURE_CLICK_REQUEST`
export const CAPTURE_CLICK_SUCCESS = `${prefix}/CAPTURE_CLICK_SUCCESS`
export const CAPTURE_CLICK_FAILURE = `${prefix}/CAPTURE_CLICK_FAILURE`

export const captureClick: CommonPromiseAction = (id: string) => ({
  type: [CAPTURE_CLICK_REQUEST, CAPTURE_CLICK_SUCCESS, CAPTURE_CLICK_FAILURE],
  promise: (api: IApi) => api.newsfeed.capture(id),
  id,
})
