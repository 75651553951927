import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { trackActivity } from './../../redux/activity/actions'

import CollectionsChecksPage from './CollectionsChecksPage'
import {
  isLoading as isLoadingCollections,
  checks,
  checksReconciliation,
  collectionStatus,
} from '../../redux/collections/selectors'
import {
  listChecks,
  saveChecks,
  updateCheck,
  updateChecks,
  restoreCheck,
  deleteCheck,
} from '../../redux/collections/actions'
import { list as listClients } from '../../redux/clientInfo/actions'
import { clients } from '../../redux/clientInfo/selectors'
import { isLoading as isLoadingFiles } from '../../redux/file/selectors'
import { isLoading as isLoadingEntityInfo } from '../../redux/entityInfo/selectors'
import { listEntityInfo, addEntityInfo } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  isLoading: createSelector(
    isLoadingCollections,
    isLoadingFiles,
    isLoadingEntityInfo,
    (collectionsLoading, loadingFiles, loadingEntityInfo) =>
      collectionsLoading || loadingFiles || loadingEntityInfo,
  ),
  checks,
  checksReconciliation,
  clients,
  collectionStatus,
})

const actions = {
  listChecks,
  saveChecks,
  updateCheck,
  updateChecks,
  restoreCheck,
  deleteCheck,
  listClients,
  listEntityInfo,
  addEntityInfo,
  trackActivity,
}

export default connect(selector, actions)(CollectionsChecksPage)
