import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { useParams } from 'react-router'
import { Form, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import InfiniteScroll from 'react-infinite-scroll-component'
import { diff } from 'deep-object-diff'
import styles from './BBCInventoryIneligibleSKUTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../ActiveToolbar'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import {
  dateToString,
  debounceEventHandler,
  formatDate,
  formatter,
  voidHandler,
} from '../../helpers/helpers'
import {
  EligibilityStatus,
  SKU_TYPES_LIST,
  IInventoryIneligibilityData,
  IInventoryIneligibility,
  FundingRequestStatus,
  IBorrowingBase,
  ReportingFlow,
} from '@common/interfaces/bbc'
import TextField from '../Common/TextField'
import Checkbox from '../Common/Checkbox'
import TableFiltersRow from '../Common/TableFiltersRow'
import Autocomplete from '../Common/Autocomplete'
import CreatableSelectField from '../Common/CreatableSelectField'
import {
  BBC_MASTER_INVENTORY_INELIGIBILITY_LIST_FILTERS_CONFIG,
  CLIENT_MASTER_INVENTORY_LIST_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import { ILoadingData } from '../../redux/types'
import Button from '../Common/Button'
import useTable from '../../hooks/useTable'
import WarningModal from '../WarningModal'
import Modal from '../Common/Modal'
import UploadFileManagement from '../Common/UploadFileManagement'
import { useDropzone } from 'react-dropzone'
import { ACCEPTED_FILES_MAPPING, DEFAULT_MAX_FILE_SIZE } from '../../constants/common'

const filtersValidate = buildFiltersValidateSchema(
  BBC_MASTER_INVENTORY_INELIGIBILITY_LIST_FILTERS_CONFIG,
)
const filtersDefaults = buildFiltersDefaults(BBC_MASTER_INVENTORY_INELIGIBILITY_LIST_FILTERS_CONFIG)
const mutators = {
  ...arrayMutators,
}

interface IProps {
  bbc: IBorrowingBase
  inventoryIneligibilityData: ILoadingData<IInventoryIneligibilityData>
  inventoryEligibilityData: ILoadingData<IInventoryIneligibilityData>
  listMasterInventory: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
    },
  ) => void
  clientListMasterInventory: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
    },
  ) => void
  updateMasterInventory: (id: string, itemId: string, data: object) => Promise<void>
  isClientPage?: boolean
  reportingFlow?: ReportingFlow
  readOnly?: boolean
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  exportMasterInventory?: (id: string, data: object) => Promise<void>
  bulkUpdateMasterInventory?: (id: string, data: object) => Promise<any>
}

const BBCInventoryIneligibleSKUTableRow = ({
  index,
  item,
  isActiveRow,
  isCurrentActiveRow,
  onSelectRow,
  isHistorical,
  isClientPage,
  categoryOptions,
  readOnly = false,
}: {
  index: number
  item: IInventoryIneligibility
  isActiveRow: boolean
  isCurrentActiveRow: boolean
  onSelectRow: (event: any, index: number) => void
  isHistorical: boolean
  isClientPage: boolean
  categoryOptions: { label: string; value: string }[]
  readOnly?: boolean
}) => {
  const handleSelectRow = useCallback((event) => onSelectRow(event, index), [index, onSelectRow])

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={item.id}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: isCurrentActiveRow,
        [styles.newRow]: item.isNew,
      })}
      onClick={handleSelectRow}
    >
      <TableCell className={genericSs.tableTextRight}>
        {item.recordDate ? formatDate(item.recordDate) : ''}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Tooltip title={item.description} placement="top" disableTouchListener>
          <span>{item.description}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>{item.sku}</TableCell>
      {!isClientPage && (
        <TableCell className={genericSs.tableTextLeft}>
          <Tooltip title={item.location} placement="top-start">
            <span>{item.location}</span>
          </Tooltip>
        </TableCell>
      )}
      {!isClientPage && (
        <TableCell className={genericSs.tableTextRight}>
          {formatter.format(item.totalValue)}
        </TableCell>
      )}
      <TableCell>
        {readOnly || isHistorical ? (
          item.type
        ) : (
          <Autocomplete
            label=""
            className={cn('focusableInput', styles.skuTypesList)}
            tabIndex={3 * index}
            name={`inventoryIneligibility[${index}].type`}
            options={SKU_TYPES_LIST}
            withBorder
          />
        )}
      </TableCell>
      <TableCell>
        {readOnly || isHistorical ? (
          item.brandCategory
        ) : (
          <CreatableSelectField
            height="medium"
            className={styles.skuTypesList}
            label=""
            name={`inventoryIneligibility[${index}].brandCategory`}
            placeholder="Category"
            value={
              item?.brandCategory
                ? {
                    value: item.brandCategory,
                    label: item.brandCategory,
                  }
                : null
            }
            onAddValue={voidHandler}
            options={categoryOptions}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
          />
        )}
      </TableCell>
      {isClientPage && (
        <TableCell className={genericSs.tableTextLeft}>{item.lastAmount?.toFixed(2)}</TableCell>
      )}
      {isClientPage && (
        <TableCell className={genericSs.tableTextLeft}>
          {item.lastActive ? formatDate(item.lastActive) : ''}
        </TableCell>
      )}
      <TableCell className={genericSs.tableTextLeft}>
        <Checkbox
          className="focusableInput"
          tabIndex={3 * index + 1}
          name={`inventoryIneligibility[${index}].eligibility`}
          reactFinalForm
          color="primary"
          disabled={readOnly || isHistorical}
        />
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <TextField
          className="focusableInput"
          tabIndex={3 * index + 2}
          name={`inventoryIneligibility[${index}].notes`}
          placeholder={item.notes}
          fullWidth={false}
        />
      </TableCell>
    </TableRow>
  )
}

const BBCInventoryIneligibleSKUTable = ({
  bbc,
  inventoryIneligibilityData,
  inventoryEligibilityData,
  listMasterInventory,
  clientListMasterInventory,
  updateMasterInventory,
  isClientPage = false,
  reportingFlow,
  readOnly = false,
  setRefreshCounter,
  exportMasterInventory,
  bulkUpdateMasterInventory,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const isHistorical = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [isUpdating, setIsUpdating] = useState(false)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
  } = useTable({
    tableId: 'inventorySKU',
    sortDefault: {
      field: 'type',
      direction: 'DESC',
    },
    filtersDefaults,
  })

  const fetchInventoryIneligibleList = useCallback(
    async (data) => {
      const params = {
        ...data,
        filters: {
          field: 'sku',
          ...data.filters,
        },
        perPage: data.perPage || PER_PAGE,
      }
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }
      if (params?.filters.lastActiveFrom && typeof params.filters.lastActiveFrom !== 'string') {
        params.filters.lastActiveFrom = dateToString(params.filters.lastActiveFrom)
      }
      if (params?.filters.lastActiveTo && typeof params.filters.lastActiveTo !== 'string') {
        params.filters.lastActiveTo = dateToString(params.filters.lastActiveTo)
      }
      isClientPage
        ? await clientListMasterInventory(id, params)
        : await listMasterInventory(id, params)
      setActiveItems([])
    },
    [id, listMasterInventory, clientListMasterInventory, isClientPage, setActiveItems],
  )

  const debounceInventoryIneligibleList = useMemo(
    () => debounceEventHandler(fetchInventoryIneligibleList, 500),
    [fetchInventoryIneligibleList],
  )

  useEffect(() => {
    debounceInventoryIneligibleList({
      page: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, filters, debounceInventoryIneligibleList])

  const {
    isLoading,
    isSaving,
    isSaved,
    data: resData,
    itemsCount,
    skusChanged,
  } = useMemo(() => {
    const inventoryData = isClientPage ? inventoryEligibilityData : inventoryIneligibilityData
    return {
      isLoading: inventoryData.isLoading,
      isSaving: inventoryData.isSaving,
      isSaved: inventoryData.isSaved,
      data: inventoryData?.data,
      itemsCount: inventoryData?.data?.data.length,
      skusChanged: inventoryData?.data?.skusChanged,
    }
  }, [inventoryEligibilityData, inventoryIneligibilityData, isClientPage])
  const categoryOptions = useMemo(
    () =>
      resData?.categories?.map((category) => ({
        value: category,
        label: category,
      })),
    [resData],
  )

  const refetchInventoryIneligibleList = useCallback(
    (skipLoader: boolean = false) => {
      fetchInventoryIneligibleList({
        page: 0,
        perPage: itemsCount,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        skipLoader,
      })
    },
    [itemsCount, orderBy, filters, fetchInventoryIneligibleList],
  )

  const activeItemsIds = useMemo(
    () => resData?.data.filter((_, index) => activeItems.includes(index)).map((item) => item.id),
    [activeItems, resData],
  )

  const handleUpdateInventoryIneligible = useCallback(
    async (itemId: string, data: any, activeItemsIds: string[]) => {
      if (activeItemsIds?.length > 1 && activeItemsIds?.includes(itemId)) {
        await updateMasterInventory(id, activeItemsIds[0], {
          ...data,
          itemId: activeItemsIds,
          reportingFlow,
        })
      } else {
        await updateMasterInventory(id, itemId, { ...data, reportingFlow })
      }
      refetchInventoryIneligibleList(true)
      setRefreshCounter((refreshCounter) => refreshCounter + 1)
    },
    [id, updateMasterInventory, refetchInventoryIneligibleList, reportingFlow, setRefreshCounter],
  )

  const handleUpdateInventoryIneligibleDebounce = useMemo(
    () =>
      debounceEventHandler(
        async (
          itemId: string,
          data: Partial<IInventoryIneligibility>,
          activeItemsIds: string[],
        ) => {
          await handleUpdateInventoryIneligible(itemId, data, activeItemsIds)
        },
        1000,
      ),
    [handleUpdateInventoryIneligible],
  )

  const handleUpdate = useCallback(
    (props) => {
      if (props.dirty) {
        const changedRows = diff(
          props.initialValues.inventoryIneligibility,
          props.values.inventoryIneligibility,
        )
        if (!Object.entries(changedRows).length) {
          return
        }
        const [[updatedRowIndex, updatedData]] = Object.entries(changedRows)
        if (Object.keys(updatedData).includes('notes')) {
          handleUpdateInventoryIneligibleDebounce(
            props.values.inventoryIneligibility[updatedRowIndex].id,
            { notes: updatedData.notes || null },
            activeItemsIds,
          )
        } else if (Object.keys(updatedData).includes('type')) {
          handleUpdateInventoryIneligible(
            props.values.inventoryIneligibility[updatedRowIndex].id,
            {
              type: updatedData.type?.value || null,
            },
            activeItemsIds,
          )
        } else if (Object.keys(updatedData).includes('eligibility')) {
          handleUpdateInventoryIneligible(
            props.values.inventoryIneligibility[updatedRowIndex].id,
            {
              eligibility: updatedData.eligibility
                ? EligibilityStatus.Eligible
                : EligibilityStatus.Ineligible,
            },
            activeItemsIds,
          )
        } else if (Object.keys(updatedData).includes('brandCategory')) {
          handleUpdateInventoryIneligible(
            props.values.inventoryIneligibility[updatedRowIndex].id,
            {
              brandCategory: updatedData.brandCategory?.value || null,
            },
            activeItemsIds,
          )
        }
      }
    },
    [handleUpdateInventoryIneligible, handleUpdateInventoryIneligibleDebounce, activeItemsIds],
  )

  const loadMore = useCallback(() => {
    fetchInventoryIneligibleList({
      loadMore: true,
      page: Math.ceil(resData?.data.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [resData, orderBy, filters, fetchInventoryIneligibleList])

  const totalRow = useMemo(
    () =>
      resData?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (result, row) => {
            result.totalValue += +row.totalValue || 0

            return result
          },
          {
            totalValue: 0,
          },
        ),
    [resData, activeItems],
  )

  const filtersConfig = useMemo(
    () =>
      isClientPage
        ? CLIENT_MASTER_INVENTORY_LIST_FILTERS_CONFIG
        : BBC_MASTER_INVENTORY_INELIGIBILITY_LIST_FILTERS_CONFIG,
    [isClientPage],
  )

  const initialValues = useMemo(
    () => ({
      inventoryIneligibility: (resData?.data || []).map((item) => ({
        ...item,
        type:
          !bbc || isHistorical || readOnly
            ? item.type
            : item.type
            ? {
                value: item.type,
                label: item.type,
              }
            : null,
      })),
    }),
    [resData, bbc, isHistorical, readOnly],
  )
  const handleExportMasterInventory = useCallback(async () => {
    await exportMasterInventory(id, {
      isClientPage,
    })
  }, [exportMasterInventory, id, isClientPage])

  const handleToggleModal = useCallback(() => {
    setIsUpdateModalOpen((prev) => !prev)
  }, [])

  const handleUploadFile = useCallback(
    async (loadedFiles: File[], isConfirmed = false) => {
      if (loadedFiles?.length) {
        setIsUpdating(true)
        const file = loadedFiles[0]
        const formData = new FormData()

        const isConfirmedString = isConfirmed === true ? 'true' : 'false'
        const isClientPageString = isClientPage === true ? 'true' : 'false'

        formData.append('files[]', file, file.name)
        formData.append('isConfirmed', isConfirmedString)
        formData.append('isClientPage', isClientPageString)

        const result = await bulkUpdateMasterInventory(id, formData)
        setIsUpdating(false)

        setFiles(loadedFiles)

        if (result?.data?.message) {
          setConfirmMessage(result.data.message)
        } else if (result?.error) {
          setFiles([])
        }
      }
    },
    [id, bulkUpdateMasterInventory, isClientPage],
  )

  const handleDelete = useCallback(
    (indices: number[]) => {
      setFiles((prevFiles) => prevFiles.filter((_, index) => !indices.includes(index)))
    },
    [setFiles],
  )

  const handleConfirmUpdate = useCallback(async () => {
    await handleUploadFile(files, true)
    setConfirmMessage('')
    setIsUpdateModalOpen(false)
    setFiles([])
    refetchInventoryIneligibleList()
  }, [setConfirmMessage, handleUploadFile, files, refetchInventoryIneligibleList])

  const handleConfirmCancel = useCallback(() => {
    setConfirmMessage('')
    setIsUpdateModalOpen(false)
    setFiles([])
  }, [setConfirmMessage])

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 1,
    accept: ACCEPTED_FILES_MAPPING.excel,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: handleUploadFile,
  })

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={voidHandler}
        mutators={mutators}
        render={(formProps) => (
          <TableContainer
            className={styles.table}
            isActivable
            onActiveRowsChange={setActiveItems}
            onActiveRowChange={setActiveItem}
          >
            <FormSpy
              subscription={{ initialValues: true, values: true, dirty: true }}
              onChange={handleUpdate}
            />
            <Form
              onSubmit={handleFiltersChange}
              initialValues={filters}
              validate={filtersValidate}
              mutators={{
                setFieldData: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              render={({ values, handleSubmit, form: { mutators } }) => (
                <FilterContainer
                  filters={filtersConfig}
                  handleSubmit={handleSubmit}
                  mutators={mutators}
                  values={values}
                  appliedFilters={filters}
                  handleExportAggregation={handleExportMasterInventory}
                  actionsSize={8}
                  title={
                    <Box mr={2}>
                      <h2>SKUs</h2>
                    </Box>
                  }
                  actions={
                    <>
                      <Button variant="outlined" color="primary" onClick={handleToggleModal}>
                        Update Attributes
                      </Button>
                      {+skusChanged > 0 && (
                        <span className={cn(genericSs.yellowTag, styles.updateWarning)}>
                          {skusChanged} SKU(s) have been changed
                        </span>
                      )}
                    </>
                  }
                />
              )}
            />
            <Table ref={wrapperRef}>
              <TableHead>
                <TableFiltersRow
                  filters={filtersConfig}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                  withResize
                />
              </TableHead>
              <TableBody id="scrollableTable">
                {isLoading ? (
                  <TableLoader columnsCount={filtersConfig.length} height={24} />
                ) : (
                  resData?.data && (
                    <InfiniteScroll
                      dataLength={resData?.data.length}
                      next={loadMore}
                      hasMore={resData?.data.length < resData?.totals.totalItems}
                      loader={
                        <TableLoader
                          columnsCount={filtersConfig.length}
                          height={24}
                          rowsCount={1}
                        />
                      }
                      scrollableTarget="scrollableTable"
                    >
                      <FieldArray name="inventoryIneligibility">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <BBCInventoryIneligibleSKUTableRow
                              key={name}
                              index={index}
                              item={formProps.values.inventoryIneligibility[index]}
                              isActiveRow={activeItems.includes(index)}
                              isCurrentActiveRow={activeItem === index}
                              onSelectRow={handleSelectRow}
                              isHistorical={isHistorical}
                              isClientPage={isClientPage}
                              categoryOptions={categoryOptions}
                              readOnly={readOnly}
                            />
                          ))
                        }
                      </FieldArray>
                    </InfiniteScroll>
                  )
                )}
              </TableBody>
            </Table>

            <Box display="flex" alignItems="center" justifyContent="space-between">
              {resData?.totals.totalItems > 0 && (
                <div className={genericSs.itemsCount}>
                  {resData?.data.length} / {resData?.totals.totalItems}
                </div>
              )}
              <SaveState isSaving={isSaving} isSaved={isSaved} />
            </Box>

            {!isClientPage && (
              <ActiveToolbar
                activeItems={activeItems}
                className={styles.activeToolbar}
                containerRef={wrapperRef}
                resetActiveItems={resetActiveItems}
              >
                <div className={genericSs.tableTextRight}>
                  {formatter.format(totalRow?.totalValue)}
                </div>
              </ActiveToolbar>
            )}
          </TableContainer>
        )}
      />

      {isUpdateModalOpen && (
        <Modal open title="Update Master Inventory" onCancel={handleToggleModal} size="small">
          <UploadFileManagement
            files={files}
            onDelete={handleDelete}
            setIsModalOpen={handleToggleModal}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            open={open}
            handleDelete={handleDelete}
            dropzoneText="Drop files here or "
            isLoading={isUpdating}
          />
        </Modal>
      )}

      {confirmMessage && (
        <WarningModal
          warningMessage={confirmMessage}
          onCancel={handleConfirmCancel}
          onConfirm={handleConfirmUpdate}
          cancelText="Cancel"
          confirmText="Update"
          isLoading={isUpdating}
          disabled={isUpdating}
        />
      )}
    </>
  )
}

export default BBCInventoryIneligibleSKUTable
