import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectHeader from './ProspectHeader'

import { reporting } from '../../redux/prospect/selectors'
import { show, hide, update } from '../../redux/prospect/actions'
import { user } from '../../redux/profile/selectors'
import { updateClient } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  reporting,
  user,
})
const actions = {
  show,
  hide,
  update,
  updateClient,
}

export default connect(selector, actions)(ProspectHeader)
