import { combineReducers } from 'redux'
import * as actions from './actions'
import {
  IEntityInfo,
  IEntityReportStatsData,
  IEntityDilutionData,
  IEntityActivityData,
  IEntityMergeConflicts,
  ICustomerAggregation,
  IVendorAggregation,
  IEntityCollateralAggregation,
  IInvestorAggregation,
  IInvestmentRowDetail,
  IEntityAccountsData,
} from '@common/interfaces/entityInfo'
import { ICapTableSummaryData } from '@common/interfaces/capTable'
import { IAlias } from '@common/interfaces/bbc'
import { loadingArrayReducer } from '../../helpers/helpers'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IEntityInfoState {
  loadingArray: string[]
  entities: IEntityInfo[]
  entity: IEntityInfo
  entityArSummary: ILoadingData<IEntityReportStatsData>
  entityApSummary: ILoadingData<IEntityReportStatsData>
  entityDilution: ILoadingData<IEntityDilutionData>
  entityActivity: ILoadingData<IEntityActivityData>
  aliases: ILoadingData<{ data: IAlias[] }>
  entityMergeConflicts: any[]
  customersAggregation: ILoadingData<ICustomerAggregation>
  vendorsAggregation: ILoadingData<IVendorAggregation>
  entityAR: ILoadingData<IEntityCollateralAggregation>
  entityAp: ILoadingData<IEntityCollateralAggregation>
  investorAggregation: ILoadingData<IInvestorAggregation>
  investmentSummary: ILoadingData<ICapTableSummaryData>
  investmentDetailRow: ILoadingData<IInvestmentRowDetail>
  entityAccounts: ILoadingData<IEntityAccountsData>
}

export default combineReducers<IEntityInfoState>({
  loadingArray: loadingArrayReducer(
    [actions.prefix],
    [
      actions.GET_CUSTOMER_AGGREGATION_REQUEST,
      actions.GET_ENTITY_ACTIVITY_REQUEST,
      actions.GET_ENTITY_DILUTION_REQUEST,
      actions.GET_ENTITY_AR_SUMMARY_REQUEST,
      actions.GET_ENTITY_AR_DETAILS_REQUEST,
      actions.GET_ENTITY_AP_SUMMARY_REQUEST,
      actions.GET_ENTITY_AP_DETAILS_REQUEST,
      actions.GET_ENTITY_DUPLICATE_ENTITIES_REQUEST,
    ],
  ),
  entities(state: IEntityInfo[] = [], action) {
    switch (action.type) {
      case actions.LIST_ENTITY_INFO_SUCCESS:
        return action.data

      default:
        return state
    }
  },
  entity(state: IEntityInfo = null, action) {
    switch (action.type) {
      case actions.GET_ENTITY_INFO_SUCCESS:
        return action.data
      case actions.GET_ENTITY_DUPLICATE_ENTITIES_SUCCESS:
        return {
          ...state,
          entitiesWithDuplicates: action.data.entitiesWithDuplicates,
        }
      case actions.UPDATE_ENTITY_INFO_SUCCESS:
      case actions.UPDATE_DUPLICATE_ENTITY_SUCCESS:
        if (state.id === action.data.data.id) {
          return {
            ...state,
            ...action.data.data,
          }
        }
        return state

      case actions.ADD_ENTITY_RELATIONSHIP_SUCCESS:
        if (action.data.parent.id === state.id) {
          return {
            ...state,
            subsidiaries: [...state.subsidiaries, action.data.subsidiary],
          }
        }
        if (action.data.subsidiary.id === state.id) {
          return {
            ...state,
            parent: action.data.parent,
          }
        }
        return state

      case actions.DELETE_ENTITY_RELATIONSHIP_SUCCESS:
        if (action.data.parent.id === state.id) {
          return {
            ...state,
            subsidiaries: state.subsidiaries.filter(
              (subsidiary) => subsidiary.id !== action.data.subsidiary.id,
            ),
          }
        }
        if (action.data.subsidiary.id === state.id) {
          return {
            ...state,
            parent: null,
          }
        }
        return state
      case actions.HIDE_ENTITY_INFO:
        return null
      default:
        return state
    }
  },
  entityMergeConflicts(state: IEntityMergeConflicts[] = [], action) {
    switch (action.type) {
      case actions.UPDATE_ENTITY_INFO_FAILURE:
        if (action.error.code === 'CUSTOM_ERROR') {
          return action.error.data
        }
        return state
      case actions.GET_ENTITY_INFO_SUCCESS:
        return []
      default:
        return state
    }
  },
  entityArSummary: createLoadingDataReducer<IEntityReportStatsData>([
    actions.GET_ENTITY_AR_SUMMARY_REQUEST,
    actions.GET_ENTITY_AR_DETAILS_REQUEST,
  ]),
  entityApSummary: createLoadingDataReducer<IEntityReportStatsData>([
    actions.GET_ENTITY_AP_SUMMARY_REQUEST,
    actions.GET_ENTITY_AP_DETAILS_REQUEST,
  ]),
  entityDilution: createLoadingDataReducer<IEntityDilutionData>([
    actions.GET_ENTITY_DILUTION_REQUEST,
  ]),
  entityActivity: createLoadingDataReducer<IEntityActivityData>([
    actions.GET_ENTITY_ACTIVITY_REQUEST,
  ]),
  aliases: createLoadingDataReducer<{ data: IAlias[] }>(
    [actions.GET_ALIASES_REQUEST, actions.LIST_ALIAS_REQUEST],
    [],
    false,
    [actions.HIDE_ALIASES],
  ),
  customersAggregation: createLoadingDataReducer<ICustomerAggregation>([
    actions.GET_CUSTOMER_AGGREGATION_REQUEST,
  ]),
  vendorsAggregation: createLoadingDataReducer<IVendorAggregation>([
    actions.GET_VENDOR_AGGREGATION_REQUEST,
  ]),
  entityAR: createLoadingDataReducer<IEntityCollateralAggregation>(
    [actions.LIST_ENTITY_AR_REQUEST],
    [actions.UPDATE_ENTITY_AR_REQUEST],
  ),
  entityAp: createLoadingDataReducer<IEntityCollateralAggregation>(
    [actions.LIST_ENTITY_AP_REQUEST],
    [actions.UPDATE_ENTITY_AP_REQUEST],
  ),
  investorAggregation: createLoadingDataReducer<IInvestorAggregation>([
    actions.LIST_INVESTORS_REQUEST,
  ]),
  investmentSummary: createLoadingDataReducer<ICapTableSummaryData>([
    actions.LIST_SUMMARY_INVESTORS_REQUEST,
  ]),
  investmentDetailRow: createLoadingDataReducer<IInvestmentRowDetail>([
    actions.GET_INVESTMENT_ROW_DETAIL_REQUEST,
  ]),
  entityAccounts: createLoadingDataReducer<IEntityAccountsData>(
    [actions.LIST_ENTITY_ACCOUNTS_REQUEST],
    [actions.UPDATE_ENTITY_ACCOUNTS_REQUEST],
    false,
    [actions.HIDE_ENTITY_ACCOUNTS],
  ),
})
