import React, { useCallback, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import { OnChange } from 'react-final-form-listeners'
import * as Yup from 'yup'
import Box from '@mui/material/Box'

import styles from './DueDiligenceValidateDocumentsPage.module.scss'

import Checkbox from '../../components/Common/Checkbox'
import TextField from '../../components/Common/TextField'
import Button from '../../components/Common/Button'
import Modal from '../../components/Common/Modal'
import InputLabel from '../../components/Common/InputLabel'
import SelectField from '../../components/Common/SelectField'
import Autocomplete from '../../components/Common/Autocomplete'
import {
  DueDiligenceDocumentRequestStep,
  IDueDiligenceDocumentRequestType,
} from '@common/interfaces/dueDiligence'

const FILE_TYPES_OPTIONS = [
  { label: 'PDF', value: 'PDF' },
  { label: 'XLS', value: 'XLS' },
]

const STEPS_OPTIONS = [
  {
    label: DueDiligenceDocumentRequestStep.CompanyBackground,
    value: DueDiligenceDocumentRequestStep.CompanyBackground,
  },
  { label: DueDiligenceDocumentRequestStep.Team, value: DueDiligenceDocumentRequestStep.Team },
  {
    label: DueDiligenceDocumentRequestStep.Financials,
    value: DueDiligenceDocumentRequestStep.Financials,
  },
]

const SUB_STEPS_OPTIONS = {
  [DueDiligenceDocumentRequestStep.CompanyBackground]: [
    { label: 'Business Information', value: 'Business Information' },
    { label: '3rd party relationship', value: '3rd party relationship' },
    { label: 'Existing Loan(s)', value: 'Existing Loan(s)' },
    { label: 'Taxes', value: 'Taxes' },
  ],
  [DueDiligenceDocumentRequestStep.Team]: [{ label: 'Ownership', value: 'Ownership' }],
  [DueDiligenceDocumentRequestStep.Financials]: [
    { label: 'Receivables', value: 'Receivables' },
    { label: 'Payables', value: 'Payables' },
    { label: 'Inventory', value: 'Inventory' },
    { label: 'eCommerce', value: 'eCommerce' },
    { label: 'Account & Finance', value: 'Account & Finance' },
  ],
}

const schema = Yup.object().shape({
  name: Yup.mixed().required('Required'),
  step: Yup.string().required('Required'),
  subStep: Yup.string().when('isPriority', (isPriority: any, validation: any) =>
    isPriority ? validation : validation.required('Required'),
  ),
})

const validate = makeValidate(schema)

interface IProps {
  isSaving: boolean
  documentRequestTypes: IDueDiligenceDocumentRequestType[]
  handleSave: (data: Partial<any>) => Promise<void>
  handleClose: () => void
}

const DueDiligenceValidateDocumentsAddModal = ({
  isSaving,
  documentRequestTypes,
  handleSave,
  handleClose,
}: IProps) => {
  const [initialValues, setInitialValues] = React.useState<
    Partial<IDueDiligenceDocumentRequestType>
  >({})
  const typesOptions = useMemo(
    () =>
      documentRequestTypes.map((type) => ({
        label: type.name,
        value: type.id,
        type: type,
      })),
    [documentRequestTypes],
  )

  const handleSelectName = useCallback(
    (data: any) => {
      if (data?.type) {
        setInitialValues(data.type)
      } else if (!data) {
        setInitialValues({})
      } else if (initialValues?.isDefault && initialValues?.name !== data) {
        setInitialValues((initialValues) => ({
          ...initialValues,
          name: data,
          isDefault: false,
        }))
      }
    },
    [initialValues],
  )

  return (
    <Modal
      open
      onCancel={handleClose}
      title="Add document"
      classes={{
        root: styles.editModal,
        footer: styles.editModalFooter,
        title: styles.editModalTitle,
      }}
    >
      <Form
        onSubmit={handleSave}
        validate={validate}
        initialValues={initialValues}
        render={({ form, invalid, values, handleSubmit }) => (
          <form className={styles.formContent}>
            <Field name="id" render={() => null} />
            <Field name="isDefault" render={() => null} />
            <Box>
              <InputLabel htmlFor="name" size="normal">
                Name
              </InputLabel>
              <Autocomplete
                label=""
                name="name"
                className={styles.autocompleteField}
                options={typesOptions}
                freeSolo
                autoSelect
                placeholder="Select or type name"
              />
            </Box>
            <Box>
              <InputLabel htmlFor="helperText" size="normal">
                Helper text
              </InputLabel>
              <TextField
                id="helperText"
                name="helperText"
                size="big"
                className={styles.inputField}
                disabled={values.isDefault}
              />
            </Box>
            <Box>
              <InputLabel htmlFor="fileType" size="normal">
                File type
              </InputLabel>
              <Autocomplete
                label=""
                id="fileType"
                name="fileType"
                options={FILE_TYPES_OPTIONS}
                placeholder="File Type"
                className={styles.autocompleteField}
                disabled={values.isDefault}
                autoSelect
              />
            </Box>
            <Box display="flex" alignItems="center">
              <InputLabel htmlFor="isPriority" size="normal" position="horizontal">
                Priority
              </InputLabel>
              <Field
                key={values.isPriority}
                id="isPriority"
                name="isPriority"
                type="checkbox"
                component={Checkbox}
                disabled={values.isDefault}
              />
            </Box>
            <Box>
              <InputLabel htmlFor="step" size="normal">
                Step
              </InputLabel>
              <SelectField
                id="step"
                name="step"
                options={STEPS_OPTIONS}
                placeholder="Step"
                className={styles.selectField}
                disabled={values.isDefault}
              />
            </Box>
            {!values.isPriority && (
              <Box>
                <InputLabel htmlFor="subStep" size="normal">
                  Sub step
                </InputLabel>
                <SelectField
                  id="subStep"
                  name="subStep"
                  options={SUB_STEPS_OPTIONS[values.step] || []}
                  placeholder="Sub step"
                  className={styles.selectField}
                  disabled={values.isDefault}
                />
              </Box>
            )}
            <Box>
              <Button
                color="primary"
                variant="contained"
                className={styles.button}
                disabled={invalid}
                isLoading={isSaving}
                onClick={handleSubmit}
                small={false}
              >
                Add document
              </Button>
            </Box>
            <OnChange name="name">
              {(name) => {
                handleSelectName(name)
              }}
            </OnChange>
            <OnChange name="isPriority">
              {() => {
                values.subStep && form.change('subStep', undefined)
              }}
            </OnChange>
            <OnChange name="step">
              {(step) => {
                step && values.subStep && form.change('subStep', undefined)
              }}
            </OnChange>
          </form>
        )}
      />
    </Modal>
  )
}

export default DueDiligenceValidateDocumentsAddModal
