import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

import { IClientInfo } from '@common/interfaces/client'
import { ClientVisualizationsPageTabNames, ROUTES } from '../../constants/routes'
import Tabs from '../../components/Common/TabsWithRoutesMenu'
import BBCARSummaryTable from '../../components/BBCARSummaryTable'
import BBCAPSummaryTable from '../../components/BBCAPSummaryTable'
import SalesBySKUVisualizationTable from '../../components/SalesBySKUVisualizationTable'
import { WorkflowPage } from '@common/interfaces/bbc'

const TABS = Object.values(ClientVisualizationsPageTabNames).reduce((acc, tab) => {
  acc[tab] = `${ROUTES.CLIENT_VISUALIZATIONS}?tab=${tab}`
  return acc
}, {})

interface IProps {
  clientInfo: IClientInfo
}

const ClientVisualizationsPage = ({ clientInfo }: IProps) => {
  const history = useHistory()
  const { search }: { search: string } = useLocation()
  const { tab } = queryString.parse(search) as { tab: string }
  const currentTab = useMemo(() => tab || ClientVisualizationsPageTabNames.Receivables, [tab])

  useEffect(() => {
    const currentSearch = queryString.parse(search, {
      parseNumbers: true,
      parseBooleans: true,
    })
    const { tab, view } = currentSearch as { tab: string; view: string }

    if (!tab || !view) {
      history.replace({
        search: queryString.stringify({
          ...currentSearch,
          tab: tab || ClientVisualizationsPageTabNames.Receivables,
          view: view || 'Chart',
        }),
      })
    }
  }, [search, history])

  return (
    <Box pt={2.5} pl={2} gap={3} display="flex" flexDirection="column">
      {clientInfo ? <Tabs tabs={TABS} /> : <Skeleton width={644} height={80} />}

      {currentTab === ClientVisualizationsPageTabNames.Receivables && (
        <BBCARSummaryTable
          refreshCount={1}
          isFilesSaving={false}
          title="Receivables Aging"
          isClientUserPage
          clientInfo={clientInfo}
        />
      )}

      {currentTab === ClientVisualizationsPageTabNames.Payable && (
        <BBCAPSummaryTable
          refreshCount={1}
          isFilesSaving={false}
          title="Payables Aging"
          isClientUserPage
          clientInfo={clientInfo}
        />
      )}

      {currentTab === ClientVisualizationsPageTabNames.Inventory && (
        <SalesBySKUVisualizationTable
          type={WorkflowPage.client}
          currentId={clientInfo?.id}
          isClientUserPage
        />
      )}
    </Box>
  )
}

export default ClientVisualizationsPage
