import React from 'react'
import { TextField as MuiRffTextField, showErrorOnBlur } from 'mui-rff'
import {
  default as MuiCoreTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField'
import styles from './TextField.module.scss'
import cn from 'classnames'

const ExtraInputProps = {
  classes: {
    input: styles.input,
    error: styles.inputError,
  },
  disableUnderline: true,
}

interface TextFieldProps extends Omit<MuiTextFieldProps, 'size'> {
  size?: 'small' | 'big' | 'filterInput'
  useFinalForm?: boolean
  showInputAdornmentBorder?: boolean
  showStartInputAdornmentBorder?: boolean
  showEndInputAdornmentBorder?: boolean
}

const TextField: React.FC<TextFieldProps> = ({
  size = 'small',
  useFinalForm = true,
  showInputAdornmentBorder = true,
  showStartInputAdornmentBorder = true,
  showEndInputAdornmentBorder = true,
  onChange,
  value,
  InputProps,
  ...props
}) => {
  const Component = useFinalForm ? MuiRffTextField : MuiCoreTextField
  const extraProps = useFinalForm ? {} : { onChange, value }
  return (
    <Component
      {...(props as any)}
      {...extraProps}
      classes={{
        root: cn(styles.root, {
          [styles.inputSmallSize]: size === 'small',
          [styles.inputBigSize]: size === 'big',
          [styles.inputFilterMenu]: size === 'filterInput',

          [styles.inputHideStartAdornmentBorder]: !(
            showStartInputAdornmentBorder && showInputAdornmentBorder
          ),
          [styles.inputHideEndAdornmentBorder]: !(
            showEndInputAdornmentBorder && showInputAdornmentBorder
          ),
          [styles.textRight]: !!InputProps?.endAdornment && size !== 'filterInput',
          [styles.inputMultiline]: props.multiline,
        }),
        error: styles.error,
      }}
      InputProps={{ ...InputProps, ...ExtraInputProps }}
      FormHelperTextProps={{ classes: { root: styles.formHelperTextRoot } }}
      showError={showErrorOnBlur}
      variant="standard"
    />
  )
}

export default TextField
