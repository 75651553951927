import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import { FormApi } from 'final-form'
import moment from 'moment'

import styles from './BBCMappingModal.module.scss'

import { IBorrowingBase, FundingRequestStatus, BBC_MAPPING_TABS } from '@common/interfaces/bbc'
import { IFile } from '@common/interfaces/box'
import ClientAliasMapping from '../ClientAliasMapping'
import Modal from '../Common/Modal'
import { ActivityType } from '@common/interfaces/activity'
import { historyChangeEventHandler, visibilityChangeEventHandler } from '../../helpers/helpers'

interface IProps {
  bbc: IBorrowingBase
  arFile: IFile
  apFile: IFile
  handleCloseModal: () => void
  trackActivity: (data: any) => void
  bbcTab: string
  tabOptions: string[]
}

const BBCMappingModal = ({
  bbc,
  arFile,
  apFile,
  handleCloseModal,
  trackActivity,
  bbcTab,
  tabOptions,
}: IProps) => {
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)
  const [tabValue, setTabValue] = useState<string>(bbcTab)
  const history = useHistory()
  const { pathname } = useLocation()
  const isHistorical = useMemo(
    () => [FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(bbc?.status),
    [bbc],
  )

  const [startTime, setStartTime] = useState(moment().toISOString())

  const currentMappingType = useMemo(() => tabValue + ' Mapping', [tabValue])

  const logActivity = useCallback(() => {
    if (bbc && bbc?.status !== FundingRequestStatus.Completed) {
      const endTime = moment().toISOString()
      const data = {
        borrowingBaseId: bbc.id,
        startedAt: startTime,
        finishedAt: endTime,
        workflow: ActivityType.Funding,
        step: currentMappingType,
      }
      trackActivity(data)
      setStartTime(endTime)
    }
  }, [bbc, startTime, currentMappingType, trackActivity])

  const handleChangeTab = useCallback(
    (tab) => {
      logActivity()
      setTabValue(tab)
    },
    [logActivity],
  )

  const handleCloseModalCallback = useCallback(async () => {
    if (!isHistorical) {
      logActivity()
    }
    handleCloseModal()
  }, [handleCloseModal, logActivity, isHistorical])

  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const fileId = useMemo(() => {
    return tabValue === BBC_MAPPING_TABS.Customer ? arFile?.id : apFile?.id
  }, [tabValue, arFile, apFile])

  const table = useMemo(() => {
    return tabValue === BBC_MAPPING_TABS.Customer ? 'client_ar_raw' : 'client_ap_raw'
  }, [tabValue])

  return (
    <div className={styles.modal}>
      <Modal
        classes={{
          root: styles.modal,
        }}
        open
        onCancel={handleCloseModalCallback}
        disableEnforceFocus
        title={'Edit mapping'}
        isTable={true}
      >
        <ClientAliasMapping
          fileId={fileId}
          table={table}
          clientName={bbc?.clientName}
          tabOptions={tabOptions.length > 1 ? tabOptions : null}
          handleTabChange={tabOptions.length > 1 ? handleChangeTab : null}
          currentTab={tabOptions.length > 1 ? tabValue : null}
          formRef={formRef}
          tableClassName={styles.aliasesTable}
          bbcId={bbc?.id}
        />
      </Modal>
    </div>
  )
}

export default BBCMappingModal
