import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '../../Common/Card'
import { ILoadingData } from '../../../redux/types'
import {
  IParticipation,
  IParticipantData,
  IParticipationData,
  PARTICIPATION_FIELDS,
  ParticipationStatus,
} from '@common/interfaces/participant'
import Table from '../../Common/Table'
import TableContainer from '../../Common/TableContainer'
import { CLIENT_PARTICIPATION_FILTERS_CONFIG } from '@common/constants/filters'
import { ROUTES } from '../../../constants/routes'
import Link from '@mui/material/Link'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import useTable from '../../../hooks/useTable'
import TableFiltersRow from '../../Common/TableFiltersRow'
import TableHead from '../../Common/TableHead'
import TableBody from '../../Common/TableBody'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import TableLoader from '../../Common/TableLoader'
import Button from '../../Common/Button'
import Modal from '../../Common/Modal'
import { Form } from 'react-final-form'
import InputLabel from '../../Common/InputLabel'
import genericSs from '@styles/generic.module.scss'
import CreatableSelectField from '../../Common/CreatableSelectField'
import FormField from '../../Common/FormField'
import cn from 'classnames'
import styles from './Participations.module.scss'
import moment from 'moment'
import FormattedTableCell from '../../Common/FormattedTableCell'
import ParticipantsActionMenu from './ParticipationActionMenu'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import { dateToString, formatDate, voidHandler } from '../../../helpers/helpers'
import CreateParticipationFees from './CreateParticipationFees'
import ParticipationFeesTable from './ParticipationFeesTable'
import { PARTICIPANT_PERCENT_FIELDS } from '@common/interfaces/participant'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../../constants/common'
import TextField from '../../Common/TextField'
import { Tooltip } from '@mui/material'
import AddButton from '../AddButton'

export const FEE_TYPES = [
  {
    label: 'Interest (4001)',
    value: 'interest',
  },
  {
    label: 'Facility Fee (1800)',
    value: 'facilityFee',
  },
  {
    label: 'Misc. Fee (4003)',
    value: 'miscFee',
  },
  {
    label: 'Pass-Through Fee (1401)',
    value: 'passThroughFee',
  },
  {
    label: 'Lockbox Fee (4003)',
    value: 'lockboxFee',
  },
  {
    label: 'Wire Fee (4003)',
    value: 'wireFee',
  },
] as const

interface IProps {
  clientId: string
  loanBalanceStartDate: string
  participants: ILoadingData<IParticipantData>
  clientParticipations: ILoadingData<IParticipationData>
  listParticipants: () => void
  listClientParticipations: (id: string, data: object) => void
  createParticipation: (id: string, data: object) => void
  terminateParticipation: (id: string, participationId: string, data: object) => void
  updateParticipation: (id: string, participationId: string, data: object) => Promise<any>
}

const Participations = ({
  clientId,
  loanBalanceStartDate,
  participants,
  clientParticipations,
  listParticipants,
  listClientParticipations,
  createParticipation,
  terminateParticipation,
  updateParticipation,
}: IProps) => {
  const [isAddParticipantModalOpen, setIsAddParticipantModalOpen] = useState(false)
  const [isAddParticipantLoading, setIsAddParticipantLoading] = useState(false)
  const [participationToEdit, setParticipationToEdit] = useState<IParticipation | null>(null)
  const [isTermsChangedModalShown, setIsTermsChangedModalShown] = useState(false)
  const [editedParticipatinData, setEditedParticipatinData] =
    useState<Partial<IParticipation> | null>(null)

  useEffect(() => {
    listParticipants()
  }, [listParticipants])

  const { participantsList } = useMemo(
    () => ({
      participantsList: participants?.data?.data,
      isParticipantLoading: participants?.isLoading,
    }),
    [participants],
  )

  const { participationsList, isParticipationLoading } = useMemo(
    () => ({
      participationsList: clientParticipations?.data?.data,
      isParticipationLoading: clientParticipations?.isLoading,
      totalCount: clientParticipations?.data?.totalCount,
    }),
    [clientParticipations],
  )

  const effectiveParticipations = useMemo(
    () => participationsList?.filter((item) => item.status === ParticipationStatus.Active),
    [participationsList],
  )

  const { orderBy, handleOrderChange } = useTable({
    tableId: 'participation',
    sortDefault: {
      field: 'participant',
      direction: 'ASC',
    },
  })

  const handleLoadParticipations = useCallback(async () => {
    listClientParticipations(clientId, {
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [listClientParticipations, clientId, orderBy])

  useEffect(() => {
    handleLoadParticipations()
  }, [handleLoadParticipations])

  const handleOpenParticipantModal = useCallback(() => {
    setParticipationToEdit(null)
    setIsAddParticipantModalOpen(true)
  }, [])

  const participantOptions = useMemo(
    () => participantsList?.map((item) => ({ label: item.participant, value: item.id })) || [],
    [participantsList],
  )

  const handleAddParticipation = useCallback(
    async (values) => {
      setIsAddParticipantLoading(true)
      const data = {
        ...values,
        participant: values.participant.label,
        contractDate: moment(values.contractDate).format('YYYY-MM-DD'),
      }
      await createParticipation(clientId, data)
      await handleLoadParticipations()
      await listParticipants()
      setIsAddParticipantModalOpen(false)
      setIsAddParticipantLoading(false)
    },
    [createParticipation, handleLoadParticipations, clientId, listParticipants],
  )

  const handleTerminateParticipation = useCallback(
    async (clientId, id, data) => {
      await terminateParticipation(clientId, id, data)
      await handleLoadParticipations()
    },
    [terminateParticipation, handleLoadParticipations],
  )

  const minDate = useMemo(
    () =>
      moment(loanBalanceStartDate || undefined)
        .startOf('day')
        .toDate(),
    [loanBalanceStartDate],
  )

  const dwightsShare = useMemo(() => {
    const dwightsShare = {}
    FEE_TYPES.forEach((item) => {
      const isInterest = item.value === 'interest'
      const share = isInterest ? 'loanExposure' : `${item.value}Share`
      dwightsShare[share] =
        1 -
        effectiveParticipations
          ?.map((participation) => participation[share])
          .reduce((a, b) => a + b, 0)
    })
    return dwightsShare
  }, [effectiveParticipations]) as Partial<IParticipation>

  const initialValues: Partial<IParticipation> = useMemo(
    () =>
      participationToEdit
        ? {
            ...Object.keys(participationToEdit).reduce((acc, key) => {
              if (PARTICIPANT_PERCENT_FIELDS.includes(key as keyof IParticipation)) {
                return {
                  ...acc,
                  [key]: participationToEdit[key] * 100,
                }
              }
              if (key === 'participant') {
                const participant = participantsList?.find(
                  (item) => item.participant === participationToEdit[key],
                )

                return {
                  ...acc,
                  [key]: {
                    label: participant?.participant,
                    value: participant?.id,
                  },
                }
              }
              return {
                ...acc,
                [key]: participationToEdit[key],
              }
            }, {}),
          }
        : {
            participant: '',
            contractDate: '',
            interestOverride: 1,
            facilityFeeOverride: 0,
            miscFeeOverride: 0,
            passThroughFeeOverride: 0,
            lockboxFeeOverride: 0,
            wireFeeOverride: 0,
          },
    [participationToEdit, participantsList],
  )

  const updatedValidation = useMemo(
    () =>
      FEE_TYPES.map((item) => {
        const isInterest = item.value === 'interest'
        const share = `${item.value}Share`
        const override = `${item.value}Override`
        const editedShare = participationToEdit?.[share] || 0
        const maxShare = Math.round((dwightsShare[share] + editedShare) * 100)

        const overrideValidation = {
          [override]: Yup.number()
            .typeError('Invalid number')
            .min(0, 'Must be positive')
            .max(100, 'Cannot exceed 100%')
            .required('Required'),
        }
        const shareValidation = {
          [share]: Yup.number()
            .typeError('Invalid number')
            .min(0, 'Must be positive')
            .max(maxShare, `Cannot exceed ${maxShare}%`)
            .required('Required'),
        }
        return Yup.object().shape({
          ...overrideValidation,
          ...(isInterest ? {} : shareValidation),
        })
      }).reduce((acc, item) => {
        return {
          ...acc,
          ...item.fields,
        }
      }, {}),
    [dwightsShare, participationToEdit],
  )

  const validate = useMemo(() => {
    const editedShare = participationToEdit?.loanExposure || 0
    const maxShare = (dwightsShare.loanExposure + editedShare) * 100
    const schema = Yup.object().shape({
      participant: Yup.object()
        .typeError('Please select or add a participant')
        .required('Required'),
      loanExposure: Yup.number()
        .typeError('Invalid number')
        .min(0, 'Must be positive')
        .max(maxShare, `Cannot exceed Dwight's outstanding exposure of ${maxShare}%`)
        .required('Required'),
      ...updatedValidation,
    })

    return makeValidate(schema)
  }, [dwightsShare, updatedValidation, participationToEdit])

  const handleEditParticipation = useCallback((participation: IParticipation) => {
    setParticipationToEdit(participation)
    setIsAddParticipantModalOpen(true)
  }, [])

  const handleUpdateParticipation = useCallback(
    async (values: any, form: any) => {
      const dirtyFields = form.getState().dirtyFields
      const dataBeingUpdated = Object.keys(dirtyFields).reduce((acc: any, key: string) => {
        if (key) {
          if (values[key]?.toString() !== initialValues[key]?.toString()) {
            acc[key] = values[key]
          }
        }
        return acc
      }, {})

      PARTICIPATION_FIELDS.forEach((item) => {
        if (Object.keys(dataBeingUpdated).includes(item.value)) {
          if (item.type === 'date') {
            dataBeingUpdated[item.value] = dateToString(dataBeingUpdated[item.value]) || null
          }
        }
      })

      setIsTermsChangedModalShown(true)
      setEditedParticipatinData(dataBeingUpdated)
    },
    [initialValues, setIsTermsChangedModalShown, setEditedParticipatinData],
  )

  const handleConfirmParticipationEdit = useCallback(
    async (values: any) => {
      const dataToUpload = {
        ...editedParticipatinData,
        ...values,
      }
      setIsAddParticipantLoading(true)
      const response = await updateParticipation(clientId, participationToEdit.id, dataToUpload)
      if (!response.error) {
        setIsTermsChangedModalShown(false)
        setIsAddParticipantModalOpen(false)
        setParticipationToEdit(null)
        setEditedParticipatinData({})
        await handleLoadParticipations()
      }
      setIsAddParticipantLoading(false)
    },
    [
      clientId,
      editedParticipatinData,
      participationToEdit,
      setIsTermsChangedModalShown,
      updateParticipation,
      handleLoadParticipations,
    ],
  )
  const handleCloseTermsChangedModal = useCallback(() => {
    setIsTermsChangedModalShown(false)
  }, [])

  const isEditedParticipationTerminated = useMemo(
    () =>
      participationToEdit?.terminationDate &&
      moment(participationToEdit.terminationDate).isSameOrBefore(moment()),
    [participationToEdit],
  )

  const titleWord = useMemo(
    () => (isEditedParticipationTerminated ? 'View' : participationToEdit ? 'Edit' : 'Add'),
    [isEditedParticipationTerminated, participationToEdit],
  )

  return (
    <Grid container spacing={5} xs={12}>
      <Grid item xs={12}>
        <Card
          withBorder={false}
          noPadding
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <span>Participations</span>
            </Box>
          }
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Card
                title="Participants"
                renderHeaderRightColumn={() => (
                  <Box display="flex" justifyContent="flex-end" alignItems={'center'} mb={2}>
                    <AddButton
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleOpenParticipantModal}
                    />
                  </Box>
                )}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableFiltersRow
                        filters={CLIENT_PARTICIPATION_FILTERS_CONFIG}
                        orderBy={orderBy}
                        handleOrderChange={handleOrderChange}
                      />
                    </TableHead>
                    <TableBody id="scrollableTable">
                      {isParticipationLoading ? (
                        <TableLoader
                          columnsCount={CLIENT_PARTICIPATION_FILTERS_CONFIG.length}
                          height={26}
                        />
                      ) : (
                        participationsList?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Link
                                component={RouterLink}
                                to={generatePath(ROUTES.PARTICIPANTS_PAGE, {
                                  id: item.participantInfo?.id,
                                })}
                              >
                                {item.participant}
                              </Link>
                              {item.terminationDate && (
                                <Tooltip title={formatDate(item.terminationDate)} placement="top">
                                  <div className={genericSs.grayCard}>
                                    <span>Terminated</span>
                                  </div>
                                </Tooltip>
                              )}
                              {item.status === ParticipationStatus.Pending && (
                                <Tooltip title={formatDate(item.contractDate)} placement="top">
                                  <div className={genericSs.grayCard}>
                                    <span>Upcoming</span>
                                  </div>
                                </Tooltip>
                              )}
                            </TableCell>
                            <FormattedTableCell value={item.loanExposure} fieldType="percent" />
                            <FormattedTableCell value={item.interestOverride} fieldType="percent" />
                            <FormattedTableCell value={item.contractDate} fieldType="date" />
                            <TableCell className={genericSs.tableTextRight}>
                              <ParticipantsActionMenu
                                participation={item}
                                terminateParticipation={handleTerminateParticipation}
                                id={clientId}
                                editParticipation={handleEditParticipation}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
          {isAddParticipantModalOpen && (
            <Modal
              title={`${titleWord} participation`}
              onCancel={() => setIsAddParticipantModalOpen(false)}
              open={isAddParticipantModalOpen}
              classes={{ root: styles.modal }}
            >
              <Form
                initialValues={initialValues}
                onSubmit={participationToEdit ? handleUpdateParticipation : handleAddParticipation}
                validate={validate}
                render={({ handleSubmit, invalid, dirty }) => (
                  <form onSubmit={handleSubmit}>
                    <Box>
                      <InputLabel htmlFor="participant" className={genericSs.textLeft}>
                        Participant
                      </InputLabel>
                      <CreatableSelectField
                        name="participant"
                        placeholder="Enter company or individual name"
                        onAddValue={voidHandler}
                        options={participantOptions}
                        height="large"
                        disabled={!!participationToEdit || isEditedParticipationTerminated}
                      />
                      <InputLabel
                        htmlFor="loanExposure"
                        className={cn(genericSs.textLeft, styles.inputLabel)}
                      >
                        Loan exposure
                      </InputLabel>
                      <FormField
                        name="loanExposure"
                        type="percent"
                        placeholder="Enter loan exposure"
                        size="big"
                        disabled={isEditedParticipationTerminated}
                      />
                      <InputLabel
                        htmlFor="loanExposure"
                        className={cn(genericSs.textLeft, styles.inputLabel)}
                      >
                        Contract date
                      </InputLabel>
                      <FormField
                        name="contractDate"
                        type="date"
                        placeholder="Enter contract date"
                        size="big"
                        minDate={minDate}
                        disabled={isEditedParticipationTerminated}
                      />
                      <Grid item xs={12} mt={2}>
                        <CreateParticipationFees disabled={isEditedParticipationTerminated} />
                      </Grid>
                      {!isEditedParticipationTerminated && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          small={false}
                          fullWidth
                          disabled={invalid || !dirty || !!participationToEdit?.terminationDate}
                          className={styles.button}
                          onClick={handleSubmit}
                          isLoading={isAddParticipantLoading}
                        >
                          {titleWord} participation
                        </Button>
                      )}
                    </Box>
                  </form>
                )}
              />
            </Modal>
          )}
        </Card>

        <Form
          initialValues={{
            effectiveDate: moment().format('YYYY-MM-DD'),
          }}
          onSubmit={handleConfirmParticipationEdit}
          render={({ handleSubmit }) => {
            return (
              <Modal
                open={isTermsChangedModalShown}
                title={'Confirmation'}
                onCancel={handleCloseTermsChangedModal}
                disableEnforceFocus
                footer={[
                  <Button
                    key="cancel"
                    small={false}
                    color="primary"
                    variant="contained"
                    onClick={handleCloseTermsChangedModal}
                    secondary
                  >
                    Cancel
                  </Button>,
                  <Button
                    small={false}
                    key="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    isLoading={isAddParticipantLoading}
                  >
                    Confirm
                  </Button>,
                ]}
              >
                <form id="participationAmendmentConfirmation">
                  <Table className={styles.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Old Value</TableCell>
                        <TableCell>New Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(editedParticipatinData || {}).map((key) => {
                        const label = PARTICIPATION_FIELDS.find((item) => item.value === key)?.label
                        return (
                          <TableRow key={key}>
                            <TableCell>{label}</TableCell>
                            <TableCell>{initialValues[key]?.toString()}</TableCell>
                            <TableCell>{editedParticipatinData[key]?.toString()}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>

                  <div className={styles.effectiveDateContainer}>
                    <h4>When do you want these changes to go into effect?</h4>
                    <p>Effective Date</p>

                    <KeyboardDatePicker
                      className={styles.effectiveDate}
                      name={'effectiveDate'}
                      inputFormat={DATE_FORMAT}
                      minDate={moment(loanBalanceStartDate || undefined).toDate()}
                    />
                    <h4>Is this a legal amendment?</h4>
                    <p>Amendment box link</p>

                    <TextField name={'boxLink'} />
                  </div>
                </form>
              </Modal>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ParticipationFeesTable
          participations={effectiveParticipations}
          dwightsShare={dwightsShare}
        />
      </Grid>
    </Grid>
  )
}

export default Participations
