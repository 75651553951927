import { AnyAction, Dispatch, ActionCreator } from 'redux'
import { Selector } from 'reselect'
import ReactGA from 'react-ga4'

import { IApi } from '../api'
import { IRootState } from './reducer'
import { logout, permissionDenied } from './profile/actions'
import { SLOW_REQUEST_TIME } from '@common/constants/tracking'

export interface IAction extends AnyAction {
  type: string[] | string
  promise?: (api: IApi) => Promise<any>
  payload?: any
}

export default function apiMiddleware(api: IApi) {
  return ({ dispatch, getState }: { dispatch: Dispatch<IAction>; getState: () => IRootState }) =>
    (next: Dispatch<IAction>) =>
    (action: IAction & ActionCreator<IAction>) => {
      if (typeof action === 'function') {
        return dispatch(action((selector: Selector<IRootState, any>) => selector(getState())))
      }

      const { promise, type, ...rest } = action as any

      if (!promise) {
        return next(action)
      }

      const [REQUEST, SUCCESS, FAILURE] = type as string[]
      const startTime = performance.now()

      next({ ...rest, type: REQUEST })

      return promise(api).then(
        (data: object) => {
          const endTime = performance.now()
          const requestTime = Math.round(endTime - startTime)

          if (requestTime > SLOW_REQUEST_TIME && process.env.NODE_ENV === 'production') {
            ReactGA.event(
              {
                action: 'timing_complete',
                category: 'API',
                label: REQUEST.split('/')[0] || '',
                value: requestTime,
              },
              {
                request: REQUEST,
                duration: requestTime,
              },
            )
          }

          return next({ ...rest, data, type: SUCCESS })
        },
        (
          error: Error & {
            code: string
          },
        ) => {
          if (error && error.code === 'AUTHORIZATION_ERROR') {
            dispatch(logout())
          }
          if (error && error.code === 'PERMISSION_DENIED') {
            dispatch(permissionDenied())
          }
          return next({ ...rest, error, type: FAILURE })
        },
      )
    }
}
