export const SLOW_REQUEST_TIME = 1000

export const EVENTS = {
  tab: 'Tab Selection',
  filters: 'Filters Change',
  orderBy: 'Order By Change',
  tableChart: 'Table/Chart Selection',
}

export const CATEGORIES = {
  clientPage: 'Client Page Visualizations',
  clientAccountActivity: 'Account activity table',
  inventoryDistributionTable: 'Inventory distribution table',
  inventoryDistributionChart: 'Inventory distribution chart',
  arSummary: 'Receivables summary table',
  apSummary: 'Payables summary table',
  salesBySKUVisualizationTable: 'Performance by SKU table',
  salesBySKUVisualizationChart: 'Performance by SKU chart',
  financialsISTable: 'Financials Income Statement table',
  financialsISChart: 'Financials Income Statement chart',
  financialsBSTable: 'Financials Balance sheet table',
  financialsBSChart: 'Financials Balance sheet chart',
  cashFlowTable: 'Cash Flow table',
  cashFlowChart: 'Cash Flow chart',
  riskRatingVisualization: 'Risk rating chart',
  inventoryHealthVisualization: 'Inventory health chart',
}
