import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'

import BankAccountInformation from './BankAccountInformation'

import { isLoading as isBankLoading, list as banks } from '../../../redux/bank/selectors'
import { createBank, listBanks } from '../../../redux/bank/actions'
import {
  isLoading as isBankAccountLoading,
  list as bankAccounts,
} from '../../../redux/bankAccount/selectors'
import {
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  listBankAccounts,
} from '../../../redux/bankAccount/actions'
import { clientId } from '../../../redux/clientInfo/selectors'

const isLoading = createSelector(
  [isBankLoading, isBankAccountLoading],
  (isLoadingBank, isAccountLoading) => isLoadingBank || isAccountLoading,
)

const fundingBankAccountId = createSelector(
  bankAccounts,
  (accounts) => accounts?.data?.data?.find(({ isFunding }) => Boolean(isFunding))?.id,
)

const selector = createStructuredSelector({
  isLoading,
  fundingBankAccountId,
  banks,
  bankAccounts,
  clientId,
})

const actions = {
  createBank,
  listBanks,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  listBankAccounts,
}

export default connect(selector, actions)(BankAccountInformation)
