import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCInventoryIneligibleFields from './BBCInventoryIneligibleFields'

import { listFields, createFields, deleteField, show as showBBC } from '../../redux/bbc/actions'
import { bbc, fields } from '../../redux/bbc/selectors'
import { listEligibleFields, updateEligibleFieldsAliases } from '../../redux/clientInfo/actions'
import { eligibleFields, clientInfo } from '../../redux/clientInfo/selectors'
import { show as showClient } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  bbc,
  fields,
  eligibleFields,
  clientInfo,
})

const actions = {
  listFields,
  createFields,
  deleteField,
  listEligibleFields,
  updateEligibleFieldsAliases,
  showBBC,
  showClient,
}

export default connect(selector, actions)(BBCInventoryIneligibleFields)
