import React, { ReactNode, useState, useEffect, useMemo, useCallback } from 'react'
import { TableRowProps } from '@mui/material/TableRow'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'

import styles from './TableFiltersRow.module.scss'
import genericSs from '@styles/generic.module.scss'

import TableRow from '../TableRow'
import TableCell from '../TableCell'
import Sortable from '../Sortable'
import { getApplicableFilters, IFilter } from '@common/constants/filters'
import useResizableColumns from '../../../hooks/useResizableColumns'

interface IProps extends TableRowProps {
  filters: IFilter[]
  orderBy: {
    field: string
    direction: string
  }
  handleOrderChange: (field: string) => void
  children?: ReactNode
  isChildrenAtStart?: boolean
  hideFilters?: boolean
  withResize?: boolean
}

const TableFiltersRow = ({
  children,
  isChildrenAtStart = false,
  filters,
  orderBy,
  handleOrderChange,
  hideFilters = false,
  withResize = false,
  ...rest
}: IProps) => {
  const [openValues, setOpenValues] = useState([])
  const { resizing, handleMouseDown } = useResizableColumns({})
  const [hoveredCell, setHoveredCell] = useState('')

  useEffect(() => {
    setOpenValues((prev: any) =>
      prev.includes(orderBy.field)
        ? prev.filter((item: any) => item !== orderBy.field + 'sort')
        : [...prev, orderBy.field + 'sort'],
    )
  }, [orderBy, setOpenValues])

  const applicableFilters = useMemo(() => getApplicableFilters(filters), [filters])

  const changeFilter = useCallback(
    (value: string) => {
      setOpenValues((prev: any) =>
        prev.includes(orderBy.field + 'sort')
          ? prev.filter((item: any) => item !== orderBy.field + 'sort')
          : [...prev, orderBy.field + 'sort'],
      )
      handleOrderChange(value)
    },
    [handleOrderChange, orderBy, setOpenValues],
  )

  return (
    <>
      <TableRow isNewFiltersRow {...rest} className={styles.tableRow}>
        {isChildrenAtStart && children}
        {applicableFilters.map((filter) => (
          <TableCell
            key={filter.field}
            className={cn(styles.tableCell, styles.tableCellText, {
              [styles.tableCellSorted]:
                openValues.includes(filter.field + 'sort') ||
                openValues.includes(filter.dbField + 'sort'),
              [styles.tableNumberCell]:
                ['percent', 'amount', 'number', 'date', 'datetime'].includes(filter.type) ||
                filter.align === 'right',
              [genericSs.tableTextLeft]: filter.align === 'left',
              [styles.tableTextRight]: filter.align === 'right',
              [styles.tableCellWithoutBorder]: filter.isHighlighted,
              [styles.highlighted]: filter.isHighlighted,
              [styles.highlightedPrimary]:
                filter.isHighlighted && filter.highlightColor === 'primary',
              [styles.highlightedSecondary]: filter.highlightColor === 'secondary',
              [styles.clickable]: filter.isSortable,
              [styles.withResize]: withResize,
              [styles.resizing]: resizing,
            })}
            isAmountFilter={['percent', 'amount', 'number'].includes(filter.type)}
            isAutocompleteFilter={['autocomplete', 'list'].includes(filter.type)}
            isDateFilter={filter.type === 'date' || filter.type === 'datetime'}
            isTextFilter={['text'].includes(filter.type)}
            onClick={() => filter.isSortable && changeFilter(filter.dbField || filter.field)}
            onMouseDown={withResize ? handleMouseDown : undefined}
            onMouseEnter={() => setHoveredCell(filter.field)}
            onMouseLeave={() => setHoveredCell('')}
          >
            <div>
              <div
                className={cn({
                  [styles.filterTitleWrapper]: !hideFilters,
                  [styles.filterTitleWrapperHidden]: hideFilters,
                })}
              >
                <Tooltip
                  title={filter.filterTitle ? filter.filterTitle : filter.title}
                  placement="top"
                >
                  <span
                    className={cn(styles.filterTitle, {
                      [styles.filterTitleFullWidth]:
                        !filter.isSortable && filter.isFullWidth !== false,
                    })}
                  >
                    {filter.filterTitle ? filter.filterTitle : filter.title}
                  </span>
                </Tooltip>
                <div
                  className={cn({
                    [styles.filterActionContainer]: !hideFilters,
                    [styles.filterActionContainerHidden]: hideFilters,
                  })}
                >
                  {filter.isSortable && (
                    <Sortable
                      className={styles.sortableIcon}
                      name={filter.dbField || filter.field}
                      orderBy={orderBy}
                      showSortIcon={hoveredCell === filter.field}
                    />
                  )}
                </div>
              </div>
            </div>
          </TableCell>
        ))}
        {!isChildrenAtStart && children}
      </TableRow>
    </>
  )
}

export default TableFiltersRow
