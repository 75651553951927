import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Skeleton from '@mui/material/Skeleton'
import cn from 'classnames'
import moment from 'moment'

import styles from './DueDiligencePageHeader.module.scss'

import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { stringAvatar } from '../Notes/helpers/helpers'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import TabsWithRoutesMenu from '../Common/TabsWithRoutesMenu'
import { ROUTES } from '../../constants/routes'
import { BoxLink, MenuIcon, SalesforceLink } from '../Common/Icons'
import Breadcrumbs from '../Common/Breadcrumbs'
import Button from '../Common/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import WarningModal from '../WarningModal'
import Modal from '../Common/Modal'
import {
  DueDiligenceProgressStatus,
  DueDiligenceProgressStep,
  DUE_DILIGENCE_STEP_STATUS_OPTIONS,
  OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS,
} from '@common/interfaces/dueDiligence'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { ClientInfoStatus } from '@common/interfaces/client'
import { LOAN_TYPES } from '@common/constants/client'
import { checkIsLoanStructureComplete } from '@common/helpers/helpers'
import { formatPrice, formatPercent } from '../../helpers/helpers'
import SelectField from '../Common/SelectField'
import TextField from '../Common/TextField'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import ArchivedChip from '../Client/ArchivedChip'
import CodatSyncChip from '../Client/CodatSyncChip'
import { ReactComponent as EditIcon } from '@assets/images/pencil-icon.svg'
import { UploadFile } from '../Common/UploadFile'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
  hide: () => void
  update: (id: string, data: object) => void
  runBBC: (id: string) => Promise<void>
  isLoading: boolean
  loginAsClient: (clientId: string) => void
  isAdminRightsRole: boolean
  updateClient: (id: string, data: FormData) => void
}

const TABS = {
  Application: [
    ROUTES.DUE_DILIGENCE,
    ROUTES.DUE_DILIGENCE_TEAM_PAGE,
    ROUTES.DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE,
  ],
  'Validate documents': ROUTES.DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE,
  'Analysis queue': ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE,
}

const MENU = {
  Application: [
    {
      label: 'Company Background',
      route: ROUTES.DUE_DILIGENCE,
    },
    {
      label: 'The team',
      route: ROUTES.DUE_DILIGENCE_TEAM_PAGE,
    },
    {
      label: 'Financials & collateral',
      route: ROUTES.DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE,
    },
  ],
}

const HeaderLoader = ({ isLoading, value }: { isLoading: boolean; value: string }) => {
  return isLoading ? <Skeleton width={120} /> : <>{value || '-'}</>
}

const DueDiligenceProgressStepItem = ({
  step,
  status,
  onChange,
  readOnly,
}: {
  step: DueDiligenceProgressStep
  status: DueDiligenceProgressStatus
  onChange: (step: DueDiligenceProgressStep, status: DueDiligenceProgressStatus) => void
  readOnly: boolean
}) => {
  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (readOnly) {
        return
      }
      onChange(step, value as DueDiligenceProgressStatus)
    },
    [onChange, step, readOnly],
  )

  return (
    <>
      <div className={styles.headerTitle}>{step}</div>
      <div className={styles.headerValue}>
        <SelectField
          className={cn(styles.progressSelect, {
            [styles.notStarted]: status === DueDiligenceProgressStatus.NotStarted,
            [styles.inProgress]: status === DueDiligenceProgressStatus.InProgress,
            [styles.complete]: status === DueDiligenceProgressStatus.Completed,
          })}
          name="status"
          useFinalForm={false}
          options={DUE_DILIGENCE_STEP_STATUS_OPTIONS}
          onChange={handleChange}
          value={status}
          disabled={readOnly}
        />
      </div>
    </>
  )
}

const DueDiligencePageHeader = ({
  dueDiligenceInfo,
  show,
  hide,
  update,
  runBBC,
  isLoading,
  loginAsClient,
  isAdminRightsRole,
  updateClient,
}: IProps) => {
  const [isRunningBBC, setIsRunningBBC] = useState(false)
  const { id } = useParams<{ id: string }>()
  const [isEditProfilePicModalOpen, setIsEditProfilePicModalOpen] = useState(false)
  const [iconUrl, setIconUrl] = useState<string | null>(null)
  const [loadedFile, setLoadedFile] = useState<File[]>([])
  const [isImageManageModalOpen, setIsImageManageModalOpen] = useState(false)
  const [savedProfilePic, setSavedProfilePic] = useState<string | null>(null)

  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const [isConvertToArchivedModalShown, setIsConvertToArchivedModalShown] = useState(false)
  const [isArchiveReasonModalShown, setIsArchiveReasonModalShown] = useState(false)
  const [archiveReason, setArchiveReason] = useState(null)
  const [reasonLost, setReasonLost] = useState(null)

  const [isSaving, setIsSaving] = useState(false)

  const initials = useMemo(() => {
    const name = dueDiligenceInfo?.clientName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [dueDiligenceInfo])

  useLoadInfo(id, dueDiligenceInfo, show)

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )
  const isArchived = useMemo(
    () => dueDiligenceInfo?.clientStatus === ClientInfoStatus.Archived,
    [dueDiligenceInfo],
  )
  const isLoanStructureComplete = useMemo(() => {
    return checkIsLoanStructureComplete(dueDiligenceInfo)
  }, [dueDiligenceInfo])

  const handleUpdateToArchived = useCallback(
    async (data: any) => {
      setIsSaving(true)
      await update(id, data)
      setIsSaving(false)
    },
    [update, id],
  )

  const handleRunBBC = useCallback(async () => {
    handleCloseMenu()
    setIsRunningBBC(true)
    await runBBC(id)
    setIsRunningBBC(false)
  }, [id, runBBC, handleCloseMenu])

  const handleCloseDateChange = useCallback(
    (value) => {
      if (readOnly) {
        return
      }
      if (!isNaN(value?.getTime()) || value === null) {
        update(id, {
          closeDate: moment(value).format('YYYY-MM-DD'),
        })
      }
    },
    [update, id, readOnly],
  )

  const toggleArchiveReasonModalShown = useCallback(() => {
    setIsArchiveReasonModalShown(true)
    handleCloseMenu()
  }, [handleCloseMenu, setIsArchiveReasonModalShown])

  const toggleArchiveReasonModalCancel = useCallback(() => {
    setArchiveReason(null)
    setIsArchiveReasonModalShown(false)
  }, [setIsArchiveReasonModalShown])

  const toggleConvertToArchiveModalShown = useCallback(() => {
    setIsConvertToArchivedModalShown(true)
    setIsArchiveReasonModalShown(false)
    handleCloseMenu()
  }, [handleCloseMenu, setIsConvertToArchivedModalShown])

  const handleConvertToArchiveCancel = useCallback(() => {
    setIsConvertToArchivedModalShown(false)
    toggleArchiveReasonModalCancel()
  }, [setIsConvertToArchivedModalShown, toggleArchiveReasonModalCancel])

  const handleChangeArchiveReason = useCallback(
    ({ target: { value } }) => {
      setArchiveReason(value)
    },
    [setArchiveReason],
  )

  const handleChangeReasonLostText = useCallback(
    ({ target: { value } }) => {
      setReasonLost(value)
    },
    [setReasonLost],
  )

  const handleConvertToArchivedConfirm = useCallback(async () => {
    await handleUpdateToArchived({
      status: isArchived ? ClientInfoStatus.DueDiligence : ClientInfoStatus.Archived,
      archiveReason,
      reasonLost,
    })
    toggleArchiveReasonModalCancel()
    setReasonLost(null)
    setIsConvertToArchivedModalShown(false)
  }, [
    handleUpdateToArchived,
    isArchived,
    toggleArchiveReasonModalCancel,
    archiveReason,
    reasonLost,
  ])

  const handleProgressStatusChange = useCallback(
    (step: DueDiligenceProgressStep, status: DueDiligenceProgressStatus) => {
      if (readOnly) {
        return
      }
      update(id, {
        progress: {
          ...dueDiligenceInfo.ddInfo.progress,
          [step]: status,
        },
      })
    },
    [dueDiligenceInfo, update, id, readOnly],
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
    ]
  }, [dueDiligenceInfo, id])

  const handleLoginAsClient = useCallback(() => {
    if (isAdminRightsRole) {
      loginAsClient(id)
    }
  }, [id, loginAsClient, isAdminRightsRole])

  useEffect(() => {
    setIconUrl(savedProfilePic || dueDiligenceInfo?.iconUrl)
  }, [savedProfilePic, dueDiligenceInfo?.iconUrl])

  const openEditProfilePicModal = useCallback(() => {
    setIsEditProfilePicModalOpen(true)
  }, [])

  const closeEditProfilePicModal = useCallback(() => {
    setIsEditProfilePicModalOpen(false)
    setLoadedFile([])
    setIconUrl(savedProfilePic || dueDiligenceInfo?.iconUrl)
  }, [savedProfilePic, dueDiligenceInfo?.iconUrl])

  const handleSetIconUrl = useCallback((image) => {
    setIconUrl(image)
  }, [])

  const handleUpdateClientInfo = useCallback(
    async (file: any) => {
      if (id) {
        const formData = new FormData()
        formData.append('files[profilePicture]', file[0], file[0].name)
        await updateClient(id, formData)
        await show(id)
      }
    },
    [id, updateClient, show],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      setLoadedFile(loadedFiles)
      const [image] = loadedFiles

      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          handleSetIconUrl(reader.result as string)
        }
        reader.readAsDataURL(image)
      }
    },
    [handleSetIconUrl],
  )

  const handleSaveProfilePic = useCallback(async () => {
    setIsSaving(true)
    await handleUpdateClientInfo(loadedFile)
    setSavedProfilePic(iconUrl)
    setIconUrl(iconUrl)
    setIsEditProfilePicModalOpen(false)
    setLoadedFile([])
    setIsSaving(false)
  }, [handleUpdateClientInfo, loadedFile, iconUrl, setLoadedFile, setIsEditProfilePicModalOpen])

  const handleDeleteFile = useCallback(async () => {
    setLoadedFile([])
    handleSetIconUrl(null)
  }, [handleSetIconUrl])

  const handleToggleImageManageModal = useCallback(() => {
    setIsImageManageModalOpen((isOpen) => !isOpen)
  }, [])

  if (!dueDiligenceInfo) {
    return (
      <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
            <Breadcrumbs breadcrumbs={breadcrumbs} isLoading />
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end" gap={1.5}>
            <Skeleton width={36} height={60} />
            <Skeleton width={36} height={60} />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      data-cy="dd-page-header"
      container
      py={3}
      pr={2}
      alignItems="flex-start"
      justifyContent="center"
      rowSpacing={2}
    >
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={!dueDiligenceInfo} />
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end" gap={1.5}>
          <ArchivedChip clientInfo={dueDiligenceInfo} />
          <CodatSyncChip clientInfo={dueDiligenceInfo} />
          <BoxLink link={dueDiligenceInfo?.salesforceDDInfo?.boxLink} />
          <SalesforceLink link={dueDiligenceInfo?.salesforceDDInfo?.salesforceLink} />
          <MenuIcon
            isLoading={isRunningBBC}
            isActive={isActionsMenuOpen}
            onClick={handleClickMenu}
          />
          <Menu open={isActionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
            {isAdminRightsRole && <MenuItem onClick={handleLoginAsClient}>Prospect View</MenuItem>}
            <Link
              className={styles.menuItemLink}
              component={RouterLink}
              to={generatePath(ROUTES.CLIENT_SETUP, { id })}
            >
              <MenuItem onClick={handleCloseMenu}>Client setup</MenuItem>
            </Link>
            {dueDiligenceInfo?.opsReportings?.length > 0 && (
              <Link
                className={styles.menuItemLink}
                component={RouterLink}
                to={generatePath(ROUTES.PROSPECT_PAGE, {
                  id: dueDiligenceInfo.opsReportings?.[0]?.id,
                })}
              >
                <MenuItem onClick={handleCloseMenu}>View OPS</MenuItem>
              </Link>
            )}
            <MenuItem disabled={readOnly || !isLoanStructureComplete} onClick={handleRunBBC}>
              Run BBC
            </MenuItem>
            {!readOnly && <MenuItem onClick={toggleArchiveReasonModalShown}>Archive</MenuItem>}
            {isArchived && <MenuItem onClick={toggleConvertToArchiveModalShown}>Restore</MenuItem>}
          </Menu>
        </Grid>
      </Grid>
      <Grid container item xs={12} lg={12} alignItems="center" mb={1}>
        <Grid container item xs={2} justifyContent="center">
          {isLoading ? (
            <Skeleton width={140} height={140} />
          ) : (
            dueDiligenceInfo && (
              <div className={styles.avatar} {...stringAvatar(dueDiligenceInfo?.clientName)}>
                {savedProfilePic || dueDiligenceInfo?.iconUrl ? (
                  <div className={styles.avatarContainer}>
                    <img
                      className={styles.avatarImg}
                      src={savedProfilePic || dueDiligenceInfo?.iconUrl}
                      alt="logo"
                    />
                  </div>
                ) : (
                  <div className={styles.avatarText}>{initials}</div>
                )}
                <EditIcon className={styles.editIcon} onClick={openEditProfilePicModal} />
              </div>
            )
          )}
        </Grid>
        <Grid item container xs={10} justifyContent="space-around">
          <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Risk rating</div>
              <div className={styles.headerValue}>
                <HeaderLoader
                  isLoading={isLoading}
                  value={
                    dueDiligenceInfo?.riskRating ? dueDiligenceInfo?.riskRating.toFixed(2) : '-'
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Max line amount</div>
              <div className={styles.headerValue}>
                <HeaderLoader
                  isLoading={isLoading}
                  value={
                    dueDiligenceInfo?.maxLineAmount
                      ? `$ ${formatPrice(dueDiligenceInfo?.maxLineAmount)}`
                      : '-'
                  }
                />
              </div>
            </Grid>
            {dueDiligenceInfo.loanType !== LOAN_TYPES.inventory && (
              <Grid item xs={12 / 7}>
                <div className={styles.headerTitle}>AR availability</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      dueDiligenceInfo?.availableAR
                        ? ` $ ${formatPrice(dueDiligenceInfo.availableAR)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
            )}
            {dueDiligenceInfo.loanType !== LOAN_TYPES.ar && (
              <Grid item xs={12 / 7}>
                <div className={styles.headerTitle}>Inv. availability</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      dueDiligenceInfo?.availableInventory
                        ? ` $ ${formatPrice(dueDiligenceInfo.availableInventory)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
            )}
            {dueDiligenceInfo.loanType !== LOAN_TYPES.inventory && (
              <Grid item xs={12 / 7}>
                <div className={styles.headerTitle}>AR advanced rate</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={formatPercent(dueDiligenceInfo?.arAdvance, 2, 100)}
                  />
                </div>
              </Grid>
            )}
            {dueDiligenceInfo.loanType !== LOAN_TYPES.ar && (
              <Grid item xs={12 / 7}>
                <div className={styles.headerTitle}>Inv. advanced rate</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={formatPercent(dueDiligenceInfo?.inventoryAdvanceRateCost, 2, 100)}
                  />
                </div>
              </Grid>
            )}

            <Grid item xs={12 / 7}>
              <div className={styles.headerTitle}>Target funding date</div>
              <div className={styles.headerValue}>
                <KeyboardDatePicker
                  useFinalForm={false}
                  name="closeDate"
                  value={dueDiligenceInfo?.salesforceDDInfo?.closeDate}
                  onChange={handleCloseDateChange}
                  disabled={readOnly}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-start">
            <div className={styles.infoDivider}></div>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-start">
            {Object.values(DueDiligenceProgressStep).map((step) => (
              <Grid item xs={12 / 7}>
                <DueDiligenceProgressStepItem
                  key={step}
                  step={step}
                  status={dueDiligenceInfo?.ddInfo?.progress[step]}
                  onChange={handleProgressStatusChange}
                  readOnly={readOnly}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.tabsContainer}>
        <TabsWithRoutesMenu tabs={TABS} menu={MENU} isQueryString={false} />
      </Grid>
      {isConvertToArchivedModalShown && (
        <WarningModal
          onCancel={handleConvertToArchiveCancel}
          warningMessage={`Are you sure you want to ${
            isArchived ? 'restore' : 'archive'
          } this prospect?`}
          onConfirm={handleConvertToArchivedConfirm}
          isLoading={isSaving}
        />
      )}
      {isArchiveReasonModalShown && (
        <Modal
          open={isArchiveReasonModalShown}
          onCancel={toggleArchiveReasonModalCancel}
          classes={{ root: styles.modalRoot }}
          title="Archive Prospect"
          footer={[
            <Button
              key="cancel"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleArchiveReasonModalCancel}
              secondary
            >
              Cancel
            </Button>,
            <Button
              isLoading={isSaving}
              key="submit"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleConvertToArchiveModalShown}
              disabled={!archiveReason || !reasonLost}
            >
              Continue
            </Button>,
          ]}
        >
          <Box className={styles.fieldsContainer}>
            <SelectField
              name="archiveReason"
              useFinalForm={false}
              options={OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS}
              onChange={handleChangeArchiveReason}
              value={archiveReason}
              disabled={readOnly}
              selectSize="big"
              border
              placeholder="Select a reason"
            />
            <TextField
              name="reasonLost"
              className={styles.textFormField}
              useFinalForm={false}
              onChange={handleChangeReasonLostText}
              value={reasonLost}
              disabled={readOnly}
              multiline
              rows={3}
              placeholder="Please explain why this opportunity was lost"
            />
          </Box>
        </Modal>
      )}
      {isEditProfilePicModalOpen && (
        <Modal
          open={isEditProfilePicModalOpen}
          onCancel={closeEditProfilePicModal}
          classes={{ root: styles.modalRoot, title: styles.modalTitle, footer: styles.modalFooter }}
          title="Upload Image"
          size="small"
          footer={[
            <Button
              color="primary"
              variant="contained"
              small={false}
              onClick={handleSaveProfilePic}
              isLoading={isSaving}
              key="submit"
              className={styles.modalFooter}
              disabled={!loadedFile.length}
            >
              Update
            </Button>,
          ]}
        >
          {iconUrl && (
            <Box className={styles.avatarWrapper}>
              <img className={styles.avatarImg} src={iconUrl} alt="logo" />
            </Box>
          )}
          <Box className={styles.imageUploadWrapper}>
            <UploadFile
              title="Profile Picture"
              size="lg"
              onDropAccepted={handleUploadFile}
              files={loadedFile}
              onDelete={handleDeleteFile}
              acceptedFileTypes={['image']}
              maxFiles={1}
              isModalOpen={isImageManageModalOpen}
              handleToggleModal={handleToggleImageManageModal}
            />
          </Box>
        </Modal>
      )}
    </Grid>
  )
}

export default DueDiligencePageHeader
