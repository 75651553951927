import { IClientInfo } from './client'
import { IParticipationFeeExposure } from './participant'

export enum FeeType {
  Wire = 'Wire',
  Miscellaneous = 'Miscellaneous',
  Facility = 'Facility',
  Lockbox = 'Lockbox',
  Termination = 'Termination',
  PassThroughs = 'Pass-Through',
  Overadvances = 'Overadvances',
  Interest = 'Interest',
  MinimumInterest = 'MinimumInterest',
  UnusedLineFee = 'Unused Line',
  ManualInterest = 'ManualInterest',
  TermLoanInterest = 'TermLoanInterest',
}

export enum TermLoanPrincipalPaymentType {
  TermLoanPrincipal = 'TermLoanPrincipal',
  TermLoanManualPrincipal = 'TermLoanManualPrincipal',
}

export const FeeTypeCodeMapping = {
  [FeeType.Wire]: 4003,
  [FeeType.Miscellaneous]: 4003,
  [FeeType.Facility]: 1800,
  [FeeType.Lockbox]: 4003,
  [FeeType.Termination]: 4003,
  [FeeType.PassThroughs]: 1401,
  [FeeType.Overadvances]: 4003,
  [FeeType.Interest]: 4001,
  [FeeType.MinimumInterest]: 4001,
  [FeeType.UnusedLineFee]: 4001,
  [FeeType.ManualInterest]: 4001,
  [FeeType.TermLoanInterest]: 4001,
}

export enum PassThroughSubTypes {
  Appraisal = 'Appraisal',
  FieldExams = 'Field Exam',
  Legal = 'Legal',
  DDRec = 'Due Diligence Reconciliation',
  TerminationExpenses = 'Termination Expenses',
}

export enum MiscellaneousSubTypes {
  Amendment = 'Amendment',
  Admin = 'Administration',
  EarlyTermination = 'Early Termination',
  Servicing = 'Servicing',
  DefaultWaiver = 'Default Waiver',
}

export const miscOptions = Object.values(MiscellaneousSubTypes)?.map((title) => ({
  value: title,
  label: title,
}))

export const passthroughOptions = Object.values(PassThroughSubTypes)?.map((title) => ({
  value: title,
  label: title,
}))

export interface IFee {
  id: string
  type: FeeType
  title: string
  description?: string
  amount: number
  recordDate: string
  clientName: string
  clientId?: string
  clientIconUrl?: string
  clientInfo: IClientInfo
  participationFeeExposure: IParticipationFeeExposure[]
}

export interface IAdjustment {
  id: string
  title: string
  amount: number
  recordDate: string
  clientName: string
  clientInfo?: IClientInfo
}

export enum EndOfMonthFlowOperationType {
  Apply = 'apply',
  Revert = 'revert',
  Lock = 'lock',
}

export interface IEndOfMonthFlow {
  id: string
  recordDate: string
  isLocked: boolean
  createdAt: string
  updatedAt: string
}

export const CLIENT_FEE_STATEMENT_HEADER_ROW = [
  'Client',
  'Date',
  '',
  '',
  'Beginning Balance',
  'Funding',
  'Cash Receipts',
  '',
  'Interest Fees',
  'Fees & Expenses',
  'Adjustments',
  'Ending Balance',
  '',
  'Float Balance',
  'Calculated Fee',
  'Interest Rate',
]

export const PARTICIPANT_ACTIVITY_EXPORT_HEADER_ROW = [
  ...CLIENT_FEE_STATEMENT_HEADER_ROW.slice(0, 12),
  'Calculated Fee',
  '',
]

export enum TransactionsOutputType {
  Bank = 'bank',
  QuickBooks = 'quickbooks',
}

export type LoanBalanceSum = {
  funding: number
  passThroughs: number
  collections: number
  charges: number
  fees: number
  adjustments: number
  loanBalance: number
  floatBalance: number
  interestAmountMonthly: number
  interestRateDaily: number
  participations?: {
    participationId: string
    participant: string
    interestAmountMonthly: number
  }[]
}
