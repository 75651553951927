import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { generatePath } from 'react-router-dom'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectManageTermsPage.module.scss'
import { usePermissions } from '../../helpers/permissionContext'

import { ReactComponent as EmptyIcon } from '@assets/images/empty-page-icon.svg'
import { IOPSReporting, IProspectTerms, OPSReportingStatus } from '@common/interfaces/prospects'
import { ROUTES } from '../../constants/routes'
import TableContainer from '../../components/Common/TableContainer/TableContainer'
import Card from '../../components/Common/Card/Card'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableBody from '../../components/Common/TableBody'
import { formatDate } from '../../helpers/helpers'
import Button from '../../components/Common/Button/Button'
import ManageTermsActionMenu from './ManageTermsActionMenu'
import ManageTermsStatusSelect from './ManageTermsStatusSelect'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../../components/Common/TableLoader'
import SaveState from '../../components/Common/SaveState'
import { All_MANAGE_TERMS_FIELDS_CONFIG } from '@common/constants/prospects'
import { getFieldValue } from '../../components/Client/ClientHelpers'
import { LOAN_TYPES } from '@common/constants/client'
import { AR_FIELDS, INVENTORY_FIELDS } from '../ProspectAddTermsPage/helpers'
import { ExpandDetailIcon } from '../../components/Common/Icons'
import { ClientInfoStatus } from '@common/interfaces/client'
import ProspectHeader from '../../components/ProspectHeader'

interface IProps {
  reporting: IOPSReporting
  prospectTerms: ILoadingData<{ data: IProspectTerms[] }>
  listProspectTerms: (id: string) => void
}

const ProspectPage = ({ reporting, listProspectTerms, prospectTerms }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const {
    data: prospectTermsData,
    isLoading,
    isSaving,
    isSaved,
  } = useMemo(
    () => ({
      data: prospectTerms?.data?.data || [],
      isLoading: prospectTerms.isLoading,
      isSaving: prospectTerms.isSaving,
      isSaved: prospectTerms.isSaved,
    }),
    [prospectTerms],
  )

  useEffect(() => {
    if (reporting?.id) {
      listProspectTerms(id)
    }
  }, [id, listProspectTerms, reporting])

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  const handleClick = useCallback(() => {
    history.push(generatePath(ROUTES.PROSPECT_ADD_TERMS_PAGE, { id }))
  }, [id, history])

  const [expanded, setExpanded] = useState([])

  const handleExpand = useCallback((label: string) => {
    setExpanded((values) =>
      values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
    )
  }, [])

  return (
    <Grid container py={3} pr={2} alignItems={'flex-start'} justifyContent={'start'}>
      <Grid item xs={12}>
        <ProspectHeader />
      </Grid>
      <Grid item xs={12}>
        <Card withBorder={false}>
          {!isLoading && !prospectTermsData.length ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="30vh">
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid container item xs={12} justifyContent={'center'}>
                  <EmptyIcon />
                </Grid>
                {!readOnly && (
                  <Grid container item xs={12} justifyContent={'center'}>
                    <Button type="submit" small={false} onClick={handleClick} isLoading={isLoading}>
                      Create new term structure
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          ) : (
            <TableContainer className={styles.table} size="big">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={genericSs.tableTextCenter}> </TableCell>
                    <TableCell className={genericSs.tableTextRight}>Date</TableCell>
                    <TableCell className={genericSs.tableTextLeft}>Status</TableCell>
                    <TableCell className={genericSs.tableTextLeft}>Summary of changes</TableCell>
                    <TableCell className={genericSs.tableTextLeft}>Created by</TableCell>
                    {!readOnly && (
                      <TableCell className={genericSs.tableTextRight}>Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody id="scrollableTable">
                  {isLoading ? (
                    <TableLoader columnsCount={readOnly ? 5 : 6} />
                  ) : (
                    prospectTermsData.map((term) => {
                      const isExpanded = expanded.includes(term.id)

                      return (
                        <React.Fragment key={term.id}>
                          <TableRow
                            key={term.id}
                            className={cn('activableRow', {
                              activeRow: isExpanded,
                              currentActiveRow: isExpanded,
                            })}
                          >
                            <TableCell className={genericSs.tableTextCenter}>
                              <ExpandDetailIcon
                                onClick={() => handleExpand(term.id)}
                                isExpanded={isExpanded}
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {formatDate(term.recordDate)}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <ManageTermsStatusSelect term={term} opsReportingId={reporting?.id} />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <Tooltip title={term.summaryOfChanges} placement="top">
                                <div>{term.summaryOfChanges}</div>
                              </Tooltip>
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {term.user?.firstName || ''}
                            </TableCell>
                            {!readOnly && (
                              <TableCell className={genericSs.tableTextRight}>
                                <ManageTermsActionMenu term={term} id={id} />
                              </TableCell>
                            )}
                          </TableRow>
                          {isExpanded && (
                            <TableRow>
                              <TableCell className={genericSs.nestedRowColumn} colSpan={6}>
                                <Grid container rowSpacing={3} justifyContent={'start'}>
                                  {All_MANAGE_TERMS_FIELDS_CONFIG.map((field) => {
                                    const value = getFieldValue(
                                      term,
                                      field.name,
                                      field.type,
                                      1,
                                      field.options,
                                    )
                                    if (
                                      [...AR_FIELDS, ...INVENTORY_FIELDS].includes(field.name) &&
                                      field.name !== 'loanType'
                                    ) {
                                      if (
                                        term.loanType === LOAN_TYPES.ar &&
                                        INVENTORY_FIELDS.includes(field.name)
                                      ) {
                                        return null
                                      }
                                      if (
                                        term.loanType === LOAN_TYPES.inventory &&
                                        AR_FIELDS.includes(field.name)
                                      ) {
                                        return null
                                      }
                                    }
                                    if (
                                      !term.covenantType &&
                                      field.name === 'qualifiedEquityRound'
                                    ) {
                                      return null
                                    }
                                    return (
                                      <Grid
                                        item
                                        md={field.name === 'commentsForCommittee' ? 6 : 3}
                                        lg={field.name === 'commentsForCommittee' ? 6 : 2}
                                      >
                                        <h3>{field.label}</h3>
                                        <span className={styles.value}>{value}</span>
                                      </Grid>
                                    )
                                  })}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      )
                    })
                  )}
                </TableBody>
              </Table>
              <Box display="flex" alignItems="center" justifyContent="end">
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </Box>
            </TableContainer>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default ProspectPage
