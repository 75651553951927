import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import queryString from 'query-string'
import genericSs from '@styles/generic.module.scss'
import styles from './ActivityQueuePage.module.scss'

import TableContainer from '../../components/Common/TableContainer'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import {
  ACTIVITY_TYPE_LABEL,
  ActivityStatus,
  ActivityType,
  ActivityViews,
  IActivityData,
} from '@common/interfaces/activity'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { dateTimeToString, debounceEventHandler, formatPrice } from '../../helpers/helpers'
import Card from '../../components/Common/Card'
import { IUser, USER_ROLE_LABEL_SHORT, UserRole } from '@common/interfaces/user'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { ACTIVITIES_LIST_FILTERS_CONFIG, IFilter, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../../components/Filters/FilterContainer'
import WarningModal from '../../components/WarningModal'
import ActivityQueueRow from './ActivityQueueRow'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Avatar from '../../components/Common/Avatar'
import Button from '../../components/Common/Button'
import { ReactComponent as DownChevron } from '@assets/images/down-chevron.svg'
import Tabs from '../../components/Common/Tabs'
import { ILoadingData } from '../../redux/types'
import SaveState from '../../components/Common/SaveState'
import TableLoader from '../../components/Common/TableLoader/TableLoader'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import Modal from '../../components/Common/Modal'
import InputLabel from '../../components/Common/InputLabel'
import Autocomplete, { getOptionValue } from '../../components/Common/Autocomplete'
import useTable from '../../../src/hooks/useTable'
import AnalysisQueueTable from '../../components/AnalysisQueueTable'
import DashboardHeader from '../../components/DashboardHeader'
import { IHeaderItem } from '../../components/DashboardHeader/DashboardHeader'
import { useHistory, useLocation } from 'react-router'
import { checkIsLoanStructureComplete } from '@common/helpers/helpers'

const filtersValidate = buildFiltersValidateSchema(ACTIVITIES_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(ACTIVITIES_LIST_FILTERS_CONFIG, {
  isActive: true,
})

const TABS = ['Activities', 'Reporting']

const ACTIVITY_TYPES = [
  {
    value: ActivityType.Wire,
    label: ACTIVITY_TYPE_LABEL[ActivityType.Wire],
  },
  {
    value: ActivityType.MidDayWire,
    label: ACTIVITY_TYPE_LABEL[ActivityType.MidDayWire],
  },
  {
    value: ActivityType.Check,
    label: ACTIVITY_TYPE_LABEL[ActivityType.Check],
  },
  {
    value: ActivityType.TestBBC,
    label: ACTIVITY_TYPE_LABEL[ActivityType.TestBBC],
  },
]

const ACTIVITIES_COLUMN_LIST_FILTERS_CONFIG: IFilter[] = [
  {
    field: 'type',
    type: 'empty',
    isSortable: true,
    title: 'Type',
  },
  {
    field: 'clients',
    dbField: 'client_name',
    type: 'empty',
    isSortable: true,
    title: 'Client',
  },
  {
    field: 'amount',
    type: 'empty',
    isSortable: false,
    title: 'Amount',
  },
  {
    field: 'user',
    type: 'empty',
    isSortable: true,
    title: 'User',
  },
  {
    field: 'createdAt',
    dbField: 'created_at',
    type: 'date',
    isSortable: true,
    title: 'Date',
  },
  {
    field: 'completedAt',
    dbField: 'completed_at',
    type: 'date',
    isSortable: true,
    title: 'Completed',
  },
  {
    title: 'Status',
    field: 'status',
    type: 'list',
    isSortable: true,
  },
  {
    field: 'action',
    type: 'empty',
    title: 'Action',
  },
]

const ACTIVITY_STATUSES = Object.values(ActivityStatus)

const testBBCSchema = Yup.object().shape({
  clientName: Yup.string().nullable().required('Required'),
})
const testBBCValidate = makeValidate(testBBCSchema)
const testBBCInitialValues: any = {
  clientName: null,
}
const createUserOptionsList = (users: IUser[]) =>
  users?.map((user) => ({
    value: user.id,
    label: `${user.firstName} - ${USER_ROLE_LABEL_SHORT[user.role]}`,
    icon: <Avatar user={user} size="small" />,
  })) || []

interface IProps {
  isAdmin: boolean
  isPortfolioAdmin: boolean
  isUW: boolean
  currentUserId: string
  fullActivities: ILoadingData<IActivityData>
  clients: ILoadingData<{ data: IClientInfo[] }>
  users: ILoadingData<{ data: IUser[] }>
  listClients: () => void
  listActivities: (params?: { filters?: object }) => void
  createActivity: (data: object) => Promise<any>
  updateActivity: (id: string, data: object) => Promise<void>
  deleteActivity: (id: string) => Promise<void>
  listUsers: ({ roles }: { withCurrent: boolean; roles: string[] }) => void
  hideBBC: () => void
  sendBBCSignReminder: (id: string) => void
}

const ActivityQueuePage = ({
  isAdmin,
  isPortfolioAdmin,
  isUW,
  currentUserId,
  fullActivities,
  clients,
  users,
  listClients,
  listActivities,
  createActivity,
  updateActivity,
  deleteActivity,
  listUsers,
  hideBBC,
  sendBBCSignReminder,
}: IProps) => {
  const [isCreateTestBBCModalShown, setIsCreateTestBBCModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [selectedActivityId, setSelectedActivityId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const { search }: { search: string } = useLocation()

  const { tab = TABS[0] } = queryString.parse(search) as { tab: string }
  const history = useHistory()

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      history.replace({
        search: queryString.stringify({ tab: selectedTab }),
      })
    },
    [history],
  )

  useEffect(() => {
    const currentSearch = queryString.parse(search, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'index',
    })

    if (!currentSearch.tab) {
      history.replace({
        search: queryString.stringify({ ...currentSearch, tab: TABS[0] }),
      })
    }
  }, [history, search])

  const effectiveFilterDefaults = useMemo(
    () => ({
      ...filtersDefaults,
      view: isUW ? ActivityViews.Diligence : ActivityViews.Portfolio,
    }),
    [isUW],
  )

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'activityQueue',
    filtersDefaults: effectiveFilterDefaults,
    sortDefault: {
      field: 'status',
      direction: 'ASC',
    },
    quickFilterDefault: 'Active',
  })

  const {
    data: activities,
    totals,
    isLoading,
    isSaved,
    isSaving,
  } = useMemo(() => {
    return {
      data: fullActivities.data?.data,
      totals: fullActivities.data?.totals,
      isLoading: fullActivities.isLoading,
      isSaved: fullActivities.isSaved,
      isSaving: fullActivities.isSaving,
    }
  }, [fullActivities])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  useSetPageTitle('Operations')

  const { usersData } = useMemo(
    () => ({
      usersData: users?.data?.data,
    }),
    [users],
  )

  useEffect(() => {
    listClients()
  }, [listClients])
  useEffect(() => {
    hideBBC()
  }, [hideBBC])

  useEffect(() => {
    listUsers({
      withCurrent: true,
      roles: [UserRole.ADMIN, UserRole.PORTFOLIO_ADMIN, UserRole.PORTFOLIO_USER, UserRole.UW_USER],
    })
  }, [listUsers])

  const fetchActivitiesList = useCallback(
    (values: any) => {
      const params = {
        ...values,
        filters: {
          ...values.filters,
        },
      }
      if (params?.filters.createdAtFrom && typeof params.filters.createdAtFrom !== 'string') {
        params.filters.createdAtFrom = dateTimeToString(params.filters.createdAtFrom)
      }
      if (params?.filters.createdAtTo && typeof params.filters.createdAtTo !== 'string') {
        params.filters.createdAtTo = dateTimeToString(params.filters.createdAtTo)
      }
      return listActivities(params)
    },
    [listActivities],
  )

  const debounceListActivities = useMemo(
    () =>
      debounceEventHandler((values: any) => {
        fetchActivitiesList(values)
      }, 500),
    [fetchActivitiesList],
  )

  const refetchActivitiesList = useCallback(() => {
    fetchActivitiesList({
      skipLoader: true,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, fetchActivitiesList])

  useEffect(() => {
    debounceListActivities({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListActivities])

  const loadMore = useCallback(() => {
    fetchActivitiesList({
      loadMore: true,
      skipLoader: true,
      page: Math.ceil(activities.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [activities, orderBy, filters, fetchActivitiesList])

  const allUsers = useMemo(() => createUserOptionsList(usersData), [usersData])

  const portfolioAdminUsers = useMemo(
    () =>
      createUserOptionsList(
        usersData?.filter(({ role }) =>
          [UserRole.PORTFOLIO_ADMIN, UserRole.UW_USER, UserRole.ADMIN].includes(role),
        ),
      ),

    [usersData],
  )

  const portfolioUsers = useMemo(
    () =>
      createUserOptionsList(
        usersData?.filter(({ role }) =>
          [
            UserRole.PORTFOLIO_ADMIN,
            UserRole.PORTFOLIO_USER,
            UserRole.UW_USER,
            UserRole.ADMIN,
          ].includes(role),
        ),
      ),

    [usersData],
  )

  const onSaveActivity = useCallback(
    async (id: string, values: object) => {
      await updateActivity(id, values)
      refetchActivitiesList()
    },
    [updateActivity, refetchActivitiesList],
  )

  const onDeleteActivity = useCallback(async (id: string) => {
    setSelectedActivityId(id)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActivityConfirm = useCallback(async () => {
    setIsDeleting(true)
    await deleteActivity(selectedActivityId)
    refetchActivitiesList()
    setSelectedActivityId(null)
    setIsDeleteModalShown(false)
    setIsDeleting(false)
  }, [selectedActivityId, deleteActivity, refetchActivitiesList])

  const handleDeleteActivityCancel = useCallback(async () => {
    setSelectedActivityId(null)
    setIsDeleteModalShown(false)
  }, [])

  const filtersConfig = useMemo(
    () =>
      ACTIVITIES_LIST_FILTERS_CONFIG.map((item) => {
        if (item.type === 'quickFilter') {
          return {
            ...item,
            quickFilters: [
              ...item.quickFilters.map((quickFilter) => ({
                ...quickFilter,
                filters:
                  quickFilter.title === 'Active'
                    ? {
                        ...quickFilter.filters,
                        view: isUW ? ActivityViews.Diligence : ActivityViews.Portfolio,
                      }
                    : quickFilter.filters,
              })),
            ],
          }
        }
        return {
          ...item,
          options:
            item.field === 'clients'
              ? clientsData?.map(({ clientName }) => ({
                  value: clientName,
                  label: clientName,
                }))
              : item.field === 'users'
              ? createUserOptionsList(usersData)
              : item.field === 'status'
              ? ACTIVITY_STATUSES.map((value) => ({
                  value,
                  label: value,
                }))
              : item.options,
        }
      }),
    [clientsData, usersData, isUW],
  )

  const handleCreateActivity = useCallback(
    async (value) => {
      if (value === ActivityType.TestBBC) {
        setIsCreateTestBBCModalShown(true)
        return
      }
      await createActivity({ type: value })
      refetchActivitiesList()
    },
    [createActivity, refetchActivitiesList],
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSendBBCSignReminder = useCallback(
    (id: string) => {
      sendBBCSignReminder(id)
    },
    [sendBBCSignReminder],
  )

  const handleCloseCreateTestBBCModal = useCallback(() => {
    setIsCreateTestBBCModalShown(false)
  }, [])

  const handleCreateTestBBC = useCallback(
    async (data) => {
      const result = await createActivity({
        type: ActivityType.TestBBC,
        clientName: data.clientName,
      })
      if (!result?.error) {
        refetchActivitiesList()
        setIsCreateTestBBCModalShown(false)
      }
    },
    [createActivity, refetchActivitiesList],
  )

  const testBBCClientsOptions = useMemo(
    () =>
      (clientsData || [])
        .filter(
          (client) =>
            [ClientInfoStatus.Current, ClientInfoStatus.DueDiligence].includes(
              client.clientStatus,
            ) && checkIsLoanStructureComplete(client),
        )
        .map(({ clientName }) => ({
          value: clientName,
          label: clientName,
        })),
    [clientsData],
  )

  const activityQueueDashboardHeader: IHeaderItem[] = useMemo(
    () => [
      { title: 'Total Collections', value: totals?.totalCollections, type: 'currency' },
      {
        title: 'Total Fundings & Pass-throughs',
        value: totals?.totalFundings + totals?.totalPassthroughs,
        type: 'currency',
        hoverText: `Fundings: $${formatPrice(totals?.totalFundings)} | 
         Pass-throughs: $${formatPrice(totals?.totalPassthroughs)}`,
      },
      { title: 'BBCs Pending', value: totals?.totalUnprocessedBBCs, type: 'number' },
      { title: 'BBCs Processed', value: totals?.totalProcessedBBCs, type: 'number' },
    ],

    [totals],
  )

  return (
    <Box py={1} pr={2}>
      <Card
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs tabs={TABS} selected={tab} handleChange={handleChangeTab} />
          </Box>
        }
        withBorder={false}
      >
        {tab === TABS[0] && (
          <>
            <DashboardHeader headerItems={activityQueueDashboardHeader} />
            <div className={styles.activityFilters}>
              <Form
                validate={filtersValidate}
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    filters={filtersConfig}
                    handleSubmit={handleSubmit}
                    mutators={mutators}
                    values={values}
                    appliedFilters={filters}
                    appliedQuickFilter={quickFilter}
                    handleAppliedQuickFilterChange={handleQuickFilterChange}
                    actions={
                      <>
                        <Button variant="outlined" onClick={handleClickMenu}>
                          Create
                          <DownChevron className={styles.downChevron} />
                        </Button>
                        <Menu
                          open={isActionsMenuOpen}
                          anchorEl={anchorEl}
                          onClose={handleCloseMenu}
                        >
                          {ACTIVITY_TYPES.map(({ value, label }) => (
                            <MenuItem
                              key={value}
                              onClick={() => {
                                handleCreateActivity(value)
                                handleCloseMenu()
                              }}
                            >
                              {label}
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    }
                  />
                )}
              />
            </div>

            <TableContainer className={styles.activityTable}>
              <Table>
                <TableHead>
                  <TableFiltersRow
                    filters={ACTIVITIES_COLUMN_LIST_FILTERS_CONFIG}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                  />
                </TableHead>
                <TableBody id="scrollableTableActivitites">
                  {isLoading ? (
                    <TableLoader columnsCount={8} height={24} />
                  ) : (
                    activities?.length > 0 && (
                      <InfiniteScroll
                        dataLength={activities.length}
                        next={loadMore}
                        hasMore={activities.length < totals?.totalItems}
                        loader={<TableLoader columnsCount={8} height={24} rowsCount={1} />}
                        scrollableTarget="scrollableTableActivitites"
                      >
                        {activities.map((activity) => (
                          <ActivityQueueRow
                            key={activity.id}
                            activity={activity}
                            isAdmin={isAdmin}
                            isPortfolioAdmin={isPortfolioAdmin}
                            currentUserId={currentUserId}
                            portfolioAdminUsers={portfolioAdminUsers}
                            portfolioUsers={portfolioUsers}
                            onSaveActivity={onSaveActivity}
                            onDeleteActivity={onDeleteActivity}
                            onSendBBCSignReminder={handleSendBBCSignReminder}
                            allUsers={allUsers}
                          />
                        ))}
                      </InfiniteScroll>
                    )
                  )}
                </TableBody>
              </Table>
              <Grid container justifyContent={'flex-end'}>
                <SaveState isSaving={isSaving} isSaved={isSaved} />
              </Grid>
            </TableContainer>
          </>
        )}
        {tab === TABS[1] && <AnalysisQueueTable />}
      </Card>

      {isCreateTestBBCModalShown && (
        <Modal
          open
          onCancel={handleCloseCreateTestBBCModal}
          title="Test Borrowing Base"
          size="small"
        >
          <Form
            validate={testBBCValidate}
            onSubmit={handleCreateTestBBC}
            initialValues={testBBCInitialValues}
            render={({ pristine, invalid, submitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box flex={1}>
                  <InputLabel htmlFor="clientName" className={genericSs.textLeft}>
                    Client
                  </InputLabel>
                  <Autocomplete
                    label=""
                    id="clientName"
                    name="clientName"
                    autocompleteSize="big"
                    options={testBBCClientsOptions}
                    getOptionValue={getOptionValue}
                    freeSolo
                    autoSelect
                    placeholder="Select client"
                  />
                </Box>
                <Box mt={5}>
                  <Button
                    isLoading={submitting || isSaving}
                    color="primary"
                    variant="contained"
                    fullWidth
                    small={false}
                    type="submit"
                    disabled={pristine || invalid || submitting}
                  >
                    Confirm
                  </Button>
                </Box>
              </form>
            )}
          />
        </Modal>
      )}

      {isDeleteModalShown && (
        <WarningModal
          warningMessage="Deleting this activity is irreversible"
          onCancel={handleDeleteActivityCancel}
          onConfirm={handleDeleteActivityConfirm}
          confirmText="Delete"
          cancelText="Cancel"
          isLoading={isDeleting}
        />
      )}
    </Box>
  )
}

export default ActivityQueuePage
