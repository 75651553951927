import React, { useCallback, useEffect, useMemo, useState } from 'react'
import genericSs from '@styles/generic.module.scss'
import {
  formatNumber,
  debounceEventHandler,
  formatValue,
  formatDate,
  formatAmount,
} from '../../helpers/helpers'
import {
  IRiskRatingSummaryData,
  RISK_METRIC_UNDERLYING_DATA,
  RISK_RATING_COLOR_MAPPING,
} from '@common/interfaces/client'
import Card from '../Common/Card'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import cn from 'classnames'
import Box from '@mui/material/Box'
import { ILoadingData } from '../../redux/types'
import {
  RISK_RATING_TABLE_FILTERS_CONFIG,
  RISK_RATING_TABLE_VALUE_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import styles from './RiskRatingTable.module.scss'
import { Form } from 'react-final-form'
import FilterContainer from '../Filters/FilterContainer'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import LinkButton from '@mui/material/Link'
import { Link, generatePath } from 'react-router-dom'
import useTable from '../../hooks/useTable'
import { ROUTES } from '../../constants/routes'
import SelectField from '../Common/SelectField'
import { Tooltip } from '@mui/material'
import { getFieldValue } from '../Client/ClientHelpers'
import TableFooter from '../Common/TableFooter'

const metricOptions: {
  value: 'score' | 'value'
  label: 'Score' | 'Value'
}[] = [
  { value: 'score', label: 'Score' },
  { value: 'value', label: 'Value' },
]

const getColorForRating = (rating: string | null) => {
  if (!rating) {
    return RISK_RATING_COLOR_MAPPING.find((mapping) => mapping.min === null && mapping.max === null)
      .colors
  }

  const numRating = parseFloat(rating)

  const mapping = RISK_RATING_COLOR_MAPPING.find(
    (mapping) =>
      (mapping.min === null || numRating >= mapping.min) &&
      (mapping.max === null || numRating < mapping.max),
  )

  return mapping ? mapping.colors : {}
}

interface IProps {
  listRiskRatingDetails: (params?: object) => void
  exportRiskRatingDetails: (params?: object) => Promise<void>
  riskRatingDetails: ILoadingData<IRiskRatingSummaryData>
}

const RiskRatingTable = ({
  riskRatingDetails,
  listRiskRatingDetails,
  exportRiskRatingDetails,
}: IProps) => {
  const [currentMetric, setCurrentMetric] = useState('score')
  const [dateValue, setDateValue] = useState('Live')

  const isScore = useMemo(() => currentMetric === 'score', [currentMetric])

  const filterConfig = useMemo(() => {
    return isScore ? RISK_RATING_TABLE_FILTERS_CONFIG : RISK_RATING_TABLE_VALUE_FILTERS_CONFIG
  }, [isScore])

  const sortDefault = useMemo(
    () => ({
      field: 'risk_rating',
      direction: 'ASC' as const,
    }),
    [],
  )

  const filtersValidate = buildFiltersValidateSchema(filterConfig)
  const filtersDefaults = buildFiltersDefaults(filterConfig)

  const { filters, orderBy, handleFiltersChange, handleOrderChange } = useTable({
    tableId: 'riskRating',
    filtersDefaults,
    sortDefault,
  })
  const handleMetricChange = useCallback(({ target: { value } }) => {
    setCurrentMetric(value)
  }, [])

  const {
    data: riskRatingItems,
    isLoading,
    totalItems,
    dateOptions,
  } = useMemo(() => {
    return {
      data: riskRatingDetails?.data?.data,
      isLoading: riskRatingDetails?.isLoading,
      totalItems: riskRatingDetails?.data?.totalItems,
      dateOptions: riskRatingDetails?.data?.dateOptions,
    }
  }, [riskRatingDetails])

  const handleDateChange = useCallback(
    (event) => {
      setDateValue(event.target.value)
    },
    [setDateValue],
  )

  const filterboxFilters = useMemo(() => {
    return isScore
      ? filterConfig.map((filter) => {
          return {
            ...filter,
            title: ['riskRatingTier', 'loanBalance', 'maturityDate'].includes(filter.field)
              ? filter.title
              : `${filter.title} Score`,
          }
        })
      : RISK_RATING_TABLE_VALUE_FILTERS_CONFIG
  }, [filterConfig, isScore])

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler((data: object) => {
        listRiskRatingDetails(data)
      }, 500),
    [listRiskRatingDetails],
  )

  const exportRiskRating = useCallback(async () => {
    await exportRiskRatingDetails({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      metric: currentMetric,
      date: dateValue,
      filters,
      isExport: true,
    })
  }, [orderBy, filters, exportRiskRatingDetails, currentMetric, dateValue])

  useEffect(() => {
    debounceFilterList({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      metric: currentMetric,
      date: dateValue,
      filters,
    })
  }, [orderBy, filters, debounceFilterList, currentMetric, dateValue])

  const loadMore = useCallback(() => {
    debounceFilterList({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      date: dateValue,
      metric: currentMetric,
      page: Math.ceil(riskRatingItems?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceFilterList, riskRatingItems, currentMetric, dateValue])

  return (
    <Card noHeaderMargin className={styles.cardContainer}>
      <TableContainer className={styles.table}>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filterboxFilters}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              title="Risk Rating Summary"
              handleExportAggregation={exportRiskRating}
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  <div>
                    <SelectField
                      key={currentMetric}
                      label="Metric"
                      variant="outlined"
                      className={styles.selectField}
                      useFinalForm={false}
                      name="metric"
                      options={metricOptions}
                      onChange={handleMetricChange}
                      value={currentMetric}
                      defaultValue=""
                      withTopLabel
                    />
                  </div>

                  <SelectField
                    className={styles.distributionOptions}
                    label="Period"
                    variant="outlined"
                    useFinalForm={false}
                    name="period"
                    options={dateOptions || []}
                    defaultValue=""
                    onChange={handleDateChange}
                    value={dateValue}
                    isLoading={isLoading}
                    withTopLabel
                  />
                </Box>
              }
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filterConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
              isChildrenAtStart
            ></TableFiltersRow>
          </TableHead>
          <TableBody id="scrollableTableDetails">
            {isLoading ? (
              <TableLoader columnsCount={filterConfig.length} />
            ) : (
              <InfiniteScroll
                dataLength={riskRatingItems?.length || 0}
                next={loadMore}
                hasMore={riskRatingItems?.length < totalItems}
                loader={<TableLoader columnsCount={filterConfig.length} rowsCount={1} />}
                scrollableTarget={`scrollableTableDetails`}
              >
                {riskRatingItems?.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell className={genericSs.tableTextLeft}>
                        <LinkButton
                          component={Link}
                          to={generatePath(ROUTES.CLIENT_PAGE_RISK_PROFILE, {
                            id: item?.clientInfo?.id,
                          })}
                        >
                          {item?.clientInfo?.clientName}
                        </LinkButton>
                      </TableCell>

                      {isScore
                        ? filterConfig
                            .filter((returnItem) => returnItem.type === 'number')
                            .map((filter) => {
                              const supplementalData = RISK_METRIC_UNDERLYING_DATA.filter(
                                ({ originalValue }) => originalValue === filter.field,
                              ).map(({ label, type, value }) => ({
                                label,
                                value: getFieldValue(item.riskRatingRawData, value, type),
                              }))

                              return (
                                <TableCell
                                  key={filter.field}
                                  className={cn(genericSs.tableTextRight, styles.tableCell)}
                                >
                                  <Tooltip
                                    classes={{
                                      tooltip: styles.tooltip,
                                      arrow: styles.tooltipArrow,
                                    }}
                                    key={item.id}
                                    disableHoverListener={['riskRating'].includes(filter.field)}
                                    title={
                                      supplementalData?.length > 0 ? (
                                        <div>
                                          {supplementalData.map(({ label, value }) => (
                                            <div key={label}>
                                              <span className={styles.supplementalLabel}>
                                                {label}
                                              </span>
                                              <span>{value}</span>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <div> No underlying data</div>
                                      )
                                    }
                                    placement="left"
                                    arrow
                                  >
                                    <div
                                      style={getColorForRating(item[filter.field])}
                                      className={cn(styles.ratingTag)}
                                    >
                                      {formatNumber(item[filter.field], 2, true) || 'N/A'}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              )
                            })
                        : filterConfig
                            .filter(
                              (returnItem) =>
                                ['number', 'amount', 'percent'].includes(returnItem.type) &&
                                returnItem.field !== 'loanBalance',
                            )
                            .map((filter) => {
                              const supplementalData = RISK_METRIC_UNDERLYING_DATA.filter(
                                ({ originalValue }) => originalValue === filter.field,
                              ).map(({ label, type, value }) => ({
                                label,
                                value: getFieldValue(item.riskRatingRawData, value, type),
                              }))

                              return (
                                <TableCell
                                  key={filter.field}
                                  className={cn(genericSs.tableTextRight, styles.tableCell)}
                                >
                                  <Tooltip
                                    classes={{
                                      tooltip: styles.tooltip,
                                      arrow: styles.tooltipArrow,
                                    }}
                                    key={item.id}
                                    disableHoverListener={filter.field === 'riskRating'}
                                    title={
                                      supplementalData?.length > 0 ? (
                                        <div>
                                          {supplementalData.map(({ label, value }) => (
                                            <div key={label}>
                                              <span className={styles.supplementalLabel}>
                                                {label}
                                              </span>
                                              <span>{value}</span>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <div> No underlying data</div>
                                      )
                                    }
                                    placement="left"
                                    arrow
                                  >
                                    <div
                                      style={getColorForRating(item[filter.field])}
                                      className={cn(styles.ratingTag)}
                                    >
                                      {filter.type === 'amount' ? '$' : ''}
                                      {filter.type === 'number'
                                        ? formatNumber(
                                            ['riskRating', 'lineTurn'].includes(filter.field)
                                              ? item[filter.field]
                                              : item.riskRatingRawData[filter.field],
                                            2,
                                            true,
                                          ) || '-'
                                        : formatValue({
                                            value: item.riskRatingRawData[filter.field],
                                            type: filter.type,
                                          })}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              )
                            })}
                      <TableCell className={genericSs.tableTextRight}>
                        {formatAmount(item.clientInfo?.loanBalance?.loanBalance, '$')}
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {item?.clientInfo?.maturityDate && formatDate(item.clientInfo.maturityDate)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </InfiniteScroll>
            )}
          </TableBody>
          <TableFooter>
            <div className={styles.footer}>
              <div className={styles.keyTitle}>Key:</div>

              <div className={cn(styles.keyItem, styles.keyGreen)}>Monitor</div>
              <div className={cn(styles.keyItem, styles.keyYellow)}>Watchlist</div>
              <div className={cn(styles.keyItem, styles.keyRed)}>Workout</div>
            </div>
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default RiskRatingTable
