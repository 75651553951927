import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import genericSs from '@styles/generic.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { IDueDiligence, IDueDiligenceReporting } from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Button from '../../components/Common/Button'
import { OngoingReportingType, ReportingFlow } from '@common/interfaces/bbc'
import { BoxLink } from '../../components/Common/Icons'
import { BANK_TRANSACTIONS_TABS } from '@common/interfaces/bankTransactions'
import Tabs from '../../components/Common/Tabs'
import BankTransactionsMapping from '../../components/BankTransactionsMapping'
import Card from '../../components/Common/Card'
import BankTransactionsMappingLegend from '../../components/BankTransactionsMappingLegend'
import { ILoadingData } from '../../redux/types'
import DueDiligenceProcessDocumentsMappingLoader from '../../components/DueDiligenceProcessDocumentsMappingLoader'
import { ClientInfoStatus } from '@common/interfaces/client'

const TABS = [BANK_TRANSACTIONS_TABS.DEBITS, BANK_TRANSACTIONS_TABS.CREDITS]

interface IProps {
  dueDiligenceInfo: IDueDiligence
  dueDiligenceReportingData: ILoadingData<{ data: IDueDiligenceReporting[] }>
  show: (id: string) => void
  showReporting: (id: string) => void
}

const DueDiligenceProcessDocumentsBankTransactionsPage = ({
  dueDiligenceInfo,
  dueDiligenceReportingData,
  show,
  showReporting,
}: IProps) => {
  const { id, type: routeType } = useParams<{ id: string; type: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<string>(BANK_TRANSACTIONS_TABS.DEBITS)

  useLoadInfo(id, dueDiligenceInfo, show)
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  useEffect(() => {
    showReporting(id)
  }, [id, showReporting])

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const ddReporting = useMemo(
    () =>
      dueDiligenceReportingData?.data?.data.find(
        ({ type }) => type === OngoingReportingType.BankTransactions,
      ),
    [dueDiligenceReportingData],
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, { id, type: routeType }),
        title: 'Bank Statement',
      },
      {
        link: pathname,
        title: 'Map Bank Statement(s)',
      },
    ]
  }, [dueDiligenceInfo, id, routeType, pathname])

  const handleGoNext = useCallback(async () => {
    history.push(generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }))
  }, [history, id])

  if (!dueDiligenceInfo) {
    return <DueDiligenceProcessDocumentsMappingLoader />
  }

  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>

      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" ml={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h2 className={genericSs.dueDiligenceHeader}>Map Bank Statement(s)</h2>
          <Box display="flex" alignItems="center" gap={3}>
            <Button
              className={genericSs.dueDiligenceSubmitButton}
              variant="contained"
              onClick={handleGoNext}
            >
              {readOnly ? 'Next' : 'Submit'}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start">
        <Card noHeaderMargin={false}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Tabs tabs={TABS} selected={currentTab} handleChange={setCurrentTab} />
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              <BankTransactionsMappingLegend />
              <BoxLink link={ddReporting?.boxLink} title="Bank Statement folder" />
            </Box>
          </Box>

          <BankTransactionsMapping
            id={id}
            currentClientName={dueDiligenceInfo?.clientName}
            transactionType={currentTab === BANK_TRANSACTIONS_TABS.DEBITS ? 'Debit' : 'Credit'}
            reportingFlow={ReportingFlow.DueDiligence}
            readOnly={readOnly}
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsBankTransactionsPage
