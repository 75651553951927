import React, { useCallback, useMemo } from 'react'
import Chip from '@mui/material/Chip'
import styles from './FilterItems.module.scss'
import { formatDate, formatDateTime } from '../../../helpers/helpers'
import { IFilter, IQuickFilter } from '@common/constants/filters'
import { ReactComponent as FilterItemsIcon } from '@assets/images/filter-items-icon.svg'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-chip-icon.svg'

interface IChipWrapperProps {
  field: string
  title: string
  value?: string
  handleDelete: (field: string, value: string) => void
}

const ChipWrapper = ({ field, title, value, handleDelete }: IChipWrapperProps) => {
  const onDelete = useCallback(() => {
    handleDelete(field, value)
  }, [field, value, handleDelete])

  return (
    <Chip
      label={title}
      className={styles.chip}
      onDelete={onDelete}
      size="small"
      deleteIcon={<DeleteIcon />}
    />
  )
}
interface IProps {
  appliedFilters: any
  appliedQuickFilter: IQuickFilter | null
  filters: IFilter[]
  handleSubmit: () => void
  mutators: any
  values: any
  handleQuickFilterClear: (quickFilter: IQuickFilter) => void
}

const FilterItems = ({
  filters,
  appliedFilters,
  appliedQuickFilter,
  handleSubmit,
  mutators,
  handleQuickFilterClear,
}: IProps) => {
  const handleClear = useCallback(
    (field) => {
      mutators.setFieldData(field, null)
      handleSubmit()
    },
    [mutators, handleSubmit],
  )
  const handleClearTypes = useCallback(
    (field, updatedTypes) => {
      mutators.setFieldData(field, updatedTypes)
      handleSubmit()
    },
    [mutators, handleSubmit],
  )

  const chips: Array<{ field: string; title: string; value?: string }> = useMemo(() => {
    const chipsData: Array<{ field: string; title: string; value?: string }> = []

    if (!appliedFilters) {
      return chipsData
    }

    if (appliedQuickFilter && !appliedQuickFilter.isHidden) {
      return chipsData
    }

    filters.forEach((filter) => {
      if (!filter.field || filter.excludeFilter || filter.type === 'quickFilter') {
        return
      }

      if (filter.type === 'text' && filter.excludeFullSearchFilter) {
        if (appliedFilters[filter.field]) {
          chipsData.push({
            field: filter.field,
            title: appliedFilters[filter.field],
          })
        }
      }

      if (filter.type === 'date') {
        if (appliedFilters[`${filter.field}From`] || appliedFilters[`${filter.field}To`]) {
          chipsData.push({
            field: filter.field,
            title: `${
              appliedFilters[`${filter.field}From`]
                ? formatDate(appliedFilters[`${filter.field}From`])
                : ''
            } - ${
              appliedFilters[`${filter.field}To`]
                ? formatDate(appliedFilters[`${filter.field}To`])
                : ''
            }`,
          })
        }
      }

      if (filter.type === 'datetime') {
        if (appliedFilters[`${filter.field}From`] || appliedFilters[`${filter.field}To`]) {
          chipsData.push({
            field: filter.field,
            title: `${
              appliedFilters[`${filter.field}From`]
                ? formatDateTime(appliedFilters[`${filter.field}From`])
                : ''
            } - ${
              appliedFilters[`${filter.field}To`]
                ? formatDateTime(appliedFilters[`${filter.field}To`])
                : ''
            }`,
          })
        }
      }

      if (['amount', 'number', 'percent'].includes(filter.type)) {
        if (appliedFilters[`${filter.field}Min`] || appliedFilters[`${filter.field}Max`]) {
          chipsData.push({
            field: filter.field,
            title: `${
              appliedFilters[`${filter.field}Min`] ? appliedFilters[`${filter.field}Min`] : ''
            } - ${
              appliedFilters[`${filter.field}Max`] ? appliedFilters[`${filter.field}Max`] : ''
            }`,
          })
        }
      }
      if (filter.type === 'list' && filter.options) {
        if (
          appliedFilters[filter.field]?.length > 0 ||
          appliedFilters[filter.field] === true ||
          appliedFilters[filter.field] === false
        ) {
          if (filter.isMultiple && appliedFilters[filter.field]) {
            appliedFilters[filter?.field]?.forEach((type: string) => {
              if (type !== '') {
                chipsData.push({
                  field: filter.field,
                  value: type,
                  title: filter.options?.find(({ value }) => value === type)?.label || type,
                })
              }
            })
          } else {
            const type = appliedFilters[filter.field]
            chipsData.push({
              field: filter.field,
              value: type,
              title: filter.options?.find(({ value }) => value === type)?.label || type,
            })
          }
        }
      }
      if (filter.type === 'autocomplete') {
        if (appliedFilters[filter.field]?.length > 0) {
          appliedFilters[filter.field]?.forEach((type: string) => {
            if (type !== '') {
              chipsData.push({
                field: filter.field,
                value: type,
                title: filter.options?.find(({ value }) => value === type)?.label || type,
              })
            }
          })
        }
      }
    })

    if (appliedFilters.fullSearch) {
      chipsData.push({
        field: 'fullSearch',
        title: appliedFilters.fullSearch,
      })
    }

    if (appliedFilters.isCustom) {
      chipsData.push({
        field: 'isCustom',
        title: filters.find(({ field }) => field === 'isCustom')?.title,
      })
    }

    return chipsData
  }, [appliedFilters, filters, appliedQuickFilter])

  const handleDelete = useCallback(
    (field: string, value: string) => {
      if (field === 'fullSearch') {
        handleClear(field)
      }
      filters.forEach((filter) => {
        if (filter.field === field) {
          if (['date', 'datetime'].includes(filter.type)) {
            handleClear(`${filter.field}From`)
            handleClear(`${filter.field}To`)
          } else if (['amount', 'number', 'percent'].includes(filter.type)) {
            handleClear(`${filter.field}Min`)
            handleClear(`${filter.field}Max`)
          } else if (
            (filter.type === 'list' && filter.isMultiple) ||
            filter.type === 'autocomplete'
          ) {
            const updatedTypes = appliedFilters[filter.field].filter(
              (type: string) => type !== value && type !== '',
            )
            handleClearTypes(filter.field, updatedTypes)
          } else {
            handleClear(filter.field)
          }
        }
      })
    },
    [filters, appliedFilters, handleClear, handleClearTypes],
  )

  const handleDeleteQuickFilter = useCallback(() => {
    handleQuickFilterClear(appliedQuickFilter)
  }, [handleQuickFilterClear, appliedQuickFilter])

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.filterItems}>
        <FilterItemsIcon className={styles.filterIcon} />
        {appliedQuickFilter && (
          <ChipWrapper
            field={appliedQuickFilter.title}
            title={appliedQuickFilter.title}
            handleDelete={handleDeleteQuickFilter}
          />
        )}
        {chips.map(({ field, title, value }) => (
          <ChipWrapper
            key={`${field}-${value}`}
            field={field}
            title={title}
            value={value}
            handleDelete={handleDelete}
          />
        ))}
      </div>
    </form>
  )
}

export default FilterItems
