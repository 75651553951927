declare global {
  interface Window {
    wsConnection: any
  }
}

import { urlBase64ToUint8Array } from '../helpers/helpers'

export const prefix = 'webSockets'

export const CREATE_ACTIVITY = `${prefix}/CREATE_ACTIVITY_SUCCESS`
export const UPDATE_ACTIVITY = `${prefix}/UPDATE_ACTIVITY_SUCCESS`
export const DELETE_ACTIVITY = `${prefix}/DELETE_ACTIVITY_SUCCESS`
export const UPDATE_LOAN_BALANCE = `${prefix}/UPDATE_LOAN_BALANCE_SUCCESS`
export const LOAN_BALANCE_RECALCULATED = `${prefix}/LOAN_BALANCE_RECALCULATED`
export const PUSH_NOTIFICATION = `${prefix}/PUSH_NOTIFICATION`
export const UPDATE_NOTIFICATIONS = `${prefix}/UPDATE_NOTIFICATIONS`
export const UPDATE_BBC_LOG = `${prefix}/UPDATE_BBC_LOG_SUCCESS`
export const UPDATE_FUNDING_REQUEST = `${prefix}/UPDATE_FUNDING_REQUEST_SUCCESS`
export const UPDATE_OPS_REPORTING = `${prefix}/UPDATE_OPS_REPORTING`
export const CODAT_PORTFOLIO_SYNC = `${prefix}/CODAT_PORTFOLIO_SYNC`
export const UPDATE_DUE_DILIGENCE = `${prefix}/UPDATE_DUE_DILIGENCE`
export const WS_SEND_MESSAGE = `${prefix}/WS_SEND_MESSAGE`
export const WS_NOTIFICATION_SUBSCRIBE = `${prefix}/WS_NOTIFICATION_SUBSCRIBE`
export const WS_PING = `${prefix}/WS_PING`
export const WS_PONG = `${prefix}/WS_PONG`

export const prepareMessage = (type: string, data: any) => {
  try {
    const prepared = JSON.stringify({
      type,
      data,
    })
    return prepared
  } catch (err) {
    return type
  }
}

export const formatMessage = (data: any) => {
  try {
    const parsed = JSON.parse(data)
    return parsed
  } catch (err) {
    return data
  }
}

export const connectToWS = (token: string, onMessage: (data: any) => void) => {
  // Avoid duplicate connections
  if (window.wsConnection) {
    return
  }
  const url = location.origin
    .replace(/^http/, 'ws')
    .replace('3000', '9000')
    .replace('.dwightfunding', '-backend.dwightfunding')
  const ws = new WebSocket(`${url}?token=${token}`)
  ws.onopen = () => {
    window.wsConnection = ws
    // receive messages
    ws.onmessage = (event) => {
      const message = formatMessage(event.data)
      onMessage(message)
    }

    // "heartbeat" to prevent socket closing. each 55s
    const interval = setInterval(() => {
      if (ws.readyState !== WebSocket.OPEN) {
        clearInterval(interval)
        return
      }
      ws.send(prepareMessage(WS_PING, {}))
    }, 55000)

    // Setup browser push notification subscription
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          if (!registration.pushManager) {
            return
          }

          const convertedVapidKey = urlBase64ToUint8Array(
            process.env.REACT_APP_VAPID_PUBLIC_KEY || '',
          )

          registration.pushManager.getSubscription().then((existedSubscription) => {
            if (existedSubscription === null) {
              registration.pushManager
                .subscribe({
                  applicationServerKey: convertedVapidKey,
                  userVisibleOnly: true,
                })
                .then((newSubscription) => {
                  ws.send(
                    prepareMessage(WS_NOTIFICATION_SUBSCRIBE, { subscription: newSubscription }),
                  )
                })
                .catch((err) => {
                  if (Notification.permission !== 'granted') {
                    console.error('Permission was not granted.')
                  } else {
                    console.error('An error occurred during the subscription process.', err)
                  }
                })
            } else {
              ws.send(
                prepareMessage(WS_NOTIFICATION_SUBSCRIBE, { subscription: existedSubscription }),
              )
            }
          })
        })
        .catch((err) => {
          console.error('An error occurred during Service Worker registration.', err)
        })
    }
  }
  ws.onclose = () => {
    delete window.wsConnection
    // If the session was interrupted, then try to reconnect
    setTimeout(() => {
      connectToWS(token, onMessage)
    }, 5000)
  }

  return ws
}
