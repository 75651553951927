import React from 'react'
import Grid from '@mui/material/Grid'
import { FormApi } from 'final-form'

import { IAlias } from '@common/interfaces/bbc'
import { tableFieldMapping } from '@common/constants/mappings'
import AliasMapping from '../AliasMapping'
import Card from '../Common/Card'
import { ILoadingData } from '../../redux/types'

interface IProps {
  aliases: ILoadingData<{ data: IAlias[] }>
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
  updateAlias?: (data: object) => Promise<any>
  deleteAlias?: (data: object) => Promise<any>
  listAliasMapping?: (id: string, data: object) => void
  listEntityAliases?: (id: string, data: object) => void
  hideAliases?: () => void
  id?: string
  fileId?: string
  table?: keyof typeof tableFieldMapping
  clientName?: string | null
  tabOptions?: string[]
  handleTabChange?: (tab: string) => void
  currentTab?: string
  formRef: React.MutableRefObject<FormApi<any, Partial<any>>>
  tableClassName?: string
  isProspect?: boolean
  isNotModal?: boolean
  children?: React.ReactNode
  readOnly?: boolean
  noPadding?: boolean
  bbcId?: string
}

const ClientAliasMapping = ({
  aliases,
  listEntityInfo,
  addEntityInfo,
  updateAlias,
  deleteAlias,
  listAliasMapping,
  listEntityAliases,
  hideAliases,
  id,
  fileId,
  table,
  clientName = null,
  tabOptions,
  handleTabChange,
  currentTab,
  formRef,
  tableClassName,
  isProspect = false,
  isNotModal = false,
  children,
  readOnly = false,
  noPadding = false,
  bbcId,
}: IProps) => {
  return (
    <Grid item xs={12}>
      <Card noHeaderMargin withBorder={false} noPadding={noPadding}>
        <AliasMapping
          formRef={formRef}
          aliasesData={aliases}
          listEntityInfo={listEntityInfo}
          addEntityInfo={addEntityInfo}
          updateAlias={updateAlias}
          deleteAlias={deleteAlias}
          listAliasMapping={id ? listEntityAliases : listAliasMapping}
          hideAliasMapping={hideAliases}
          id={fileId || id}
          table={table}
          clientName={clientName}
          tabOptions={tabOptions}
          handleTabChange={handleTabChange}
          currentTab={currentTab}
          tableClassName={tableClassName}
          isProspect={isProspect}
          isNotModal={isNotModal}
          children={children}
          readOnly={readOnly}
          bbcId={bbcId}
        />
      </Card>
    </Grid>
  )
}

export default ClientAliasMapping
