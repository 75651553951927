import { History } from 'history'
import { Action } from 'redux'
import { put, select, takeEvery } from 'redux-saga/effects'
import { IResponse } from '@common/interfaces/request'

import {
  CHECK_SETUP_PASSWORD_FAILURE,
  loadClientInfo,
  loadProfile,
  LOGIN_AS_CLIENT_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_AS_CLIENT_SUCCESS,
  LOGOUT_SUCCESS,
  openSidebar,
  PERMISSION_DENIED,
  PROFILE_SUCCESS,
  SETUP_PASSWORD_SUCCESS,
} from './actions'

import { IApi } from '../../api'
import { ROUTES } from '../../constants/routes'
import { UserRole } from '@common/interfaces/user'
import {
  getLoanBalanceLastRecalculationDate,
  getLoanBalanceStartDate,
} from '../loanBalance/actions'
import { listLastNotifications } from '../notification/actions'
import { getBanner } from '../clientInfo/actions'
import { IClientInfo, ClientInfoStatus } from '@common/interfaces/client'
import { clientInfo } from './selectors'

export function* loginSuccess(history: History, api: IApi) {
  yield takeEvery([LOGIN_SUCCESS, SETUP_PASSWORD_SUCCESS], function* (action: IResponse & Action) {
    api.client.setToken(action.data.jwt)

    yield put(loadProfile())
  })
}

export function* logoutSuccess(history: History, api: IApi) {
  yield takeEvery(LOGOUT_SUCCESS, function* () {
    api.client.removeToken()

    yield history.push(ROUTES.LOGIN)
    yield put(loadProfile())
  })
}

export function* profileSuccess(history: History) {
  yield takeEvery(PROFILE_SUCCESS, function* (action: IResponse & Action) {
    const { role, isProspectUser } = action.data
    if (role === UserRole.CLIENT_USER) {
      yield put(openSidebar())
      yield put(loadClientInfo())
      yield put(getBanner())
    } else if (!isProspectUser) {
      yield put(getLoanBalanceStartDate())
      yield put(getLoanBalanceLastRecalculationDate())
    }
    if (role !== UserRole.PARTICIPANT_USER) {
      yield put(listLastNotifications())
    }
  })
}

export function* onCheckPasswordSetup(history: History) {
  yield takeEvery(CHECK_SETUP_PASSWORD_FAILURE, function* () {
    yield history.push(ROUTES.LOGIN)
  })
}

export function* permissionDenied(history: History) {
  yield takeEvery(PERMISSION_DENIED, function* () {
    yield history.push(ROUTES.HOMEPAGE)
  })
}

export function* loginAsClientSuccess(api: IApi) {
  yield takeEvery([LOGIN_AS_CLIENT_SUCCESS], function* (action: IResponse & Action) {
    yield api.client.setToken(action.data.jwt)
    window.location.href = '/'
  })
}

export function* logoutAsClientSuccess(api: IApi) {
  yield takeEvery([LOGOUT_AS_CLIENT_SUCCESS], function* (action: IResponse & Action) {
    yield api.client.setToken(action.data.jwt)

    const info: IClientInfo = yield select(clientInfo)
    if (info?.id) {
      window.location.href =
        info.clientStatus === ClientInfoStatus.DueDiligence
          ? `/due-diligence/${info.id}`
          : `/clients/${info.id}`
    } else {
      window.location.href = '/'
    }
  })
}
