import { combineReducers } from 'redux'
import * as actions from './actions'
import {
  IClientGeneralLedgerMapping,
  IClientGeneralLedgerDilutionByCustomerData,
  IClientGeneralLedgerDilutionOverTimeData,
} from '@common/interfaces/clientGeneralLedger'
import { DOWNLOAD_TEMPLATE_REQUEST } from '../bbc/actions'
import { loadingArrayReducer } from '../../helpers/helpers'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IGeneralLedgerState {
  loadingArray: string[]
  typeOptions: { name: string }[]
  typeMapping: ILoadingData<{ data: IClientGeneralLedgerMapping[] }>
  dilutionByCustomer: ILoadingData<IClientGeneralLedgerDilutionByCustomerData>
  dilutionOverTime: ILoadingData<IClientGeneralLedgerDilutionOverTimeData>
}

export default combineReducers<IGeneralLedgerState>({
  loadingArray: loadingArrayReducer(
    [actions.prefix, DOWNLOAD_TEMPLATE_REQUEST.replace('_REQUEST', '')],
    [actions.LIST_TYPE_MAPPING_REQUEST, actions.UPDATE_TYPE_MAPPING_REQUEST],
  ),
  typeOptions(state: { name: string }[] = [], action) {
    switch (action.type) {
      case actions.LIST_TYPES_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  typeMapping: createLoadingDataReducer<{ data: IClientGeneralLedgerMapping[] }>(
    [actions.LIST_TYPE_MAPPING_REQUEST],
    [actions.UPDATE_TYPE_MAPPING_REQUEST],
    false,
    [actions.HIDE_TYPE_MAPPING],
  ),
  dilutionByCustomer: createLoadingDataReducer<IClientGeneralLedgerDilutionByCustomerData>(
    [actions.LIST_DILUTION_BY_CUSTOMER_REQUEST],
    [],
    false,
    [actions.HIDE_DILUTION_BY_CUSTOMER],
  ),
  dilutionOverTime: createLoadingDataReducer<IClientGeneralLedgerDilutionOverTimeData>(
    [actions.LIST_DILUTION_OVER_TIME_REQUEST],
    [],
    false,
    [actions.HIDE_DILUTION_OVER_TIME],
  ),
})
