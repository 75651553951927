import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import MuiTableContainer, { TableContainerProps } from '@mui/material/TableContainer'
import cn from 'classnames'

import styles from './TableContainer.module.scss'
import { debounceEventHandler } from '../../../helpers/helpers'

interface IProps extends TableContainerProps {
  isActivable?: boolean
  hasFooter?: boolean
  rowIdPrefix?: string
  onActiveRowsChange?: (rows: any[]) => void
  onActiveRowChange?: (row: any) => void
  scrollLeft?: boolean
  coupledTables?: string[]
  size?: 'big' | 'small'
}

const TableContainer = ({
  isActivable,
  hasFooter,
  rowIdPrefix = 'mapping-table-row',
  onActiveRowsChange,
  onActiveRowChange,
  size,
  scrollLeft,
  coupledTables,
  ...props
}: IProps) => {
  const tableRef = useRef(null)
  const preventScrollOnKeyPress = useCallback((event: any) => {
    if (
      ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.code) &&
      !document.querySelector('input:focus')
    ) {
      event.preventDefault()
    }
  }, [])

  const lengthOfTableColumns = useMemo(() => {
    if (scrollLeft && tableRef.current) {
      return (
        Array.from(
          tableRef.current?.children?.[0]?.children?.[0]?.children?.[0]?.children || [],
        )?.filter((element: any) => !element.classList.contains('summaryColumn')).length || 0
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollLeft, tableRef.current?.children?.[0]?.children?.[0]?.children?.[0]?.children?.length])

  useEffect(() => {
    if (scrollLeft && lengthOfTableColumns > 0) {
      tableRef.current.scrollLeft = tableRef.current.scrollWidth
    }
  }, [scrollLeft, lengthOfTableColumns, tableRef])

  const handleScroll = useCallback(() => {
    if (tableRef.current && coupledTables) {
      const scrollLeft = tableRef.current.scrollLeft
      coupledTables.forEach((tableId) => {
        const coupledTable = document.getElementById(tableId)
        if (coupledTable) {
          coupledTable.scrollLeft = scrollLeft
        }
      })
    }
  }, [tableRef, coupledTables])

  const handleUserKeyPress = useMemo(
    () =>
      debounceEventHandler((event: any) => {
        const focusedInput = document.querySelector('input:focus')
        let focusedFocusableInput = document.querySelector('.focusableInput input:focus')
        const activeRows = document.querySelectorAll('.activeRow')
        const currentActiveRow = document.querySelector('.currentActiveRow')
        const activableRows = document.querySelectorAll('.activableRow')
        const isInputFocused = !!focusedInput
        if (isInputFocused && !focusedFocusableInput) {
          return
        }
        if (event.code === 'Enter' && !focusedFocusableInput && event.target) {
          focusedFocusableInput = event.target
        }
        if (['ArrowUp', 'ArrowDown'].includes(event.code) && !isInputFocused) {
          event.preventDefault()
          if (!activeRows.length) {
            onActiveRowsChange([0])
            onActiveRowChange(0)
          }
          let firstActiveIndex = +activeRows[0]?.getAttribute('data-index')
          let lastActiveIndex = +activeRows[activeRows.length - 1]?.getAttribute('data-index')
          const currentActiveRowIndex = +currentActiveRow?.getAttribute('data-index')
          if (!event.shiftKey) {
            let newActiveIndex
            if (event.code === 'ArrowUp') {
              if (firstActiveIndex === 0) {
                newActiveIndex = document
                  .querySelector('.activableRow:last-of-type')
                  ?.getAttribute('data-index')
              } else {
                newActiveIndex = document
                  .querySelector(`.activableRow[data-index="${+firstActiveIndex - 1}"]`)
                  ?.getAttribute('data-index')
              }
            } else if (event.code === 'ArrowDown') {
              if (lastActiveIndex === activableRows.length - 1) {
                newActiveIndex = document
                  .querySelector('.activableRow:first-of-type')
                  ?.getAttribute('data-index')
              } else {
                newActiveIndex = document
                  .querySelector(`.activableRow[data-index="${+lastActiveIndex + 1}"]`)
                  ?.getAttribute('data-index')
              }
            }
            if (!newActiveIndex) {
              newActiveIndex = 0
            }
            document
              .getElementById(`${rowIdPrefix}-${newActiveIndex}`)
              .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            onActiveRowsChange([+newActiveIndex])
            onActiveRowChange(+newActiveIndex)
          } else {
            if (event.code === 'ArrowUp') {
              if (currentActiveRowIndex < lastActiveIndex) {
                lastActiveIndex = lastActiveIndex - 1
              } else {
                firstActiveIndex = firstActiveIndex - 1 < 0 ? 0 : firstActiveIndex - 1
              }
            } else if (event.code === 'ArrowDown') {
              if (currentActiveRowIndex > firstActiveIndex) {
                firstActiveIndex = firstActiveIndex + 1
              } else {
                lastActiveIndex =
                  lastActiveIndex + 1 >= activableRows.length
                    ? activableRows.length - 1
                    : lastActiveIndex + 1
              }
            }
            if (!firstActiveIndex) {
              firstActiveIndex = 0
            }
            if (!lastActiveIndex) {
              lastActiveIndex = 0
            }
            document
              .getElementById(
                `${rowIdPrefix}-${event.code === 'ArrowUp' ? firstActiveIndex : lastActiveIndex}`,
              )
              .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            onActiveRowsChange(
              Array(lastActiveIndex - firstActiveIndex + 1)
                .fill(undefined)
                .map((_, idx) => firstActiveIndex + idx),
            )
          }
        } else if (['ArrowLeft', 'ArrowRight', 'Enter'].includes(event.code)) {
          const focusableInputs = document.querySelectorAll('.focusableInput')
          let nextTabIndex = activeRows[0]
            ? activeRows[0].querySelector('.focusableInput')?.getAttribute('tabindex')
            : +focusableInputs[0]?.getAttribute('tabindex')
          if (focusedFocusableInput) {
            const currentTabIndex = +(
              focusedFocusableInput.closest('.focusableInput').getAttribute('tabindex') ||
              focusedFocusableInput.getAttribute('tabindex')
            )
            // @ts-ignore
            const currentFocusableInputIndex = [...focusableInputs].findIndex(
              (element) =>
                +(
                  element.getAttribute('tabindex') ||
                  element.querySelector('input')?.getAttribute('tabindex')
                ) === currentTabIndex,
            )
            if (event.code === 'ArrowLeft') {
              const nextFocusableInput =
                focusableInputs[
                  currentFocusableInputIndex - 1 < 0
                    ? focusableInputs.length - 1
                    : currentFocusableInputIndex - 1
                ]
              nextTabIndex = +(
                nextFocusableInput.getAttribute('tabindex') ||
                nextFocusableInput.querySelector('input')?.getAttribute('tabindex')
              )
            } else {
              const nextFocusableInput =
                focusableInputs[
                  currentFocusableInputIndex + 1 > focusableInputs.length - 1
                    ? 0
                    : currentFocusableInputIndex + 1
                ]
              nextTabIndex = +(
                nextFocusableInput.getAttribute('tabindex') ||
                nextFocusableInput.querySelector('input')?.getAttribute('tabindex')
              )
            }
          }
          const input =
            document.querySelector(
              `.focusableInput[tabindex="${nextTabIndex}"] .MuiInputBase-root`,
            ) || document.querySelector(`.focusableInput[tabindex="${nextTabIndex}"]`)
          if (input) {
            // @ts-ignore
            input.click()
            const newActiveRowIndex = input.closest('.activableRow')?.getAttribute('data-index')
            if (newActiveRowIndex) {
              document
                .getElementById(`${rowIdPrefix}-${newActiveRowIndex}`)
                .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            }
          } else {
            const checkbox = document.querySelector(
              `.focusableInput input[tabindex="${nextTabIndex}"][type="checkbox"]`,
            )
            if (checkbox) {
              // @ts-ignore
              checkbox.focus()
            }
          }
        }
      }, 300),
    [onActiveRowsChange, onActiveRowChange, rowIdPrefix],
  )

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      const { shiftKey } = event
      if (document.querySelector('input:focus')) {
        return
      }

      if (shiftKey) {
        if (window.getSelection) {
          if (window.getSelection().empty) {
            window.getSelection().empty()
          } else if (window.getSelection().removeAllRanges) {
            window.getSelection().removeAllRanges()
          }
        }
      }
    }
    const currentRef = tableRef.current
    // @ts-ignore
    currentRef.addEventListener('keydown', handleKeyPress)
    // @ts-ignore
    currentRef.addEventListener('click', handleKeyPress)
    return () => {
      // @ts-ignore
      currentRef.removeEventListener('keydown', handleKeyPress)
      // @ts-ignore
      currentRef.removeEventListener('click', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    if (isActivable) {
      window.addEventListener('keydown', handleUserKeyPress)
      window.addEventListener('keydown', preventScrollOnKeyPress)
    }

    return () => {
      if (isActivable) {
        window.removeEventListener('keydown', handleUserKeyPress)
        window.removeEventListener('keydown', preventScrollOnKeyPress)
      }
    }
  }, [handleUserKeyPress, preventScrollOnKeyPress, isActivable])

  return (
    <MuiTableContainer
      onScroll={handleScroll}
      ref={tableRef}
      classes={{
        root: cn(
          styles.container,

          {
            [styles.hasFooter]: hasFooter,
            [styles.big]: size === 'big',
            [styles.small]: size === 'small',
          },
        ),
      }}
      {...props}
    />
  )
}

export default TableContainer
