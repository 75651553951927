import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const checksFiles = (state: IRootState) => state[prefix].checksFiles

export const checks = (state: IRootState) => state[prefix].checks

export const checksReconciliation = (state: IRootState) => state[prefix].checksReconciliation

export const checkAccounts = (state: IRootState) => state[prefix].checkAccounts

export const wiresFiles = (state: IRootState) => state[prefix].wiresFiles

export const wiresData = (state: IRootState) => state[prefix].wiresData

export const wiresDataNegative = (state: IRootState) => state[prefix].wiresDataNegative

export const wiresDataDeleted = (state: IRootState) => state[prefix].wiresDataDeleted

export const wiresDataTotals = (state: IRootState) => state[prefix].wiresDataTotals

export const collectionStatus = (state: IRootState) => state[prefix].collectionStatus

export const aggregation = (state: IRootState) => state[prefix].aggregation

export const deletedItems = (state: IRootState) => state[prefix].deletedItems

export const negativeItems = (state: IRootState) => state[prefix].negativeItems
