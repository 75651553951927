import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'

import styles from './BBCInventoryIneligibleTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import Card from '../Common/Card'
import Badge from '@mui/material/Badge'
import produce from 'immer'
import { debounceEventHandler, formatPrice } from '../../helpers/helpers'
import {
  EligibilityStatus,
  IInventoryIneligibility,
  IInventoryIneligibilityData,
  INELIGIBLE_REASONS_LIST,
  IneligibleReasons,
} from '@common/interfaces/bbc'
import Checkbox from '../Common/Checkbox'
import { ReactComponent as EditIcon } from '@assets/images/edit-outlined-icon.svg'
import TableFiltersRow from '../Common/TableFiltersRow'
import { buildFiltersDefaults } from '../../helpers/filters'
import {
  BBC_INVENTORY_INELIGIBILITY_SHORT_LIST_FILTERS_CONFIG,
  BBC_INVENTORY_INELIGIBILITY_LOCATION_SHORT_LIST_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import IconButton from '../Common/IconButton'
import FilterContainer from '../Filters/FilterContainer'
import { ClearIcon, IconWrapper } from '../Common/Icons'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import SelectField from '../Common/SelectField/SelectField'
import { handleMultipleSelect } from '../../helpers/helpers'
import { ExternalLink } from '../Common/Icons'
import { addHttp } from '@common/helpers/helpers'
import TextField from '../Common/TextField'
import AutocompleteField from '../Common/AutocompleteField'
import Button from '../Common/Button'
import Modal from '../Common/Modal'
import InputLabel from '../Common/InputLabel'
import { UploadFile } from '../Common/UploadFile'

const filtersDefaults = buildFiltersDefaults(BBC_INVENTORY_INELIGIBILITY_SHORT_LIST_FILTERS_CONFIG)
const eligibilityOptions = [
  { label: 'Select', value: '', disabled: true },
  { label: 'Yes', value: EligibilityStatus.Eligible },
  { label: 'No', value: EligibilityStatus.Ineligible },
]

const WarehouseWaiverEditModal = ({
  item,
  isLoading,
  onClose,
  onSubmit,
}: {
  item: IInventoryIneligibility
  isLoading: boolean
  onClose: () => void
  onSubmit: (itemId: string, data: File | string) => void
}) => {
  const [openModal, setOpenModal] = useState<boolean>(null)
  const toggleOpenModal = useCallback(() => {
    setOpenModal((isOpen) => !isOpen)
  }, [])
  const handleSubmit = useCallback(
    (values) => {
      const [file] = values.files || []
      onSubmit(item.id, file || values.boxLink || null)
    },
    [item, onSubmit],
  )

  const initialValues = useMemo(
    () => ({
      boxLink: item?.inventoryLocation?.warehouseWaiverLink || '',
      files: null,
    }),
    [item],
  )

  return (
    <Modal open onCancel={onClose} title="Edit warehouse waiver" size="small">
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ pristine, submitting, handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box flex={1}>
                <InputLabel htmlFor="boxLink" className={genericSs.textLeft}>
                  Box link
                </InputLabel>
                <TextField
                  id="boxLink"
                  name="boxLink"
                  placeholder="Box link"
                  InputProps={{
                    type: 'url',
                  }}
                  disabled={!!values.files}
                />
              </Box>

              <Box flex={1}>
                <UploadFile
                  title="Warehouse Waiver"
                  size="lg"
                  files={values.files}
                  onDropAccepted={(loadedFiles: File[]) => {
                    form.change('files', loadedFiles)
                  }}
                  isModalOpen={openModal}
                  handleToggleModal={toggleOpenModal}
                  onDelete={() => {
                    form.change('files', null)
                  }}
                  maxFiles={1}
                />
              </Box>
            </Box>

            <Box mt={5}>
              <Button
                type="submit"
                fullWidth
                small={false}
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={(pristine || submitting) && !values.files}
                isLoading={submitting || isLoading}
              >
                Update
              </Button>
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

interface IProps {
  fieldId: string
  field: string
  title: string
  listInventoryIneligibleCategories: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
    },
  ) => Promise<{ data: IInventoryIneligibilityData }>
  listInventoryCategories: (
    id: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
    },
  ) => Promise<{ data: IInventoryIneligibilityData }>
  updateInventoryIneligibleCategories: (id: string, itemId: string, data: object) => Promise<any>
  updateFields: (data: object) => Promise<any>
  disabled: boolean
  handleDelete: (id: string) => void
  handleEdit: (id: string) => void
  isEligibleTable?: boolean
  refreshCounter?: number
  ineligibleReason: IneligibleReasons
}

const BBCInventoryIneligibleTable = ({
  fieldId,
  field,
  title,
  listInventoryIneligibleCategories,
  listInventoryCategories,
  updateInventoryIneligibleCategories,
  disabled,
  handleDelete,
  handleEdit,
  isEligibleTable = false,
  refreshCounter,
  ineligibleReason,
  updateFields,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [orderBy, setOrderBy] = useState({
    field: 'eligibility',
    direction: 'DESC',
  })
  const [filters, setFilters] = useState(filtersDefaults)
  const [result, setResult] = useState(null as IInventoryIneligibilityData)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const [selectedIneligibleReason, setSelectedIneligibleReason] = useState(ineligibleReason)
  const saveIntervalRef = useRef(null)
  const [warehouseWaiverModalItem, setWarehouseWaiverModalItem] =
    useState<IInventoryIneligibility>(null)

  const onSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems),
    [activeItems],
  )

  const itemsCount = useMemo(() => result?.data.length, [result])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  // tslint:disable-next-line:no-shadowed-variable
  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const fetchInventoryIneligibleList = useCallback(
    async (data: any) => {
      const params = {
        ...data,
        filters: {
          field,
          ...data.filters,
        },
        perPage: PER_PAGE,
      }
      !data.loadMore && !data.skipLoader && setIsLoading(true)
      const res = isEligibleTable
        ? await listInventoryCategories(id, params)
        : await listInventoryIneligibleCategories(id, params)
      if (!data.loadMore) {
        setResult(res.data)
      } else {
        setResult(
          produce((draft) => {
            draft.data.push(...res.data.data)
          }),
        )
      }
      setIsLoading(false)
    },
    [id, field, listInventoryIneligibleCategories, listInventoryCategories, isEligibleTable],
  )

  const debounceInventoryIneligibleList = useMemo(
    () => debounceEventHandler(fetchInventoryIneligibleList, 500),
    [fetchInventoryIneligibleList],
  )

  useEffect(() => {
    debounceInventoryIneligibleList({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceInventoryIneligibleList])

  const refetchInventoryIneligibleList = useCallback(
    (skipLoader: boolean = false) => {
      fetchInventoryIneligibleList({
        page: 0,
        perPage: itemsCount,
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        skipLoader,
      })
    },
    [itemsCount, filters, orderBy, fetchInventoryIneligibleList],
  )

  const activeIds = useMemo(
    () => result?.data?.filter((item, index) => activeItems.includes(index)).map((item) => item.id),
    [activeItems, result],
  )

  const isCustom = useMemo(() => field.includes('custom_'), [field])

  const handleUpdateInventoryIneligible = useCallback(
    async (itemId: string, value: string) => {
      setIsSaving(true)
      setIsSaved(false)
      const result = await updateInventoryIneligibleCategories(id, itemId, {
        eligibility: value,
        itemId: activeIds.length > 1 ? activeIds : [itemId],
      })
      !result?.error && setIsSaved(true)
      setIsSaving(false)
      refetchInventoryIneligibleList(true)
    },
    [id, updateInventoryIneligibleCategories, refetchInventoryIneligibleList, activeIds],
  )

  const handleUpdateInventoryLocationForeign = useCallback(
    async (itemId: string, value: boolean) => {
      setIsSaving(true)
      await updateInventoryIneligibleCategories(id, itemId, {
        isCanadaMexicoLocation: value,
        itemId: activeIds.length > 1 ? activeIds : [itemId],
      })
      setIsSaving(false)
      refetchInventoryIneligibleList(true)
    },
    [id, updateInventoryIneligibleCategories, refetchInventoryIneligibleList, activeIds],
  )

  const handleUpdateInventoryLocationMappedLocation = useCallback(
    async (itemId: string, value: string) => {
      setIsSaving(true)
      await updateInventoryIneligibleCategories(id, itemId, {
        itemId: activeIds.length > 1 ? activeIds : [itemId],
        inventoryLocation: {
          mappedLocation: value,
        },
      })
      setIsSaving(false)
      refetchInventoryIneligibleList(true)
    },
    [id, updateInventoryIneligibleCategories, refetchInventoryIneligibleList, activeIds],
  )

  const handleUpdateInventoryLocationEntityName = useCallback(
    async (itemId: string, value: string) => {
      setIsSaving(true)
      await updateInventoryIneligibleCategories(id, itemId, {
        itemId: activeIds.length > 1 ? activeIds : [itemId],
        inventoryLocation: {
          entityName: value,
        },
      })
      setIsSaving(false)
      refetchInventoryIneligibleList(true)
    },
    [id, updateInventoryIneligibleCategories, refetchInventoryIneligibleList, activeIds],
  )

  const handleChangeInventoryLocationMappedLocation = useCallback(
    (itemId: string, value: string) => {
      setResult((result) => ({
        ...result,
        data: result.data.map((item) =>
          item.id === itemId
            ? {
                ...item,
                inventoryLocation: {
                  ...item.inventoryLocation,
                  mappedLocation: value,
                },
              }
            : item,
        ),
      }))

      if (saveIntervalRef.current) {
        clearTimeout(saveIntervalRef.current)
      }

      saveIntervalRef.current = setTimeout(() => {
        handleUpdateInventoryLocationMappedLocation(itemId, value)
      }, 500)

      return () => {
        clearTimeout(saveIntervalRef.current)
      }
    },
    [handleUpdateInventoryLocationMappedLocation],
  )

  const handleUpdateInventoryLocationWarehouseWaiver = useCallback(
    async (itemId: string, data: File | string) => {
      setIsSaving(true)
      if (data instanceof File) {
        const formData = new FormData()
        if (activeIds.length > 1) {
          activeIds.forEach((item) => {
            formData.append('itemId', item)
          })
        } else {
          formData.append('itemId[]', itemId)
        }
        formData.append('files', data, data.name)
        await updateInventoryIneligibleCategories(id, itemId, formData)
      } else {
        await updateInventoryIneligibleCategories(id, itemId, {
          itemId: activeIds.length > 1 ? activeIds : [itemId],
          inventoryLocation: {
            warehouseWaiverLink: data,
          },
        })
      }
      setIsSaving(false)
      refetchInventoryIneligibleList(true)
      setWarehouseWaiverModalItem(null)
    },
    [id, updateInventoryIneligibleCategories, refetchInventoryIneligibleList, activeIds],
  )

  const loadMore = useCallback(() => {
    fetchInventoryIneligibleList({
      loadMore: true,
      page: Math.ceil(result?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [result, orderBy, filters, fetchInventoryIneligibleList])

  const filtersConfig = useMemo(() => {
    let filterConfig = BBC_INVENTORY_INELIGIBILITY_SHORT_LIST_FILTERS_CONFIG
    if (field === 'location') {
      filterConfig = BBC_INVENTORY_INELIGIBILITY_LOCATION_SHORT_LIST_FILTERS_CONFIG
    }
    return filterConfig.map((filter) => ({
      ...filter,
      title: filter.field === 'value' ? title : filter.title,
    }))
  }, [field, title])

  const onEdit = useCallback(() => {
    handleEdit(field)
  }, [handleEdit, field])

  const onDelete = useCallback(() => {
    handleDelete(field)
  }, [handleDelete, field])

  const handleSelectRow = useCallback((event, index) => onSelectRow(event, index), [onSelectRow])

  const vendors = useMemo(
    () =>
      (result?.vendors || []).map((vendor) => ({
        value: vendor,
        label: vendor,
      })),
    [result],
  )

  const openWarehouseWaiverModal = useCallback((item: IInventoryIneligibility) => {
    setWarehouseWaiverModalItem(item)
  }, [])

  const handleReasonChange = useCallback(
    async (event) => {
      await updateFields({ id: fieldId, ineligibleReason: event.target.value })
      setSelectedIneligibleReason(event.target.value)
    },
    [updateFields, fieldId],
  )

  const closeWarehouseWaiverModal = useCallback(() => {
    setWarehouseWaiverModalItem(null)
  }, [])

  useEffect(() => {
    if (refreshCounter) {
      refetchInventoryIneligibleList(true)
    }
  }, [refreshCounter, refetchInventoryIneligibleList])

  return (
    <Card noHeaderMargin className={styles.ineligbleFieldsCard}>
      {!disabled && (
        <Box className={styles.deleteBadge}>
          <Badge color="primary">
            <ClearIcon action={onDelete} title="Delete" />
          </Badge>
        </Box>
      )}
      <TableContainer
        className={cn(styles.table, {
          [styles.tableLocation]: field === 'location',
        })}
      >
        <Form
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([filterField, value], state, { changeValue }) => {
              changeValue(state, filterField, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              smallFilter
              title={
                <Box pr={1}>
                  <h2>{title} </h2>
                </Box>
              }
              actions={
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                  {isCustom && (
                    <div className={styles.fieldsList}>
                      <SelectField
                        name="ineligibleReason"
                        className={styles.fieldsList}
                        options={INELIGIBLE_REASONS_LIST}
                        value={selectedIneligibleReason}
                        useFinalForm={false}
                        onChange={handleReasonChange}
                        key="ineligibilityReasonInput"
                        withTopLabel
                        title=""
                        label="Ineligibility Reason"
                        variant="outlined"
                      />
                    </div>
                  )}
                </Box>
              }
              editTitle={
                !disabled &&
                isCustom && (
                  <div className={styles.dashboardBlockTitle}>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      className={styles.dashboardBlockContentIconEdit}
                      onClick={onEdit}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )
              }
              actionsSize={2}
              size="small"
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id={`scrollableTable-${field}`}>
            {isLoading ? (
              <TableLoader columnsCount={field === 'location' ? 3 : 2} height={36} />
            ) : (
              <InfiniteScroll
                dataLength={result?.data.length || 0}
                next={loadMore}
                hasMore={result?.data.length < result?.totals.totalItems}
                loader={
                  <TableLoader
                    columnsCount={field === 'location' ? 3 : 2}
                    height={36}
                    rowsCount={1}
                  />
                }
                scrollableTarget={`scrollableTable-${field}`}
              >
                {result?.data.map((item, index) => {
                  const isCurrentActiveRow = activeItem === index
                  const isActiveRow = activeItems.includes(index)

                  return (
                    <TableRow
                      key={item.id}
                      className={cn('activableRow', {
                        activeRow: isActiveRow,
                        currentActiveRow: isCurrentActiveRow,
                        [styles.newRow]: item.isNew,
                      })}
                      data-index={index}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      {field === 'location' ? (
                        <>
                          <TableCell className={genericSs.tableTextLeft}>{item.value}</TableCell>
                          <TableCell
                            className={cn(genericSs.tableTextRight, genericSs.pricePrefix)}
                          >
                            $ {formatPrice(item.totalValue)}
                          </TableCell>
                          <TableCell className={genericSs.textLeft}>
                            <SelectField
                              useFinalForm={false}
                              name="eligibility"
                              options={eligibilityOptions}
                              value={item.eligibility}
                              onChange={(event) =>
                                handleUpdateInventoryIneligible(item.id, event.target.value)
                              }
                              disabled={disabled}
                            />
                          </TableCell>

                          <TableCell className={genericSs.tableTextLeft}>
                            <TextField
                              useFinalForm={false}
                              name="mappedLocation"
                              placeholder="Warehouse"
                              value={item.inventoryLocation?.mappedLocation}
                              disabled={disabled}
                              onChange={(event) =>
                                handleChangeInventoryLocationMappedLocation(
                                  item.id,
                                  event.target.value,
                                )
                              }
                            />
                          </TableCell>

                          <TableCell className={genericSs.tableTextLeft}>
                            <AutocompleteField
                              name="entityName"
                              options={vendors}
                              style={{ width: '100%' }}
                              disabled={disabled}
                              value={
                                item.inventoryLocation?.entityName
                                  ? {
                                      value: item.inventoryLocation.entityName,
                                      label: item.inventoryLocation.entityName,
                                    }
                                  : null
                              }
                              onChange={(event, newValue) => {
                                handleUpdateInventoryLocationEntityName(
                                  item.id,
                                  // @ts-ignore
                                  newValue?.value || null,
                                )
                              }}
                              size="small"
                            />
                          </TableCell>

                          <TableCell className={genericSs.textLeft}>
                            <Checkbox
                              checked={item.isCanadaMexicoLocation}
                              color="primary"
                              disabled={disabled}
                              onChange={(event, checked) =>
                                handleUpdateInventoryLocationForeign(item.id, checked)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Box display="flex" gap={2}>
                              {item.inventoryLocation?.warehouseWaiverLink && (
                                <ExternalLink
                                  title={'Warehouse Waiver Link'}
                                  link={addHttp(item.inventoryLocation?.warehouseWaiverLink)}
                                  useOnClick
                                />
                              )}
                              {!disabled && (
                                <IconWrapper
                                  title="Edit"
                                  action={() => openWarehouseWaiverModal(item)}
                                >
                                  <EditIcon />
                                </IconWrapper>
                              )}
                            </Box>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell className={genericSs.tableTextLeft}>{item.value}</TableCell>
                          <TableCell
                            className={cn(genericSs.tableTextRight, genericSs.pricePrefix)}
                          >
                            $ {formatPrice(item.totalValue)}
                          </TableCell>
                          <TableCell className={genericSs.textLeft}>
                            <SelectField
                              useFinalForm={false}
                              name="eligibility"
                              options={eligibilityOptions}
                              value={item.eligibility}
                              onChange={(event) =>
                                handleUpdateInventoryIneligible(item.id, event.target.value)
                              }
                              disabled={disabled}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  )
                })}
              </InfiniteScroll>
            )}
          </TableBody>
        </Table>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <SaveState isSaving={isSaving} isSaved={isSaved} />
        </Box>

        {warehouseWaiverModalItem && (
          <WarehouseWaiverEditModal
            item={warehouseWaiverModalItem}
            isLoading={isSaving}
            onClose={closeWarehouseWaiverModal}
            onSubmit={handleUpdateInventoryLocationWarehouseWaiver}
          />
        )}
      </TableContainer>
    </Card>
  )
}

export default BBCInventoryIneligibleTable
