import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Card from '../../components/Common/Card'
import Grid from '@mui/material/Grid'
const SubmitReportingSectionItem = () => {
  return (
    <Grid item xs={12}>
      <Skeleton height={80} width={500} />
      <Skeleton height={20} width={150} />
    </Grid>
  )
}

const SubmitReportingSection = () => {
  return (
    <Grid item xs={6}>
      <Card>
        <Grid item container xs={12} spacing={3} mb={2.5}>
          <Grid item xs={12}>
            <Skeleton width={200} height={36} />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <SubmitReportingSectionItem />
          <SubmitReportingSectionItem />
          <SubmitReportingSectionItem />
        </Grid>
      </Card>
    </Grid>
  )
}
const SubmitReportingPageLoader = () => {
  return (
    <Grid container xs={12} spacing={3} mt={4.5}>
      <SubmitReportingSection />
      <SubmitReportingSection />
      <Grid item xs={12}>
        <Skeleton height={80} width={200} />
      </Grid>
    </Grid>
  )
}

export default SubmitReportingPageLoader
