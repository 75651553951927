import React from 'react'
import KeyboardDatePicker from '../KeyboardDatePicker'
import { DATE_FORMAT } from '../../../constants/common'
import moment from 'moment'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '../TextField'
import CurrencyField from '../CurrencyField'
import SelectField from '../SelectField'
import Checkbox from '../Checkbox'
import { IField } from '@common/interfaces/client'
import InputLabel from '../InputLabel'
import genericSs from '@styles/generic.module.scss'

const today = moment().startOf('day').toDate()

const FormField = ({
  label,
  name,
  type,
  disabled,
  options,
  placeholder,
  size = 'big',
  minDate = today,
  maxDate = null,
  labelSize = 'small',
  multiple = false,
  withClear = false,
  className,
  rows,
}: IField & {
  labelSize?: 'small' | 'big'
  multiple?: boolean
  withClear?: boolean
  rows?: number
}) => {
  if (!name) return null
  let component = null

  const baseProps = {
    id: name,
    name,
    disabled,
    placeholder,
    className,
  }

  switch (type) {
    case 'currency':
      component = <CurrencyField id={name} name={name} disabled={disabled} size={size} />
      break
    case 'percent':
    case 'number':
      if (options) {
        component = <SelectField {...baseProps} options={options} selectSize={size} />
      } else {
        component = (
          <TextField
            {...baseProps}
            placeholder={placeholder}
            size={size}
            InputProps={{
              startAdornment: type === 'number' && (
                <InputAdornment position="start">#</InputAdornment>
              ),
              endAdornment: type === 'percent' && <InputAdornment position="end">%</InputAdornment>,
              type: 'number',
            }}
          />
        )
      }
      break
    case 'date':
      component = (
        <KeyboardDatePicker
          {...baseProps}
          inputFormat={DATE_FORMAT}
          minDate={minDate}
          size={size}
          placeholder={placeholder}
          maxDate={maxDate}
        />
      )
      break
    case 'select':
      component = (
        <SelectField
          {...baseProps}
          options={options}
          selectSize={size}
          multiple={multiple}
          withClear={withClear}
        />
      )
      break
    case 'boolean':
      component = <Checkbox {...baseProps} color="primary" reactFinalForm />
      break
    case 'text':
      component = (
        <TextField {...baseProps} size={size} rows={rows} multiline={rows ? true : false} />
      )
      break
    default:
      component = <div>Invalid type</div>
      break
  }

  return (
    <>
      {label &&
        (labelSize === 'small' ? (
          <h4 className={genericSs.textLeft}>{label}</h4>
        ) : (
          <InputLabel htmlFor={name} className={genericSs.textLeft}>
            {label}
          </InputLabel>
        ))}
      {component}
    </>
  )
}

export default FormField
