import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ClientDashboardHeader from './ClientDashboardHeader'

import { captureClick } from '../../redux/newsfeed/actions'
import { clientInfo } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  clientInfo,
})
const actions = {
  captureClick,
}

export default connect(selector, actions)(ClientDashboardHeader)
