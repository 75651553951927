import React, { useRef } from 'react'
import { useParams } from 'react-router'
import { FormApi } from 'final-form'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import styles from './EntitySettingsAliasMapping.module.scss'

import Card from '../../components/Common/Card'
import EntitySettingsHeader from '../../components/EntitySettingsHeader'
import ClientAliasMapping from '../../components/ClientAliasMapping'
import EntityAccountMapping from '../../components/EntityAccountMapping'

const EntitySettingsAliasMapping = () => {
  const { id: entityId } = useParams<{ id?: string }>()
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)

  return (
    <Box py={1} pr={2}>
      <EntitySettingsHeader />
      <Grid container spacing={2}>
        <Grid item xs={12} mx={3.75}>
          <Card title="Mapping Aliases">
            <ClientAliasMapping
              id={entityId}
              table="aliases"
              formRef={formRef}
              isNotModal
              noPadding
              tableClassName={styles.aliasTable}
            />
          </Card>
        </Grid>
        <Grid item xs={12} mx={3.75}>
          <Card title="Entity Bank Accounts">
            <EntityAccountMapping />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EntitySettingsAliasMapping
