import React, { useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import { Form, FormSpy } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import moment from 'moment'

import styles from './CompanyInfoForm.module.scss'

import { IOPSReporting } from '@common/interfaces/prospects'
import {
  DATE_DATABASE_FORMAT,
  FEDERAL_TAX_VALIDATE_REGEXP,
  PHONE_VALIDATE_REGEXP,
  STATES,
  YEAR_FORMAT,
} from '../../../constants/common'
import InputLabel from '../../Common/InputLabel'
import TextField from '../../Common/TextField'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import SelectField from '../../Common/SelectField'
import AutocompletePlace from '../../Common/AutocompletePlace'
import Button from '../../Common/Button'
import { PhoneMaskInput, FederalTaxIdMaskInput } from '../../Common/MaskInput'

const schema = Yup.object().shape({
  fullLegalName: Yup.string(),
  employeeCount: Yup.number()
    .nullable(true)
    .typeError('Invalid # of employees')
    .min(0, 'Invalid # of employees'),
  federalTaxId: Yup.string()
    .nullable(true)
    .matches(FEDERAL_TAX_VALIDATE_REGEXP, 'Not a valid federal tax id'),
  phone: Yup.string().nullable(true).matches(PHONE_VALIDATE_REGEXP, 'Not a valid phone number'),
})
const validate = makeValidate(schema)
const STATES_OPTIONS = Object.values(STATES).map((state: string) => ({
  value: state,
  label: state,
}))

const mutators = {
  setFieldData: ([field, value]: any, state: any, { changeValue }: any) => {
    changeValue(state, field, () => value)
  },
}

interface IProps {
  isSaving: boolean
  opsReporting: IOPSReporting
  handleSubmit: (data: any) => void
  setIsDirty: (dirty: boolean) => void
}

const today = new Date()

const CompanyInfoForm = ({ isSaving, opsReporting, handleSubmit, setIsDirty }: IProps) => {
  const phoneMaskProps = useMemo(() => ({ inputComponent: PhoneMaskInput() }), [])
  const federalTaxIdMaskProps = useMemo(() => ({ inputComponent: FederalTaxIdMaskInput() }), [])
  const initialValues = useMemo(
    () => ({
      fullLegalName: opsReporting?.clientInfo.fullLegalName || '',
      employeeCount: opsReporting?.clientInfo.employeeCount || null,
      address: opsReporting?.clientInfo.billingCountry
        ? {
            label: `${opsReporting?.clientInfo.billingStreet}, ${opsReporting?.clientInfo.billingCity}, ${opsReporting?.clientInfo.billingState} ${opsReporting?.clientInfo.billingPostalCode}, ${opsReporting?.clientInfo.billingCountry}`,
          }
        : null,
      billingAddress: {
        country: opsReporting?.clientInfo.billingCountry,
        state: opsReporting?.clientInfo.billingState,
        city: opsReporting?.clientInfo.billingCity,
        street: opsReporting?.clientInfo.billingStreet,
        postalCode: opsReporting?.clientInfo.billingPostalCode,
      },
      establishedDate: opsReporting?.clientInfo?.establishedDate || null,
      phone: opsReporting?.clientInfo?.phone || null,
      stateOfFormation: opsReporting?.clientInfo?.stateOfFormation || null,
      federalTaxId: opsReporting?.clientInfo?.federalTaxId || null,
    }),
    [opsReporting],
  )

  const onSubmit = useCallback(
    (data: any) => {
      const { address, billingAddress, establishedDate, federalTaxId, ...rest } = data

      handleSubmit({
        establishedDate: establishedDate
          ? moment(establishedDate).startOf('year').format(DATE_DATABASE_FORMAT)
          : null,
        billingCountry: billingAddress?.country || '',
        billingState: billingAddress?.state || '',
        billingCity: billingAddress?.city || '',
        billingPostalCode: billingAddress?.postalCode || '',
        billingStreet: billingAddress?.street || '',
        federalTaxId: federalTaxId ? federalTaxId.replaceAll('-', '') : null,
        ...rest,
      })
    },
    [handleSubmit],
  )

  const onFormChange = useCallback(
    (props: any) => {
      setIsDirty(props.dirty)
    },
    [setIsDirty],
  )

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={mutators}
      render={(props) => (
        <form className={styles.formWrapper}>
          <div className={styles.formContent}>
            <Box>
              <InputLabel className={styles.inputLabel}>Legal company name</InputLabel>
              <TextField
                name="fullLegalName"
                placeholder="E.g., Main Street LLC."
                size="big"
                className={styles.inputField}
              />
            </Box>
            <Box>
              <InputLabel className={styles.inputLabel}># of employees</InputLabel>
              <TextField
                name="employeeCount"
                type="number"
                placeholder="2001"
                size="big"
                className={styles.inputField}
              />
            </Box>
            <Box>
              <InputLabel className={styles.inputLabel}>HQ address</InputLabel>
              <AutocompletePlace
                label=""
                name="address"
                placeholder="E.g., 123 Spring Street, New York, NY..."
                options={[]}
                className={styles.autocompleteField}
                onSelect={(address: any) =>
                  props.form.mutators.setFieldData('billingAddress', address)
                }
              />
            </Box>
            <Box>
              <InputLabel className={styles.inputLabel}>Year founded</InputLabel>
              <KeyboardDatePicker
                className={styles.datePickerField}
                name="establishedDate"
                inputFormat={YEAR_FORMAT}
                maxDate={today}
                openTo="year"
                views={['year']}
                placeholder="E.g., 2000"
              />
            </Box>
            <Box>
              <InputLabel className={styles.inputLabel}>Company phone number</InputLabel>
              <TextField
                name="phone"
                InputProps={phoneMaskProps}
                placeholder="E.g., (123) 456-7890"
                size="big"
                className={styles.inputField}
              />
            </Box>
            <Box>
              <InputLabel className={styles.inputLabel}>State of formation</InputLabel>
              <SelectField
                name="stateOfFormation"
                options={STATES_OPTIONS}
                placeholder="Select state"
                className={styles.selectField}
              />
            </Box>
            <Box>
              <InputLabel className={styles.inputLabel}>Federal tax ID</InputLabel>
              <TextField
                name="federalTaxId"
                InputProps={federalTaxIdMaskProps}
                placeholder="E.g., 12-3456789"
                size="big"
                className={styles.inputField}
              />
            </Box>
          </div>

          <div className={styles.buttonDivider} />

          <div className={styles.buttonWrapper}>
            <div />
            <Button
              isLoading={isSaving}
              className={styles.button}
              variant="contained"
              disabled={props.submitting || props.invalid}
              onClick={props.handleSubmit}
            >
              Next
            </Button>
          </div>
          <FormSpy subscription={{ dirty: true }} onChange={onFormChange} />
        </form>
      )}
    />
  )
}

export default CompanyInfoForm
