import { UserRole } from './../../../../common/interfaces/user'
import { Action } from 'redux'
import { History } from 'history'
import { takeEvery, select, put } from 'redux-saga/effects'
import { generatePath, matchPath } from 'react-router-dom'
import { ExportToCsv } from 'export-to-csv-fix-source-map'

import { IApi } from '../../api'
import { ROUTES } from '../../constants/routes'
import {
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  EXPORT_AGGREGATION_REQUEST,
  EXPORT_AGGREGATION_SUCCESS,
  EXPORT_AGGREGATION_FAILURE,
  EXPORT_CLIENT_FEE_STATEMENT_SUCCESS,
  EXPORT_CLIENT_FEE_STATEMENT_FAILURE,
  CREATE_CLIENT_OVERADVANCE_SUCCESS,
  UPDATE_CLIENT_OVERADVANCE_SUCCESS,
  DELETE_CLIENT_OVERADVANCE_SUCCESS,
  CREATE_REQUIRED_REPORTS_SUCCESS,
  REQUEST_FUNDING_SUCCESS,
  MODIFY_FUNDING_SUCCESS,
  CANCEL_FUNDING_SUCCESS,
  TERMINATE_SUCCESS,
  SUBMIT_ONGOING_REPORTING_SUCCESS,
  listOveradvances,
  show,
  EXPORT_PORTFOLIO_REVIEW_REQUEST,
  EXPORT_PORTFOLIO_REVIEW_FAILURE,
  EXPORT_PORTFOLIO_REVIEW_SUCCESS,
  UPDATE_TERMS_SUCCESS,
  EXPORT_MASTER_INVENTORY_SUCCESS,
  EXPORT_MASTER_INVENTORY_FAILURE,
  EXPORT_RISK_RATING_DETAILS_SUCCESS,
  EXPORT_RISK_RATING_DETAILS_FAILURE,
} from './actions'
import { IResponse } from '@common/interfaces/request'
import { clientInfo } from './selectors'
import { listRequiredReports } from '../requiredReport/actions'
import { download, dateToString, formatDate, formatPrice } from '../../helpers/helpers'
import { reloadClientInfo } from '../profile/actions'
import { pathname } from '../router/selectors'
import { clientInfo as profileClientInfo, role } from '../profile/selectors'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import moment from 'moment'

export function* createSuccess(history: History) {
  yield takeEvery(CREATE_SUCCESS, function* (data: IResponse & Action) {
    yield history.push(generatePath(ROUTES.CLIENT_SETUP, { id: data.data.id }))
  })
}

export function* updateSuccess() {
  yield takeEvery(UPDATE_SUCCESS, function* (data: IResponse & Action) {
    const { id: clientId } = yield select(clientInfo)
    const path: string = yield select(pathname)
    if (matchPath(path, { path: ROUTES.CLIENT_PAGE })) {
      yield put(listRequiredReports(clientId))
    }
  })
}

export function* exportClientAggregation(api: IApi) {
  yield takeEvery(EXPORT_AGGREGATION_REQUEST, function* (action: Action) {
    try {
      const path: string = yield select(pathname)
      const info: IClientInfo = yield select(clientInfo)
      const profileInfo: IClientInfo = yield select(profileClientInfo)
      const { clientName } = matchPath(path, { path: ROUTES.CLIENT_PAGE }) ? info : profileInfo
      // @ts-ignore
      const { clientId, params } = action
      let dateFrom = params.filters.recordDateFrom
      let dateTo = params.filters.recordDateTo

      params.exportAggregation = true
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }

      const { data, accruedInterestData } = yield api.clientInfo.listAggregation(clientId, params)
      if ((!dateTo || !dateFrom) && data.length > 0) {
        const dates = data
          .map(({ record_date }: { record_date: string }) => record_date)
          .sort((first: string, second: string) => +new Date(first) - +new Date(second))

        if (!dateFrom) {
          dateFrom = dates[0]
        }
        if (!dateTo) {
          dateTo = dates[dates.length - 1]
        }
      }

      dateFrom = dateFrom ? formatDate(dateFrom) : ''
      dateTo = dateTo ? formatDate(dateTo) : ''
      const title = `${clientName}.Account_Activity.${dateFrom}-${dateTo}`
      const csvExporter = new ExportToCsv({
        showLabels: true,
        showTitle: true,
        filename: title,
        title,
        headers: ['Date', 'Type', '"Amount"', 'ABL Loan Balance', 'Description', 'Original Memo'],
      })

      const rows =
        data.length > 0
          ? data.map(
              ({ type, amount, loanBalanceEnding, description, recordDate, originalMemo }: any) => [
                recordDate ? formatDate(recordDate) : '',
                type,
                amount ? formatPrice(amount) : '',
                loanBalanceEnding ? formatPrice(loanBalanceEnding) : '',
                description || '',
                originalMemo || '',
              ],
            )
          : [
              {
                recordDate: '',
                type: '',
                amount: '',
                loanBalanceEnding: '',
                description: '',
                originalMemo: '',
              },
            ]

      rows.push(['', '', '', '', ''])

      rows.push([
        accruedInterestData?.recordDate || '',
        accruedInterestData?.type,
        accruedInterestData?.amount ? formatPrice(accruedInterestData?.amount) : '',
        '',
        '',
      ])

      csvExporter.generateCsv(rows)

      yield put({ type: EXPORT_AGGREGATION_SUCCESS })
    } catch (error) {
      yield put({ type: EXPORT_AGGREGATION_FAILURE, error })
    }
  })
}

export function* exportClientFeeStatement() {
  yield takeEvery(EXPORT_CLIENT_FEE_STATEMENT_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_CLIENT_FEE_STATEMENT_FAILURE, error })
    }
  })
}

export function* loadOveradvances() {
  yield takeEvery(
    [
      CREATE_CLIENT_OVERADVANCE_SUCCESS,
      UPDATE_CLIENT_OVERADVANCE_SUCCESS,
      DELETE_CLIENT_OVERADVANCE_SUCCESS,
    ],
    function* () {
      const info: IClientInfo = yield select(clientInfo)
      const { id: clientId } = info

      yield put(listOveradvances(clientId))
    },
  )
}

export function* createRequireReportSuccess() {
  yield takeEvery(CREATE_REQUIRED_REPORTS_SUCCESS, function* () {
    yield put(reloadClientInfo())
  })
}

export function* repullClientInfo() {
  yield takeEvery(
    [
      REQUEST_FUNDING_SUCCESS,
      SUBMIT_ONGOING_REPORTING_SUCCESS,
      MODIFY_FUNDING_SUCCESS,
      CANCEL_FUNDING_SUCCESS,
      UPDATE_TERMS_SUCCESS,
    ],
    function* () {
      const userRole: UserRole = yield select(role)
      if (userRole === UserRole.CLIENT_USER) {
        yield put(reloadClientInfo())
      } else {
        const info: IClientInfo = yield select(clientInfo)
        const { id: clientId } = info
        yield put(show(clientId))
      }
    },
  )
}

export function* terminateSuccess(history: History) {
  yield takeEvery(TERMINATE_SUCCESS, function* (data: IResponse & Action) {
    const { id: clientId } = yield select(clientInfo)
    if (data.data.clientStatus === ClientInfoStatus.Past) {
      yield history.push(generatePath(ROUTES.CLIENT_PAGE, { id: clientId }))
    }
  })
}

export function* exportPortfolioReview(api: IApi) {
  yield takeEvery(EXPORT_PORTFOLIO_REVIEW_REQUEST, function* () {
    try {
      const userRole: UserRole = yield select(role)
      let data: Blob
      if (userRole === UserRole.PARTICIPANT_USER) {
        data = yield api.clientInfo.exportParticipantPortfolioReview()
      } else {
        data = yield api.clientInfo.exportPortfolioReview()
      }
      const date = moment().format('YYYY-MM-DD')

      download(data, `Portfolio Review as of ${date}.xlsx`)

      yield put({ type: EXPORT_PORTFOLIO_REVIEW_SUCCESS })
    } catch (error) {
      yield put({ type: EXPORT_PORTFOLIO_REVIEW_FAILURE, error })
    }
  })
}

export function* exportRiskRatingSummary() {
  yield takeEvery(EXPORT_RISK_RATING_DETAILS_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_RISK_RATING_DETAILS_FAILURE, error })
    }
  })
}

export function* exportMasterInventory() {
  yield takeEvery(EXPORT_MASTER_INVENTORY_SUCCESS, function* (action: IResponse & Action) {
    try {
      yield download(action.data, action.data.name)
    } catch (error) {
      yield put({ type: EXPORT_MASTER_INVENTORY_FAILURE, error })
    }
  })
}
