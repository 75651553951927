import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import { Link, generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'
import { ReactComponent as EmptyIcon } from '@assets/images/empty-page-icon.svg'
import styles from './CapTableSummary.module.scss'
import genericSs from '@styles/generic.module.scss'
import { formatPriceConditionalDecimal, formatPercent, formatDate } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IFilter } from '@common/constants/filters'
import { Form } from 'react-final-form'
import useTable from '../../hooks/useTable'
import FilterContainer from '../Filters/FilterContainer'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Card from '../Common/Card'
import { ExpandAndMinimize } from '../Common/Icons'
import { EditMapping } from '../Common/Icons/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import SelectField from '../Common/SelectField'
import MultiCellTooltip from '../MultiCellTooltip'
import { ICapTableSummaryData, ICapTableSummary } from '@common/interfaces/capTable'
import { IClientInfo } from '@common/interfaces/client'
import { ILoadingData } from '../../redux/types'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { EditSourceData, ExternalLink } from '../../components/Common/Icons/Icons'
import { usePermissions } from '../../helpers/permissionContext'
import {
  CAP_TABLE_SUMMARY_TITLE_FILTERS_CONFIG,
  CAP_TABLE_SUMMARY_DATA_FILTERS_CONFIG,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { IFile } from '@common/interfaces/box'

const COUPLED_TABLES = ['capTableSummmary', 'capTableSummmaryActiveToolbar']

interface IRowProps {
  activeCells: number[][]
  activeRows: number[]
  rowIndex: number
  onSelectRow: (event: any, rowIndex: number) => void
  item?: ICapTableSummary
  headers?: string[]
}

const CapTableSummaryCell = ({
  data,
  cellIndex,
  isSelected,
  isActive,
}: {
  data: any
  rowIndex: number
  cellIndex: number
  isSelected: boolean
  isActive: boolean
}) => {
  return (
    <TableCell
      className={cn(genericSs.tableTextRight, 'activableCell', {
        activeCell: isSelected,
      })}
      data-index={cellIndex}
    >
      <MultiCellTooltip isActive={isActive}>
        <div>
          <span>$</span>
          {data !== '0' ? formatPriceConditionalDecimal(data) : '-'}
        </div>
      </MultiCellTooltip>
    </TableCell>
  )
}

const CapTableSummaryRow = ({
  activeCells,
  activeRows,
  rowIndex,
  onSelectRow,
  item,
  headers,
}: IRowProps) => {
  const { isParticipant } = usePermissions()

  const handleSelectRow = useCallback(
    (event) => onSelectRow(event, rowIndex),
    [rowIndex, onSelectRow],
  )
  const isSelected = useMemo(() => activeRows.includes(rowIndex), [activeRows, rowIndex])

  return (
    <TableRow onClick={handleSelectRow} className={cn('activableRow')} data-index={rowIndex}>
      <TableCell />
      <TableCell
        style={{
          zIndex: 50,
        }}
      >
        {item.entityId && !isParticipant ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.ENTITY_PAGE, {
              id: item.entityId,
            })}
          >
            {item.investor}
          </LinkButton>
        ) : (
          item.investor
        )}
      </TableCell>

      {item.investments &&
        headers?.map((header, index) =>
          item.investments?.[header] ? (
            <CapTableSummaryCell
              key={header}
              data={item.investments?.[header]}
              rowIndex={rowIndex}
              cellIndex={index}
              isSelected={isSelected}
              isActive={activeCells[rowIndex]?.includes(index)}
            />
          ) : (
            <TableCell key={header} />
          ),
        )}
      <TableCell
        className={cn(genericSs.tableTextRight, 'activableCell', {
          activeCell: isSelected,
        })}
      >
        <div className={styles.amountHolder}>
          <span>$</span>
          {formatPriceConditionalDecimal(item.totalInvestment)}
        </div>
      </TableCell>
      <TableCell
        className={cn(genericSs.tableTextRight, 'activableCell', {
          activeCell: isSelected,
        })}
      >
        <div className={styles.amountHolder}>{formatPercent(item.outstandingSharesPercent)}</div>
      </TableCell>
      <TableCell
        className={cn(genericSs.tableTextRight, 'activableCell', {
          activeCell: isSelected,
        })}
      >
        <div className={styles.amountHolder}>{formatPercent(item.dilutedSharesPercent)}</div>
      </TableCell>
    </TableRow>
  )
}

interface IProps {
  listCapTable: (params: object) => void
  capTableSummary: ILoadingData<ICapTableSummaryData>
  clientInfo: IClientInfo
  handleOpenMappingModal?: () => void
  mappingComplete?: boolean
  boxLink?: string
  handleOpenSourceData?: (value: boolean) => void
  isFileSelectShown?: boolean
  selectedFile?: IFile
  setCapTableFilters?: (value: any) => void
  isDisabled?: boolean
}

const CapTableSummary = ({
  listCapTable,
  capTableSummary,
  clientInfo,
  handleOpenMappingModal,
  mappingComplete,
  boxLink,
  handleOpenSourceData,
  isFileSelectShown,
  selectedFile,
  setCapTableFilters,
  isDisabled,
}: IProps) => {
  const wrapperRef = useRef(null)
  const [activeCells, setActiveCells] = useState<number[][]>([])
  const [selectedDate, setSelectedDate] = useState<string>(null)
  const [isModalShown, setIsModalShown] = useState(false)
  const [isAggregate, setIsAggregate] = useState(false)

  const {
    isLoading,
    data: capTableSummaryData,
    headers,
    dates: capTableDateOptions,
  } = useMemo(() => {
    return {
      isLoading: capTableSummary?.isLoading,
      data: capTableSummary?.data?.data,
      headers: capTableSummary?.data?.rounds,
      dates: capTableSummary?.data?.dates,
    }
  }, [capTableSummary])

  const filtersConfig = useMemo(
    () => [
      ...CAP_TABLE_SUMMARY_TITLE_FILTERS_CONFIG,
      ...(headers || []).map((header, index) => ({
        field: header,
        type: 'amount',
        isSortable: true,
        title: header,
      })),
      ...CAP_TABLE_SUMMARY_DATA_FILTERS_CONFIG,
    ],
    [headers],
  )

  const totalInvestmentFields = useMemo(() => {
    return CAP_TABLE_SUMMARY_DATA_FILTERS_CONFIG.map((filter) => filter.field)
  }, [])

  const filtersValidate = buildFiltersValidateSchema(filtersConfig as IFilter[])
  const filtersDefaults = buildFiltersDefaults(filtersConfig as IFilter[])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    handleSelectRow,
    activeItems,
    setActiveItems,
  } = useTable({
    tableId: 'capTableSummary',
    filtersDefaults,
    sortDefault: {
      field: 'SUM("capTableDetails"."diluted_shares")',
      direction: 'DESC',
    },
  })

  const dateOptions = useMemo(() => {
    return capTableDateOptions?.map((date) => ({
      value: date,
      label: formatDate(date),
    }))
  }, [capTableDateOptions])

  useEffect(() => {
    if (clientInfo?.clientName) {
      listCapTable({
        clientName: clientInfo?.clientName,
        recordDate: selectedDate,
        isAggregate,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
      })
    }
  }, [clientInfo, listCapTable, selectedDate, isAggregate, orderBy, filters])

  useEffect(() => {
    if (!selectedDate && capTableDateOptions?.length > 0) {
      setSelectedDate(capTableDateOptions[0])
    }
  }, [selectedDate, capTableDateOptions])

  const resetMultiCellsSelection = useCallback(() => {
    setActiveCells([])
  }, [])

  const resetMultiRowsSelection = useCallback(() => {
    setActiveItems([])
  }, [setActiveItems])

  const onCloseModal = useCallback(() => {
    setIsModalShown(false)
    resetMultiCellsSelection()
    resetMultiRowsSelection()
  }, [resetMultiCellsSelection, resetMultiRowsSelection])

  const summaryRows = useMemo(() => {
    const headerTotals = (headers || []).map((round: string, index: number) => ({
      field: round,
      amounts: (capTableSummaryData || [])
        .filter((item: ICapTableSummary, capTableIndex: number) =>
          activeItems.includes(capTableIndex),
        )
        .reduce((sum: number, item: ICapTableSummary) => {
          return sum + (parseFloat(item.investments?.[round]) || 0)
        }, 0),
    }))

    const investmentTotals = (totalInvestmentFields || []).map(
      (totalName: string, index: number) => ({
        field: totalName,
        amounts: (capTableSummaryData || [])
          .filter((item: ICapTableSummary, capTableIndex: number) =>
            activeItems.includes(capTableIndex),
          )
          .reduce((sum: number, item: ICapTableSummary) => {
            if (totalName === 'totalInvestment') {
              return sum + parseFloat(String(item.totalInvestment))
            } else {
              const fieldName = `${totalName}Percent`
              return sum + parseFloat(String(item[fieldName]))
            }
          }, 0),
      }),
    )

    return [...headerTotals, ...investmentTotals]
  }, [capTableSummaryData, activeItems, headers, totalInvestmentFields])

  const handleReportingDateChange = useCallback(
    ({ target: { value } }) => {
      setSelectedDate(value)
    },
    [setSelectedDate],
  )

  const handleAggregateChange = useCallback(
    ({ target: { value } }) => {
      setIsAggregate(value === 'Aggregate')
      handleOrderChange('SUM("capTableDetails"."diluted_shares")', 'DESC')
    },
    [setIsAggregate, handleOrderChange],
  )

  const toggleExpandAndMinimize = useCallback(() => {
    setIsModalShown(!isModalShown)
  }, [isModalShown])

  const handleOpenMapping = useCallback(() => {
    handleOpenMappingModal()
    setCapTableFilters({
      clientName: clientInfo?.clientName,
      recordDate: selectedDate,
      isAggregate,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [
    handleOpenMappingModal,
    clientInfo,
    selectedDate,
    isAggregate,
    orderBy,
    filters,
    setCapTableFilters,
  ])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
      onCloseCustom={onCloseModal}
    >
      {!dateOptions?.length && !isLoading && !handleOpenSourceData ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="30vh">
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid container item xs={12} justifyContent={'center'}>
              <EmptyIcon />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent={'center'}
              className={styles.noProcessedCapTable}
            >
              No Processed Cap Table
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Card ref={wrapperRef} withBorder={handleOpenSourceData ? false : true}>
          {(dateOptions?.length > 0 || handleOpenSourceData) && (
            <TableContainer
              className={cn(styles.table)}
              id={'capTableSummmary'}
              coupledTables={COUPLED_TABLES}
            >
              <Form
                validate={filtersValidate}
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    filters={filtersConfig as IFilter[]}
                    handleSubmit={handleSubmit}
                    mutators={mutators}
                    values={values}
                    appliedFilters={filters}
                    withFilters={false}
                    title={'Cap Table Summary'}
                    actions={
                      <Grid item container justifyContent="flex-end" columnSpacing={1} spacing={2}>
                        <Grid item>
                          <SelectField
                            key={'isAggregate'}
                            label="Round"
                            variant="outlined"
                            useFinalForm={false}
                            name="isAggregate"
                            options={['All', 'Aggregate'].map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            onChange={handleAggregateChange}
                            value={isAggregate ? 'Aggregate' : 'All'}
                            defaultValue=""
                            className={styles.selectField}
                          />
                        </Grid>
                        <Grid item>
                          <SelectField
                            key={selectedDate}
                            label="Document"
                            variant="outlined"
                            useFinalForm={false}
                            name="recordDate"
                            options={dateOptions || []}
                            onChange={handleReportingDateChange}
                            value={selectedDate}
                            defaultValue=""
                            className={styles.selectField}
                            withTopLabel
                          />
                        </Grid>
                        {handleOpenMappingModal && !isDisabled && (
                          <Grid item>
                            <EditMapping
                              mappingRequired={!mappingComplete && mappingComplete !== undefined}
                              action={handleOpenMapping}
                              title="Edit mapping"
                              mappingDisabled={mappingComplete === undefined}
                            />
                          </Grid>
                        )}
                        {boxLink && (
                          <Grid item>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <ExternalLink
                                link={selectedFile?.link}
                                className={styles.externalLink}
                              />
                            </Box>
                          </Grid>
                        )}

                        {handleOpenSourceData && !isDisabled && (
                          <Grid item>
                            <EditSourceData
                              action={() => handleOpenSourceData(!isFileSelectShown)}
                              error={!!selectedFile?.error}
                            />
                          </Grid>
                        )}

                        <Grid item>
                          <ExpandAndMinimize
                            action={toggleExpandAndMinimize}
                            isExpanded={isModalShown}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                )}
              />

              <Table>
                <TableHead className={cn(styles.tableHead)}>
                  <TableFiltersRow
                    filters={filtersConfig as IFilter[]}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                    withResize
                  />
                </TableHead>
                <TableBody>
                  {capTableSummaryData && (
                    <>
                      {capTableSummaryData.map((item: ICapTableSummary, rowIndex: number) => (
                        <CapTableSummaryRow
                          key={item.investor}
                          activeCells={activeCells}
                          activeRows={activeItems}
                          rowIndex={rowIndex}
                          onSelectRow={handleSelectRow}
                          item={item}
                          headers={headers}
                        />
                      ))}
                    </>
                  )}
                  {activeItems?.length > 1 && (
                    <TableRow className="summaryRow">
                      <TableCell />
                      <TableCell className={genericSs.tableTextLeft}>
                        {activeItems.length} rows selected
                      </TableCell>
                      {[...headers, ...totalInvestmentFields]?.map((header, index) => (
                        <TableCell key={header} className={genericSs.tableTextRight}>
                          <div className={styles.amountHolder}>
                            {totalInvestmentFields.includes(header) &&
                            header !== 'totalInvestment' ? (
                              formatPercent(summaryRows[index]?.amounts)
                            ) : (
                              <span>
                                $ {formatPriceConditionalDecimal(summaryRows[index]?.amounts)}
                              </span>
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Card>
      )}
    </FullscreenModal>
  )
}

export default CapTableSummary
