import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { Form, FormRenderProps } from 'react-final-form'
import * as Yup from 'yup'
import moment from 'moment'
import { OnChange } from 'react-final-form-listeners'
import { FormApi } from 'final-form'

import styles from './ClientAccountActivityCreateModal.module.scss'
import genericSs from '@styles/generic.module.scss'

import Modal from '../../Common/Modal'
import Button from '../../Common/Button'
import SelectField, { IOption } from '../../Common/SelectField'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../../constants/common'
import CurrencyField from '../../Common/CurrencyField'
import InputLabel from '../../Common/InputLabel'
import TextField from '../../Common/TextField'
import CreatableSelectField from '../../Common/CreatableSelectField'
import {
  FeeType,
  TermLoanPrincipalPaymentType,
  miscOptions,
  passthroughOptions,
} from '@common/interfaces/loanServicing'
import { IClientInfo, TermLoanStatus } from '@common/interfaces/client'
import { ITermLoan } from '@common/interfaces/termLoan'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import { dateToString, voidHandler } from '../../../helpers/helpers'
import WarningModal from '../../WarningModal'
import cn from 'classnames'
import { ILoadingData } from '../../../redux/types'
import {
  IParticipation,
  IParticipationData,
  ParticipationStatus,
  ParticipationWireDirections,
  ParticipationWireTypes,
} from '@common/interfaces/participant'
import FormField from '../../Common/FormField'
import Checkbox from '../../Common/Checkbox'

const ACTIVITY_TYPES = {
  PASSTHROUGHS_FEE: 'PASSTHROUGHS_FEE',
  MISCELLANEOUS_FEE: 'MISCELLANEOUS_FEE',
  ADJUSTMENT: 'ADJUSTMENT',
  INTEREST_FEE: 'INTEREST_FEE',
  FACILITY_FEE: 'FACILITY_FEE',
  LOCKBOX_FEE: 'LOCKBOX_FEE',
  TERM_LOAN_PAYMENT: 'TERM_LOAN_PAYMENT',
  WIRE: 'WIRE',
  CHECK: 'CHECK',
  FUNDING: 'FUNDING',
  PARTICIPATION_WIRE: 'PARTICIPATION_WIRE',
}

const ACTIVITY_TYPE_TO_PARTICIPATION_FIELD = {
  [ACTIVITY_TYPES.PASSTHROUGHS_FEE]: 'passThroughFee',
  [ACTIVITY_TYPES.MISCELLANEOUS_FEE]: 'miscFee',
  [ACTIVITY_TYPES.FACILITY_FEE]: 'facilityFee',
  [ACTIVITY_TYPES.LOCKBOX_FEE]: 'lockboxFee',
  [ACTIVITY_TYPES.INTEREST_FEE]: 'interest',
}

const ACTIVITY_TYPES_OPTIONS = [
  {
    value: ACTIVITY_TYPES.PASSTHROUGHS_FEE,
    label: 'Pass-Through Fees (1401)',
  },
  {
    value: ACTIVITY_TYPES.MISCELLANEOUS_FEE,
    label: 'Misc. Fees (4003)',
  },
  {
    value: ACTIVITY_TYPES.ADJUSTMENT,
    label: 'Adjustments (1000)',
  },
  {
    value: ACTIVITY_TYPES.INTEREST_FEE,
    label: 'Interest Fee (4001)',
  },
  {
    value: ACTIVITY_TYPES.FACILITY_FEE,
    label: 'Facility Fee (1800)',
  },
  {
    value: ACTIVITY_TYPES.LOCKBOX_FEE,
    label: 'Lockbox Fee (4003)',
  },
  {
    value: ACTIVITY_TYPES.TERM_LOAN_PAYMENT,
    label: 'Term Loan Principal',
  },
  {
    value: ACTIVITY_TYPES.WIRE,
    label: 'Wire (1000)',
  },
  {
    value: ACTIVITY_TYPES.CHECK,
    label: 'Check (1000)',
  },
  {
    value: ACTIVITY_TYPES.FUNDING,
    label: 'Funding (1000)',
  },
  {
    value: ACTIVITY_TYPES.PARTICIPATION_WIRE,
    label: 'Participation Wire (1000)',
  },
]

const INITIAL_VALUES = {
  type: ACTIVITY_TYPES.PASSTHROUGHS_FEE,
  feeType: '',
  description: '',
  recordDate: new Date(),
  wireFee: true,
}

const clearForm = (form: FormApi<any>) => {
  const fields = ['feeType', 'description', 'debtor', 'termLoanId']
  const registeredFields = form.getRegisteredFields()
  form.batch(() => {
    for (const field of fields) {
      if (registeredFields.includes(field)) {
        form.change(field, null)
        form.resetFieldState(field)
      }
    }
  })
}

const participationWireTypeOptions = Object.values(ParticipationWireTypes).map((type) => ({
  label: type,
  value: type,
}))

const baseParticipationWireDirectionOptions = Object.values(ParticipationWireDirections).map(
  (direction) => ({
    label: direction,
    value: direction,
  }),
)

const ClientAccountActivityCreateForm = ({
  pristine,
  invalid,
  submitting,
  values,
  handleSubmit,
  form,
  formRef,
  loanBalanceStartDate,
  typeOptions,
  termLoansOptions,
  handleAddDebtor,
  loadDebtors,
  participations,
  error,
}: FormRenderProps<any> & {
  formRef?: React.MutableRefObject<FormApi<any, Partial<any>>>
  loanBalanceStartDate: string
  typeOptions: IOption[]
  termLoansOptions: IOption[]
  handleAddDebtor: (debtor: string) => void
  loadDebtors: (debtor: string) => Promise<IOption[]>
  participations: IParticipation[]
  error?: string
}) => {
  formRef.current = form

  const currentAmount = useMemo(() => {
    return form.getFieldState('amount')?.value
  }, [form])

  const updateParticipationFees = useCallback(
    (value, previous) => {
      let changeFields = true
      if (ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]) {
        const formsToChange = {}
        participations?.forEach((participation) => {
          const isInterest = values.type === ACTIVITY_TYPES.INTEREST_FEE
          const formName = `${participation.id}-${
            ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]
          }Share`
          const share = isInterest
            ? `loanExposure`
            : `${ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]}Share`

          const previousSharePercentage =
            (form.getFieldState(formName)?.value || 0) / (previous || 1)

          const areSharesEqual =
            previousSharePercentage.toFixed(2) === participation[share].toFixed(2)
          if (!previousSharePercentage || areSharesEqual) {
            const newValue = (currentAmount * participation[share]).toFixed(2)
            formsToChange[formName] = newValue
          } else {
            changeFields = false
          }
        })
        if (changeFields) {
          for (const [key, value] of Object.entries(formsToChange)) {
            form.change(key, value)
          }
        }
      }
    },
    [form, values.type, currentAmount, participations],
  )
  const [participantWireDirectionOptions, setParticipantWireDirectionOptions] = useState(
    baseParticipationWireDirectionOptions,
  )

  const handleChangeParticipantWireType = useCallback(
    (value) => {
      if (value === ParticipationWireTypes.Interest || value === ParticipationWireTypes.OtherFee) {
        setParticipantWireDirectionOptions([
          {
            label: ParticipationWireDirections.Outgoing,
            value: ParticipationWireDirections.Outgoing,
          },
        ])
        formRef.current.change('participantWireDirection', ParticipationWireDirections.Outgoing)
      } else {
        setParticipantWireDirectionOptions(baseParticipationWireDirectionOptions)
      }
    },
    [formRef],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Box flex={1}>
          <InputLabel htmlFor="type" className={genericSs.textLeft}>
            Type
          </InputLabel>
          <SelectField
            className={styles.selectFormField}
            id="type"
            name="type"
            options={typeOptions}
            placeholder="Type"
          />
        </Box>

        {values.type === ACTIVITY_TYPES.PASSTHROUGHS_FEE ? (
          <Box flex={1}>
            <InputLabel htmlFor="feeType" className={genericSs.textLeft}>
              Fee subtype
            </InputLabel>
            <CreatableSelectField
              className={styles.creatableSelectFormField}
              name="feeType"
              placeholder="Fee subtype"
              onAddValue={voidHandler}
              options={passthroughOptions}
              height="medium"
            />
          </Box>
        ) : values.type === ACTIVITY_TYPES.MISCELLANEOUS_FEE ? (
          <Box flex={1}>
            <InputLabel htmlFor="feeType" className={genericSs.textLeft}>
              Fee subtype
            </InputLabel>
            <CreatableSelectField
              className={styles.creatableSelectFormField}
              name="feeType"
              placeholder="Fee subtype"
              onAddValue={voidHandler}
              options={miscOptions}
              height="medium"
            />
          </Box>
        ) : values.type === ACTIVITY_TYPES.WIRE ? (
          <Box flex={1}>
            <InputLabel htmlFor="debtor" className={genericSs.textLeft}>
              Debtor
            </InputLabel>
            <CreatableSelectField
              className={styles.creatableSelectFormField}
              name="debtor"
              placeholder="Debtor"
              onAddValue={handleAddDebtor}
              options={[]}
              isAsync
              loadOptions={loadDebtors}
              height="medium"
            />
          </Box>
        ) : values.type === ACTIVITY_TYPES.CHECK ? (
          <Box display="flex" flexDirection="row" flexWrap="wrap" rowGap={1}>
            <Box flex={1} marginRight={2}>
              <InputLabel htmlFor="debtor" className={genericSs.textLeft}>
                Debtor
              </InputLabel>
              <CreatableSelectField
                className={styles.creatableSelectFormField}
                name="debtor"
                placeholder="Debtor"
                onAddValue={handleAddDebtor}
                options={[]}
                isAsync
                loadOptions={loadDebtors}
                height="medium"
              />
              <InputLabel htmlFor="accountNumber" className={cn(genericSs.textLeft, styles.label)}>
                Account number
              </InputLabel>
              <TextField
                className={styles.textFormField}
                id="accountNumber"
                name="accountNumber"
                placeholder="Enter account number"
                InputProps={{
                  type: 'number',
                }}
              />
              <InputLabel htmlFor="routingNumber" className={cn(genericSs.textLeft, styles.label)}>
                Routing number
              </InputLabel>
              <TextField
                className={styles.textFormField}
                id="routingNumber"
                name="routingNumber"
                placeholder="Enter routing number"
                InputProps={{
                  type: 'number',
                }}
              />
            </Box>
            <Box flex={1}>
              <InputLabel htmlFor="checkNumber" className={genericSs.textLeft}>
                Check number
              </InputLabel>
              <TextField
                className={styles.textFormField}
                id="checkNumber"
                name="checkNumber"
                placeholder="Enter check number"
                InputProps={{
                  type: 'number',
                }}
              />
              <InputLabel htmlFor="boxLink" className={cn(genericSs.textLeft, styles.label)}>
                Box link
              </InputLabel>
              <TextField
                className={styles.textFormField}
                id="boxLink"
                name="boxLink"
                placeholder="Enter box link"
                InputProps={{
                  type: 'text',
                }}
              />
            </Box>
          </Box>
        ) : values.type === ACTIVITY_TYPES.TERM_LOAN_PAYMENT ? (
          <Box flex={1}>
            <InputLabel htmlFor="termLoanId" className={genericSs.textLeft}>
              Term Loan
            </InputLabel>
            <SelectField
              className={styles.selectFormField}
              id="termLoanId"
              name="termLoanId"
              options={termLoansOptions}
              placeholder="Term Loan"
            />
          </Box>
        ) : values.type === ACTIVITY_TYPES.PARTICIPATION_WIRE ? (
          <Box flex={1}>
            <InputLabel htmlFor="termLoanId" className={genericSs.textLeft}>
              Participant
            </InputLabel>
            <SelectField
              className={styles.selectFormField}
              id="participant"
              name="participant"
              options={participations.map((participation) => ({
                label: participation.participant,
                value: participation.id,
              }))}
              placeholder="Participant"
            />
          </Box>
        ) : (
          <Box flex={1}>
            <InputLabel htmlFor="description" className={genericSs.textLeft}>
              Description
            </InputLabel>
            <TextField
              className={styles.textFormField}
              id="description"
              name="description"
              placeholder="Description (Optional)"
              InputProps={{
                type: 'text',
              }}
            />
          </Box>
        )}

        <Box flex={1}>
          <InputLabel htmlFor="recordDate" className={genericSs.textLeft}>
            Date
          </InputLabel>
          <KeyboardDatePicker
            className={styles.dateFormField}
            name="recordDate"
            inputFormat={DATE_FORMAT}
            minDate={moment(loanBalanceStartDate || undefined).toDate()}
            placeholder="Select date"
          />
        </Box>

        <Box flex={1}>
          <InputLabel htmlFor="amount" className={genericSs.textLeft}>
            Amount
          </InputLabel>
          <CurrencyField
            id="amount"
            name="amount"
            placeholder="Enter Amount"
            className={styles.textFormField}
          />
          <OnChange name="amount">{updateParticipationFees}</OnChange>
          <OnChange name="type">{updateParticipationFees}</OnChange>
        </Box>

        {values.type === ACTIVITY_TYPES.FUNDING && (
          <Box flex={1}>
            <InputLabel htmlFor="amount" className={genericSs.textLeft}>
              Wire fee
            </InputLabel>
            <Box className={styles.wireFeeCheckboxWrapper}>
              <Checkbox
                name="wireFee"
                checked={values.wireFee}
                color="primary"
                onChange={(event) => {
                  form.change('wireFee', event.target.checked)
                }}
              />
            </Box>
          </Box>
        )}

        {values.type === ACTIVITY_TYPES.PARTICIPATION_WIRE && (
          <>
            <Box flex={1}>
              <InputLabel htmlFor="type" className={genericSs.textLeft}>
                Type
              </InputLabel>
              <SelectField
                className={styles.selectFormField}
                id="participantWireType"
                name="participantWireType"
                options={participationWireTypeOptions}
                placeholder="Type"
              />
            </Box>
            <Box flex={1}>
              <InputLabel htmlFor="type" className={genericSs.textLeft}>
                Direction
              </InputLabel>
              <SelectField
                className={styles.selectFormField}
                id="participantWireDirection"
                name="participantWireDirection"
                options={participantWireDirectionOptions}
                placeholder="Direction"
              />
            </Box>
          </>
        )}

        {Object.keys(ACTIVITY_TYPE_TO_PARTICIPATION_FIELD).includes(values.type) &&
          participations?.map((participation: IParticipation) => {
            const isInterest = values.type === ACTIVITY_TYPES.INTEREST_FEE
            const overrideForm = `${participation.id}-${
              ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]
            }Override`
            const shareForm = `${participation.id}-${
              ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]
            }Share`

            const share = `${ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]}Share`

            const defaultShare =
              (isInterest ? participation.loanExposure : participation[share]) * 100

            const currentShareValue = form.getFieldState(shareForm)?.value || 0

            const sharePercent = currentAmount
              ? ((currentShareValue / currentAmount) * 100).toFixed(2)
              : defaultShare.toFixed(2)

            return (
              <Box flex={1} key={participation.participant}>
                <InputLabel htmlFor={participation.participant} className={genericSs.textLeft}>
                  {participation.participant}
                </InputLabel>
                <Box display="flex" flexDirection="row" flexWrap="wrap" rowGap={1}>
                  <Box flex={1} marginRight={2}>
                    <InputLabel htmlFor={overrideForm} className={genericSs.textLeft}>
                      Dwight Override (%)
                    </InputLabel>
                    <FormField
                      name={overrideForm}
                      type="percent"
                      size="big"
                      placeholder="Enter override"
                    />
                  </Box>
                  <Box flex={1}>
                    <InputLabel htmlFor={shareForm} className={genericSs.textLeft}>
                      Share ({sharePercent}%)
                    </InputLabel>
                    <CurrencyField
                      className={styles.textFormField}
                      id={shareForm}
                      name={shareForm}
                      placeholder="Enter share"
                    />
                  </Box>
                </Box>
              </Box>
            )
          })}
      </Box>

      <Box mt={5}>
        {error && <Box color="error.main">{error}</Box>}

        <Button
          type="submit"
          fullWidth
          small={false}
          key="submit"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={pristine || invalid || submitting}
          isLoading={submitting}
        >
          Apply
        </Button>
      </Box>

      <OnChange name="type">{() => clearForm(form)}</OnChange>
      <OnChange name="participantWireType">{handleChangeParticipantWireType}</OnChange>
    </form>
  )
}

const today = moment()

interface IProps {
  loanBalanceStartDate: string
  clientInfo: IClientInfo
  termLoans: ITermLoan[]
  clientParticipations: ILoadingData<IParticipationData>
  createFee: (data: object) => void
  createFunding: (id: string, data: object) => void
  createAdjustment: (data: object) => void
  createWire: (data: object) => void
  createCheck: (data: object) => void
  createTermLoanPayment: (id: string, termLoanId: string, data: object) => void
  addEntityInfo: (data: object) => Promise<any>
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
  handleCancel: () => void
  handleConfirm: () => void
  listClientParticipations: (id: string, data: object) => void
  createParticipationWire: (id: string, participationId: string, data: object) => void
}

const ClientAccountActivityCreateModal = ({
  loanBalanceStartDate,
  clientInfo,
  termLoans,
  clientParticipations,
  createFee,
  createFunding,
  createAdjustment,
  createWire,
  createCheck,
  createTermLoanPayment,
  createParticipationWire,
  addEntityInfo,
  listEntityInfo,
  handleCancel,
  handleConfirm,
  listClientParticipations,
}: IProps) => {
  const formRef: React.MutableRefObject<FormApi<any, Partial<any>>> = useRef(null)

  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [error, setError] = useState('')

  const clientInfoId = useMemo(() => clientInfo?.id, [clientInfo])

  useEffect(() => {
    if (clientInfoId) {
      listClientParticipations(clientInfoId, {
        orderBy: 'participant',
        order: 'ASC',
      })
    }
  }, [clientInfoId, listClientParticipations])

  const { participationsList } = useMemo(
    () => ({
      participationsList: clientParticipations?.data?.data,
      isParticipationLoading: clientParticipations?.isLoading,
    }),
    [clientParticipations],
  )

  const effectiveParticipations = useMemo(
    () =>
      participationsList?.filter(
        (participation) => participation.status === ParticipationStatus.Active,
      ) || [],
    [participationsList],
  )

  const termLoansOptions = useMemo(
    () =>
      termLoans
        .filter(({ status }) => status === TermLoanStatus.Active)
        .map(({ id, collateralName }) => ({
          value: id,
          label: collateralName,
        })),
    [termLoans],
  )

  const typeOptions = useMemo(
    () =>
      ACTIVITY_TYPES_OPTIONS.filter(
        ({ value }) => termLoansOptions.length > 0 || value !== ACTIVITY_TYPES.TERM_LOAN_PAYMENT,
      ).filter(
        ({ value }) =>
          effectiveParticipations?.length > 0 || value !== ACTIVITY_TYPES.PARTICIPATION_WIRE,
      ),
    [termLoansOptions, effectiveParticipations],
  )

  const validate = useMemo(() => {
    const minDate = moment(loanBalanceStartDate || undefined).toDate()

    const schema = Yup.object()
      .shape({
        type: Yup.string().typeError('Required'),
        feeType: Yup.object()
          .nullable()
          .when('type', (type: string, validation: any) =>
            [ACTIVITY_TYPES.PASSTHROUGHS_FEE, ACTIVITY_TYPES.MISCELLANEOUS_FEE].includes(type)
              ? validation.required('Required')
              : validation,
          ),
        debtor: Yup.object()
          .nullable()
          .when('type', (type: string, validation: any) =>
            type === ACTIVITY_TYPES.WIRE ? validation.required('Required') : validation,
          ),
        termLoanId: Yup.string().when('type', (type: string, validation: any) =>
          type === ACTIVITY_TYPES.TERM_LOAN_PAYMENT
            ? validation.required('Term Loan Required')
            : validation,
        ),
        participant: Yup.string().when('type', (type: string, validation: any) =>
          type === ACTIVITY_TYPES.PARTICIPATION_WIRE ? validation.required('Required') : validation,
        ),
        participantWireType: Yup.string().when('type', (type: string, validation: any) =>
          type === ACTIVITY_TYPES.PARTICIPATION_WIRE ? validation.required('Required') : validation,
        ),
        participantWireDirection: Yup.string().when('type', (type: string, validation: any) =>
          type === ACTIVITY_TYPES.PARTICIPATION_WIRE ? validation.required('Required') : validation,
        ),
        recordDate: Yup.date()
          .typeError('Please type date in MM/DD/YY format')
          .required('Required')
          .min(minDate, 'Cannot create fee for a locked month'),
        amount: Yup.number().typeError('Invalid number').required('Required'),
      })
      .test('sumOfShares', `Sum of shares must be less than or equal to amount`, (values) => {
        const sumOfShares = effectiveParticipations?.reduce((acc, participation) => {
          const share =
            values[
              `${participation.id}-${ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]}Share`
            ] || 0
          return acc + +share
        }, 0)

        if (values.amount < 0) {
          return sumOfShares >= values.amount && sumOfShares <= 0
        }
        return sumOfShares <= values.amount || (!sumOfShares && !values.amount)
      })
    return schema
  }, [loanBalanceStartDate, effectiveParticipations])

  const handleSubmit = useCallback(
    async (values: any) => {
      if (!isConfirmed && !moment(values.recordDate).isSame(today, 'day')) {
        setIsConfirmModalShown(true)
        return
      }

      const participations = effectiveParticipations.reduce((acc, participation) => {
        acc[participation.id] = {
          override:
            values[
              `${participation.id}-${ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]}Override`
            ] || 0,
          share:
            values[
              `${participation.id}-${ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[values.type]}Share`
            ] || 0,
        }
        return acc
      }, {})

      const data: any = {
        clientId: clientInfo.id,
        recordDate: dateToString(values.recordDate),
        amount: values.amount,
      }

      Object.keys(ACTIVITY_TYPE_TO_PARTICIPATION_FIELD).includes(values.type) &&
        (data.participations = participations)

      switch (values.type) {
        case ACTIVITY_TYPES.ADJUSTMENT:
          await createAdjustment({
            ...data,
            title: values.description,
          })
          break
        case ACTIVITY_TYPES.WIRE:
          await createWire({
            ...data,
            debtor: values.debtor?.value,
          })
          break
        case ACTIVITY_TYPES.CHECK:
          await createCheck({
            ...data,
            debtor: values.debtor?.value,
            checkNumber: values.checkNumber,
            accountNumber: values.accountNumber,
            routingNumber: values.routingNumber,
            boxLink: values.boxLink,
          })
          break
        case ACTIVITY_TYPES.PASSTHROUGHS_FEE:
          await createFee({
            ...data,
            type: FeeType.PassThroughs,
            title: values.feeType?.value,
          })
          break
        case ACTIVITY_TYPES.MISCELLANEOUS_FEE:
          await createFee({
            ...data,
            type: FeeType.Miscellaneous,
            title: values.feeType?.value,
          })
          break
        case ACTIVITY_TYPES.INTEREST_FEE:
          await createFee({
            ...data,
            type: FeeType.ManualInterest,
            description: values.description,
          })
          break
        case ACTIVITY_TYPES.LOCKBOX_FEE:
          await createFee({
            ...data,
            type: FeeType.Lockbox,
            description: values.description,
          })
          break
        case ACTIVITY_TYPES.FACILITY_FEE:
          await createFee({
            ...data,
            type: FeeType.Facility,
            description: values.description,
          })
          break
        case ACTIVITY_TYPES.TERM_LOAN_PAYMENT:
          await createTermLoanPayment(clientInfoId, values.termLoanId, {
            ...data,
            type: TermLoanPrincipalPaymentType.TermLoanManualPrincipal,
          })
          break
        case ACTIVITY_TYPES.FUNDING:
          await createFunding(clientInfo.id, {
            ...data,
            wireFee: values.wireFee,
            description: values.description || null,
          })
          break
        case ACTIVITY_TYPES.PARTICIPATION_WIRE:
          await createParticipationWire(clientInfo.id, values.participant, {
            ...data,
            type: values.participantWireType,
            direction: values.participantWireDirection,
          })
          break
        default:
          break
      }
      handleConfirm()
    },
    [
      handleConfirm,
      createFee,
      createFunding,
      createAdjustment,
      createWire,
      createTermLoanPayment,
      createParticipationWire,
      clientInfoId,
      isConfirmed,
      clientInfo,
      createCheck,
      effectiveParticipations,
    ],
  )

  const handleAddDebtor = useCallback(
    async (debtor: string) =>
      addEntityInfo({ name: debtor, type: 'debtor', clientName: clientInfo.clientName }),
    [addEntityInfo, clientInfo],
  )

  const loadDebtors = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map(({ name }) => ({
        value: name,
        label: name,
      }))
    },
    [listEntityInfo],
  )

  const handleConfirmConfirm = useCallback(() => {
    setIsConfirmModalShown(false)
    setIsConfirmed(true)
  }, [])

  const handleConfirmCancel = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [])

  useEffect(() => {
    if (isConfirmed) {
      formRef?.current?.submit()
    }
  }, [isConfirmed])

  const initialValues = useMemo(() => {
    const newInitialValues = { ...INITIAL_VALUES }
    effectiveParticipations?.forEach((participation) => {
      Object.keys(ACTIVITY_TYPE_TO_PARTICIPATION_FIELD).forEach((key) => {
        const override = `${ACTIVITY_TYPE_TO_PARTICIPATION_FIELD[key]}Override`
        newInitialValues[`${participation.id}-${override}`] =
          participation[`${override}`] * 100 || 0
      })
    })
    return newInitialValues
  }, [effectiveParticipations])

  return (
    <Modal
      open
      onCancel={handleCancel}
      title="Add Transaction"
      size="small"
      classes={{ root: styles.modalRoot, body: styles.modalBody }}
    >
      <Form
        validate={(values) => {
          setError(null)
          try {
            validate.validateSync(values, { abortEarly: false })
          } catch (errors) {
            return errors.inner.reduce((formErrors: any, error: any) => {
              error.message !== 'Required' && setError(error.message)
              return {
                ...formErrors,
                [error.path]: error.message,
              }
            }, {})
          }
        }}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={(formProps) => (
          <ClientAccountActivityCreateForm
            {...formProps}
            formRef={formRef}
            loanBalanceStartDate={loanBalanceStartDate}
            typeOptions={typeOptions}
            termLoansOptions={termLoansOptions}
            handleAddDebtor={handleAddDebtor}
            loadDebtors={loadDebtors}
            participations={effectiveParticipations}
            error={error}
          />
        )}
      />

      {isConfirmModalShown && (
        <WarningModal
          warningMessage="Are you sure you want to apply this? It is not set for today."
          onConfirm={handleConfirmConfirm}
          onCancel={handleConfirmCancel}
          confirmText="Apply"
          cancelText="Cancel"
        />
      )}
    </Modal>
  )
}

export default ClientAccountActivityCreateModal
