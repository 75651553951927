import Base from './base'

export default class Collection extends Base {
  listChecks(params?: object) {
    return this.apiClient.get('collections/checks', params)
  }

  uploadChecks(data: FormData) {
    return this.apiClient.put('collections/checks', data)
  }

  saveChecks(data: object) {
    return this.apiClient.post('collections/checks', data)
  }

  updateCheck(id: string, data: object) {
    return this.apiClient.put(`collections/checks/${id}`, data)
  }

  updateChecks(data: object) {
    return this.apiClient.patch('collections/checks', data)
  }

  restoreCheck(id: string) {
    return this.apiClient.patch(`collections/checks/${id}`)
  }

  deleteCheck(id: string, data: object) {
    return this.apiClient.delete(`collections/checks/${id}`, data)
  }

  loadCheckImage(id: string) {
    return this.apiClient.get(`collections/checks/${id}/image`)
  }

  listCheckAccounts() {
    return this.apiClient.get('collections/checks/accounts')
  }

  addCheckAccount(data: object) {
    return this.apiClient.post('collections/checks/accounts', data)
  }

  listWires(params?: object) {
    return this.apiClient.get('collections/wires', params)
  }

  uploadWires(data: FormData) {
    return this.apiClient.put('collections/wires', data)
  }

  saveWires(data: object) {
    return this.apiClient.post('collections/wires', data)
  }

  updateWire(id: string, data: object) {
    return this.apiClient.put(`collections/wires/${id}`, data)
  }

  updateWires(data: object) {
    return this.apiClient.patch('collections/wires', data)
  }

  restoreWire(id: string) {
    return this.apiClient.patch(`collections/wires/${id}`)
  }

  deleteWire(id: string, data: object) {
    return this.apiClient.delete(`collections/wires/${id}`, data)
  }

  listAggregation(params?: { page?: number; filters?: object }) {
    return this.apiClient.get('collections/aggregation', params)
  }

  exportAggregation(params?: {
    filters?: object
    orderBy?: string
    orderDirection?: string
    collectionsExport?: boolean
  }) {
    return this.apiClient.post('collections/export-aggregation', params, { isFile: true })
  }

  listAggregationRow(params: { clientName: string; recordDate: string; filters?: object }) {
    return this.apiClient.get('collections/aggregation/row', params)
  }

  updateAggregationRow(id: string, data: object) {
    return this.apiClient.put(`collections/aggregation/row/${id}`, data)
  }

  deleteAggregationRow(data: object) {
    return this.apiClient.delete('collections/aggregation/row', data)
  }

  listDeletedItems(params: object) {
    return this.apiClient.get('collections/deleted', params)
  }

  sentDeletedItems(data: object) {
    return this.apiClient.patch(`collections/deleted`, data)
  }

  createDeletedItem(data: object) {
    return this.apiClient.post(`collections/deleted`, data)
  }

  deleteDeletedItems(data: object) {
    return this.apiClient.delete(`collections/deleted`, data)
  }

  listNegativeItems(params: object) {
    return this.apiClient.get('collections/negative', params)
  }

  listMidDayWires(params?: object) {
    return this.apiClient.get('collections/mid-day-wires', params)
  }

  listMidDayWiresTotals(params?: object) {
    return this.apiClient.get('collections/mid-day-wires-totals', params)
  }

  uploadMidDayWires(data: FormData) {
    return this.apiClient.put('collections/mid-day-wires', data)
  }

  saveMidDayWires(data: object) {
    return this.apiClient.post('collections/mid-day-wires', data)
  }

  restoreMidDayWire(id: string) {
    return this.apiClient.patch(`collections/mid-day-wires/${id}`)
  }

  deleteMidDayWire(id: string, data: object) {
    return this.apiClient.delete(`collections/mid-day-wires/${id}`, data)
  }

  createWire(data: object) {
    return this.apiClient.post('collections/wires/create', data)
  }
  listWireFilesAndStatus(id: string) {
    return this.apiClient.get(`collections/wires/${id}/files-status`)
  }
  createCheck(data: object) {
    return this.apiClient.post('collections/checks/create', data)
  }
}
