import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { ExpandAndMinimize } from '../Common/Icons'
import { IFilter } from '@common/constants/filters'
import styles from './OngoingReportingMapping.module.scss'
import genericSs from '@styles/generic.module.scss'
import Grid from '@mui/material/Grid'
import { debounceEventHandler, formatPriceNoDecimal } from '../../helpers/helpers'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import {
  CLIENT_FINANCIALS_FIELDS_MAPPING,
  CLIENT_INCOME_STATEMENT_TAGS_MAPPING,
  CLIENT_BALANCE_SHEET_TAGS_MAPPING,
  ONGOING_REPORTING_TYPE_LABEL,
  IOngoingReportingFilesMapping,
  IOngoingReportingRawMappingData,
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
  FinancialRowTypes,
  REPORTING_PERIOD_OPTIONS,
  IOngoingReportingRawMapping,
} from '@common/interfaces/bbc'
import AutocompleteField from '../Common/AutocompleteField'
import Card from '../Common/Card'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ReactComponent as CancelIcon } from '../../assets/images/close-chip.svg'
import { ReactComponent as BlueCancelIcon } from '../../assets/images/blue-close-chip.svg'
import SelectField from '../Common/SelectField'
import FullscreenModal from '../../components/Common/FullscreenModal'

import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { ONGOING_REPORTING_MAPPING_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { Chip } from '@mui/material'
import TaggingField from '../Common/TaggingField'
import FilterContainer from '../Filters/FilterContainer'
import useTable from '../../hooks/useTable'
import MultiCellTooltip, { IMultiCellTotal } from '../MultiCellTooltip'

const filtersValidate = buildFiltersValidateSchema(ONGOING_REPORTING_MAPPING_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(ONGOING_REPORTING_MAPPING_LIST_FILTERS_CONFIG)
const groupBy = (option: any) => option.isUsed

const coupledTables = ['mappingScrollableTableHead', 'ongoingMappingActiveToolbar']

interface IProps {
  filesMapping?: IOngoingReportingFilesMapping
  statementType: OngoingReportingType
  isDisabled?: boolean
  dataConsolidated: IOngoingReportingRawMappingData
  listConsolidatedOngoingReporting: (
    id: string,
    params?: {
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) => void
  updateConsolidatedOngoingReporting: (id: string, data: object) => Promise<void>
  refreshCounter?: number
  isMappingModalShown?: boolean
  setIsMappingModalShown?: Dispatch<SetStateAction<boolean>>
  additionalParams?: object
  reportingFlow: ReportingFlow
  setIsModalMultiSelected?: Dispatch<SetStateAction<boolean>>
  currentReportingPeriod?: ReportingPeriods
  setCurrentReportingPeriod: Dispatch<SetStateAction<ReportingPeriods>>
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  isNotesShown: boolean
}

const OngoingReportingMapping = ({
  filesMapping,
  statementType,
  isDisabled = false,
  dataConsolidated,
  listConsolidatedOngoingReporting,
  updateConsolidatedOngoingReporting,
  refreshCounter = 1,
  isMappingModalShown = false,
  setIsMappingModalShown,
  additionalParams,
  reportingFlow,
  setIsModalMultiSelected,
  currentReportingPeriod,
  setCurrentReportingPeriod,
  setRefreshCounter,
  isNotesShown,
}: IProps) => {
  const wrapperRef = useRef(null)
  const { id } = useParams<{ id: string }>()
  const [isModalShown, setIsModalShown] = useState(isMappingModalShown)
  const tableRef = useRef(null)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeCells,
    activeRows,
    handleSelectCell,
    handleSelectActiveRow,
    resetActiveCells,
  } = useTable({
    tableId: 'ongoingReporting',
    filtersDefaults,
    sortDefault: { field: null, direction: null },
  })

  const tagsMappings = useMemo(
    () =>
      statementType === OngoingReportingType.IncomeStatement ||
      statementType === OngoingReportingType.IncomeStatementProjections
        ? CLIENT_INCOME_STATEMENT_TAGS_MAPPING
        : CLIENT_BALANCE_SHEET_TAGS_MAPPING,
    [statementType],
  )

  const tagsOptions = useMemo(
    () =>
      Object.keys(tagsMappings).map((key) => ({
        label: tagsMappings[key],
        value: key,
      })),
    [tagsMappings],
  )

  const getTagValues = (tags: { tag: string }[]) => {
    const tagsMap = tags
      ?.map((tag) =>
        tag?.tag
          ? {
              value: tag.tag,
              label: tagsMappings[tag.tag],
            }
          : null,
      )
      .filter(Boolean)
    return tagsMap
  }

  const itemsCount = useMemo(() => dataConsolidated?.data.length, [dataConsolidated])

  const fetchConsolidatedOngoingReportingList = useCallback(
    async (data: any) => {
      const params = {
        statementType,
        ...(additionalParams || {}),
        ...data,
        reportingPeriod: currentReportingPeriod,
        reportingFlow,
        filters: {
          ...data.filters,
        },
      }
      await listConsolidatedOngoingReporting(id, params)
      resetActiveCells()
    },
    [
      id,
      statementType,
      listConsolidatedOngoingReporting,
      additionalParams,
      reportingFlow,
      currentReportingPeriod,
      resetActiveCells,
    ],
  )

  const debounceListConsolidatedOngoingReporting = useMemo(
    () => debounceEventHandler(fetchConsolidatedOngoingReportingList, 500),
    [fetchConsolidatedOngoingReportingList],
  )

  useEffect(() => {
    refreshCounter &&
      debounceListConsolidatedOngoingReporting({
        filters,
        page: 0,
        perPage: PER_PAGE,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      })
  }, [
    id,
    statementType,
    filters,
    orderBy,
    debounceListConsolidatedOngoingReporting,
    refreshCounter,
  ])

  const refetchConsolidatedOngoingReportingList = useCallback(() => {
    fetchConsolidatedOngoingReportingList({
      filters,
      page: 0,
      perPage: itemsCount,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [itemsCount, filters, orderBy, fetchConsolidatedOngoingReportingList])

  useEffect(() => {
    if (filesMapping) {
      refetchConsolidatedOngoingReportingList()
    }
  }, [filesMapping, refetchConsolidatedOngoingReportingList])

  const activeItemsIds = useMemo(
    () =>
      dataConsolidated?.data
        .filter((_, index) => activeRows.includes(index))
        .map((item) => item.id),
    [activeRows, dataConsolidated],
  )

  useEffect(() => {
    setIsModalMultiSelected && setIsModalMultiSelected(activeItemsIds?.length > 1)
  }, [activeItemsIds, setIsModalMultiSelected])

  const handleUpdateConsolidatedOngoingReportingMapping = useCallback(
    async (itemId: string, field: string) => {
      if (isDisabled) {
        return
      }
      await updateConsolidatedOngoingReporting(id, {
        reportingFlow,
        itemId:
          activeItemsIds.length > 1 && activeItemsIds.includes(itemId) ? activeItemsIds : itemId,
        field,
        isTagUpdate: false,
        reportingPeriod: currentReportingPeriod,
      })
    },
    [
      id,
      isDisabled,
      updateConsolidatedOngoingReporting,
      activeItemsIds,
      reportingFlow,
      currentReportingPeriod,
    ],
  )

  const handleUpdateConsolidatedOngoingReportingTagging = useCallback(
    async (itemId: string, tags: { value: string }[]) => {
      if (isDisabled) {
        return
      }
      const tagsArray = tags.map((tag) => tag.value)

      await updateConsolidatedOngoingReporting(id, {
        reportingFlow,
        itemId:
          activeItemsIds.length > 1 && activeItemsIds.includes(itemId) ? activeItemsIds : itemId,
        tags: tagsArray,
        isTagUpdate: true,
        reportingPeriod: currentReportingPeriod,
      })
    },
    [
      id,
      isDisabled,
      updateConsolidatedOngoingReporting,
      activeItemsIds,
      reportingFlow,
      currentReportingPeriod,
    ],
  )

  const fieldsOptions = useMemo(
    () =>
      (dataConsolidated?.dictionary.fields || [])
        .map((field) => ({
          label: CLIENT_FINANCIALS_FIELDS_MAPPING[field],
          value: field,
          isUsed: dataConsolidated?.dictionary.usedFields.includes(field),
        }))
        .sort((a, b) => +a.isUsed - +b.isUsed),
    [dataConsolidated],
  )
  const missedColumns = useMemo(
    () =>
      Array.from(Array(Math.max(6 - (dataConsolidated?.dictionary.headers.length || 0), 0)).keys()),
    [dataConsolidated],
  )

  const filtersConfig = useMemo(
    () => [
      ...ONGOING_REPORTING_MAPPING_LIST_FILTERS_CONFIG.filter(
        ({ field }) => !field.startsWith('amount'),
      ),
      ...(dataConsolidated?.dictionary?.headers || []).map((header, index) => ({
        field: `amount${dataConsolidated.dictionary.headers.length - index}`,
        type: 'amount',
        isSortable: true,
        title: header,
      })),
      ...missedColumns.map((index) => ({
        field: `missedColumn-${index}`,
        type: 'empty',
      })),
    ],
    [dataConsolidated, missedColumns],
  )

  const loadMore = useCallback(() => {
    fetchConsolidatedOngoingReportingList({
      loadMore: true,
      page: Math.ceil(itemsCount / PER_PAGE),
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [itemsCount, orderBy, filters, fetchConsolidatedOngoingReportingList])

  const summaryRow = useMemo(
    () =>
      dataConsolidated?.data
        .filter((_, index) => activeRows.includes(index))
        .map(({ amounts }) => amounts)
        .reduce((result, row) => {
          if (!result.length) {
            dataConsolidated.dictionary.headers.forEach((header, columnIndex) => {
              result[columnIndex] = 0
            })
          }
          dataConsolidated.dictionary.headers.forEach((header, columnIndex) => {
            result[columnIndex] += row[columnIndex] || 0
          })

          return result
        }, []),
    [dataConsolidated, activeRows],
  )

  const totalCell: IMultiCellTotal = useMemo(() => {
    const rowIndexesWithActiveCells = activeCells
      .map((indexes, index) => (indexes?.length ? index : null))
      .filter((index) => index !== null)

    const filteredData = (dataConsolidated?.data || [])
      .map((data: IOngoingReportingRawMapping, rowIndex: number) => ({
        data,
        rowIndex,
      }))
      .filter((_, rowIndex) => rowIndexesWithActiveCells.includes(rowIndex))
      .map(({ data: { amounts }, rowIndex }) =>
        amounts.filter((_, columnIndex) => activeCells[rowIndex].includes(columnIndex)),
      )
      .flat()
    const sum = filteredData.reduce((result: number, amount: number) => result + amount, 0)

    return {
      type: 'currency',
      count: filteredData.length,
      sum: sum,
      avg: filteredData.length ? sum / filteredData.length : 0,
    }
  }, [dataConsolidated, activeCells])

  useEffect(() => {
    const tableBody = tableRef?.current?.children?.[1]
    if (tableBody) {
      const scrollLeft = tableBody.scrollWidth
      tableBody.scrollLeft = scrollLeft
      coupledTables.forEach((tableId) => {
        const coupledTable = document.getElementById(tableId)
        if (coupledTable) {
          coupledTable.scrollLeft = scrollLeft
        }
      })
    }
  }, [tableRef?.current?.children])

  const handleScroll = useCallback(() => {
    const tableBody = tableRef.current?.children?.[1]
    if (tableBody && coupledTables) {
      const scrollLeft = tableBody.scrollLeft
      coupledTables.forEach((tableId) => {
        const coupledTable = document.getElementById(tableId)
        if (coupledTable) {
          coupledTable.scrollLeft = scrollLeft
        }
      })
    }
  }, [tableRef])

  const handleHeaderScroll = useCallback(() => {
    const tableHeader = document.getElementById('mappingScrollableTableHead')
    const tableBody = tableRef.current?.children?.[1]
    if (tableHeader && tableBody) {
      tableBody.scrollLeft = tableHeader.scrollLeft
    }
  }, [tableRef])

  const handleReportingPeriodChange = useCallback(
    ({ target: { value } }) => {
      setCurrentReportingPeriod(value)
    },
    [setCurrentReportingPeriod],
  )

  const handleClose = useCallback(() => {
    setIsMappingModalShown(false)
    setRefreshCounter && setRefreshCounter((counter) => counter + 1)
  }, [setIsMappingModalShown, setRefreshCounter])

  const handleExpandAndMinimize = useCallback(() => {
    setIsModalShown((prev) => !prev)
    setIsMappingModalShown && setIsMappingModalShown((prev) => !prev)
    setRefreshCounter && setRefreshCounter((counter) => counter + 1)
  }, [setIsMappingModalShown, setRefreshCounter])

  if (!isMappingModalShown && setIsMappingModalShown) {
    return null
  }

  return (
    <Grid container spacing={1}>
      <FullscreenModal
        isOpen={isModalShown || isMappingModalShown}
        setIsOpen={setIsModalShown}
        onCloseCustom={handleClose}
        classes={{ body: styles.fullScreenModal, root: styles.fullScreenModalRoot }}
      >
        <Grid item xs={12}>
          <Card noHeaderMargin ref={wrapperRef}>
            <TableContainer
              className={cn(styles.table, {
                [styles.tableThin]: !isModalShown && !isMappingModalShown && isNotesShown,
              })}
            >
              <Form
                validate={filtersValidate}
                onSubmit={handleFiltersChange}
                initialValues={filters}
                mutators={{
                  setFieldData: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                  },
                }}
                render={({ values, handleSubmit, form: { mutators } }) => (
                  <FilterContainer
                    filters={filtersConfig as IFilter[]}
                    handleSubmit={handleSubmit}
                    mutators={mutators}
                    values={values}
                    appliedFilters={filters}
                    withFilters={false}
                    title={<div>{ONGOING_REPORTING_TYPE_LABEL[statementType]} Mapping</div>}
                    actions={
                      <Grid
                        xs={12}
                        container
                        spacing={2}
                        justifyContent={'flex-end'}
                        gap={2}
                        alignSelf="center"
                      >
                        <SelectField
                          key={currentReportingPeriod}
                          label="Period"
                          variant="outlined"
                          useFinalForm={false}
                          name="reportingPeriod"
                          options={REPORTING_PERIOD_OPTIONS}
                          onChange={handleReportingPeriodChange}
                          value={currentReportingPeriod}
                          defaultValue=""
                          withTopLabel
                          className={styles.selectField}
                        />
                        <ExpandAndMinimize
                          action={handleExpandAndMinimize}
                          isExpanded={isModalShown}
                        />
                      </Grid>
                    }
                  />
                )}
              />
              <Table ref={tableRef}>
                <TableHead id="mappingScrollableTableHead" onScroll={handleHeaderScroll}>
                  <TableFiltersRow
                    filters={filtersConfig as IFilter[]}
                    orderBy={orderBy}
                    handleOrderChange={handleOrderChange}
                    withResize
                  />
                </TableHead>
                <TableBody id="scrollableTable" onScroll={handleScroll}>
                  {dataConsolidated?.data && (
                    <InfiniteScroll
                      dataLength={dataConsolidated.data.length}
                      next={loadMore}
                      hasMore={dataConsolidated?.data.length < dataConsolidated?.totals.totalItems}
                      loader={
                        <TableRow>
                          <TableCell colSpan={9}>Loading...</TableCell>
                        </TableRow>
                      }
                      scrollableTarget="scrollableTable"
                    >
                      {dataConsolidated.data.map((item, index) => (
                        <TableRow
                          id={`mapping-table-row-${index}`}
                          key={item.id}
                          data-index={index}
                          className={cn('activableRow', {
                            [styles.firstLevelRow]: item.rowType === FinancialRowTypes.Total,
                            [styles.secondLevelRow]: item.rowType === FinancialRowTypes.SubTotal,
                            [styles.headerRow]: item.rowType === FinancialRowTypes.Header,
                          })}
                        >
                          <TableCell
                            className={cn('accountRow', {
                              [styles.secondLevel]: item.depth === 2,
                              [styles.thirdLevel]: item.depth === 3,
                              [styles.fourthLevel]: item.depth === 4,
                              [styles.fifthLevel]: item.depth === 5,
                              [styles.sixthLevel]: item.depth === 6,
                              activeCell: activeRows.includes(index),
                            })}
                            onClick={(event) => handleSelectActiveRow(event, index)}
                          >
                            <Tooltip title={item.income} placement={'top'}>
                              <span>{item.income}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={cn({
                              activeCell: activeRows.includes(index),
                            })}
                          >
                            <AutocompleteField
                              name={`mapping[${index}].account`}
                              key={`mapping[${index}].account`}
                              tabIndex={index}
                              disabled={isDisabled}
                              options={fieldsOptions}
                              onChange={(event, newValue) => {
                                // @ts-ignore
                                handleUpdateConsolidatedOngoingReportingMapping(
                                  item.id,
                                  // @ts-ignore
                                  newValue?.value || null,
                                )
                              }}
                              groupBy={groupBy}
                              freeSolo={false}
                              value={
                                item.field
                                  ? {
                                      value: item.field,
                                      label: CLIENT_FINANCIALS_FIELDS_MAPPING[item.field],
                                    }
                                  : null
                              }
                            />
                          </TableCell>

                          <TableCell
                            className={cn(genericSs.tableTextLeft, {
                              activeCell: activeRows.includes(index),
                            })}
                          >
                            {item.prediction &&
                              CLIENT_FINANCIALS_FIELDS_MAPPING[item.prediction] && (
                                <div
                                  className={cn({
                                    [styles.predictionCardActive]: activeRows.includes(index),
                                    [styles.predictionCard]: !activeRows.includes(index),
                                  })}
                                  onClick={() =>
                                    !isDisabled &&
                                    handleUpdateConsolidatedOngoingReportingMapping(
                                      item.id,
                                      item.prediction,
                                    )
                                  }
                                >
                                  {CLIENT_FINANCIALS_FIELDS_MAPPING[item.prediction]}
                                </div>
                              )}
                          </TableCell>
                          <TableCell
                            className={cn({
                              activeCell: activeRows.includes(index),
                            })}
                          >
                            <TaggingField
                              limitTags={1}
                              disabled={isDisabled}
                              renderTags={(tagValue, getTagProps) => {
                                return tagValue.map((option, idx) => (
                                  <Tooltip key={idx} title={option.label} placement={'top'}>
                                    <Chip
                                      {...getTagProps({ idx })}
                                      className={styles.chip}
                                      label={option.label}
                                      size="small"
                                      deleteIcon={
                                        !activeRows.includes(index) ? (
                                          <CancelIcon />
                                        ) : (
                                          <BlueCancelIcon />
                                        )
                                      }
                                    />
                                  </Tooltip>
                                ))
                              }}
                              options={tagsOptions}
                              onChange={(event, newValue) => {
                                // @ts-ignore
                                handleUpdateConsolidatedOngoingReportingTagging(
                                  item.id,
                                  // @ts-ignore
                                  newValue || null,
                                )
                              }}
                              // @ts-ignore
                              value={getTagValues(item.tags)}
                              isFreeSolo={false}
                            />
                          </TableCell>
                          {dataConsolidated &&
                            dataConsolidated.dictionary.headers.map((header, columnIndex) => (
                              <TableCell
                                key={header}
                                data-index={columnIndex}
                                onClick={(event) => handleSelectCell(event, index, columnIndex)}
                                className={cn(genericSs.tableTextRight, 'activableCell', {
                                  activeCell:
                                    activeRows.includes(index) ||
                                    activeCells[index]?.includes(columnIndex),
                                })}
                              >
                                {item.amounts[columnIndex] ? (
                                  <MultiCellTooltip
                                    isActive={activeCells[index]?.includes(columnIndex)}
                                    data={totalCell}
                                  >
                                    <div>
                                      <span
                                        className={cn(genericSs.pricePrefix, {
                                          [styles.totalRowPrefix]: [
                                            FinancialRowTypes.SubTotal,
                                            FinancialRowTypes.Total,
                                          ].includes(item.rowType),
                                        })}
                                      >
                                        $
                                      </span>
                                      {formatPriceNoDecimal(item.amounts[columnIndex])}
                                    </div>
                                  </MultiCellTooltip>
                                ) : null}
                              </TableCell>
                            ))}
                          {missedColumns.map((key) => (
                            <TableCell key={key} />
                          ))}
                        </TableRow>
                      ))}
                    </InfiniteScroll>
                  )}

                  {activeRows.length > 1 && (
                    <TableRow className="summaryRow">
                      <TableCell className={genericSs.tableTextLeft}>
                        {activeRows.length} rows selected
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />

                      {dataConsolidated &&
                        dataConsolidated.dictionary.headers.map((header, columnIndex) => (
                          <TableCell key={header} className={genericSs.tableTextRight}>
                            {summaryRow[columnIndex] ? (
                              <>
                                <span className={genericSs.pricePrefix}>$</span>
                                {formatPriceNoDecimal(summaryRow[columnIndex])}
                              </>
                            ) : null}
                          </TableCell>
                        ))}

                      {missedColumns.map((key) => (
                        <TableCell key={key} />
                      ))}
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Box display="flex" position="relative" justifyContent="flex-end" alignItems="center">
                {dataConsolidated?.totals.totalItems > 0 && (
                  <div className={styles.itemsCount}>
                    {dataConsolidated.data.length} / {dataConsolidated.totals.totalItems}
                  </div>
                )}
              </Box>
            </TableContainer>
          </Card>
        </Grid>
      </FullscreenModal>
    </Grid>
  )
}

export default OngoingReportingMapping
