import React, { useCallback, useMemo, useState } from 'react'
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from 'mui-rff'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './Autocomplete.module.scss'
import DropDownArrow from '../DropDownArrow'
import { debounceEventHandler } from '../../../helpers/helpers'

interface OptionType {
  label?: string
  value: string
  icon?: React.ReactNode
  disabled?: boolean
  className?: string
}

export interface IProps extends AutocompleteProps<OptionType, false, boolean, boolean> {
  isAsync?: boolean
  autocompleteSize?: 'small' | 'big' | 'allTags'
  loadOptions?: (value: string) => Promise<OptionType[]>
  withBorder?: boolean
  withTopLabel?: boolean
  hideTags?: boolean
  isFilterMenu?: boolean
  startAdornment?: React.ReactNode
  isSelectedValues?: boolean
}

export const getOptionValue = (option: any) => option?.value || option

const Autocomplete = ({
  options,
  className,
  isAsync = false,
  loadOptions,
  autocompleteSize = 'small',
  limitTags = 2,
  withBorder = false,
  withTopLabel = false,
  hideTags = false,
  isFilterMenu = false,
  name,
  isSelectedValues,
  ...restProps
}: IProps) => {
  const [asyncOptions, setAsyncOptions] = useState<OptionType[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const handleInputChange = useCallback(
    async (
      event: React.ChangeEvent<{}>,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (!isAsync) {
        return
      }
      if (reason === 'input') {
        if (inputValue.length > 1) {
          const loadedOptions = await loadOptions(inputValue)
          setAsyncOptions(loadedOptions)
        } else {
          setAsyncOptions([])
        }
      }
    },
    [isAsync, loadOptions],
  )
  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  const isTopLabelShown = useMemo(() => {
    return (withTopLabel && isFilterMenu && isSelectedValues) || isOpen
  }, [withTopLabel, isSelectedValues, isOpen, isFilterMenu])

  const textFieldProps = useMemo(
    () => ({
      error: !!restProps.helperText,
      helperText: restProps.helperText,
      variant: withTopLabel ? 'outlined' : 'standard',
      ...(withTopLabel && { label: restProps.label }),
    }),
    [withTopLabel, restProps],
  )
  const sortedOptions = useMemo(
    () =>
      ((isAsync ? asyncOptions : options) || []).sort((a) => {
        if (Array.isArray(restProps.value)) {
          const isSelected = !!restProps?.value?.find(({ value }) => value === a.value)
          if (isSelected) return -1
          if (!isSelected) return 1
        }

        return 0
      }),
    [isAsync, asyncOptions, options, restProps.value],
  )

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <div className={cn({ [styles.filterBox]: isFilterMenu })}>
      <MuiAutocomplete
        key={options?.length}
        {...restProps}
        handleHomeEndKeys
        name={name}
        options={sortedOptions}
        getOptionLabel={(option) =>
          typeof option === 'object' ? option?.label || '' : option || ''
        }
        isOptionEqualToValue={(option: any, value: any) =>
          option?.value === (value && value?.value ? value.value : value)
        }
        className={cn(styles.select, className, 'commonFilter', {
          [styles.big]: autocompleteSize === 'big',
          [styles.allTags]: autocompleteSize === 'allTags',
          [styles.withBorderSelect]: withBorder,
          [styles.withTopLabel]: withTopLabel && !isFilterMenu,
          [styles.withTopLabelFilter]: isTopLabelShown,
          [styles.filterLabel]: isFilterMenu,
          [styles.borderSelected]: isFilterMenu && isSelectedValues && !isOpen,
          [styles.borderStandard]: isFilterMenu && !isOpen && !isSelectedValues,
          [styles.hideTags]: hideTags,
        })}
        classes={{
          focused: styles.focused,
          inputRoot: styles.inputRoot,
          input: styles.input,
          tag: styles.tag,
          paper: styles.paper,
          listbox: styles.listbox,
          option: styles.option,
          endAdornment: styles.endAdornment,
          clearIndicator: styles.clearIndicator,
          popupIndicator: styles.popupIndicator,
          popupIndicatorOpen: styles.popupIndicatorOpen,
          popper: 'commonFilter',
        }}
        getLimitTagsText={hideTags ? (more) => `${more} Selected` : undefined}
        limitTags={hideTags || isFilterMenu ? 0 : limitTags}
        // @ts-ignore
        renderInput={(params: any) => {
          return (
            <TextField
              className={cn({
                [styles.textContainerFilter]: isFilterMenu,
              })}
              {...params}
              {...textFieldProps}
              placeholder={isFilterMenu && isOpen ? null : restProps.placeholder}
              classes={{
                placeholder: styles.placeholder,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {(params.InputProps.startAdornment === undefined || !isSelectedValues) &&
                      !isOpen &&
                      restProps.startAdornment}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Tooltip title={typeof option === 'string' ? option : option.label} placement="top">
              <Chip
                label={typeof option === 'string' ? option : option.label}
                {...getTagProps({ index })}
              />
            </Tooltip>
          ))
        }
        renderOption={(props, option) => (
          <li {...props} key={typeof option === 'string' ? option : option.value}>
            {typeof option !== 'string' && option.icon ? option.icon : null}
            <span
              className={cn(styles.optionLabel, {
                [styles.optionIconLabel]: typeof option !== 'string' && option.icon,
              })}
            >
              {typeof option === 'string' ? option : option.label}
            </span>
          </li>
        )}
        popupIcon={
          !isFilterMenu ? (
            <DropDownArrow
              className={cn({
                [styles.bigArrow]: autocompleteSize === 'big',
                [styles.arrow]: autocompleteSize !== 'big',
              })}
            />
          ) : null
        }
        onInputChange={debounceHandleInputChange}
        disableCloseOnSelect={restProps.multiple}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </div>
  )
}

export default Autocomplete
