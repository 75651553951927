import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ClientTermLoanAmortization from './ClientTermLoanAmortization'
import {
  createTermLoanPayment,
  deleteTermLoanPayment,
  updateTermLoanPayment,
} from '../../../redux/clientInfo/actions'
import { loanBalanceStartDate } from '../../../redux/loanBalance/selectors'

const selector = createStructuredSelector({
  loanBalanceStartDate,
})

const actions = {
  createTermLoanPayment,
  deleteTermLoanPayment,
  updateTermLoanPayment,
}

export default connect(selector, actions)(ClientTermLoanAmortization)
