import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import styles from './FilterContainer.module.scss'
import Grid from '@mui/material/Grid'
import Button from '../../Common/Button'
import { IFilter, IQuickFilter } from '@common/constants/filters'
import FilterButton from '../FilterButton'
import FilterItems from '../FilterItems'
import FilterMenu from '../FilterMenu'
import TextField from '../../Common/TextField'
import { OnChange } from 'react-final-form-listeners'
import { ReactComponent as SearchIcon } from '@assets/images/search-icon.svg'
import cn from 'classnames'
import IconButton from '../../Common/IconButton'
import ExportButton from '../../Common/ExportButton'

interface IProps {
  filters: IFilter[]
  mutators?: any
  handleSubmit: () => void
  handleExportAggregation?: () => Promise<void>
  values: any
  appliedFilters?: any
  appliedQuickFilter?: string | null
  handleAppliedQuickFilterChange?: (quickFilter: string | null) => void
  withFullSearch?: boolean
  title?: string | React.ReactNode
  actions?: React.ReactNode
  additionalActions?: React.ReactNode
  editTitle?: React.ReactNode
  filtersSize?: number
  actionsSize?: number
  actionsInsideFilters?: boolean
  withFilters?: boolean
  withFilterItems?: boolean
  size?: 'small' | 'big'
  smallFilter?: boolean
  filterMenuClassName?: string
}

const FilterContainer = ({
  filters: filtersConfig,
  handleSubmit,
  mutators,
  values,
  appliedFilters,
  appliedQuickFilter = null,
  handleAppliedQuickFilterChange,
  handleExportAggregation,
  withFullSearch = true,
  title = '',
  actions,
  additionalActions,
  filtersSize = 4,
  actionsSize = 7,
  actionsInsideFilters = false,
  withFilters = true,
  withFilterItems = true,
  size = 'big',
  smallFilter = false,
  editTitle,
  filterMenuClassName,
}: IProps) => {
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isFilterMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    isSearchOpen && inputRef.current.focus()
  }, [isSearchOpen])

  const quickFilters = useMemo(
    () => filtersConfig.find((filter) => filter.type === 'quickFilter')?.quickFilters || [],
    [filtersConfig],
  )

  const appliedQuickFilterItem = useMemo(
    () => quickFilters.find((quickFilter) => quickFilter.title === appliedQuickFilter),
    [quickFilters, appliedQuickFilter],
  )

  const filters = useMemo(
    () => filtersConfig.filter((filter) => filter.type !== 'quickFilter'),
    [filtersConfig],
  )

  const numFilters = useMemo(() => {
    if (!appliedFilters && !appliedQuickFilterItem) {
      return 0
    }
    let length = 0
    filters.forEach((filter) => {
      if (['date', 'datetime'].includes(filter.type)) {
        if (appliedFilters[`${filter?.field}From`] || appliedFilters[`${filter?.field}To`]) {
          length++
        }
      } else if (['amount', 'number', 'percent'].includes(filter.type)) {
        if (appliedFilters[`${filter?.field}Min`] || appliedFilters[`${filter?.field}Max`]) {
          length++
        }
      } else {
        if (
          appliedFilters[`${filter?.field}`]?.length > 0 &&
          (filter.type !== 'list' || !!filter.options)
        ) {
          length++
        } else if (
          (appliedFilters[`${filter?.field}`] === true ||
            appliedFilters[`${filter?.field}`] === false) &&
          filter.type === 'list'
        ) {
          length++
        }
      }
    })
    if (appliedFilters.isCustom) {
      length++
    }
    if (appliedQuickFilterItem && appliedQuickFilterItem.isHidden) {
      length++
    }
    return length
  }, [appliedFilters, filters, appliedQuickFilterItem])

  const openSearch = useCallback(() => {
    setIsSearchOpen(true)
  }, [])

  const closeSearch = useCallback(() => {
    setIsSearchOpen(false)
  }, [])

  const toggleIsFilterMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((anchorEl: HTMLButtonElement) => (anchorEl ? null : event.currentTarget))
  }, [])

  const handleCloseFilterMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClearAllFilters = useCallback(
    (event, withSubmit: boolean = true) => {
      filters.forEach((filter) => {
        if (['date', 'datetime'].includes(filter.type)) {
          mutators.setFieldData(`${filter.field}From`, null)
          mutators.setFieldData(`${filter.field}To`, null)
        } else if (['amount', 'number', 'percent'].includes(filter.type)) {
          mutators.setFieldData(`${filter.field}Min`, null)
          mutators.setFieldData(`${filter.field}Max`, null)
        } else if (
          filter.type === 'autocomplete' ||
          (filter.type === 'list' && filter.isMultiple)
        ) {
          mutators.setFieldData(filter.field, [])
        } else {
          mutators.setFieldData(filter.field, null)
        }
        mutators.setFieldData('fullSearch', null)
      })
      if (appliedQuickFilterItem) {
        Object.keys(appliedQuickFilterItem.filters).forEach((field) => {
          mutators.setFieldData(
            field,
            Array.isArray(appliedQuickFilterItem.filters[field]) ? [] : null,
          )
        })
        handleAppliedQuickFilterChange && handleAppliedQuickFilterChange(null)
      }
      if (withSubmit) {
        handleSubmit()
      }
    },
    [handleSubmit, filters, mutators, appliedQuickFilterItem, handleAppliedQuickFilterChange],
  )

  const hasFilters = useMemo(
    () =>
      filters
        .filter((filter) => !filter.excludeFilter)
        .filter((filter) => filter.type !== 'quickFilter')
        .filter(
          (filter) =>
            (filter.type === 'text' && filter.excludeFullSearchFilter) ||
            (filter.type === 'list' && filter.options) ||
            ['autocomplete', 'amount', 'number', 'percent', 'date', 'datetime'].includes(
              filter.type,
            ),
        ).length > 0,
    [filters],
  )

  const handleExport = useCallback(async () => {
    setIsExportLoading(true)
    await handleExportAggregation()
    setIsExportLoading(false)
  }, [handleExportAggregation])

  const handleFilterApply = useCallback(async () => {
    if (!appliedQuickFilterItem?.isHidden) {
      handleAppliedQuickFilterChange && (await handleAppliedQuickFilterChange(null))
    }
    await handleSubmit()
  }, [handleSubmit, appliedQuickFilterItem, handleAppliedQuickFilterChange])

  const handleQuickFilterApply = useCallback(
    (quickFilter: IQuickFilter) => {
      if (quickFilter.title === appliedQuickFilter) {
        Object.keys(quickFilter.filters).forEach((field) => {
          mutators.setFieldData(field, Array.isArray(quickFilter.filters[field]) ? [] : null)
        })
        handleAppliedQuickFilterChange && handleAppliedQuickFilterChange(null)
      } else {
        handleClearAllFilters(null, false)
        Object.keys(quickFilter.filters).forEach((field) => {
          mutators.setFieldData(field, quickFilter.filters[field])
        })
        handleAppliedQuickFilterChange && handleAppliedQuickFilterChange(quickFilter.title)
      }
      handleSubmit()
    },
    [
      mutators,
      handleSubmit,
      appliedQuickFilter,
      handleClearAllFilters,
      handleAppliedQuickFilterChange,
    ],
  )

  const handleQuickFilterClear = useCallback(
    (quickFilter: IQuickFilter) => {
      Object.keys(quickFilter.filters).forEach((field) => {
        mutators.setFieldData(field, Array.isArray(quickFilter.filters[field]) ? [] : null)
      })
      handleAppliedQuickFilterChange && handleAppliedQuickFilterChange(null)
      handleSubmit()
    },
    [mutators, handleSubmit, handleAppliedQuickFilterChange],
  )

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        pt={1}
        data-search={isSearchOpen || values?.fullSearch ? 'open' : ''}
      >
        <Grid item container xs={12} pb={2}>
          <Grid container item xs={12 - actionsSize} alignItems="center">
            {title && (
              <span
                className={cn(styles.title, {
                  [styles.smallTitle]: smallFilter,
                })}
              >
                {title}
              </span>
            )}
            {editTitle}

            {withFullSearch && (
              <div
                className={cn(styles.searchContainer, {
                  [styles.searchContainerOpen]: isSearchOpen,
                })}
              >
                <TextField
                  name="fullSearch"
                  placeholder="Search"
                  className={cn(styles.searchField, {
                    [styles.searchFieldOpen]: isSearchOpen || values?.fullSearch,
                    [styles.searchFieldFocused]: isSearchOpen,
                  })}
                  size="big"
                  onBlur={closeSearch}
                  onFocus={openSearch}
                  inputRef={inputRef}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: isSearchOpen ? (
                      <SearchIcon className={styles.searchIcon} />
                    ) : null,
                  }}
                />
                <OnChange name="fullSearch">
                  {() => {
                    handleSubmit()
                  }}
                </OnChange>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  className={cn(styles.searchButton, {
                    [styles.hiddenSearchButton]: isSearchOpen || values?.fullSearch,
                    [styles.smallFilter]: smallFilter,
                  })}
                  onClick={openSearch}
                >
                  <SearchIcon />
                </IconButton>
              </div>
            )}
            {withFilters && hasFilters && !isSearchOpen && (
              <FilterButton
                smallFilter={smallFilter}
                isFilterMenuOpen={isFilterMenuOpen}
                toggleIsFilterMenuOpen={toggleIsFilterMenuOpen}
                numFilters={numFilters}
                values={values}
                size={size}
              />
            )}
            {actions && actionsInsideFilters && !isSearchOpen && actions}
            {(numFilters > 0 || values?.fullSearch) && !isSearchOpen && (
              <Button
                className={styles.clearAllButton}
                type="button"
                color="primary"
                variant="contained"
                size="small"
                onClick={handleClearAllFilters}
              >
                Clear all
              </Button>
            )}
            {additionalActions && !isSearchOpen && (
              <div className={styles.spaceBeforeAdditionalActions} />
            )}
            {additionalActions && !isSearchOpen && additionalActions}
          </Grid>
          <Grid
            item
            xs={actionsSize}
            container
            justifyContent="flex-end"
            alignItems="flex-start"
            gap={1}
          >
            {actions && !actionsInsideFilters && actions}
            {handleExportAggregation && (
              <ExportButton isLoading={isExportLoading} handleExport={handleExport} />
            )}
          </Grid>
        </Grid>

        {isFilterMenuOpen && (
          <FilterMenu
            isOpen={isFilterMenuOpen}
            anchorEl={anchorEl}
            onClose={handleCloseFilterMenu}
            className={filterMenuClassName}
            filters={filters}
            handleSubmit={handleFilterApply}
            values={values}
            appliedFilters={appliedFilters}
            filtersSize={filtersSize}
            quickFilters={quickFilters}
            appliedQuickFilter={appliedQuickFilter}
            handleQuickFilterApply={handleQuickFilterApply}
          />
        )}
      </Grid>

      {withFilterItems && numFilters > 0 && (
        <>
          <Grid item xs={12}>
            <FilterItems
              filters={filters}
              handleSubmit={handleSubmit}
              mutators={mutators}
              appliedFilters={appliedFilters}
              appliedQuickFilter={appliedQuickFilterItem}
              values={values}
              handleQuickFilterClear={handleQuickFilterClear}
            />
          </Grid>
          <br />
        </>
      )}
    </>
  )
}

export default FilterContainer
