import React, { ChangeEvent } from 'react'
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'
import { FieldInputProps, FieldMetaState } from 'react-final-form'
import { Checkboxes } from 'mui-rff'

import styles from './Checkbox.module.scss'

import { ReactComponent as UncheckedIcon } from '../../../assets/images/checkbox-unchecked-icon.svg'
import { ReactComponent as CheckedIcon } from '../../../assets/images/checkbox-checked-icon.svg'
import { ReactComponent as DisabledUncheckedIcon } from '../../../assets/images/checkbox-unchecked-disabled-icon.svg'
import { ReactComponent as DisabledCheckedIcon } from '../../../assets/images/checkbox-checked-disabled-icon.svg'
import { handleStopPropagation } from '../../../helpers/helpers'

interface IProps extends CheckboxProps {
  checked?: boolean
  disabled?: boolean
  input?: FieldInputProps<boolean, HTMLElement>
  meta?: FieldMetaState<boolean>
  label?: string
  reactFinalForm?: boolean
}

const Checkbox = ({ input, onChange, name, reactFinalForm = false, ...props }: IProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    input?.onChange(event)
    // @ts-ignore
    input?.onBlur(event)

    if (onChange) {
      onChange(event, checked)
    }
  }
  if (reactFinalForm) {
    return (
      <Checkboxes
        name={name}
        classes={{ root: styles.root }}
        className={props.className}
        color="primary"
        disableFocusRipple
        icon={props.disabled ? <DisabledUncheckedIcon /> : <UncheckedIcon />}
        checkedIcon={props.disabled ? <DisabledCheckedIcon /> : <CheckedIcon />}
        disableRipple
        disabled={props.disabled}
        data={{
          label: props.label,
          value: true,
        }}
        onClick={handleStopPropagation}
      />
    )
  }

  return (
    <MuiCheckbox
      {...props}
      checked={input?.checked || props.checked}
      classes={{ root: styles.root }}
      color="primary"
      onChange={handleChange}
      icon={props.disabled ? <DisabledUncheckedIcon /> : <UncheckedIcon />}
      checkedIcon={props.disabled ? <DisabledCheckedIcon /> : <CheckedIcon />}
      onClick={handleStopPropagation}
    />
  )
}

export default Checkbox
