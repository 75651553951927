import Base from './base'

export default class Prospect extends Base {
  list(params: object) {
    return this.apiClient.get('prospects', params)
  }

  create(data: object) {
    return this.apiClient.post('prospects', data)
  }

  show(id: string) {
    return this.apiClient.get(`prospects/${id}`)
  }

  showFoldersInfo(id: string) {
    return this.apiClient.get(`prospects/${id}/folders`)
  }

  update(id: string, data: object) {
    return this.apiClient.patch(`prospects/${id}`, data)
  }

  startReporting(id: string, data?: object) {
    return this.apiClient.post(`prospects/${id}`, data)
  }

  updateReporting(id: string, data: object) {
    return this.apiClient.put(`prospects/${id}`, data)
  }

  listInventory(id: string, params: object = {}) {
    return this.apiClient.get(`prospects/${id}/inventory`, params)
  }

  listArSummary(id: string, params?: object) {
    return this.apiClient.get(`prospects/ar/summary/${id}`, params)
  }

  updateArSummary(id: string, data: object) {
    return this.apiClient.put(`prospects/ar/summary/${id}`, data)
  }
  listApSummary(id: string, params?: object) {
    return this.apiClient.get(`prospects/ap/summary/${id}`, params)
  }
  updateDate(data: object) {
    return this.apiClient.put(`prospects/date`, data)
  }
  getDate(data: object) {
    return this.apiClient.get(`prospects/date`, data)
  }
  updateFinancialsDates(data: object) {
    return this.apiClient.put(`prospects/financials-dates`, data)
  }
  listProspectReporting(data: object) {
    return this.apiClient.get(`prospects/reporting`, data)
  }
  pullReports(id: string, params?: object) {
    return this.apiClient.get(`prospects/${id}/reports`, params)
  }
  calculateOpsFlags(id: string) {
    return this.apiClient.get(`prospects/${id}/flags-calculate`)
  }
  createProspectTerms(id: string, data: object) {
    return this.apiClient.post(`prospects/${id}/terms`, data)
  }
  updateProspectTerms(id: string, prospectTermsId: string, data: object) {
    return this.apiClient.put(`prospects/${id}/terms/${prospectTermsId}`, data)
  }
  listProspectTerms(id: string) {
    return this.apiClient.get(`prospects/${id}/terms`)
  }
  deleteProspectTerms(id: string, prospectTermsId: string) {
    return this.apiClient.delete(`prospects/${id}/terms/${prospectTermsId}`)
  }
  generateTermSheet(id: string, prospectTermsId: string) {
    return this.apiClient.get(`prospects/${id}/terms/${prospectTermsId}/termsheet`)
  }

  updateReportingFlowAdditionalData(id: string, flowId: string, data: object) {
    return this.apiClient.put(`prospects/${id}/${flowId}`, data)
  }

  showInventorySummaryInfo(id: string) {
    return this.apiClient.get(`prospects/${id}/summary/inventory`)
  }

  showCashFlowSummaryInfo(id: string, params: object) {
    return this.apiClient.get(`prospects/${id}/summary/cash-flow`, params)
  }

  showTermStructureSummaryInfo(id: string) {
    return this.apiClient.get(`prospects/${id}/summary/term-structure`)
  }
}
