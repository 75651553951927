import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const typeOptions = (state: IRootState) => state[prefix].typeOptions

export const typeMapping = (state: IRootState) => state[prefix].typeMapping

export const dilutionByCustomer = (state: IRootState) => state[prefix].dilutionByCustomer

export const dilutionOverTime = (state: IRootState) => state[prefix].dilutionOverTime
