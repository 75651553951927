import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Modal from '../../Common/Modal'
import { Form } from 'react-final-form'
import {
  IParticipation,
  IParticipationWire,
  ParticipationWireDirections,
  ParticipationWireTypes,
} from '@common/interfaces/participant'
import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import { Box } from '@mui/material'
import FormField from '../../Common/FormField'
import moment from 'moment'
import Button from '../../Common/Button'
import WarningModal from '../../WarningModal'
import { OnChange } from 'react-final-form-listeners'

interface IProps {
  clientId: string
  effectiveParticipations: IParticipation[]
  loanBalanceStartDate: string
  createParticipationWire?: (id: string, participationId: string, data: object) => void
  updateParticipationWire?: (
    id: string,
    participationId: string,
    participationWireId: string,
    data: object,
  ) => void
  participationWire?: IParticipationWire
  handleCloseModal: () => void
}

const today = moment()

const baseDirectionOptions = Object.values(ParticipationWireDirections).map((direction) => ({
  label: direction,
  value: direction,
}))

const CreateAndEditParticipationWireModal = ({
  clientId,
  effectiveParticipations,
  loanBalanceStartDate,
  participationWire,
  createParticipationWire,
  updateParticipationWire,
  handleCloseModal,
}: IProps) => {
  const formRef = useRef(null)
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [directionOptions, setDirectionOptions] = useState(baseDirectionOptions)

  const handleSubmit = useCallback(
    async (values) => {
      if (!isConfirmed && !moment(values.recordDate).isSame(today, 'day')) {
        setIsConfirmModalShown(true)
        return
      }

      const recordDate = moment(values.recordDate).toISOString()
      const data = {
        ...values,
        recordDate,
      }
      setIsLoading(true)
      if (participationWire) {
        await updateParticipationWire(clientId, values.participant, participationWire.id, data)
      } else {
        await createParticipationWire(clientId, values.participant, data)
      }
      setIsLoading(false)
      handleCloseModal()
    },
    [
      clientId,
      isConfirmed,
      handleCloseModal,
      createParticipationWire,
      updateParticipationWire,
      participationWire,
    ],
  )

  useEffect(() => {
    if (isConfirmed) {
      formRef?.current?.submit()
      setIsConfirmed(false)
    }
  }, [isConfirmed, formRef])

  const handleConfirmConfirm = useCallback(() => {
    setIsConfirmModalShown(false)
    setIsConfirmed(true)
  }, [])

  const handleConfirmCancel = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [])

  const validate = useMemo(() => {
    const schema = Yup.object().shape({
      participant: Yup.string().required('Required'),
      recordDate: Yup.date()
        .typeError('Please type date in MM/DD/YY format')
        .required('Required')
        .min(moment(loanBalanceStartDate || undefined).toDate(), 'Cannot be in a locked month'),
      amount: Yup.number().required('Required').min(1, 'Cannot be negative or zero'),
      type: Yup.string().required('Required'),
      direction: Yup.string().required('Required'),
    })
    return makeValidate(schema)
  }, [loanBalanceStartDate])

  const initialValues = useMemo(() => {
    if (participationWire) {
      return {
        participant: participationWire.participation.id,
        recordDate: participationWire.recordDate,
        amount: participationWire.amount,
        type: participationWire.type,
        direction: participationWire.direction,
      }
    }
    return {
      participant: '',
      recordDate: today.format('YYYY-MM-DD'),
      amount: '',
      type: '',
      direction: '',
    }
  }, [participationWire])

  const handleChangeType = useCallback(
    (value) => {
      if (value === ParticipationWireTypes.Interest || value === ParticipationWireTypes.OtherFee) {
        setDirectionOptions([
          {
            label: ParticipationWireDirections.Outgoing,
            value: ParticipationWireDirections.Outgoing,
          },
        ])
        formRef.current.change('direction', ParticipationWireDirections.Outgoing)
      } else {
        setDirectionOptions(baseDirectionOptions)
      }
    },
    [formRef],
  )

  const actionTerm = useMemo(() => (participationWire ? 'Update' : 'Create'), [participationWire])

  return (
    <Modal open onCancel={handleCloseModal} title={`${actionTerm} Participant Wire`} size="small">
      <Form
        validate={validate}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ submitting, invalid, handleSubmit, form, dirty }) => {
          formRef.current = form

          return (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={3}>
                <Box flex={1}>
                  <FormField
                    labelSize="big"
                    name="participant"
                    label="Participant"
                    type="select"
                    options={effectiveParticipations.map((participation) => ({
                      label: participation.participant,
                      value: participation.id,
                    }))}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    labelSize="big"
                    name="recordDate"
                    label="Record Date"
                    type="date"
                    minDate={moment(loanBalanceStartDate || undefined).toDate()}
                  />
                </Box>
                <Box flex={1}>
                  <FormField name="amount" label="Amount" type="currency" labelSize="big" />
                </Box>
                <Box flex={1}>
                  <FormField
                    labelSize="big"
                    name="type"
                    label="Type"
                    type="select"
                    options={Object.values(ParticipationWireTypes).map((type) => ({
                      label: type,
                      value: type,
                    }))}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    labelSize="big"
                    name="direction"
                    label="Direction"
                    type="select"
                    options={directionOptions}
                  />
                </Box>
                <OnChange name="type">{handleChangeType}</OnChange>

                <Box display="flex">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting || invalid || !dirty}
                    fullWidth
                    small={false}
                    isLoading={isLoading}
                    onClick={handleSubmit}
                  >
                    {actionTerm}
                  </Button>
                </Box>
              </Box>
            </form>
          )
        }}
      />

      {isConfirmModalShown && (
        <WarningModal
          warningMessage="Are you sure you want to apply this? It is not set for today."
          onConfirm={handleConfirmConfirm}
          onCancel={handleConfirmCancel}
          confirmText="Apply"
          cancelText="Cancel"
        />
      )}
    </Modal>
  )
}

export default CreateAndEditParticipationWireModal
