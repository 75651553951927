import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { matchPath } from 'react-router'
import { trackActivity } from '../../redux/activity/actions'

import OngoingReportingPage from './OngoingReportingPage'
import {
  dataConsolidated,
  dataSummary,
  isLoading,
  ongoingReporting,
  reportingUpdatesList,
} from '../../redux/ongoingReporting/selectors'
import {
  updateOngoingReporting,
  submitOngoingReporting,
  showOngoingReporting,
} from '../../redux/ongoingReporting/actions'
import { pathname } from '../../redux/router/selectors'
import { ROUTES } from '../../constants/routes'
import { OngoingReportingType } from '@common/interfaces/bbc'

const selector = createStructuredSelector({
  isLoading,
  statementType: createSelector(pathname, (path) =>
    matchPath(path, { path: ROUTES.ONGOING_REPORTING_BALANCE_SHEET })
      ? OngoingReportingType.BalanceSheet
      : matchPath(path, { path: ROUTES.ONGOING_REPORTING_INCOME_STATEMENT })
      ? OngoingReportingType.IncomeStatement
      : null,
  ),
  data: ongoingReporting,
  dataConsolidated,
  dataSummary,
  reportingUpdatesList,
})

const actions = {
  showOngoingReporting,
  updateOngoingReporting,
  submitOngoingReporting,
  trackActivity,
}

export default connect(selector, actions)(OngoingReportingPage)
